import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import { TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide';
import CloseButton from '../../shared/CloseButton';
import Details from './Details';
import History from './History';
import Style from './styles/FileInformation.module.scss';
import AppStore from '../../../stores/AppStore';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';

const FileInformation: FC = () => {
  useEffect(() => {
    // close when component is destoryed
    return () => FileInformationStore.close();
  }, []);

  if (FileInformationStore.file === null) return null;

  if (AppStore.client?.user.clientUserRole === ClientUserRole.External) {
    return (
      <>
        <CloseButton onClick={FileInformationStore.close} />
        <h2 className={Style.heading}>{FileInformationStore.file.title}</h2>
        <TabMenu cssClass={Style.tab} type="line" tabs={[{ id: 1, label: `Details` }]}>
          <TabContent for={1}>
            <div className={Style.tabContainer}>
              <div className={Style.scrollableContainer}>
                <Details />
              </div>
            </div>
          </TabContent>
        </TabMenu>
      </>
    );
  }

  return (
    <>
      <CloseButton onClick={FileInformationStore.close} />
      <h2 className={Style.heading}>{FileInformationStore.file.title}</h2>
      <TabMenu
        cssClass={Style.tab}
        type="line"
        tabs={[
          { id: 1, label: `Details` },
          { id: 2, label: `History` },
        ]}>
        <TabContent for={1}>
          <div className={Style.tabContainer}>
            <div className={Style.scrollableContainer}>
              <Details />
            </div>
          </div>
        </TabContent>

        <TabContent for={2}>
          <div className={Style.tabContainer}>
            <div className={Style.scrollableContainer}>
              <History />
            </div>
          </div>
        </TabContent>
      </TabMenu>
    </>
  );
};

export default observer(FileInformation);
