import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import { ComboBox, Grid, Icon, IOption, Toggle } from '@aurecon-creative-technologies/styleguide';
import TeamChartStore from './TeamChartStore';
import SearchBar from '../shared/SearchBar';

interface ITeamChartFilterProps {
  disableFilter: boolean;
}
const TeamChartFilter: FC<ITeamChartFilterProps> = (props) => {
  const { disableFilter } = props;
  return (
    <div className={Style.topFilter}>
      <Grid row gap={8} cssClass={Style.rowItem}>
        <Grid item md={6}>
          <div className={Style.headline}>
            Notifying these team members will require their attention and they will appear in the visibility list.
          </div>
        </Grid>
        <Grid item md={6}>
          <SearchBar
            cssClass={Style.searchBox}
            searchValue={TeamChartStore.keywords}
            onSearch={(keyword) => {
              TeamChartStore.setSearchText(keyword);
            }}
            placeHolderText="Search for user name, user email"
          />
        </Grid>
      </Grid>
      <Grid row gap={8} cssClass={`${Style.rowItem}`}>
        <Grid item md={6}>
          <ComboBox
            label="Delivery Team"
            placeholder="Select a Delivery Team"
            disabled={disableFilter}
            showIcon={true}
            icon="person"
            cssClass={Style.teamDropdown}
            selected={TeamChartStore.selectedDeliveryTeamId?.toString()}
            onClear={TeamChartStore.setSelectedDeliveryId}
            onSelect={(item) => TeamChartStore.setSelectedDeliveryId(Number(item?.id))}
            options={TeamChartStore.deliveryTeamOptions.map<IOption>((o) => {
              return {
                ...o,
                display: (
                  <span>
                    <Icon type="person" /> {o.value}
                  </span>
                ),
              };
            })}
          />
        </Grid>
        <Grid item md={6}>
          <ComboBox
            label="Task Team"
            placeholder="Select a Task Team"
            icon="person"
            showIcon={true}
            disabled={disableFilter}
            cssClass={Style.teamDropdown}
            selected={TeamChartStore.selectedTaskTeamId?.toString()}
            options={TeamChartStore.getTaskTeamDropdownOptions.map<IOption>((o) => {
              return {
                ...o,
                display: (
                  <span>
                    <Icon type="person" /> {o.value}
                  </span>
                ),
              };
            })}
            onSelect={(item) => {
              TeamChartStore.setSelectedTaskTeamId(Number(item?.id));
            }}
            onClear={TeamChartStore.setSelectedTaskTeamId}
          />
        </Grid>
        <Grid item md={12}>
          <Toggle label={'Tree Diagram'} hideValue disabled={true} labelPosition="left" />
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(TeamChartFilter);
