import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import BuildingExplorer from '@arcgis/core/widgets/BuildingExplorer';
import VisualisationStore from '../VisualisationStore';

import WidgetBase from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';

const BuildingExplorerWidget: FC = () => {
  const buildingLayers = VisualisationStore.buildingLayers;

  return WidgetBase(BuildingExplorer, { visible: true, layers: buildingLayers }, Style.measurementWidgetWrapper);
};

export default observer(BuildingExplorerWidget);
