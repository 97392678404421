import { flatMap } from 'lodash';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { getClientInfo, IClient } from '../api/authenticated/config/getClientInfo';
import { getAppSettings, IAppConfiguration } from '../api/unauthenticated/config/getAppSettings';

class AppStore {
  constructor() {
    makeAutoObservable(this, { projects: computed }, { autoBind: true });
  }

  public settings?: IAppConfiguration;
  public hasInit = false;
  public client?: IClient;
  public hasClientInfoError = false;
  public disableNavigation = false;
  public programmeId?: number;
  public projectNumber?: string;
  public deliveryTeamId?: number;
  public deliveryTeamCode?: string;
  public taskTeamId?: number;
  public taskTeamCode?: string;

  public loadSettings() {
    return getAppSettings().then((settings) => {
      runInAction(() => {
        this.settings = settings;
      });
      return settings;
    });
  }

  public init() {
    getClientInfo()
      .then((res) => {
        runInAction(() => {
          this.client = res;
          this.hasInit = true;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.hasClientInfoError = true;
        });
      });
  }

  public get projectAdminister() {
    return flatMap(this.client?.programmes, (programme) => programme.projects).some((e) => e.canAdminister == true);
  }

  public get projects() {
    return flatMap(this.client?.programmes, (programme) => programme.projects);
  }

  public setDisableNavigation(disable: boolean) {
    runInAction(() => {
      this.disableNavigation = disable;
    });
  }

  public setProgrammeId(programmeId?: number) {
    runInAction(() => {
      this.programmeId = programmeId;
    });
  }

  public setProjectNumber(projectNumber?: string) {
    runInAction(() => {
      this.projectNumber = projectNumber;
    });
  }

  public setDeliveryTeamId(deliveryTeamId?: number) {
    runInAction(() => {
      this.deliveryTeamId = deliveryTeamId;
    });
  }

  public setDeliveryTeamCode(deliveryTeamCode?: string) {
    runInAction(() => {
      this.deliveryTeamCode = deliveryTeamCode;
    });
  }

  public setTaskTeamId(taskTeamId?: number) {
    runInAction(() => {
      this.taskTeamId = taskTeamId;
    });
  }

  public setTaskTeamCode(taskTeamCode?: string) {
    runInAction(() => {
      this.taskTeamCode = taskTeamCode;
    });
  }

  public get selectedProjectNumber() {
    return this.projectNumber;
  }

  public get selectedProgrammeId() {
    return this.programmeId;
  }

  public get selectedDeliveryTeamId() {
    return this.deliveryTeamId;
  }

  public get selectedDeliveryTeamCode() {
    return this.deliveryTeamCode;
  }

  public get selectedTaskTeamId() {
    return this.taskTeamId;
  }

  public get selectedTaskTeamCode() {
    return this.taskTeamCode;
  }
}

export default new AppStore();
