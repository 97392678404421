import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import { TaskTypeText } from '../../../common/constants/TaskTypeText';
import TaskStatusIndicator from '../../shared/TaskStatusIndicator';
import { ITaskHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import HistoryDate from './HistoryDate';
import { ITaskEventGroup } from './TaskEventGroup';
import { groupBy, orderBy } from 'lodash';
import TaskEventRejected from './TaskEventRejected';
import TaskEventApproved from './TaskEventApproved';
import TaskEventReassigned from './TaskEventReassigned';
import TaskEventCreated from './TaskEventCreated';
import Style from './styles/TaskExpanded.module.scss';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../common/constants/Pages';

export interface ITaskExpandedProps {
  task: ITaskHistoryItem;
}

const TaskExpanded: FC<ITaskExpandedProps> = ({ task }) => {
  const navigate = useNavigate();
  // group task events by date descending
  const taskEventGroups: ITaskEventGroup[] = orderBy(
    Object.values(groupBy(task.taskEvents, (event) => event.dateTime.toLocaleDateString())).map((events) => {
      const sortEvent = orderBy(events, 'dateTime', 'desc');
      return {
        date: sortEvent[0].dateTime.toLocaleDateString(),
        dateTime: sortEvent[0].dateTime,
        created: events.find((event) => !!event.taskCreatedEvent)?.taskCreatedEvent,
        approved: events.filter((event) => !!event.taskApprovedEvent).map((event) => event.taskApprovedEvent!),
        rejected: events.filter((event) => !!event.taskRejectedEvent).map((event) => event.taskRejectedEvent!),
        reassigned: events.filter((event) => !!event.taskReassignedEvent).map((event) => event.taskReassignedEvent!),
      };
    }),
    (eventGroup) => eventGroup.date,
    'desc'
  );

  return (
    <li className={Style.item}>
      <div className={Style.title}>
        <a className={Style.link} onClick={() => navigate(`${Pages.Tasks.Route}/${task.taskId}`)}>
          {TaskTypeText[task.taskTypeId]}
          <Icon name="launch" className={Style.launch} />
        </a>
        <TaskStatusIndicator taskStatusId={task.taskStatusId} />
      </div>
      <ol className={Style.events}>
        {taskEventGroups.map((eventGroup) => (
          <li key={eventGroup.dateTime.toISOString()}>
            <HistoryDate dateTime={eventGroup.dateTime} />
            <TaskEventRejected taskRejectedEvents={eventGroup.rejected} />
            <TaskEventApproved taskApprovedEvents={eventGroup.approved} />
            <TaskEventReassigned taskReassignedEvents={eventGroup.reassigned} />
            <TaskEventCreated taskCreatedEvent={eventGroup.created} />
          </li>
        ))}
      </ol>
    </li>
  );
};

export default observer(TaskExpanded);
