import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import { formatDate } from '../../../utils/dateUtils';
import { FileState } from '../../../common/enums/FileState';
import Style from './styles/Details.module.scss';
import { getFileSizeString } from '../../../utils/miscUtils';

const Details: FC = () => {
  if (FileInformationStore.file === null) return null;
  const file = FileInformationStore.file;
  return (
    <>
      {FileInformationStore.isLoadingThumbnail ? (
        <div className={Style.previewBox}>Loading...</div>
      ) : FileInformationStore.thumbnail ? (
        <img className={Style.previewBox} src={FileInformationStore.thumbnail} />
      ) : (
        <div className={Style.previewBox}>Preview not available</div>
      )}
      <h3 className={Style.fieldListHeading}>File Information</h3>
      <ul className={Style.fieldList}>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Filename</div>
          <div className={Style.fieldValue}>{file.title}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Original Filename</div>
          <div className={Style.fieldValue}>{file.originalFilename}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Uploaded By</div>
          <div className={Style.fieldValue}>{file.uploadedByUserName}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Modified</div>
          <div className={Style.fieldValue}>{formatDate(file.modifiedDate)}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Task Team Originator</div>
          <div className={Style.fieldValue}>
            {file.deliveryTeamTitle}
            <strong> | {file.taskTeamTitle}</strong>
          </div>
        </li>
        {(FileInformationStore.fileStateId == FileState.Shared ||
          FileInformationStore.fileStateId === FileState.Published) && (
          <>
            <li className={Style.field}>
              <div className={Style.fieldLabel}>Suitability</div>
              <div className={Style.fieldValue}>{file.suitabilityTitle}</div>
            </li>
            <li className={Style.field}>
              <div className={Style.fieldLabel}>Revision</div>
              <div className={Style.fieldValue}>{file.formattedRevision}</div>
            </li>
          </>
        )}
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Size</div>
          <div className={Style.fieldValue}>{getFileSizeString(file.uploadedSize)}</div>
        </li>
      </ul>
      <h3 className={Style.fieldListHeading}>Details</h3>
      <ul className={Style.fieldList}>
        {FileInformationStore.metadataFields.map((f) => (
          <li className={Style.field} key={f.title}>
            <div className={Style.fieldLabel}>{f.title}</div>
            <div className={Style.fieldValue}>{file[`fieldValue${f.fieldValueIndex}`]}</div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default observer(Details);
