import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from '../FilesStore';
import SecondaryButton from '../../shared/SecondaryButton';
import ReviewModal from '../reviewModal/ReviewModal';
import { TaskType } from '../../../common/enums/TaskType';
import { useSaveTask } from '../customHooks/SaveTaskHook';
import { useGetAddTaskData } from '../customHooks/GetAddTaskDataHook';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';

interface DeliveryTeamReviewButtonProps {
  taskTeamId: number;
}

const DeliveryTeamReviewButton: FC<DeliveryTeamReviewButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);
  const saveTask = useSaveTask(taskTeamId, TaskType.DeliveryTeamReview, FilesStore);
  const getAddTaskData = useGetAddTaskData(TaskType.DeliveryTeamReview, taskTeamId);
  return (
    <>
      {FilesStore.hasSelectedReferenceFiles ? (
        <Tooltip show={'Reference Files selected.'} defaultUp={false}>
          <SecondaryButton
            disabled={!FilesStore.canDeliveryTeamReviewOnSelectedFiles || !taskTeamId}
            onClick={() => setIsShowing(true)}>
            Review
          </SecondaryButton>
        </Tooltip>
      ) : (
        <SecondaryButton
          disabled={!FilesStore.canDeliveryTeamReviewOnSelectedFiles || !taskTeamId}
          onClick={() => setIsShowing(true)}>
          Review
        </SecondaryButton>
      )}
      {isShowing && (
        <ReviewModal
          title="Delivery Team Review"
          description="If the 'Delivery Team Review' workflow is approved, no changes will be made to the files."
          closeModal={() => setIsShowing(false)}
          approversLabel="Authorisers"
          approversSearchLabel="Add Authorisers"
          allApproversLabel="Require all authorisers to authorise?"
          isSingleApproverRequired={true}
          showSuitability
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Review"
        />
      )}
    </>
  );
};

export default observer(DeliveryTeamReviewButton);
