import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/Sidebar.module.scss';
import SidebarButton from './SidebarButton';
import { classNames } from '../../utils/miscUtils';
import { Pages } from '../../common/constants/Pages';
import AppStore from '../../stores/AppStore';
import { ClientUserRole } from '../../common/enums/ClientUserRole';

export interface ISidebarProps {
  className?: string;
}

const Sidebar: React.FunctionComponent<ISidebarProps> = ({ className }) => {
  return (
    <div className={classNames(Style.sidebar, className)}>
      {AppStore.client?.user.clientUserRole !== ClientUserRole.External && (
        <>
          <SidebarButton label="Files" icon="description" route={Pages.Files.Route} />
          <SidebarButton label="Tasks" icon="list" route={Pages.Tasks.Route} />
          <SidebarButton label="Visualisation" icon="image" route={Pages.Visualisation.Route} />
        </>
      )}
      <SidebarButton label="Transmittals" icon="email" route={Pages.Transmittals.Route} />
    </div>
  );
};

export default observer(Sidebar);
