import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import GoBackButton from '../shared/GoBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import PageNotFound from '../shared/PageNotFound';
import PageHeading from '../shared/PageHeading';
import { classNames } from '../../utils/miscUtils';
import UserCircle from '../shared/UserCircle';
import Icon from '../shared/Icon';
import { formatDate } from '../../utils/dateUtils';
import ThreeUserCircles from '../shared/ThreeUserCircles';
import Dot from '../shared/Dot';
import SeeMoreLink from '../shared/SeeMoreLink';
import TransmittalDetailsActions from './TransmittalDetailsActions';
import TransmittalDetailsFiles from './TransmittalDetailsFiles';
import Styles from './styles/TransmittalDetails.module.scss';
import TransmittalNotifiersModal from './TransmittalNotifiersModal';
import TransmittalVisibleModal from './TransmittalVisibleModal';
import FilesStore from '../files/FilesStore';
import FileInformation from '../files/fileInformation/FileInformation';
import TransmittalResponse from './TransmittalResponse';
import parse from 'html-react-parser';
import { Pages } from '../../common/constants/Pages';
import { UserManagementTypeEnum } from '../../enums/UserManagementTypeEnum';

const TransmittalDetails: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const transmittal = TransmittalDetailsStore.transmittal;
  useEffect(() => {
    const init = async () => {
      await TransmittalDetailsStore.init(params.transmittalTitle, params.projectNumber ?? null);
    };

    init();
  }, [params]);

  const transmittalMessages = TransmittalDetailsStore.getTransmittalMessages() ?? [];

  useEffect(() => {
    if (!transmittal) return;
    const effectAsync = async () => {
      await FilesStore.loadProjectMetadataByProjectNumber(transmittal.projectNumber);
    };

    effectAsync();
  }, [transmittal]);

  const onClose = async () => {
    if (!transmittal) return;
    await TransmittalDetailsStore.closedTransmittal(transmittal.id);
    await TransmittalDetailsStore.init(params.transmittalTitle);
  };

  const onFlagged = async (flagged: boolean) => {
    if (!transmittal) return;
    await TransmittalDetailsStore.flagTransmittal(transmittal.id, flagged);
    await TransmittalDetailsStore.init(params.transmittalTitle);
  };

  if (TransmittalDetailsStore.isLoadingTransmittal) return <Loader />;
  if (!transmittal) return <PageNotFound />;

  return (
    <>
      <GoBackButton onClick={() => navigate(Pages.Transmittals.Route)} />
      {TransmittalDetailsStore.selectedResponse && (
        <>
          <div className={Styles.heading}>
            <PageHeading>Subject: {TransmittalDetailsStore.selectedResponse.subject}</PageHeading>
            <div>{formatDate(transmittal.lastUpdated)}</div>
          </div>
          <div className={Styles.subHeading}>
            <h2>
              Transmittal ID <span className={Styles.transmittalTitle}>{transmittal.title}</span>
            </h2>
            {transmittal.closed && (
              <>
                <div className={Styles.iconWrapper}>
                  <Dot className={Styles.closed} />
                  <small>Closed</small>
                </div>
              </>
            )}
            {transmittal.overdue && !transmittal.closed && (
              <>
                <div className={Styles.iconWrapper}>
                  <Dot className={Styles.overdue} />
                  <small>Overdue</small>
                </div>
              </>
            )}
            {transmittal.flagged && <Icon className={Styles.flagged} name="flag" />}
          </div>
          <TransmittalDetailsActions
            initiator={transmittal.createdByEmail}
            closed={transmittal.closed}
            flagged={transmittal.flagged}
            onClose={onClose}
            onFlagged={onFlagged}
          />
          <div className={Styles.detailsPanel}>
            <div className={Styles.panel}>
              <ol className={Styles.fields}>
                <li className={Styles.fieldValue}>
                  <div className={Styles.label}>Initiator</div>
                  <UserCircle initials={transmittal.createdByInitials} />
                  <div className={Styles.initiatorName}>{transmittal.createdByName}</div>
                  {transmittal.deliveryTeamTitle && transmittal.taskTeamTitle && (
                    <>
                      {<Icon className={Styles.teamIcon} name="people" />}
                      <div>
                        {`${transmittal.deliveryTeamTitle} | `}
                        <strong>{transmittal.taskTeamTitle}</strong>
                      </div>
                    </>
                  )}
                </li>
                <li className={Styles.fieldValue}>
                  <div className={Styles.label}>
                    <Icon className={Styles.labelIcon} name="notifications" />
                    Notified
                  </div>
                  <div>
                    <ThreeUserCircles
                      users={TransmittalDetailsStore.getNotifyUsersFromSelectedResponse().map((u) => ({
                        id: u.id,
                        type: UserManagementTypeEnum.User,
                        initials: u.initials,
                        email: u.email,
                        name: u.name,
                      }))}>
                      <SeeMoreLink onClick={() => (TransmittalDetailsStore.showNotifierModal = true)} />
                    </ThreeUserCircles>
                  </div>
                </li>
                <li className={Styles.fieldValue}>
                  <div className={Styles.label}>
                    <Icon className={Styles.labelIcon} name="visibility" />
                    Visible to
                  </div>
                  <div>
                    <ThreeUserCircles
                      users={transmittal.transmittalVisibilityTaskTeamUsers
                        .concat(transmittal.transmittalVisibilityExternalUsers)
                        .map((u) => ({
                          id: u.id,
                          type: UserManagementTypeEnum.User,
                          initials: u.initials,
                          email: u.email,
                          name: u.name,
                        }))}
                      appointingParties={transmittal.transmittalVisibilityAppointingParties.map((ap) => ({
                        id: ap.id,
                        type: UserManagementTypeEnum.AppointingParty,
                        appointingPartyTitle: ap.title,
                        appointingPartyCode: ap.namingCode,
                        userCount: ap.userCount,
                      }))}
                      deliveryTeams={transmittal.transmittalVisibilityDeliveryTeams.map((dt) => ({
                        id: dt.id,
                        type: UserManagementTypeEnum.DeliveryTeam,
                        deliveryTeamTitle: dt.title,
                        deliveryTeamCode: dt.namingCode,
                        userCount: dt.userCount,
                      }))}
                      taskTeams={transmittal.transmittalVisibilityTaskTeams.map((tt) => ({
                        id: tt.id,
                        type: UserManagementTypeEnum.TaskTeam,
                        deliveryTeamTitle: tt.deliveryTeamTitle,
                        deliveryTeamCode: tt.deliveryTeamCode,
                        taskTeamTitle: tt.title,
                        taskTeamCode: tt.namingCode,
                        userCount: tt.userCount,
                      }))}>
                      <SeeMoreLink onClick={() => (TransmittalDetailsStore.showVisibleUserModal = true)} />
                    </ThreeUserCircles>
                  </div>
                </li>
                <li>
                  <hr />
                </li>
                <li className={classNames(Styles.fieldValue, Styles.column)}>
                  <div className={Styles.label}>Type</div>
                  <div>{transmittal.transmittalTypeTitle}</div>
                </li>
                <li className={classNames(Styles.fieldValue, Styles.column)}>
                  <div className={Styles.label}>Reason</div>
                  <div>{transmittal.transmittalReasonTitle}</div>
                </li>
                <li></li>
                <li className={classNames(Styles.fieldValue, Styles.column)}>
                  <div className={Styles.label}>Initiated Date</div>
                  <div>{formatDate(transmittal.createdDate)}</div>
                </li>
                <li className={classNames(Styles.fieldValue, Styles.column)}>
                  <div className={Styles.label}>Due Date</div>
                  <div>{transmittal.dueDate ? formatDate(transmittal.dueDate) : ''}</div>
                </li>
                <li>
                  <hr />
                </li>
                <li className={Styles.message}>{parse(TransmittalDetailsStore.selectedResponse.message)}</li>
                <li>
                  <hr />
                </li>
              </ol>
              <TransmittalDetailsFiles />
            </div>
            {FilesStore.isShowingFileInformation && (
              <div className={Styles.fileDetails}>
                <FileInformation />
              </div>
            )}
            {TransmittalDetailsStore.showResponse && (
              <TransmittalResponse
                transmittalMessages={transmittalMessages}
                onSelected={(id) => TransmittalDetailsStore.setResponseSelected(id)}
              />
            )}
          </div>
        </>
      )}

      {TransmittalDetailsStore.showNotifierModal && (
        <TransmittalNotifiersModal
          data={TransmittalDetailsStore.transmittalNotifications}
          closeModal={() => (TransmittalDetailsStore.showNotifierModal = false)}></TransmittalNotifiersModal>
      )}
      {TransmittalDetailsStore.showVisibleUserModal && (
        <TransmittalVisibleModal
          data={TransmittalDetailsStore.transmittalVisible}
          closeModal={() => (TransmittalDetailsStore.showVisibleUserModal = false)}></TransmittalVisibleModal>
      )}
    </>
  );
};

export default observer(TransmittalDetails);
