import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { TabContent } from '@aurecon-creative-technologies/styleguide';
import GoBackButton from '../shared/GoBackButton';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';
import ProjectUserManagementStore from './ProjectUserManagementStore';
import AppointingParty from './appointingParty/AppointingParty';
import AppointedParties from './appointedParties/AppointedParties';

const ProjectUserManagement: FC = () => {
  const selectedTabId = ProjectUserManagementStore.selectedTabId;
  const params = useParams();

  ProjectUserManagementStore.init(params?.projectNumber ?? '');

  const onSelectTab = (id: number) => {
    ProjectUserManagementStore.setSelectedTabId(id);
  };

  return (
    <>
      <GoBackButton />
      <PageHeading subPage>User Management</PageHeading>
      <PageTab
        defaultActiveTab={selectedTabId}
        tabs={[
          { id: 1, label: 'Appointed Parties' },
          { id: 2, label: 'Appointing Party' },
        ]}
        onSelectTab={onSelectTab}>
        <TabContent for={1}>
          <AppointedParties />
        </TabContent>
        <TabContent for={2}>
          <AppointingParty />
        </TabContent>
      </PageTab>
    </>
  );
};

export default observer(ProjectUserManagement);
