import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import UserCircle from '../shared/UserCircle';
import { IUser } from '../../api/authenticated/transmittals/getTransmittal';
import Style from './styles/TransmittalNotifiers.module.scss';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';

export interface ITransmittalInitiatorProps {
  notifiedUsers: IUser[];
}

const TransmittalNotifiers: FC<ITransmittalInitiatorProps> = ({ notifiedUsers }) => {
  const sortnotifiedUsers = [...notifiedUsers];
  sortnotifiedUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
  const firstThreeUsers = sortnotifiedUsers.length > 3 ? sortnotifiedUsers.slice(0, 3) : sortnotifiedUsers;

  return (
    <div className={Style.notifiedUserWrapper}>
      {firstThreeUsers.map((user) => (
        <Tooltip
          key={user.id}
          show={
            <div className={Style.initiatorUserTooltip}>
              <div className={Style.userName}>{user.name}</div>
              <div className={Style.taskTeam}>{user.email}</div>
            </div>
          }
          defaultUp={false}>
          <div className={Style.initiatorTooltipItem}>
            <span>
              <UserCircle initials={user.initials} />
            </span>
          </div>
        </Tooltip>
      ))}
      {sortnotifiedUsers.length > 3 && (
        <Tooltip
          show={
            <div className={Style.initiatorUserTooltip}>
              {sortnotifiedUsers.slice(3).map((user) => (
                <div key={user.id}>
                  <div className={Style.userItem}>
                    <span className={Style.userCircle}>
                      <UserCircle initials={user.initials} />
                    </span>
                    <span className={Style.displayName}>{user.name}</span>
                  </div>
                </div>
              ))}
            </div>
          }
          defaultUp={false}>
          <span className={Style.extraNumber}>{`+${sortnotifiedUsers.length - 3}`}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default observer(TransmittalNotifiers);
