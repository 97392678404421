import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/TransmittalInitiator.module.scss';
import Icon from '../shared/Icon';
import UserCircle from '../shared/UserCircle';

export interface ITransmittalInitiatorProps {
  userInitials: string;
  createdByUserName: string;
  taskTeamTitle: string;
  taskTeamCode: string;
  deliveryTeamTitle: string;
}

const TransmittalInitiator: FC<ITransmittalInitiatorProps> = (props) => {
  return (
    <Tooltip
      show={
        <div className={Style.initiatorTooltip}>
          <div className={Style.userName}>{props.createdByUserName}</div>
          <div className={Style.taskTeam}>
            <span>
              <Icon className={Style.teamIcon} name="people" />
            </span>
            {props.taskTeamTitle && (
              <span>
                {`${props.deliveryTeamTitle} | `}
                <strong>{props.taskTeamTitle}</strong>
              </span>
            )}
          </div>
        </div>
      }
      defaultUp={false}>
      <div className={Style.initiatorTooltipItem}>
        <span>
          <UserCircle initials={props.userInitials} />
        </span>
        <span className={Style.initiatorName}>{props.createdByUserName}</span>
        <span>
          <Icon className={Style.teamIcon} name="people" />
        </span>
        {props.taskTeamTitle && (
          <span>
            <strong>{props.taskTeamCode}</strong>
          </span>
        )}
      </div>
    </Tooltip>
  );
};

export default observer(TransmittalInitiator);
