import { makeAutoObservable, runInAction } from 'mobx';
import { FileState } from '../../../common/enums/FileState';
import { IFile } from '../../../api/authenticated/cms/FileModel';
import { IMetadataField, getProjectMetadata } from '../../../api/authenticated/cms/getProjectMetadata';
import { getProjectFileThumbnailUrl } from '../../../api/authenticated/cms/getProjectFileThumbnailUrl';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { orderBy } from 'lodash';
import { getFileHistory, IHistoryItem, IReleasedHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import { getProjectFileDownloadUrl } from '../../../api/authenticated/cms/getProjectFileDownloadUrl';
import { getProjectFileDocumentViewerUrl } from '../../../api/authenticated/cms/getProjectFileViewerUrl';
import AppStore from '../../../stores/AppStore';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import { getExternalUserFileThumbnailUrl } from '../../../api/authenticated/cms/getExternalUserFileThumbnailUrl';
import { getExternalUserFileDownloadUrl } from '../../../api/authenticated/cms/getExternalUserFileDownloadUrl';
import { getExternalUserFileDocumentViewerUrl } from '../../../api/authenticated/cms/getExternalUserFileViewerUrl';

export class FileInformationStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public abortController?: AbortController;
  public file: IFile | null = null;
  public metadataFields: IMetadataField[] = [];
  public fileStateId: FileState | null = null;
  public isLoadingThumbnail = false;
  public thumbnail: string | null = null;
  public isLoadingHistory = false;
  public historyItems: IHistoryItem[] = [];
  public busyReleasedHistoryItem: { [id: number]: boolean } = {};
  public urn = '';
  public token = '';
  public projectNumber = '';
  public transmittalId: number | null = null;

  public async init(file: IFile, fileStateId: FileState, projectNumber: string | null, transmittalId: number | null) {
    this.abortController?.abort();

    const fileMetaDataFieilds = await getProjectMetadata(
      projectNumber ? projectNumber : NavBarSelectorStore.selectedItem!.project.projectNumber
    );

    runInAction(() => {
      this.abortController = new AbortController();
      this.file = file;
      this.metadataFields = fileMetaDataFieilds;
      this.fileStateId = fileStateId;
      this.isLoadingThumbnail = false;
      this.thumbnail = null;
      this.isLoadingHistory = false;
      this.historyItems = [];
      this.busyReleasedHistoryItem = {};
      this.projectNumber = projectNumber ? projectNumber : NavBarSelectorStore.selectedItem!.project.projectNumber;
      this.transmittalId = transmittalId ? transmittalId : null;
    });

    this.loadFileThumbnail();
    this.loadFileHistory();
  }

  public close() {
    this.abortController?.abort();

    runInAction(() => {
      this.file = null;
    });
  }

  public async loadFileThumbnail() {
    if (!this.file) return;

    runInAction(() => {
      this.isLoadingThumbnail = true;
    });

    const abortController = this.abortController;
    if (AppStore.client?.user.clientUserRole === ClientUserRole.External) {
      try {
        const url = await getExternalUserFileThumbnailUrl(
          this.projectNumber,
          this.file.id,
          this.file.fileRevisionId,
          this.file.releasedFileId,
          this.transmittalId,
          abortController?.signal
        );
        runInAction(() => {
          this.thumbnail = url;
        });
      } finally {
        if (!abortController?.signal.aborted) {
          runInAction(() => {
            this.isLoadingThumbnail = false;
          });
        }
      }
    } else {
      try {
        const url = await getProjectFileThumbnailUrl(
          this.projectNumber,
          this.file.id,
          this.file.fileRevisionId,
          this.file.releasedFileId,
          abortController?.signal
        );
        runInAction(() => {
          this.thumbnail = url;
        });
      } finally {
        if (!abortController?.signal.aborted) {
          runInAction(() => {
            this.isLoadingThumbnail = false;
          });
        }
      }
    }
  }

  public async loadFileHistory() {
    if (!this.file || !this.fileStateId) return;

    runInAction(() => {
      this.isLoadingHistory = true;
    });

    const abortController = this.abortController;
    try {
      let historyItems = await getFileHistory(
        this.projectNumber,
        this.file.id,
        this.fileStateId,
        AppStore.client?.user.clientUserRole === ClientUserRole.External ? this.transmittalId : null,
        abortController?.signal
      );

      historyItems = orderBy(historyItems, (item) => item.dateTime, 'desc');

      runInAction(() => {
        this.historyItems = historyItems;
      });
    } finally {
      if (!abortController?.signal.aborted) {
        runInAction(() => {
          this.isLoadingHistory = false;
        });
      }
    }
  }

  public async download(releasedHistoryItem: IReleasedHistoryItem) {
    if (this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId]) return;

    runInAction(() => {
      this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId] = true;
    });

    const abortController = this.abortController;
    if (AppStore.client?.user.clientUserRole === ClientUserRole.External) {
      try {
        if (!this.transmittalId) return;
        const url = await getExternalUserFileDownloadUrl(
          this.projectNumber ? this.projectNumber : NavBarSelectorStore.selectedItem!.project.projectNumber,
          this.transmittalId,
          null,
          null,
          releasedHistoryItem.releasedFileId,
          abortController?.signal
        );
        if (url) window.open(url);
      } finally {
        if (!abortController?.signal.aborted) {
          runInAction(() => {
            this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId] = false;
          });
        }
      }
    } else {
      try {
        const url = await getProjectFileDownloadUrl(
          this.projectNumber ? this.projectNumber : NavBarSelectorStore.selectedItem!.project.projectNumber,
          null,
          null,
          releasedHistoryItem.releasedFileId,
          null,
          null,
          abortController?.signal
        );
        if (url) window.open(url);
      } finally {
        if (!abortController?.signal.aborted) {
          runInAction(() => {
            this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId] = false;
          });
        }
      }
    }
  }

  public get filesSize(): number {
    if (!this.file) return 0;
    return this.file.uploadedSize;
  }

  public async openFile(releasedHistoryItem: IReleasedHistoryItem) {
    if (this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId]) return;

    runInAction(() => {
      this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId] = true;
    });

    const abortController = this.abortController;
    if (AppStore.client?.user.clientUserRole === ClientUserRole.External) {
      if (!this.transmittalId) return;
      try {
        const url = await getExternalUserFileDocumentViewerUrl(
          this.projectNumber ? this.projectNumber : NavBarSelectorStore.selectedItem!.project.projectNumber,
          null,
          null,
          releasedHistoryItem.releasedFileId,
          this.transmittalId,
          abortController?.signal
        );
        if (url) window.open(url);
      } finally {
        if (!abortController?.signal.aborted) {
          runInAction(() => {
            this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId] = false;
          });
        }
      }
    } else {
      try {
        const url = await getProjectFileDocumentViewerUrl(
          this.projectNumber ? this.projectNumber : NavBarSelectorStore.selectedItem!.project.projectNumber,
          null,
          null,
          releasedHistoryItem.releasedFileId,
          null,
          null,
          abortController?.signal
        );
        if (url) window.open(url);
      } finally {
        if (!abortController?.signal.aborted) {
          runInAction(() => {
            this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileId] = false;
          });
        }
      }
    }
  }
}

export default new FileInformationStore();
