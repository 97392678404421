import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Dropdown } from '@aurecon-creative-technologies/styleguide';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import { classNames } from '../../utils/miscUtils';
import SearchBar from '../shared/SearchBar';
import Style from './styles/TransmittalTabContainerActionBar.module.scss';
import ActionBarStore from './ActionBarStore';
import PrimaryButton from '../shared/PrimaryButton';
import Icon from '../shared/Icon';
import SecondaryButton from '../shared/SecondaryButton';
import { ISearchTransmittal } from '../../api/authenticated/transmittals/searchTransmittal';
import { ReactComponent as SortAscIcon } from './images/sort_asc.svg';
import { ReactComponent as SortDescIcon } from './images/sort_desc.svg';
import { TabFilter } from './Types';
import { SortTypes } from '../../common/enums/SortType';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';
import TransmittalsStore from './TransmittalsStore';
import DropDownMenu from '../shared/DropDownMenu';
import { TransmittalExportOption } from '../../common/enums/ExportOption';
import { TransmittalExportOptionText } from '../../common/constants/ExportOptionText';
import TransmittalsExportCSVLink from './TransmittalsExportCSVLink';
import DropDownMenuButton from '../shared/DropDownMenuButton';

export interface TransmittalActionBarProps {
  selectedTab: TabFilter;
  onFilter?: (conditions: ISearchTransmittal) => void;
}

const TransmittalTabContainerActionBar: FC<TransmittalActionBarProps> = ({ onFilter }) => {
  useEffect(() => {
    ActionBarStore.setSelectedTab();
  }, []);
  const handleFilterBtnClick = (isVisible: boolean) => {
    ActionBarStore.getTypes();
    ActionBarStore.setIsVisibleFilter(isVisible);
  };
  const handleOnFilter = () => {
    onFilter && onFilter(ActionBarStore.searchPayload);
  };

  const exportData = ActionBarStore.getCSVExport();
  const navigate = useNavigate();
  const handleCreateTransmittalClick = () => {
    navigate(`${Pages.Transmittals.Route}/new`);
  };
  return (
    <div>
      <ActionBar className={Style.actionBar}>
        <ActionBarSection className={Style.actionBarLabel}>
          <PrimaryButton onClick={handleCreateTransmittalClick}>New</PrimaryButton>
          <SecondaryButton
            className={Style.filterIconBtn}
            onClick={() => handleFilterBtnClick(!ActionBarStore.isVisibleFilter)}>
            <Icon
              className={Style.filterIcon}
              name={ActionBarStore.isVisibleFilter ? 'filter_alt_off' : 'filter_alt'}
            />
          </SecondaryButton>
        </ActionBarSection>
        <ActionBarSection className={Style.actionBarLabel}>
          {ActionBarStore.showSelectAllBtn() && (
            <SecondaryButton onClick={() => ActionBarStore.selectedAllTransmittals()}>Select All</SecondaryButton>
          )}
          {ActionBarStore.showSelectClear() && (
            <SecondaryButton onClick={() => ActionBarStore.clearSelectionTransmittals()}>
              Clear Selection
            </SecondaryButton>
          )}
          <div className={Style.expandBtn}>
            <DropDownMenu
              label="Export to CSV"
              icon="expand_more"
              iconClassName={Style.expandBtnIcon}
              itemsContainerClassName={Style.itemsContainer}
              menuClassName={Style.btnMenu}>
              {ActionBarStore.disabledExportBtn ? (
                <DropDownMenuButton disabled={true}>
                  {TransmittalExportOptionText[TransmittalExportOption.ExportSelected]}
                </DropDownMenuButton>
              ) : (
                <CSVLink
                  disabled={ActionBarStore.disabledExportBtn}
                  data={exportData.data}
                  headers={exportData.headers}
                  filename={exportData.filename}
                  target="_blank"
                  className={Style.btnLink}>
                  {TransmittalExportOptionText[TransmittalExportOption.ExportSelected]}
                </CSVLink>
              )}
              <TransmittalsExportCSVLink />
            </DropDownMenu>
          </div>
          {ActionBarStore.getTotalTransmittalSelected > 0 && (
            <div className={Style.totalSelected}>{ActionBarStore.getTotalTransmittalSelected} transmittal selected</div>
          )}
        </ActionBarSection>
        <ActionBarSection className={Style.searchBox}>
          <SearchBar
            searchValue={TransmittalsStore.searchPayload.searchText}
            onSearch={(keyword) => {
              ActionBarStore.setSearchText(keyword);
              handleOnFilter();
            }}
            placeHolderText="Search for Transmittal Name, ID, Initiator, Notified Users, Visible to Users, Team, Subject, Message"
          />
        </ActionBarSection>
      </ActionBar>
      {ActionBarStore.isVisibleFilter && (
        <ActionBar>
          <ActionBarSection>
            <div className={Style.actionBarLabel}>Filter</div>
            <Dropdown
              toggleAllItem="Select All"
              placeholder="All Types"
              selectedMultipleItems={ActionBarStore.searchPayload.transmittalTypeIds}
              items={ActionBarStore.typeItems}
              onSelectMultipleItems={(items) => {
                ActionBarStore.setSelectedType(items);
                handleOnFilter();
              }}
              multiple={true}
              multipleDisplayValues={true}
              cssClass={Style.dropdown}
              selectedMultipleItemsRender={(selectedItems) => <p>Multiple Types ({selectedItems.length})</p>}
            />
            <div className={Style.marginBetweenDropDowns}></div>
            <div className={Style.marginBetweenDropDowns}></div>
            <Dropdown
              toggleAllItem="Select All"
              placeholder="All Groups"
              selectedMultipleItems={ActionBarStore.selectedGroups}
              items={ActionBarStore.groupItems}
              onSelectMultipleItems={(items) => {
                ActionBarStore.setSelectedGroups(items);
                handleOnFilter();
              }}
              multiple={true}
              multipleDisplayValues={true}
              cssClass={Style.dropdown}
              selectedMultipleItemsRender={(selectedItems) => <p>Group ({selectedItems.length})</p>}
            />
            <div className={classNames(Style.actionBarLabel, Style.marginBetweenFiltersAndSort)}>Sort</div>
            <Dropdown
              cssClass={Style.dropdown}
              selectedItem={ActionBarStore.sortColumn}
              items={ActionBarStore.sortItems}
              onSelectItem={(items) => {
                ActionBarStore.setSortColumn(items);
                handleOnFilter();
              }}
            />
            <div className={Style.marginBetweenDropDowns}></div>
            <SecondaryButton
              className={Style.sortIconBtn}
              onClick={() => {
                ActionBarStore.setSortOrder();
                handleOnFilter();
              }}>
              {ActionBarStore.sortDirection === SortTypes.DESC ? <SortAscIcon /> : <SortDescIcon />}
            </SecondaryButton>
          </ActionBarSection>
        </ActionBar>
      )}
    </div>
  );
};

export default observer(TransmittalTabContainerActionBar);
