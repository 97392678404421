import { serverAxios } from '../api';

export async function getExternalUserFileDownloadUrl(
  projectNumber: string,
  transmittalId: number,
  fileId: number | null,
  fileRevisionId: number | null,
  releasedFileId: number | null,
  abortSignal?: AbortSignal
): Promise<string | null> {
  const result = await serverAxios.get<{ url: string | null }>('api/cms/projectfile/externaldownloadurl', {
    params: {
      projectNumber,
      transmittalId,
      fileId,
      fileRevisionId,
      releasedFileId,
    },
    signal: abortSignal,
  });
  return result.data?.url;
}
