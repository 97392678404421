import { uniq, uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  IReleasedFile,
  ITransmittal,
  ITransmittalMessage,
  IAttachmentFile,
  ITransmittalListItem,
} from '../../api/authenticated/transmittals/getTransmittal';
import {
  ISearchTransmittal,
  ITransmittalSummary,
  searchTransmittals,
} from '../../api/authenticated/transmittals/searchTransmittal';
import { TransmittalSortOptionText } from '../../common/constants/TransmittalSortOptionText';
import { SortTypes } from '../../common/enums/SortType';
import { TransmittalSortOption } from '../../common/enums/TransmittalSortOption';
import { IPagedResponse, IPageMetaData } from '../../common/models/IPagedResponse';
import config from '../../config';
import { dateTimeFormat } from '../../utils/dateUtils';
import { PagingArray } from '../../utils/pagingArray';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { TabFilter, TabFilterText } from './Types';
import ActionBarStore from './ActionBarStore';
import convertHTMLtoText from '../../utils/convertHTMLtoText';
import { getSelectedItems } from '../../utils/getSelectedItems';
import { getAllTransmittals, ITransmittalDetails } from '../../api/authenticated/transmittals/getAllTransmittals';
import { ITransmittalCSVData } from './TransmittalsExportCSVLink';

export class TransmittalsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public transmittals: ITransmittalListItem[] = [];
  public exportAllTransmittals: ITransmittalDetails[] = [];
  public selectedIds: number[] = [];
  public selectedItems: ITransmittal[] = [];
  public transmittalSummary: ITransmittalSummary = {
    totalInitiatedByMe: 0,
    totalMyNotifications: 0,
    totalVisibleToMe: 0,
  };
  public currentTab: TabFilter = TabFilter.MyNotifications;
  public isLoading = false;
  public searchPayload: ISearchTransmittal = {
    sortColumn: TransmittalSortOptionText[TransmittalSortOption.LastUpdated],
    sortDirection: SortTypes.DESC,
    projectNumber: null,
  };
  public isSearchedOrFiltered = false;
  public pageSize = config.defaultPageSize;
  public pageNumber = 1;
  public pagingMetaData: IPageMetaData = {
    pageNumber: 0,
    pageCount: 0,
  } as IPageMetaData;

  private lastSelectedIndexes: number[] = [];

  public setPagingMetaData(res: IPagedResponse<ITransmittal>) {
    const paginatedListObject = new PagingArray(this.pageSize, this.pageNumber, res.totalCount, res.pageCount);
    runInAction(() => {
      this.pagingMetaData = paginatedListObject.getMetaData();
    });
  }

  public async setCurrentTab(value: TabFilter) {
    runInAction(() => {
      this.currentTab = value;
      this.pageNumber = 1;
    });

    this.resetTransmittalContent();
    this.resetSearchParameters();
    await this.fetchTransmittals();
  }

  public resetTransmittalContent() {
    runInAction(() => {
      this.transmittals = [];
      this.selectedIds = [];
    });
  }

  public resetSearchParameters() {
    this.searchPayload.page = this.pageNumber;
    this.searchPayload.pageSize = this.pageSize;
  }

  public resetFilterParameters() {
    this.searchPayload.transmittalTypeIds = undefined;
    this.searchPayload.transmittalReasonIds = undefined;
    this.searchPayload.overdue = undefined;
    this.searchPayload.flagged = undefined;
    this.searchPayload.closed = undefined;
    this.searchPayload.sortColumn = TransmittalSortOptionText[TransmittalSortOption.LastUpdated];
    this.searchPayload.sortDirection = SortTypes.DESC;
  }

  public setTabFilterCondition() {
    runInAction(() => {
      this.searchPayload.notificationToMe = this.currentTab === TabFilter.MyNotifications;
      this.searchPayload.initiatedByMe = this.currentTab === TabFilter.InitiatedByMe;
      this.searchPayload.visibleToMe = this.currentTab === TabFilter.VisibleToMe;
    });
  }

  public handleFilterChange(conditions: ISearchTransmittal) {
    runInAction(() => {
      this.pageNumber = 1;
      this.searchPayload = { ...conditions, page: 1 };
    });
    if (!this.isLoading) {
      this.fetchTransmittals();
    }
  }

  public async setCurrentPage(page) {
    if (page === this.pageNumber) return;
    this.pageNumber = page;
    this.transmittals = [];
    this.searchPayload.page = this.pageNumber;
    this.searchPayload.pageSize = this.pageSize;
    await this.fetchTransmittals();
  }

  public checkIsSearchedOrFiltered(conditions: ISearchTransmittal) {
    runInAction(() => {
      if (
        (conditions.transmittalTypeIds && conditions.transmittalTypeIds.length > 0) ||
        (conditions.transmittalReasonIds && conditions.transmittalReasonIds.length > 0) ||
        conditions.closed ||
        conditions.flagged ||
        conditions.overdue ||
        (conditions.sortColumn &&
          conditions.sortColumn !== TransmittalSortOptionText[TransmittalSortOption.LastUpdated]) ||
        conditions.sortDirection === SortTypes.ASC ||
        conditions.searchText
      ) {
        this.isSearchedOrFiltered = true;
      } else {
        this.isSearchedOrFiltered = false;
      }
    });
  }

  public get totalSelected() {
    return this.selectedIds.length;
  }

  public selectAllTransmittal() {
    this.transmittals.forEach((t) => {
      t.checked = true;
    });
    const pageSelectedIds = this.transmittals.map((x) => x.id);
    this.selectedIds = uniq([...this.selectedIds, ...pageSelectedIds]);
    this.selectedItems = uniqBy([...this.selectedItems, ...this.transmittals], 'id');
  }

  public clearTransmittalSelected() {
    runInAction(() => {
      this.transmittals.forEach((t) => {
        t.checked = false;
      });
      this.selectedIds = [];
      this.selectedItems = [];
    });
  }

  public getCSVData() {
    return {
      filename: `Transmittals_${NavBarSelectorStore.selectedItem?.project.projectNumber}_${
        TabFilterText[this.currentTab]
      }_${dateTimeFormat(new Date(), 'yyyyMMdd')}.csv`,
      headers: [
        { label: 'Transmittal ID', key: 'title' },
        { label: 'Subject', key: 'subject' },
        { label: 'Last Updated', key: 'lastUpdated' },
        { label: 'Initiator', key: 'initiator' },
        { label: 'Notified', key: 'notified' },
        { label: 'Visible to', key: 'visibleTo' },
        { label: 'Type', key: 'type' },
        { label: 'Reason', key: 'reason' },
        { label: 'Initiated Date', key: 'initiatedDate' },
        { label: 'Due Date', key: 'dueDate' },
        { label: 'Message', key: 'message' },
        { label: 'Number of Responses', key: 'totalResponses' },
        { label: 'Number of Content Files', key: 'totalContentFiles' },
        { label: 'Number of Supporting Files', key: 'totalSupportingFiles' },
        { label: 'Status', key: 'status' },
      ],
      data: this.selectedItems.map((t) => {
        const totalReleaseFiles = t.transmittalMessages.reduce(
          (count, msg) => count + msg.transmittalMessageReleasedFiles.length,
          0
        );
        const totalAttachmentFiles = t.transmittalMessages.reduce(
          (count, msg) => count + msg.transmittalMessageAttachmentFiles.length,
          0
        );
        const transmittalMessages = [...t.transmittalMessages];
        transmittalMessages.sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1));
        const lastMessage = transmittalMessages[0];
        return {
          title: t.title,
          subject: lastMessage.subject,
          lastUpdated: dateTimeFormat(t.lastUpdated),
          initiator: t.createdByEmail,
          notified: t.allNotifiedUsers
            .map((x) => x.name)
            .sort((a, b) => (a > b ? 1 : -1))
            .join(', '),
          visibleTo: t.transmittalVisibilityTaskTeamUsers
            .map((x) => x.name)
            .concat(t.transmittalVisibilityExternalUsers.map((eu) => eu.name))
            .sort((a, b) => (a > b ? 1 : -1))
            .join(', '),
          type: t.transmittalTypeTitle,
          reason: t.transmittalReasonTitle,
          initiatedDate: dateTimeFormat(t.createdDate),
          dueDate: t.dueDate ? dateTimeFormat(t.dueDate) : '',
          message: convertHTMLtoText(lastMessage.message),
          totalResponses: t.transmittalMessages.length,
          totalContentFiles: totalReleaseFiles,
          totalSupportingFiles: totalAttachmentFiles,
          status: t.overdue && !t.closed ? 'Overdue' : t.closed ? 'Closed' : '',
        };
      }),
    };
  }
  public async getExportAllTransmittals() {
    try {
      const response = await getAllTransmittals({
        projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
        notificationToMe: this.searchPayload.notificationToMe,
        initiatedByMe: this.searchPayload.initiatedByMe,
        visibleToMe: this.searchPayload.visibleToMe,
      });

      this.exportAllTransmittals = response;
    } catch {
      runInAction(() => {
        this.exportAllTransmittals = [];
      });
    }
  }

  public mappingCSVData(exportDataSet: ITransmittalDetails[]) {
    return {
      filename: `Transmittals_${NavBarSelectorStore.selectedItem?.project.projectNumber}_${
        TabFilterText[this.currentTab]
      }_${dateTimeFormat(new Date(), 'yyyyMMdd')}.csv`,
      headers: [
        { label: 'Transmittal ID', key: 'title' },
        { label: 'Subject', key: 'subject' },
        { label: 'Last Updated', key: 'lastUpdated' },
        { label: 'Initiator', key: 'initiator' },
        { label: 'Notified', key: 'notified' },
        { label: 'Visible to', key: 'visibleTo' },
        { label: 'Type', key: 'type' },
        { label: 'Reason', key: 'reason' },
        { label: 'Initiated Date', key: 'initiatedDate' },
        { label: 'Due Date', key: 'dueDate' },
        { label: 'Message', key: 'message' },
        { label: 'Number of Responses', key: 'totalResponses' },
        { label: 'Number of Content Files', key: 'totalContentFiles' },
        { label: 'Number of Supporting Files', key: 'totalSupportingFiles' },
        { label: 'Status', key: 'status' },
      ],
      data: exportDataSet.map((t) => {
        return {
          title: t.title,
          subject: t.lastSubject,
          lastUpdated: t.lastUpdated ? dateTimeFormat(t.lastUpdated) : '',
          initiator: t.createdByEmail,
          notified: t.notifyToUserNames,
          visibleTo: t.visibleUserNames,
          type: t.transmittalTypeTitle,
          reason: t.transmittalReasonTitle,
          initiatedDate: dateTimeFormat(t.createdDate),
          dueDate: t.dueDate ? dateTimeFormat(t.dueDate) : '',
          message: convertHTMLtoText(t.lastMessage),
          totalResponses: t.totalMessage,
          totalContentFiles: t.totalAttachmentFiles,
          totalSupportingFiles: t.totalReleasedFiles,
          status: t.overdue && !t.closed ? 'Overdue' : t.closed ? 'Closed' : '',
        };
      }),
    } as ITransmittalCSVData;
  }

  public async fetchTransmittals() {
    this.setTabFilterCondition();
    if (!this.searchPayload.page || !this.searchPayload.pageSize) {
      this.searchPayload.page = this.pageNumber;
      this.searchPayload.pageSize = this.pageSize;
    }
    if (this.searchPayload.closed || this.searchPayload.flagged || this.searchPayload.overdue) {
      ActionBarStore.setSelectedGroups(ActionBarStore.selectedGroups);
    }

    this.checkIsSearchedOrFiltered(this.searchPayload);
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      const response = await searchTransmittals({
        ...this.searchPayload,
        projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
      });
      this.setPagingMetaData(response.pageResult);
      runInAction(() => {
        this.transmittals = response.pageResult.data?.map((t) => {
          return { ...t, checked: this.isSelectedItem(t.id), totalVisibleUser: this.calFunc(t) };
        });
        this.transmittalSummary = response.transmittalSummary;
        this.isLoading = false;
      });
    } catch {
      runInAction(() => {
        this.transmittals = [];
        this.selectedIds = [];
        this.selectedItems = [];
        this.transmittalSummary = {
          totalInitiatedByMe: 0,
          totalMyNotifications: 0,
          totalVisibleToMe: 0,
        };
        this.isLoading = false;
      });
    }
  }

  public calFunc(transmittal: ITransmittal) {
    let totalDTUsers = 0;
    transmittal.transmittalVisibilityDeliveryTeams.forEach((x) => (totalDTUsers += x.userCount));

    let totalTTUsers = 0;
    transmittal.transmittalVisibilityTaskTeams.forEach((tt) => {
      totalTTUsers += tt.userCount ?? 0;
    });

    let totalAppUsers = 0;
    transmittal.transmittalVisibilityAppointingParties.forEach((x) => {
      totalAppUsers += x.userCount;
    });

    return (
      totalTTUsers +
      totalDTUsers +
      transmittal.transmittalVisibilityAllUsers.length +
      transmittal.transmittalVisibilityExternalUsers.length +
      transmittal.transmittalVisibilityTaskTeamUsers.length +
      totalAppUsers
    );
  }

  public get filteredTransmittals(): ITransmittal[] {
    return [...this.transmittals];
  }

  public setSelectedTransmittal(transmittal: ITransmittal, selected: boolean, shiftOn: boolean) {
    runInAction(() => {
      const currentSelectedItemIds = this.transmittals.filter((f) => f.checked).map((m) => m.id);
      const selectedItemIds = getSelectedItems(
        this.transmittals,
        transmittal.id,
        selected,
        shiftOn,
        currentSelectedItemIds,
        this.lastSelectedIndexes
      ) as number[];

      const selectedItems = this.transmittals.filter((f) => selectedItemIds.some((s) => f.id === s));
      const nonSelectedItems = this.transmittals.filter((f) => !selectedItemIds.some((s) => f.id === s));
      nonSelectedItems.forEach((f) => (f.checked = false));
      selectedItems.forEach((f) => (f.checked = true));
      const selectedIndex = this.transmittals.findIndex((t) => t.id === transmittal.id);
      this.lastSelectedIndexes.push(selectedIndex);
      this.selectedIds = [...selectedItemIds];
      this.selectedItems = [...selectedItems];
    });
  }

  public countLinkedAndAttachmentFilesFromMessages(transmittalMessages: ITransmittalMessage[]) {
    let attachmentFiles: IAttachmentFile[] = [];
    let linkedFiles: IReleasedFile[] = [];

    transmittalMessages.forEach((m) => {
      attachmentFiles = attachmentFiles.concat(m.transmittalMessageAttachmentFiles);
      linkedFiles = linkedFiles.concat(m.transmittalMessageReleasedFiles);
    });

    const totalLinkedFiles = attachmentFiles
      .map((item) => item.id)
      .filter((value, index, self) => self.indexOf(value) === index).length;

    const totalAttachmentFiles = linkedFiles
      .map((item) => item.id)
      .filter((value, index, self) => self.indexOf(value) === index).length;

    return totalLinkedFiles + totalAttachmentFiles;
  }

  private isSelectedItem(id: number) {
    return this.selectedIds.some((x) => x === id);
  }
}

export default new TransmittalsStore();
