import { serverAxios } from '../api';

export interface IFileData {
  isLocked: boolean;
  fileRevisionId: number | null;
  releasedFileId: number | null;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
}

export interface ISupersedeFile extends IFileData {
  id: number;
  title: string;
  extension: string;
  originalFilename: string;
  uploadedDate: Date;
}

export async function getSupersedeFiles(
  taskTeamId: number,
  uploadedFileName: string,
  searchKeyword?: string,
  abortSignal?: AbortSignal
): Promise<ISupersedeFile[]> {
  const result = await serverAxios.get<{ supersedeFiles: ISupersedeFile[] }>('api/cms/getsupersedefiles', {
    params: {
      taskTeamId,
      uploadedFileName,
      searchKeyword,
    },
    signal: abortSignal,
  });
  return result.data?.supersedeFiles;
}
