import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import { TaskTypeText } from '../../../common/constants/TaskTypeText';
import TaskStatusIndicator from '../../shared/TaskStatusIndicator';
import { IHistoryItem, ITaskHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import HistoryDate from './HistoryDate';
import Style from './styles/TaskCollapsed.module.scss';
import { orderBy } from 'lodash';

export interface ITaskCollapsedProps {
  item: IHistoryItem;
  task: ITaskHistoryItem;
  onClick: () => void;
}

const TaskCollapsed: FC<ITaskCollapsedProps> = ({ item, task, onClick }) => {
  const sortEvent = orderBy(item.taskHistoryItem?.taskEvents, 'dateTime', 'desc');
  return (
    <li className={Style.item} onClick={onClick}>
      <HistoryDate dateTime={sortEvent[0]?.dateTime} />
      <div className={Style.titleContainer}>
        <div className={Style.title}>
          <TaskStatusIndicator taskStatusId={task.taskStatusId} />
          &nbsp;- {TaskTypeText[task.taskTypeId]}
        </div>
        <Icon name="expand_more" className={Style.expandMore} />
      </div>
    </li>
  );
};

export default observer(TaskCollapsed);
