import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TaskUserStatus } from '../../common/enums/TaskUserStatus';
import { TaskUserStatusText } from '../../common/constants/TaskUserStatusText';
import { classNames } from '../../utils/miscUtils';
import Dot from './Dot';
import Style from './styles/TaskUserStatusIndicator.module.scss';

export interface ITaskUserStatusIndicatorProps {
  taskUserStatusId: TaskUserStatus;
  className?: string;
}

const TaskUserStatusIndicator: FC<ITaskUserStatusIndicatorProps> = ({ taskUserStatusId, className }) => {
  return (
    <span className={classNames(className)}>
      <Dot
        className={classNames(
          [taskUserStatusId === TaskUserStatus.AwaitingReview, Style.awaitingReview],
          [taskUserStatusId === TaskUserStatus.Approved, Style.approved],
          [taskUserStatusId === TaskUserStatus.Rejected, Style.rejected]
        )}
      />
      <span>{TaskUserStatusText[taskUserStatusId]}</span>
    </span>
  );
};

export default observer(TaskUserStatusIndicator);
