import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableRow, TableCell, IHeader, Dropdown } from '@aurecon-creative-technologies/styleguide';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import AppointingPartyStore from './AppointingPartyStore';

const headers: IHeader[] = [
  {
    label: 'User Name',
    style: { width: '33%' },
  },
  {
    label: 'User Email',
    style: { width: '33%' },
  },
  {
    label: 'Additional Roles',
    style: { width: '33%' },
  },
  {
    label: '',
  },
];

const AppointingPartyUserTable: FC = () => {
  return (
    <Table headers={headers} breakpoint={10} hoverable>
      {AppointingPartyStore.getAppointingPartyUsers().map((user) => (
        <TableRow key={user.id}>
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>
            <Dropdown
              placeholder="Select Additional Role"
              items={AppointingPartyStore.appointingPartyRoles}
              selectedMultipleItems={user.appointingPartyRoleIds}
              onSelectMultipleItems={(value) => AppointingPartyStore.changeUserRole(user, value)}
              multiple={true}
              multipleDisplayValues={true}
            />
          </TableCell>
          <TableCell>
            <PrimaryIconButton icon="delete" onClick={() => AppointingPartyStore.setRemoveUser(user)} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default observer(AppointingPartyUserTable);
