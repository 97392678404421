function parents(node, ps) {
  if (node.parentNode === null) {
    return ps;
  }
  return parents(node.parentNode, ps.concat([node]));
}

function getStyleValue(node, prop) {
  return getComputedStyle(node, null).getPropertyValue(prop);
}

function canScroll(node) {
  const styleValues =
    getStyleValue(node, 'overflow') + getStyleValue(node, 'overflow-y') + getStyleValue(node, 'overflow-x');
  return /(auto|scroll)/.test(styleValues);
}

export function getScrollParent(node) {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return;
  }

  const parentNode = parents(node.parentNode, []);

  for (let i = 0; i < parentNode.length; i++) {
    if (canScroll(parentNode[i])) {
      return parentNode[i];
    }
  }

  return document.scrollingElement || document.documentElement;
}
