import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from '../../ContentSelection';
import FilesStore from '../../FilesStore';
import Style from './styles/AddContent.module.scss';
import PrimaryButton from '../../../shared/PrimaryButton';
import SecondaryButton from '../../../shared/SecondaryButton';
import UploadStore from './UploadStore';
import { classNames } from '../../../../utils/miscUtils';
import UploadUnsavedModal from './UploadUnsavedModal';

export const fileTypes = [
  {
    id: 1,
    name: 'File Upload',
  },
  /*
  These will be added back late MVP / release 2 - might as well leave them here.
  {
    id: 2,
    name: 'Word Document',
    icon: <WordIcon />,
  },
  {
    id: 3,
    name: 'Excel Workbook',
    icon: <ExcelIcon />,
  },
  {
    id: 4,
    name: 'Powerpoint Presentation',
    icon: <PowerpointIcon />,
  },
  {
    id: 5,
    name: 'OneNote Notebook',
    icon: <WordIcon />,
  },
  */
];

interface IDropdownItemProps {
  children: ReactNode;
  leftIcon?: ReactNode;
  onClick: () => void;
}

// interface ISubDropdownItemProps {
//   children: ReactNode;
//   rightIcon?: ReactNode;
//   leftIcon?: ReactNode;
//   files?: string;
//   onClick: () => void;
// }

const AddContent: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showUploadUnsavedModal, setShowUploadUnsavedModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdownRef = useRef<any>(null);

  const onAddButtonClick = (): void => setIsOpen(!isOpen);
  const onCancelButtonClick = async () => {
    await UploadStore.unlockSupersedeFiles();
    UploadStore.clear();
    setShowUploadUnsavedModal(false);
    FilesStore.setSelectedSection(ContentSelection.WIP);
  };

  const handleCancelButtonClick = () =>
    UploadStore.hasUnsavedChanges ? setShowUploadUnsavedModal(true) : onCancelButtonClick();

  const onUploadButtonClick = () => {
    UploadStore.uploadFiles();
  };

  useEffect(() => {
    const handleClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isOpen) {
      window.addEventListener('click', handleClickEvent);
    }

    return () => {
      window.removeEventListener('click', handleClickEvent);
    };
  }, [isOpen]);

  const onDropdownItemSelect = (selected: string) => {
    UploadStore.clear();
    UploadStore.getFileData();

    if (selected === 'File Upload') FilesStore.setSelectedSection(ContentSelection.UploadFile);
    else if (selected === 'WordFile') FilesStore.setSelectedSection(ContentSelection.AddTemplateDocument);
    else FilesStore.setSelectedSection(ContentSelection.AddBlankDocument, selected);
  };

  const DropdownItem = ({ onClick, children, leftIcon }: IDropdownItemProps) => {
    return (
      <ul className={classNames(Style.dropdownMenuList, Style.dropdownSubmenuList)} onClick={onClick}>
        <li className={Style.dropdownItem}>
          <span className={Style.iconButton}>{leftIcon}</span>
          {children}
        </li>
      </ul>
    );
  };

  //This will be added back late MVP / release 2 - might as well leave them here.
  // const SubDropdownItem = ({ children, files, rightIcon, leftIcon, onClick }: ISubDropdownItemProps) => {
  //   return (
  //     <ul className={classNames(Style.dropdownMenuList, Style.dropdownSubmenuList)}>
  //       <li className={Style.dropdownItem}>
  //         <span className={Style.iconButton}>{leftIcon}</span>
  //         {children}
  //         <span className={Style.iconRight}>{rightIcon}</span>
  //         <ul className={classNames(Style.dropdownMenuList, Style.dropdownSubmenuList)} onClick={onClick}>
  //           <li className={Style.dropdownItem}>{files}</li>
  //         </ul>
  //       </li>
  //     </ul>
  //   );
  // };

  const isToggleButton = FilesStore.selectedSection !== ContentSelection.WIP;

  return (
    <div className={Style.dropdown}>
      {isToggleButton ? (
        <>
          <PrimaryButton onClick={onUploadButtonClick} disabled={UploadStore.isUploadDisabled()}>
            Upload
          </PrimaryButton>
          <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
          {showUploadUnsavedModal && (
            <UploadUnsavedModal
              closeModal={() => setShowUploadUnsavedModal(false)}
              redirect={onCancelButtonClick}></UploadUnsavedModal>
          )}
        </>
      ) : (
        <PrimaryButton onClick={onAddButtonClick}>Add</PrimaryButton>
      )}
      <div>
        {isOpen && (
          <div ref={dropdownRef} className={Style.dropdownMenu}>
            {fileTypes.map((fileType) => (
              <DropdownItem
                key={fileType.id}
                //leftIcon={fileType.icon}
                onClick={() => onDropdownItemSelect(fileType.name)}>
                {fileType.name}
              </DropdownItem>
            ))}
            {/*
            //This will be added back late MVP / release 2 - might as well leave them here.
            <SubDropdownItem
              rightIcon={<Icon type="chevron_right" size="25px" />}
              onClick={() => onDropdownItemSelect('WordFile')}
              files={'WordFile'}>
              From Template
            </SubDropdownItem>
            */}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AddContent);
