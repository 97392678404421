import React, { useEffect } from 'react';
import { Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import NavBarSelectorStore from './NavBarSelectorStore';
import NavBarSelectorProgramme from '../../shared/NavBarSelectorProgramme';
import NavBarSelectorProject from '../../shared/NavBarSelectorProject';
import NavBarSelectorTaskTeam from '../../shared/NavBarSelectorTaskTeam';
import Style from './styles/NavBarSelector.module.scss';
import Icon from '../../shared/Icon';

interface INavBarSelectorProp {
  disable: boolean;
}

const NavBarSelector: React.FC<INavBarSelectorProp> = (props) => {
  useEffect(() => {
    NavBarSelectorStore.initSelector();
  }, []);

  const handleOnSelectItem = (id: string | number): void => {
    NavBarSelectorStore.setSelectedSelectorItem(id.toString());
  };

  const items: IDropdownItemProps[] = NavBarSelectorStore.items.map((item) => {
    if (item.type === 'Programme') return { id: item.id, label: <NavBarSelectorProgramme item={item} /> };
    if (item.type === 'Project') return { id: item.id, label: <NavBarSelectorProject item={item} /> };
    return { id: item.id, label: <NavBarSelectorTaskTeam item={item} /> };
  });

  const buildSelectedRenderItem = (selected) => {
    return (
      <div className={Style.dropdownSelected}>
        <Icon name="folder" className={Style.icon}></Icon>
        <span className={Style.selectedProgramme}>{NavBarSelectorStore.selectedItem?.programme.title}</span>
        <span>|</span>
        <span className={Style.selectedProject}>{NavBarSelectorStore.selectedItem?.project.title}</span>
        {NavBarSelectorStore.selectedItem?.type === 'TaskTeam' && <span>{selected.label}</span>}
      </div>
    );
  };

  return (
    <Dropdown
      size={'small'}
      cssClass={Style.fileSelectorDropdown}
      selectedItem={NavBarSelectorStore.selectedItem?.id}
      items={items}
      onSelectItem={handleOnSelectItem}
      disabled={props.disable}
      selectedItemRender={(selected) => buildSelectedRenderItem(selected)}
    />
  );
};

export default observer(NavBarSelector);
