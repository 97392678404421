import { computed, makeAutoObservable, runInAction } from 'mobx';
import { addFile } from '../../../../api/authenticated/cms/addFile';
import {
  getProjectMetadata,
  IMetadataField,
  IFileMetadataFieldValue,
} from '../../../../api/authenticated/cms/getProjectMetadata';
import { upload } from '../../../../api/authenticated/cms/upload';
import { addSupersedeFile } from '../../../../api/authenticated/cms/addSupersedeFile';
import NavBarSelectorStore from '../../navBarSelector/NavBarSelectorStore';
import { orderBy, uniq, uniqBy } from 'lodash';
import { getSetting } from '../../../../api/authenticated/config/getSetting';
import { NavigationItemTypes } from '../../../../common/models/ItemType';
import { UploadFileStatusEnum, UploadFileStatusText } from '../../../../common/enums/UploadFileStatus';
import { ITableColumn } from '../../fileTable/TableColumn';
import { MetadataFieldTitle, MetadataFieldType } from '../../../../common/enums/MetadataFieldType';
import { SortType, SortTypes } from '../../../../common/enums/SortType';
import { IChunk, IUploadFile } from '../../../../common/interfaces/fileUpload';
import { getDuplicateFiles } from '../../../../api/authenticated/cms/getDuplicateFiles';
import { calculateChunks } from '../../../../utils/miscUtils';
import { dateTimeFormat } from '../../../../utils/dateUtils';
import FilesStore from '../../FilesStore';
import AppStore from '../../../../stores/AppStore';
import getFileExtension from '../../../../utils/fileUtils';
import { IFileData } from '../../../../api/authenticated/cms/getSupersedeFiles';

export interface IMetadataSelectedValue {
  fieldValueIndex: number;
  value: string;
  title: string;
}

export interface IFileMetadataValue {
  file: File;
  status: UploadFileStatusEnum;
  metadata: IMetadataSelectedValue[];
  isSelected: boolean;
  missingMetadata: boolean;
  isLocked?: boolean;
  fileId?: number;
  fileRevisionId?: number | null;
  missingFileExtension: boolean;
}

export interface IDropDownField {
  key: string;
  id: string;
  label: string;
}

export interface ISelectedFile extends IFileData {
  fileId: number;
  fileName: string;
  status: UploadFileStatusEnum;
}

export const TextFilterField = {
  FileName: 'fileName',
  FileSize: 'fileSize',
  Status: 'status',
};

export class UploadStore {
  constructor() {
    makeAutoObservable(this, { uploadCompletePercentage: computed, filesFailedToUpload: computed }, { autoBind: true });
  }

  private chunkSize = 3000000;
  public fileMetadata: IMetadataField[] = [];
  public isLoadingFileDataFields = false;
  public fileDataValues: IMetadataSelectedValue[] = [];
  public filteredFiles: IFileMetadataValue[] = [];
  public selectedfiles: IFileMetadataValue[] = [];
  public tableColumns: ITableColumn[] = [];
  public showProgressBar = false;
  private fileChunks: { [filename: string]: IUploadFile } = {};
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public isSelectedInvalidExtension = false;
  public showMetadataWarning = false;
  public hasUnsavedChanges = false;
  public isProcessing = false;
  public isOverLimit = false;
  public openResultsModal = false;
  public fileUploadLimit: number = AppStore.client?.fileUploadLimit ?? 100;

  public clear() {
    runInAction(() => {
      this.fileDataValues = [];
      this.selectedfiles = [];
      this.filteredFiles = [];
      this.showProgressBar = false;
      this.hasUnsavedChanges = false;
      this.isOverLimit = false;
      this.isProcessing = false;
      this.isSelectedInvalidExtension = false;
      this.tableColumns = [];
    });
  }

  public async getFileData(): Promise<void> {
    runInAction(() => {
      this.isLoadingFileDataFields = true;
      this.tableColumns = [];
    });
    try {
      const task1 = getSetting(NavBarSelectorStore.selectedItem!.project.projectNumber);
      const task2 = getProjectMetadata(NavBarSelectorStore.selectedItem!.project.projectNumber);
      const result = {
        settings: await task1,
        fileMetadata: await task2,
      };
      runInAction(() => {
        if (result.settings) this.chunkSize = result.settings.uploadChunkSize;
        if (result.fileMetadata) {
          this.fileMetadata = result.fileMetadata;
        }
      });
    } finally {
      runInAction(() => {
        this.isLoadingFileDataFields = false;
      });
    }
  }

  public setFileDataValues(fieldValueIndex: number, val?: string) {
    runInAction(() => {
      const value = this.fileDataValues.find((v) => v.fieldValueIndex === fieldValueIndex);
      const metadata = this.fileMetadata.find((v) => v.fieldValueIndex === fieldValueIndex);
      const childField = this.fileMetadata.find((v) => v.dataType.parentFieldValueIndex === fieldValueIndex);
      if (childField) {
        this.removeFileDataValues(childField.fieldValueIndex);
      }
      if (val) {
        if (value) {
          value.value = val;
          value.title = metadata?.dataType?.fieldValues?.find((f) => f.code === val)?.title ?? '';
        } else {
          this.fileDataValues.push({
            fieldValueIndex: fieldValueIndex,
            value: val,
            title: metadata?.dataType?.fieldValues?.find((f) => f.code === val)?.title ?? '',
          });
        }
      } else {
        this.removeFileDataValues(fieldValueIndex);
      }
      this.hasUnsavedChanges = true;
    });
  }

  public getSelectedMetadataValueByIndex(fieldValueIndex: number) {
    return this.fileDataValues.find((v) => v.fieldValueIndex === fieldValueIndex)?.value;
  }

  public removeFileDataValues(fieldValueIndex: number) {
    const childItems = this.fileMetadata.filter((x) => x.dataType.parentFieldValueIndex === fieldValueIndex);
    if (childItems.length) {
      runInAction(() => {
        this.fileDataValues = this.fileDataValues.filter(
          (x) => !childItems.map((x) => x.fieldValueIndex).some((y) => y === x.fieldValueIndex)
        );
      });
    }

    const index = this.fileDataValues.findIndex((v) => v.fieldValueIndex === fieldValueIndex);
    if (index > -1) {
      runInAction(() => {
        this.fileDataValues.splice(index, 1);
      });
    }
  }
  public isSupersedeFileUploadDisabled(): boolean {
    return this.selectedfiles.length <= 0 || this.isSelectedInvalidExtension;
  }

  public isUploadDisabled(): boolean {
    if (this.selectedfiles.length <= 0) return true;
    return this.selectedfiles.some(
      (f) => f.missingMetadata || f.status === UploadFileStatusEnum.Duplicate || f.missingFileExtension
    );
  }

  public setShowMetadataWarning(value: boolean) {
    runInAction(() => {
      this.showMetadataWarning = value;
    });
  }

  public applyFilter(tableColumn: ITableColumn | null, value?: string) {
    runInAction(() => {
      const column = this.tableColumns.find((col) => col.valueField === tableColumn?.valueField);

      if (column) {
        if (column.listFilter) column.listFilter.filter = value ?? '';
        if (column.textFilter) column.textFilter.filter = value ?? '';
      }

      if (!tableColumn) {
        this.filteredFiles = this.selectedfiles.map((f) => ({ ...f, metadata: [...f.metadata] }));
        return;
      }

      const filleterColumns = this.tableColumns.filter(
        (x) => x.listFilter?.filter && x.listFilter?.filter.trim().length > 0
      );
      const filleterTextColumns = this.tableColumns.filter(
        (x) => x.textFilter?.filter && x.textFilter?.filter.trim().length > 0
      );

      this.filteredFiles = [
        ...this.selectedfiles.filter((f) => {
          const textFilter = this.tableColumns.some((col) => {
            if (col.valueField === TextFilterField.FileName && col.textFilter) {
              return col.textFilter.filter
                ? f.file.name.toLowerCase().indexOf(col.textFilter.filter.toLowerCase()) >= 0
                : true;
            }
            return false;
          });

          const filterMetadata = f.metadata.filter((mt) =>
            filleterColumns.some((col) => col.valueField == mt.fieldValueIndex.toString())
          );
          const filterTextMetadata = f.metadata.filter((mt) =>
            filleterTextColumns.some((col) => col.valueField == mt.fieldValueIndex.toString())
          );
          let listFilter = true;
          filleterColumns.forEach((col) => {
            if (
              filterMetadata.length < filleterColumns.length ||
              filterMetadata.some((mt) => mt.fieldValueIndex === +col.valueField && mt.title !== col.listFilter?.filter)
            ) {
              listFilter = false;
            }
          });
          if (filterTextMetadata.length > 0) {
            filleterTextColumns.forEach((col) => {
              if (
                filterTextMetadata.length >= filleterTextColumns.length &&
                filterTextMetadata.some(
                  (mt) =>
                    mt.fieldValueIndex === +col.valueField &&
                    (col.textFilter?.filter
                      ? mt.value.toLowerCase().indexOf(col.textFilter?.filter.toLowerCase()) === -1
                      : true)
                )
              ) {
                listFilter = false;
              }
            });
          }
          return textFilter && listFilter;
        }),
      ];
    });
  }

  public applySort(tableColumn: ITableColumn | null, direction: SortType) {
    runInAction(() => {
      if (!tableColumn) {
        this.filteredFiles = this.selectedfiles.map((f) => ({ ...f, metadata: [...f.metadata] }));
        return;
      }

      tableColumn.sort = direction;
      this.tableColumns
        .filter((col) => col.valueField !== tableColumn?.valueField)
        .forEach((col) => (col.sort = SortTypes.NONE));

      if (tableColumn.valueField === TextFilterField.FileName) {
        this.filteredFiles.sort((f1, f2) =>
          this.sort(f1.file.name.toLowerCase(), f2.file.name.toLowerCase(), direction)
        );
        return;
      }

      if (tableColumn.valueField === TextFilterField.Status) {
        this.filteredFiles.sort((f1, f2) => {
          const status1 = UploadFileStatusText[f1.status];
          const status2 = UploadFileStatusText[f2.status];
          return this.sort(status1, status2, direction);
        });
        return;
      }

      if (tableColumn.valueField === TextFilterField.FileSize) {
        this.filteredFiles.sort((f1, f2) => this.sort(f1.file.size, f2.file.size, direction));
        return;
      }

      this.filteredFiles.sort((f1, f2) => {
        const metaDataValue1 = f1.metadata.find((mt) => `${mt.fieldValueIndex}` === tableColumn.valueField);
        const metadataValue2 = f2.metadata.find((mt) => `${mt.fieldValueIndex}` === tableColumn.valueField);
        if (tableColumn.textFilter)
          return this.sort(
            metaDataValue1?.value.toLowerCase() ?? '',
            metadataValue2?.value.toLowerCase() ?? '',
            direction
          );

        return this.sort(
          metaDataValue1?.title.toLowerCase() ?? '',
          metadataValue2?.title.toLowerCase() ?? '',
          direction
        );
      });
    });
  }

  public onSelectedFiles(fileName: string | null, isSelected: boolean, selectAll = false) {
    if (selectAll) {
      this.filteredFiles.forEach((f) => {
        f.isSelected = isSelected;
        const file = this.selectedfiles.find((file) => file.file.name === f.file.name);
        if (file) {
          file.isSelected = isSelected;
        }
      });
      return;
    }

    const index = this.filteredFiles.findIndex((f) => f.file.name === fileName);
    if (index < 0) return;
    this.filteredFiles[index].isSelected = isSelected;
    const selectedFile = this.selectedfiles.find((file) => file.file.name === fileName);
    if (selectedFile) {
      selectedFile.isSelected = isSelected;
    }
  }

  public async addFiles(files: File[], multiple: boolean, acceptedExtension?: string) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;

    runInAction(() => {
      this.isProcessing = true;
      this.hasUnsavedChanges = true;
    });
    const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
    try {
      this.validationUploadFiles(files, acceptedExtension);

      if (this.isOverLimit) {
        this.isProcessing = false;
        return;
      }

      const newFiles = uniqBy(files, 'name').filter((f) => !this.selectedfiles.some((sf) => sf.file.name === f.name));

      const validatedNewFiles = await Promise.all(
        newFiles.map(async (file) => ({
          file,
          status: await this.setSelectFileStatus(file, taskTeamId),
          metadata: JSON.parse(JSON.stringify(this.fileDataValues)),
          isSelected: false,
          missingMetadata: this.isRowMissingMetadata(this.fileDataValues),
          missingFileExtension: !file.type && !getFileExtension(file.name),
        }))
      );

      runInAction(() => {
        if (!multiple) {
          // Reset selected files when use replace file.
          this.selectedfiles = [];
        }
        this.selectedfiles = [...this.selectedfiles, ...validatedNewFiles];
        this.loadTableColumns();
        this.applyFilter(null);
        this.updateTableColumnFilter();
      });
    } finally {
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  }
  public async setSelectFileStatus(file: File, taskTeamId: number) {
    if (!file.type && !getFileExtension(file.name)) return UploadFileStatusEnum.MissingFileExtension;
    if ((await getDuplicateFiles(taskTeamId, file.name)).length > 0) return UploadFileStatusEnum.Duplicate;

    return UploadFileStatusEnum.Ready;
  }
  public validationUploadFiles(files: File[], acceptedExtension?: string) {
    runInAction(() => {
      let isInvalidExtension = false;
      this.isOverLimit = false;
      if (this.selectedfiles.length + files.length > this.fileUploadLimit) this.isOverLimit = true;
      if (acceptedExtension) {
        isInvalidExtension = files.some(
          (file) => file.name.split('.').pop()?.toLowerCase() !== acceptedExtension.toLocaleLowerCase()
        );
      }
      this.isSelectedInvalidExtension = isInvalidExtension;
    });
  }

  public async unlockSupersedeFiles() {
    const supersedingFiles = this.selectedfiles.filter(
      (f) => f.file && f.isLocked && f.status === UploadFileStatusEnum.Supersede
    );
    await Promise.all(supersedingFiles.map(async (f) => this.unlockSupersedeFile(f.file.name)));
  }

  public async unlockSupersedeFile(name: string) {
    const removedFile = this.selectedfiles.find((f) => f.file.name === name);
    if (!removedFile) return;

    if (removedFile.fileId && removedFile.status === UploadFileStatusEnum.Supersede)
      await FilesStore.unlockFile(removedFile.fileId);
  }

  public async removeFile(name: string) {
    FilesStore.setShowLoading(true, 'File unlocking');
    await this.unlockSupersedeFile(name);
    FilesStore.setShowLoading(false, '');
    const index = this.selectedfiles.findIndex((f) => f.file.name === name);

    if (index > -1) {
      runInAction(() => {
        this.selectedfiles.splice(index, 1);
        this.applyFilter(
          this.tableColumns[0],
          this.tableColumns[0].textFilter?.filter ?? this.tableColumns[0].listFilter?.filter ?? ''
        );
      });
      this.updateTableColumnFilter();
    }
  }

  public async removeFiles() {
    const removedFiles = this.selectedfiles.filter((f) => f.isSelected);

    FilesStore.setShowLoading(true, 'File unlocking');
    await Promise.all(removedFiles.map(async (selectedFile) => this.unlockSupersedeFile(selectedFile.file.name)));
    FilesStore.setShowLoading(false, '');

    runInAction(() => {
      this.selectedfiles = this.selectedfiles.filter((f) => !f.isSelected);
      this.applyFilter(
        this.tableColumns[0],
        this.tableColumns[0].textFilter?.filter ?? this.tableColumns[0].listFilter?.filter ?? ''
      );
      this.updateTableColumnFilter();
    });
  }

  public async uploadFiles() {
    this.startUploadProcess();
    // compute chunks for all files
    this.selectedfiles.forEach((selectedFile) => {
      this.setCalculateFileChunks(selectedFile.file);
    });

    // files are uploaded in parallel
    const updatedFilePromises = this.selectedfiles
      .filter((f) => f.status !== UploadFileStatusEnum.Supersede)
      .map(async (selectedFile) => this.uploadFile(selectedFile));
    const supersedingPromises = this.selectedfiles
      .filter((f) => f.fileId && f.status === UploadFileStatusEnum.Supersede)
      .map(async (selectedFile) =>
        this.addSupersedeFile(selectedFile.file, selectedFile.fileId || 0, selectedFile.fileRevisionId || null)
      );
    await Promise.all([...updatedFilePromises, ...supersedingPromises]);

    // upload complete show confirmation page
    runInAction(() => {
      this.showUploadSuccess = !this.filesFailedToUpload.length;
      this.showUploadFailed = !!this.filesFailedToUpload.length;
      this.openResultsModal = true;
    });
  }

  public setOpenResultsModal(open: boolean) {
    runInAction(() => {
      this.openResultsModal = open;
    });
  }

  public applyMetadataToSelectedFiles() {
    runInAction(() => {
      if (this.filteredFiles.some((x) => x.isSelected && x.status !== UploadFileStatusEnum.Supersede)) {
        this.filteredFiles.forEach((f) => {
          if (!f.isSelected || (f.isSelected && f.status === UploadFileStatusEnum.Supersede)) return;

          f.metadata = this.fileDataValues.map((x) => ({ ...x }));
          const missingMetadata = this.isRowMissingMetadata(this.fileDataValues);
          f.missingMetadata = missingMetadata;

          const updateFile = this.selectedfiles.find((file) => file.file.name === f.file.name);
          if (updateFile) {
            updateFile.metadata = this.fileDataValues.map((x) => ({ ...x }));
            updateFile.missingMetadata = missingMetadata;
          }
        });
      }
      this.updateTableColumnFilter();
    });
  }

  private updateTableColumnFilter() {
    let options: IMetadataSelectedValue[] = [];
    this.filteredFiles.forEach((element) => {
      options = options.concat(element.metadata);
    });

    this.tableColumns.forEach((clm) => {
      const metadata = this.fileMetadata.find((m) => m.fieldValueIndex.toString() === clm.valueField);
      if (metadata && metadata.dataType.fieldType === 'List') {
        const clmMetadata = options.filter((x) => x.fieldValueIndex === metadata?.fieldValueIndex);
        const fieldValues = [...new Map(clmMetadata.map((o) => [o.title, o])).values()].map((x) => x.title);
        fieldValues.sort((f1, f2) => this.sort(f1.toLowerCase(), f2.toLowerCase(), SortTypes.ASC));
        clm.listFilter = { fieldValues: fieldValues };
      }
    });
  }

  public isRowMissingMetadata(metadata: IMetadataSelectedValue[]) {
    const requiredFields = this.fileMetadata.filter((x) => x.dataType.required).map((x) => x.fieldValueIndex);
    if (!requiredFields.length) return true;

    const metadataFieldsOfFile = metadata.map((f) => f.fieldValueIndex);
    const missingItems = requiredFields.filter((item) => metadataFieldsOfFile.indexOf(item) < 0);
    return missingItems.length > 0;
  }

  public unCheckNotMatchFileAfterFiltered() {
    runInAction(() => {
      this.selectedfiles.forEach((f) => {
        if (f.isSelected === true && !this.filteredFiles.some((x) => x.file.name === f.file.name)) {
          f.isSelected = false;
        }
      });
    });
  }

  private startUploadProcess() {
    runInAction(() => {
      this.showProgressBar = true;
      this.fileChunks = {};
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  private setCalculateFileChunks(file: File) {
    runInAction(() => {
      this.fileChunks[file.name] = {
        numberOfChunksUploaded: 0,
        failed: false,
        chunks: calculateChunks(file, this.chunkSize),
      };
    });
  }

  private async uploadFile(selectedFile: IFileMetadataValue) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;

    const file = selectedFile.file;
    try {
      const result = await this.addFile(
        NavBarSelectorStore.selectedItem.taskTeam.id,
        selectedFile,
        this.fileChunks[file.name].chunks
      );
      await this.uploadChunks(file, this.fileChunks[file.name], result.fileUploadId);
    } catch (err) {
      runInAction(() => {
        this.fileChunks[file.name].failed = true;
      });
    }
  }

  private getMetadataFieldValue(fieldValueIndex: number, metadataValues: IMetadataSelectedValue[]) {
    return metadataValues?.find((v) => v.fieldValueIndex === fieldValueIndex)?.value;
  }

  private addFile(taskTeamId: number, selectedFile: IFileMetadataValue, chunks: IChunk[]) {
    const file = selectedFile.file;
    return addFile({
      taskTeamId: taskTeamId,
      filename: file.name,
      totalFileSize: file.size,
      totalFileChunks: chunks.length,
      fieldValue1: this.getMetadataFieldValue(1, selectedFile.metadata),
      fieldValue2: this.getMetadataFieldValue(2, selectedFile.metadata),
      fieldValue3: this.getMetadataFieldValue(3, selectedFile.metadata),
      fieldValue4: this.getMetadataFieldValue(4, selectedFile.metadata),
      fieldValue5: this.getMetadataFieldValue(5, selectedFile.metadata),
      fieldValue6: this.getMetadataFieldValue(6, selectedFile.metadata),
      fieldValue7: this.getMetadataFieldValue(7, selectedFile.metadata),
      fieldValue8: this.getMetadataFieldValue(8, selectedFile.metadata),
      fieldValue9: this.getMetadataFieldValue(9, selectedFile.metadata),
      fieldValue10: this.getMetadataFieldValue(10, selectedFile.metadata),
      fieldValue11: this.getMetadataFieldValue(11, selectedFile.metadata),
      fieldValue12: this.getMetadataFieldValue(12, selectedFile.metadata),
      fieldValue13: this.getMetadataFieldValue(13, selectedFile.metadata),
      fieldValue14: this.getMetadataFieldValue(14, selectedFile.metadata),
      fieldValue15: this.getMetadataFieldValue(15, selectedFile.metadata),
      fieldValue16: this.getMetadataFieldValue(16, selectedFile.metadata),
      fieldValue17: this.getMetadataFieldValue(17, selectedFile.metadata),
      fieldValue18: this.getMetadataFieldValue(18, selectedFile.metadata),
      fieldValue19: this.getMetadataFieldValue(19, selectedFile.metadata),
      fieldValue20: this.getMetadataFieldValue(20, selectedFile.metadata),
    });
  }

  private async uploadChunks(file: File, uploadFile: IUploadFile, fileUploadId: number, isSupersede?: boolean) {
    // must use a for loop here to ensure each upload is complete before starting the next
    for (const chunk of uploadFile.chunks) {
      const blob = file.slice(chunk.start, chunk.end);
      const isLast = chunk.index === uploadFile.chunks.length - 1;
      await upload({
        blob: blob,
        projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileUploadId: fileUploadId,
        index: chunk.index,
        offset: chunk.start,
        isLastChunk: isLast,
        isSupersede: isSupersede,
        fileName: file.name,
      });
      runInAction(() => {
        uploadFile.numberOfChunksUploaded++;
      });
    }
  }

  private sort(a: string | number, b: string | number, direction: SortType) {
    const sort = direction === SortTypes.ASC ? 1 : -1;
    return a > b ? sort : -sort;
  }

  private loadTableColumns() {
    const tableColumns: ITableColumn[] = [
      {
        label: 'Original Filename',
        valueField: TextFilterField.FileName,
        textFilter: { filter: '' },
      },
      {
        label: 'Status',
        valueField: TextFilterField.Status,
      },
    ];
    const descMetadata = this.fileMetadata.find((m) => m.title === MetadataFieldTitle.Description);
    if (descMetadata) {
      tableColumns.push({
        label: descMetadata.title,
        valueField: `${descMetadata.fieldValueIndex}`,
        textFilter: { filter: '' },
      } as ITableColumn);
    }
    this.fileMetadata
      .filter((f) => f.title !== MetadataFieldTitle.Description)
      .forEach((field) => {
        const fieldValue = this.fileDataValues.find((val) => val.fieldValueIndex === field.fieldValueIndex)?.value;
        tableColumns.push({
          label: field.title,
          valueField: `${field.fieldValueIndex}`,
          listFilter:
            field.dataType?.fieldType === MetadataFieldType.List
              ? {
                  fieldValues: orderBy(
                    uniq(field.dataType.fieldValues.filter((f) => f.code === fieldValue).map((v) => v.title))
                  ),
                  filter: '',
                }
              : undefined,
          textFilter: field.dataType?.fieldType === MetadataFieldType.UserText ? { filter: '' } : undefined,
        } as ITableColumn);
      });
    tableColumns.push({
      label: 'File Size',
      valueField: TextFilterField.FileSize,
      textFilter: { filter: '' },
    } as ITableColumn);
    runInAction(() => {
      this.tableColumns = tableColumns;
    });
  }

  public get uploadCompletePercentage() {
    let total = 0;
    let uploaded = 0;
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      total += uploadFile.chunks.length;
      uploaded += uploadFile.failed ? uploadFile.chunks.length : uploadFile.numberOfChunksUploaded;
    });
    const percentage = (uploaded / total) * 100;
    return Number(percentage.toFixed(0));
  }

  public get filesFailedToUpload() {
    const failedFiles: string[] = [];
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      if (uploadFile.failed) failedFiles.push(filename);
    });
    return failedFiles;
  }

  private getParentFieldSelectedValue(field: IMetadataField): IMetadataSelectedValue | undefined {
    return this.fileDataValues.find((fv) => fv.fieldValueIndex === field.dataType.parentFieldValueIndex);
  }

  private getLinkedFieldValues(
    field: IMetadataField,
    parentFieldSelected: IMetadataSelectedValue
  ): IFileMetadataFieldValue[] | undefined {
    return field.dataType.fieldValues.filter((fv) => fv.parentCode === parentFieldSelected.value);
  }

  public showMetadataField(field: IMetadataField): boolean {
    //Ignore if it's not a sub field / linked field
    if (!field.dataType.parentFieldValueIndex) return true;

    return this.getDropdownValues(field)?.length > 0;
  }

  public getDropdownValues(field: IMetadataField): IDropDownField[] {
    let fieldValues: IFileMetadataFieldValue[] = [];

    //If it's not a linked list, just return all the values
    if (!field.dataType.parentFieldValueIndex) {
      fieldValues = field.dataType.fieldValues;
    } else {
      //Get the values linked to the parent field
      const parentFieldSelected = this.getParentFieldSelectedValue(field);

      if (parentFieldSelected) {
        fieldValues = this.getLinkedFieldValues(field, parentFieldSelected) ?? [];
      }
    }

    return fieldValues.map((fv, key) => {
      return { key: `${key}`, id: fv.code, label: fv.title };
    });
  }

  private async addSupersedeFile(file: File, fileId: number, fileRevisionId: number | null) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    try {
      const result = await addSupersedeFile({
        taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
        fileId: fileId,
        filename: file.name,
        totalFileSize: file.size,
        totalFileChunks: this.fileChunks[file.name].chunks.length,
        fileRevisionId: fileRevisionId,
      });

      let isSuccess = false;
      try {
        await this.uploadChunks(file, this.fileChunks[file.name], result.fileUploadId, true);
        isSuccess = true;
      } catch {
        isSuccess = false;
      }

      if (!isSuccess) {
        runInAction(() => {
          this.showUploadFailed = true;
        });
      } else {
        runInAction(() => {
          this.showUploadSuccess = true;
          this.openResultsModal = true;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.fileChunks[file.name].failed = true;
      });
    }
  }

  public async handleUploadSupersedeFile(fileId: number, fileRevisionId: number | null) {
    this.startUploadProcess();
    if (this.selectedfiles.length > 0) {
      const selectedFile = this.selectedfiles[0];
      this.setCalculateFileChunks(selectedFile.file);
      await this.addSupersedeFile(selectedFile.file, fileId, fileRevisionId);
    }
  }

  public getResultsCSVData() {
    return {
      filename: `${NavBarSelectorStore.selectedItem?.project.projectNumber}_Bulk_Upload_Result_${dateTimeFormat(
        new Date(),
        'yyyyMMdd'
      )}.csv`,
      headers: [
        { label: 'Original File Name', key: 'fileName' },
        { label: 'Upload Status', key: 'status' },
      ],
      data: Object.keys(this.fileChunks).map((fileName) => ({
        fileName,
        status: this.fileChunks[fileName].failed ? 'Failed' : 'Success',
      })),
    };
  }

  private updateSelectedSupersedeFiles(files: ISelectedFile[], selectedFiles: IFileMetadataValue[]) {
    selectedFiles.forEach((f) => {
      const updatedFile = files.find((sf) => sf.fileName === f.file.name);
      if (!updatedFile) return;

      const keys = Object.keys(updatedFile).filter((key) => key.startsWith('fieldValue'));
      const fieldValues = keys.map((key, index) => ({
        fieldValueIndex: index + 1,
        value: updatedFile[key],
        title: updatedFile[key],
      }));

      f.status = updatedFile.status;
      f.missingMetadata = false;
      f.fileId = updatedFile.fileId;
      f.fileRevisionId = updatedFile.fileRevisionId;
      f.isLocked = updatedFile.isLocked;
      f.metadata = fieldValues;
    });
  }
  public updateSelectedFilesStatus(files: ISelectedFile[]) {
    runInAction(() => {
      this.updateSelectedSupersedeFiles(files, this.selectedfiles);
      this.updateSelectedSupersedeFiles(files, this.filteredFiles);
    });
  }

  public clearFileData() {
    runInAction(() => {
      this.fileDataValues = [];
    });
  }
}

export default new UploadStore();
