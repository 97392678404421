import { IDateInputDates, IOption } from '@aurecon-creative-technologies/styleguide';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  getProjectMembersAndTeams,
  IProjectMembersAndTeams,
  IUser,
} from '../../api/authenticated/um/getProjectMembersAndTeams';
import { createTransmittal, ICreateTransmittal } from '../../api/authenticated/transmittals/createTransmittal';
import { AxiosError } from 'axios';
import { getTransmittalTypes, IType } from '../../api/authenticated/transmittals/getTransmittalTypes';
import { getTransmittalReasons, IReason } from '../../api/authenticated/transmittals/getTransmittalReasons';
import { IFile } from '../../api/authenticated/cms/FileModel';
import { startOfDay, subDays } from 'date-fns';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { IAttachmentFile } from '../../api/authenticated/transmittals/getTransmittal';
import { getTransmittalMessageAttachmentFiles } from '../../api/authenticated/transmittals/getTransmittalMessageAttachmentFiles';
import { TaskTeamItem } from '../../common/models/ItemType';
import { ITeamChartResult } from '../TeamChart/interface/TeamChart.interface';
import { TransmittalTeamChartType } from './Types';

export class CreateTransmittalStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public projectMembersAndTeams: IProjectMembersAndTeams | null = null;
  public errorMessage: string | null = null;
  public hasUnsavedChanges = false;
  public hasEmptyMandatoryField = true;

  public createTransmittal: ICreateTransmittal | null = null;

  public showUploadSupportFileModal = false;
  public transmittalTitle?: string | null = null;
  public transmittalMessageId?: number | null = null;
  public draftTransmittalId?: number;
  public draftTransmittalMessageId?: number;
  public draft = false;
  public supportingFiles: IAttachmentFile[] = [];
  public openPanelIds = new Set<string>();

  public types: IType[] | null = null;
  public typeItems: IOption[] = [];

  public reasons: IReason[] | null = null;
  public reasonItems: IOption[] = [];
  public allReasons: IReason[] | [] = [];
  public contentFiles: IFile[] = [];

  public subjectErrorMessage: string | undefined;
  public isOpenFiles = false;
  public dueDateErrorMessage: string | undefined;
  public isProcessing = false;
  public showUploadSupportFileErrorModal = false;
  public uploadSupportFileErrorMessage?: string;

  public init() {
    const selectedItem = NavBarSelectorStore.selectedItem as TaskTeamItem;
    if (!NavBarSelectorStore.selectedItem) return;
    this.createTransmittal = {
      projectNumber: selectedItem.project.projectNumber,
      type: 0,
      reason: 0,
      subject: '',
      message: '',
      initiatorTaskTeamId: selectedItem.taskTeam?.id ?? null,
      dueDate: null,
      notifyToUsers: [],
      visibleToTaskTeamUsers: [],
      visibleToDeliveryTeams: [],
      visibleToTaskTeams: [],
      visibleToAppointingParties: [],
      draft: false,
      transmittalTitle: '',
    };

    runInAction(() => {
      this.typeItems = [];
      this.reasonItems = [];
      this.showUploadSupportFileErrorModal = false;
    });
  }

  public get projectNumber() {
    return NavBarSelectorStore.selectedItem?.project.projectNumber;
  }

  public get disableTransmitButton(): boolean {
    if (!this.createTransmittal) return true;

    return (
      !this.createTransmittal.type ||
      !this.createTransmittal.reason ||
      !this.createTransmittal.notifyToUsers ||
      !this.createTransmittal.notifyToUsers.length ||
      !this.createTransmittal.subject ||
      (this.createTransmittal.subject && this.createTransmittal.subject.length > 1000) ||
      !this.createTransmittal.message ||
      this.isProcessing ||
      this.isInvalidDueDate(this.createTransmittal.dueDate)
    );
  }

  public setIsOpenFiles(open: boolean) {
    this.isOpenFiles = open;
  }

  public setContentFiles(files: IFile[]) {
    const newFiles = files.filter((file) => !this.contentFiles.some((contentFile) => contentFile.id === file.id));
    this.contentFiles = [...this.contentFiles, ...newFiles];
  }

  public removeContentFile(fileId: number) {
    const idx = this.contentFiles.findIndex((f) => f.id === fileId);
    if (idx < 0) return;

    this.contentFiles.splice(idx, 1);
  }

  onSubjectChange = (value: string) => {
    runInAction(() => {
      if (!this.createTransmittal) return;
      if (value.length > 1000) this.subjectErrorMessage = 'Subject must not exceed 1000 characters';
      else this.subjectErrorMessage = undefined;
      this.createTransmittal.subject = value;
    });
  };

  onMessageChange = (value: string) => {
    runInAction(() => {
      if (!this.createTransmittal) return;

      //check if the value is empty
      if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        this.createTransmittal.message = '';
      } else {
        this.createTransmittal.message = value;
      }
    });
  };

  onEditorBlur = () => {
    runInAction(() => {
      if (!this.createTransmittal) return;
      const formattedMessage = this.createTransmittal.message.trim();

      this.createTransmittal.message = formattedMessage;
    });
  };

  public isInvalidDueDate(date: Date | null): boolean {
    const todayStart = startOfDay(subDays(new Date(), 0));
    return !!date && date < todayStart;
  }

  onDueDateChange = (value: IDateInputDates) => {
    runInAction(() => {
      if (!this.createTransmittal) return;

      this.dueDateErrorMessage = this.isInvalidDueDate(value.startDate)
        ? 'Due Date cannot be prior to current date.'
        : undefined;

      this.createTransmittal.dueDate = value.startDate;
    });
  };

  public getMatchedNotifiedUsers(searchText: string): IUser[] {
    if (!this.projectMembersAndTeams) return [] as IUser[];
    return this.projectMembersAndTeams.users.filter(
      (r) =>
        (this.compareText(r.userName, searchText) || this.compareText(r.userEmail, searchText)) &&
        !this.createTransmittal?.notifyToUsers.some((u) => u.userId === r.userId)
    );
  }

  public onSelectedNotifiedUsersUpdated(users: IUser[]) {
    runInAction(() => {
      if (!this.createTransmittal) return;
      this.createTransmittal.notifyToUsers = [];

      users.forEach((u) => {
        const user = this.projectMembersAndTeams?.users.find(
          (r) => r.userId === u.userId && r.taskTeamId === u.taskTeamId && r.deliveryTeamId === u.deliveryTeamId
        );

        if (!user) return;
        this.addSelectedNotifyToUser(user);
      });
    });
  }

  public setNotifierUsersFromTeamChart(teamChartData: ITeamChartResult) {
    if (!this.createTransmittal) return;

    const originIds = this.createTransmittal.notifyToUsers.map((x) => {
      return x.userId;
    });
    const newIds = teamChartData.taskTeamUsers.map((n) => {
      return n.id;
    });
    const removeIds = originIds?.filter((u) => !newIds.includes(u));
    const addIds = newIds.filter((u) => !originIds?.includes(u));

    this.removeSelectedNotifyUserByIds(removeIds);
    this.setAddTeamChartNotifyDeliveryTeam(teamChartData);
    this.setAddTeamChartNotifyTaskTeam(teamChartData, addIds);
    this.setAddTeamChartNotifyExternalTeam(teamChartData);
  }

  private removeSelectedNotifyUserByIds(removeIds: number[]) {
    if (!removeIds.length || !this.createTransmittal?.notifyToUsers.length) return;

    this.createTransmittal.notifyToUsers = this.createTransmittal?.notifyToUsers.filter(
      (x) => !removeIds.includes(x.userId)
    );
  }

  private setAddTeamChartNotifyDeliveryTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.deliveryUsers.length) return;

    teamChartData.deliveryUsers.forEach((u) => {
      const user = this.projectMembersAndTeams?.users.find(
        (r) => r.userId === u.id && r.deliveryTeamId === u.deliveryTeamId
      );

      if (!user) return;
      const selectedUser = this.createTransmittal?.notifyToUsers.find(
        (u) => u.userId === user.userId && u.deliveryTeamId === user.deliveryTeamId
      );
      if (selectedUser) return;
      this.addSelectedNotifyToUser(user);
    });
  }

  private setAddTeamChartNotifyTaskTeam(teamChartData: ITeamChartResult, addIds: number[]) {
    if (!addIds.length) return;

    teamChartData.taskTeamUsers
      .filter((x) => addIds.includes(x.id))
      .forEach((u) => {
        const user = this.projectMembersAndTeams?.users.find(
          (r) => r.userId === u.id && r.deliveryTeamId === u.deliveryTeamId && r.taskTeamId === u.taskTeamId
        );
        if (!user) return;
        const selectedUser = this.createTransmittal?.notifyToUsers.find(
          (u) =>
            u.userId === user.userId && u.deliveryTeamId === user.deliveryTeamId && u.taskTeamId === user.taskTeamId
        );
        if (selectedUser) return;
        this.addSelectedNotifyToUser(user);
      });
  }

  private setAddTeamChartNotifyExternalTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.externalUsers.length) return;

    teamChartData.externalUsers.forEach((u) => {
      const user = this.projectMembersAndTeams?.users.find((r) => r.userId === u.id);
      if (!user) return;
      const selectedUser = this.createTransmittal?.notifyToUsers.find(
        (u) => u.userId === user.userId && u.isExternal === user.isExternal
      );
      if (selectedUser) return;
      this.addSelectedNotifyToUser(user);
    });
  }

  private addSelectedNotifyToUser(user: IUser) {
    this.createTransmittal?.notifyToUsers.push({
      ...user,
      email: user.userEmail,
      name: user.userName,
    });
  }

  public setVisibilityUsersFromTeamChart(teamChartData: ITeamChartResult) {
    if (!this.createTransmittal) return;

    const originIds = this.createTransmittal.visibleToTaskTeamUsers.map((x) => {
      return x.userId;
    });
    const newIds = teamChartData.taskTeamUsers.map((n) => {
      return n.id;
    });
    const removeIds = originIds?.filter((u) => !newIds.includes(u));
    const addIds = newIds.filter((u) => !originIds?.includes(u));

    this.removeSelectedVisibleUserByIds(removeIds);
    this.setAddTeamChartVisibleDeliveryTeam(teamChartData);
    this.setAddTeamChartVisibleTaskTeam(teamChartData, addIds);
    this.setAddTeamChartVisibleExternalTeam(teamChartData);
  }

  private removeSelectedVisibleUserByIds(removeIds: number[]) {
    if (!removeIds.length || !this.createTransmittal?.visibleToTaskTeamUsers.length) return;

    this.createTransmittal.visibleToTaskTeamUsers = this.createTransmittal?.visibleToTaskTeamUsers.filter(
      (x) => !removeIds.includes(x.userId)
    );
  }

  private setAddTeamChartVisibleDeliveryTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.deliveryUsers.length) return;

    teamChartData.deliveryUsers.forEach((u) => {
      const user = this.projectMembersAndTeams?.users.find(
        (r) => r.userId === u.id && r.deliveryTeamId === u.deliveryTeamId
      );

      if (!user) return;
      const selectedUser = this.createTransmittal?.visibleToTaskTeamUsers.find(
        (u) => u.userId === user.userId && u.deliveryTeamId === user.deliveryTeamId
      );
      if (selectedUser) return;
      this.addSelectedVisibleToUser(user);
    });
  }

  private setAddTeamChartVisibleTaskTeam(teamChartData: ITeamChartResult, addIds: number[]) {
    if (!addIds.length) return;

    teamChartData.taskTeamUsers
      .filter((x) => addIds.includes(x.id))
      .forEach((u) => {
        const user = this.projectMembersAndTeams?.users.find((r) => r.userId === u.id);
        if (!user) return;
        const selectedUser = this.createTransmittal?.visibleToTaskTeamUsers.find((u) => u.userId === user.userId);
        if (selectedUser) return;
        this.addSelectedVisibleToUser(user);
      });
  }

  private setAddTeamChartVisibleExternalTeam(teamChartData: ITeamChartResult) {
    if (!teamChartData.externalUsers.length) return;

    teamChartData.externalUsers.forEach((u) => {
      const user = this.projectMembersAndTeams?.users.find((r) => r.userId === u.id);
      if (!user) return;
      const selectedUser = this.createTransmittal?.visibleToTaskTeamUsers.find(
        (u) => u.userId === user.userId && u.isExternal === user.isExternal
      );
      if (selectedUser) return;
      this.addSelectedVisibleToUser(user);
    });
  }

  private addSelectedVisibleToUser(user: IUser) {
    this.createTransmittal?.visibleToTaskTeamUsers.push({
      ...user,
      email: user.userEmail,
      name: user.userName,
    });
  }

  public getMatchedVisibilityItems(searchText: string): IProjectMembersAndTeams {
    if (!this.projectMembersAndTeams)
      return { users: [], deliveryTeams: [], taskTeams: [], appointingParties: [] } as IProjectMembersAndTeams;
    return {
      users: this.projectMembersAndTeams.users
        .filter(
          (r) =>
            (this.compareText(r.userName, searchText) || this.compareText(r.userEmail, searchText)) &&
            !this.createTransmittal?.visibleToTaskTeamUsers.some((u) => u.userId === r.userId)
        )
        .slice(0, 4),
      deliveryTeams: this.projectMembersAndTeams.deliveryTeams
        .filter(
          (r) =>
            this.compareText(r.deliveryTeamTitle, searchText) &&
            !this.createTransmittal?.visibleToDeliveryTeams.some((u) => u.deliveryTeamId === r.deliveryTeamId)
        )
        .slice(0, 4),
      taskTeams: this.projectMembersAndTeams.taskTeams
        .filter(
          (r) =>
            this.compareText(r.taskTeamTitle, searchText) &&
            !this.createTransmittal?.visibleToTaskTeams.some((u) => u.taskTeamId === r.taskTeamId)
        )
        .slice(0, 4),
      appointingParties: this.projectMembersAndTeams.appointingParties
        .filter(
          (r) =>
            this.compareText(r.appointingPartyTitle, searchText) &&
            !this.createTransmittal?.visibleToAppointingParties.some((u) => u.appointingPartyId === r.appointingPartyId)
        )
        .slice(0, 4),
    };
  }

  public onSelectedVisibilityItemsUpdated(selectedProjectMembersAndTeams: IProjectMembersAndTeams) {
    runInAction(() => {
      if (!this.createTransmittal) return;

      this.createTransmittal.visibleToTaskTeamUsers = [];
      selectedProjectMembersAndTeams.users.forEach((user) => {
        this.addSelectedVisibleToUser(user);
      });

      this.createTransmittal.visibleToDeliveryTeams = selectedProjectMembersAndTeams.deliveryTeams ?? [];
      this.createTransmittal.visibleToTaskTeams = selectedProjectMembersAndTeams.taskTeams ?? [];
      this.createTransmittal.visibleToAppointingParties = selectedProjectMembersAndTeams.appointingParties ?? [];
    });
  }

  public async onSavingTransmittal() {
    this.onEditorBlur();
    if (!this.createTransmittal) return;
    runInAction(() => {
      this.errorMessage = null;
      this.isProcessing = true;
    });
    try {
      const result = await createTransmittal({
        ...this.createTransmittal,
        draft: false,
        visibleToTaskTeams: this.createTransmittal.visibleToTaskTeams.map((t) => t.taskTeamId),
        visibleToDeliveryTeams: this.createTransmittal.visibleToDeliveryTeams.map((t) => t.deliveryTeamId),
        visibleToAppointingParties: this.createTransmittal.visibleToAppointingParties.map((t) => t.appointingPartyId),
        ContentFileIds: this.contentFiles.filter((f) => !!f.releasedFileId).map((f) => Number(f.releasedFileId)),
      });

      runInAction(() => {
        this.transmittalTitle = result.transmittalTitle;
        this.isProcessing = false;
      });
      this.init();
    } catch (errors) {
      runInAction(() => {
        this.errorMessage = (errors as AxiosError<string>)?.response?.data ?? 'Transmittal cannot be created.';
        this.isProcessing = false;
      });
    }
  }

  private compareText(firstText: string, secondText: string) {
    return firstText.toLowerCase().indexOf(secondText.toLowerCase()) > -1;
  }

  public async getOptionUsers() {
    if (!NavBarSelectorStore.selectedItem?.project) return;

    try {
      const projectMembersAndTeams = await getProjectMembersAndTeams(
        NavBarSelectorStore.selectedItem.project.projectNumber
      );
      projectMembersAndTeams.users.sort((a, b) => (a.userName > b.userName ? 1 : -1));
      projectMembersAndTeams.taskTeams.sort((a, b) => (a.taskTeamTitle > b.taskTeamTitle ? 1 : -1));

      runInAction(() => {
        this.projectMembersAndTeams = projectMembersAndTeams;
      });
    } catch {
      runInAction(() => {
        this.projectMembersAndTeams = null;
      });
    }
  }

  public async getTypes() {
    if (!NavBarSelectorStore.selectedItem?.project) return;
    try {
      const projectTypes = await getTransmittalTypes(NavBarSelectorStore.selectedItem.project.projectNumber);

      runInAction(() => {
        if (!projectTypes) return null;

        this.typeItems = projectTypes.map((t) => {
          return { id: t.id.toString(), value: t.title };
        });

        return this.typeItems;
      });
    } catch {
      runInAction(() => {
        this.types = null;
      });
    }
  }

  public handleTypeChange(typeId?: number) {
    runInAction(() => {
      if (!this.createTransmittal) return;
      this.createTransmittal.type = typeId ?? 0;
      this.createTransmittal.reason = 0;

      if (!this.allReasons.length || !this.createTransmittal.type) {
        this.reasonItems = [];
      } else {
        this.reasonItems = this.allReasons
          .filter((r) => r.transmittalTypeId === this.createTransmittal?.type)
          .map((reason) => ({
            id: reason.id.toString(),
            value: reason.title,
          }));
      }
    });
  }

  public async getReasons() {
    if (!NavBarSelectorStore.selectedItem?.project) return;
    try {
      const transmittalReasons = await getTransmittalReasons(NavBarSelectorStore.selectedItem.project.projectNumber);

      runInAction(() => {
        if (!transmittalReasons) return null;

        this.allReasons = transmittalReasons;
        return this.allReasons;
      });
    } catch {
      runInAction(() => {
        this.allReasons = [];
      });
    }
  }

  public handleReasonChange(reason?: number) {
    runInAction(() => {
      if (!this.createTransmittal) return;
      this.createTransmittal.reason = reason ?? 0;
    });
  }

  public clear() {
    runInAction(() => {
      if (!this.createTransmittal) return;

      this.createTransmittal.notifyToUsers = [];
      this.createTransmittal.visibleToTaskTeamUsers = [];
      this.createTransmittal.visibleToDeliveryTeams = [];
      this.createTransmittal.visibleToTaskTeams = [];
      this.createTransmittal.visibleToAppointingParties = [];
      this.createTransmittal.type = 0;
      this.createTransmittal.reason = 0;
      this.createTransmittal.dueDate = null;
      this.createTransmittal.subject = '';
      this.createTransmittal.message = '';
      this.createTransmittal.transmittalTitle = '';
      this.contentFiles = [];
      this.isOpenFiles = false;
      this.draft = false;
      this.draftTransmittalId = undefined;
      this.draftTransmittalMessageId = undefined;
      this.supportingFiles = [];
      this.showUploadSupportFileErrorModal = false;
    });
  }

  public async toggleUploadFileModal(open: boolean, uploadSucceeded?: boolean, errorMessage?: string) {
    runInAction(() => {
      this.showUploadSupportFileModal = open;
    });

    if (!uploadSucceeded && errorMessage) {
      runInAction(() => {
        this.showUploadSupportFileErrorModal = true;
        this.uploadSupportFileErrorMessage = errorMessage;
      });
    }

    // load supporting files
    if (this.getNavBarSelectorProjectNumber && this.draftTransmittalId && this.draftTransmittalMessageId) {
      const supportingFiles = await getTransmittalMessageAttachmentFiles(
        this.getNavBarSelectorProjectNumber,
        this.draftTransmittalId,
        this.draftTransmittalMessageId
      );
      runInAction(() => {
        this.supportingFiles = [...supportingFiles];
      });
    }
  }

  public panelToggle(id: string) {
    runInAction(() => {
      this.openPanelIds.has(id) ? this.openPanelIds.delete(id) : this.openPanelIds.add(id);
    });
  }

  public get getCreateTransmittal() {
    return this.createTransmittal;
  }

  public setDraftInfo(transmittalTitle: string, transmittalId: number, transmittalMessageId: number) {
    runInAction(() => {
      if (this.createTransmittal) {
        this.createTransmittal.transmittalId = transmittalId;
        this.createTransmittal.transmittalMessageId = transmittalMessageId;
        this.createTransmittal.transmittalTitle = transmittalTitle;
        this.createTransmittal.draft = true;
      }
      this.draftTransmittalId = transmittalId;
      this.draftTransmittalMessageId = transmittalMessageId;
      this.draft = true;
    });
  }

  public setShowUploadSupportFileErrorModal(open: boolean) {
    runInAction(() => {
      this.showUploadSupportFileErrorModal = open;
    });
  }

  public selectedUsersForTeamChart(dataType?: TransmittalTeamChartType) {
    if (!dataType) return [];
    if (dataType === 'Notify') return this.createTransmittal?.notifyToUsers;
    if (dataType === 'Visible') return this.createTransmittal?.visibleToTaskTeamUsers;
  }

  public get getNavBarSelectorProjectNumber() {
    return NavBarSelectorStore.selectedItem?.project.projectNumber;
  }

  public get getUploadSupportingFiles() {
    return this.supportingFiles;
  }
}

export default new CreateTransmittalStore();
