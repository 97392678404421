import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow, TableCell, IHeader, Checkbox } from '@aurecon-creative-technologies/styleguide';
import { IFile } from '../../../api/authenticated/cms/FileModel';
import FilesStore from '../FilesStore';
import Icon from '../../shared/Icon';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import DropDownMenuForTableCell from '../../shared/DropDownMenuForTableCell';
import WorkflowPill from '../../shared/WorkflowPill';
import Style from './styles/FileTableRow.module.scss';
import { getFileSizeDetails, getFileSizeString } from '../../../utils/miscUtils';
import DownloadModal from '../../shared/DownloadModal';
import SupersedeFileUpload from '../wipTab/upload/SupersedeFileUpload';
import { ContentSelection } from '../ContentSelection';
import { ITableColumn } from './TableColumn';
import LayoutStore from '../../layout/LayoutStore';
import ErrorModal from '../../shared/ErrorModal';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import { dateTime12HFormat } from '../../../utils/dateUtils';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
export interface IFileTableRowProps {
  headers: IHeader[];
  file: IFile;
  tableColumns: ITableColumn[];
}

const ShiftKey = 'Shift';
const BodyTagName = 'body';
const FileTableRow: FC<IFileTableRowProps> = ({ headers, file, tableColumns }) => {
  const { totalFileSize, isOverLimit } = getFileSizeDetails(file.uploadedSize);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showSupersedeFileModal, setShowSupersedeFileModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [shiftOn, setShiftOn] = useState(false);
  useEffect(() => {
    const handleKeyPressDown = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== BodyTagName) return;
      if (e.key === ShiftKey) {
        window?.getSelection()?.removeAllRanges();
        setShiftOn(true);
      }
    };
    const handleKeyPressUp = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== BodyTagName) return;
      if (e.key === ShiftKey) {
        window.getSelection();
        setShiftOn(false);
      }
    };
    window.addEventListener('keydown', handleKeyPressDown, false);
    window.addEventListener('keyup', handleKeyPressUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyPressUp);
      window.removeEventListener('keydown', handleKeyPressDown);
    };
  }, [shiftOn]);

  const cellValue = (h: IHeader) => {
    const col = tableColumns.find((c) => c.label === h.label);
    if (!col) {
      return '';
    }
    if (file[col.valueField] instanceof Date) return dateTime12HFormat(file[col.valueField] as Date);
    return file[col.valueField];
  };

  const handleOpenSupersedeModal = async (fileId: number) => {
    const canLock = await FilesStore.lockSupersedeFile(fileId);
    if (canLock) {
      setShowSupersedeFileModal(true);
    }
  };
  const handleResendToForge = async (fileRevisionId: number | null) => {
    await FilesStore.resendToForge(fileRevisionId);
    if (FilesStore.errorMessage !== null) setShowErrorModal(true);
    else LayoutStore.displayToast('success', 'The file was resent successfully.');
  };

  const handleForgeViewer = async (fileRevisionId: number | null) => {
    if (fileRevisionId === null) return;
    await ForgeViewerStore.getForgeViewer(fileRevisionId, NavBarSelectorStore.selectedItem!.project.projectNumber);
    if (ForgeViewerStore.errorMessage !== null) setShowErrorModal(true);
  };

  return (
    <>
      <TableRow key={FilesStore.getFileKey(file)}>
        {headers.map((h, i) => {
          const def = headers[i];
          if (def.onCheckbox) {
            return (
              <TableCell key={i}>
                <Checkbox
                  onChange={(checked) => FilesStore.handleSelectStart(file, checked, shiftOn)}
                  checked={FilesStore.isFileSelected(file)}
                />
              </TableCell>
            );
          }
          if (def.label === '') {
            return (
              <DropDownMenuForTableCell key={i}>
                <DropDownMenuButton onClick={() => FilesStore.showFileInformation(file, null)}>Info</DropDownMenuButton>
                <DropDownMenuButton
                  onClick={() => {
                    if (isOverLimit) {
                      setShowDownloadModal(true);
                    } else {
                      FilesStore.downloadFile(file);
                    }
                  }}>
                  Download
                </DropDownMenuButton>
                {file.hasDocumentViewer && (
                  <DropDownMenuButton onClick={() => FilesStore.openFile(file)}>Open File</DropDownMenuButton>
                )}
                {file.isForgeFile && file.fileRevisionId !== null && (
                  <DropDownMenuButton onClick={() => handleForgeViewer(file.fileRevisionId)}>
                    Open File
                  </DropDownMenuButton>
                )}
                {FilesStore.selectedSection == ContentSelection.WIP && (
                  <DropDownMenuButton
                    disabled={file.isLocked}
                    onClick={function (): void {
                      handleOpenSupersedeModal(file.id);
                    }}>
                    Supersede
                  </DropDownMenuButton>
                )}
                {file.canSubmitForge && (
                  <DropDownMenuButton onClick={() => handleResendToForge(file.fileRevisionId)}>
                    Resend to Forge
                  </DropDownMenuButton>
                )}
              </DropDownMenuForTableCell>
            );
          }
          if (def.label === 'Workflow') {
            return (
              <TableCell key={i}>
                <WorkflowPill taskTypeId={file.taskTypeId} />
              </TableCell>
            );
          }
          if (def.label === 'File Size') {
            return (
              <TableCell key={i}>
                <span>{getFileSizeString(file.uploadedSize)}</span>
              </TableCell>
            );
          }
          if (def.label === 'Task Team Originator') {
            return (
              <TableCell key={i}>
                <span>
                  {file.deliveryTeamTitle}
                  <strong> | {file.taskTeamTitle}</strong>
                </span>
              </TableCell>
            );
          }
          return (
            <TableCell align={def.align} key={i} checkbox>
              {i == 1 ? (
                <div className={Style.filenameCell}>
                  <span>{cellValue(h)}</span>
                  {file.isLocked && <Icon className={Style.lockIcon} name="lock" />}
                </div>
              ) : (
                cellValue(h)
              )}
            </TableCell>
          );
        })}
      </TableRow>

      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FilesStore.downloadFile(file);
          setShowDownloadModal(false);
        }}
      />

      {FilesStore.selectedSection == ContentSelection.WIP && showSupersedeFileModal && (
        <SupersedeFileUpload
          fileId={file.id}
          fileRevisionId={file.fileRevisionId}
          fileExtension={file.extension}
          closeModal={function (): void {
            FilesStore.closeSupersedeModal(file.id);
            setShowSupersedeFileModal(false);
          }}
          showModal={showSupersedeFileModal}
        />
      )}
      {FilesStore.isLockSupersedeFileError && (
        <ErrorModal
          closeModal={() => {
            FilesStore.closeLockSupersedeFileErrorModal();
          }}
        />
      )}
      {showErrorModal && <ErrorModal closeModal={() => setShowErrorModal(false)} />}
    </>
  );
};

export default observer(FileTableRow);
