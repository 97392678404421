import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useReviewStore } from './ReviewStoreContext';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import ModalActions from '../../shared/ModalActions';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';

const ActionButtons: FC = () => {
  const store = useReviewStore();

  const submit = async () => {
    await store.save();
  };

  return (
    <ModalActions>
      <SecondaryButton onClick={store.closeModal} disabled={store.isSaving}>
        Cancel
      </SecondaryButton>
      <ButtonSavingSpinner isSaving={store.isSaving}>
        <PrimaryButton disabled={!store.canSave} onClick={submit}>
          Submit
        </PrimaryButton>
      </ButtonSavingSpinner>
    </ModalActions>
  );
};

export default observer(ActionButtons);
