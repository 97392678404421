import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import DistanceMeasurement2D from '@arcgis/core/widgets/DistanceMeasurement2D';
import DirectLineMeasurement3D from '@arcgis/core/widgets/DirectLineMeasurement3D';
import VisualisationStore from '../VisualisationStore';

import WidgetBase from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';

const MeasurementLineWidget: FC = () => {
  return VisualisationStore.mapView.type === '2d'
    ? WidgetBase(DistanceMeasurement2D, { visible: true, unit: 'meters' }, Style.measurementWidgetWrapper)
    : WidgetBase(DirectLineMeasurement3D, { visible: true, unit: 'meters' }, Style.measurementWidgetWrapper);
};

export default observer(MeasurementLineWidget);
