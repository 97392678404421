import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';
import { TaskTeamItem } from './ItemType';
import Style from './styles/NavBarSelectorTaskTeam.module.scss';

export interface INavBarSelectorTaskTeamProps {
  item: TaskTeamItem;
}

const NavBarSelectorTaskTeam: React.FC<INavBarSelectorTaskTeamProps> = ({ item }) => {
  return (
    <NavBarSelectorItemContainer className={Style.container}>
      <Icon className={Style.icon} name="folder" />
      <span className={Style.selectedProgramme}>{item.programme.title}</span>
      <span>|</span>
      <span className={Style.selectedProject}>{item.project.title}</span>
      <Icon className={Style.icon} name="people" />
      <span>{item.taskTeam.deliveryTeamTitle}</span>
      <span>|</span>
      <span className={Style.taskTeamTitle}>{item.taskTeam.title}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorTaskTeam);
