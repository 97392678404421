import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Table,
  TableRow,
  TableCell,
  IHeader,
  Dropdown,
  Accordion,
  AccordionPanel,
  Tooltip,
  Button,
} from '@aurecon-creative-technologies/styleguide';
import AppointedPartiesStore from './AppointedPartiesStore';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import Style from './styles/AppointedPartiesUserTable.module.scss';
import AddDeliveryTeamAuthoriserStore from './AddDeliveryTeamAuthoriserStore';
import AddTaskTeamMemberStore from './AddTaskTeamMemberStore';

const headers: IHeader[] = [
  {
    label: 'User Name',
    style: { width: '33%' },
  },
  {
    label: 'User Email',
    style: { width: '33%' },
  },
  {
    label: 'Additional Roles',
    style: { width: '33%' },
  },
  {
    label: '',
  },
];

const AppointedPartiesUserTable: FC = () => {
  return (
    <div>
      <Accordion
        activePanelIds={Array.from(AppointedPartiesStore.activePanelIds)}
        onPanelToggle={AppointedPartiesStore.handlePanelToggle}
        verticalPanelGap="24px"
        headingBgColour="#424242"
        headingFontColour="#FFFFFF"
        panelBgColour="#FAFAFA"
        headingIconColour="#FFFFFF">
        {AppointedPartiesStore.getDeliveryTeams().map((deliveryTeam) => (
          <AccordionPanel
            key={deliveryTeam.id}
            label={`${deliveryTeam.title} (${deliveryTeam.transmittalCode})`}
            panelId={`D-${deliveryTeam.id}`}
            rightLabelContents={
              <Tooltip show={<span className={Style.tooltip}>Add Delivery Team Authoriser</span>} defaultUp>
                <Button
                  type="icon-square"
                  icon="person_add"
                  cssClass={Style.addUserDeliveryTeam}
                  onClick={() => AddDeliveryTeamAuthoriserStore.show(deliveryTeam)}
                />
              </Tooltip>
            }>
            <div key={deliveryTeam.id}>
              <Table headers={headers} breakpoint={10} hoverable>
                {!deliveryTeam.users.length && (
                  <TableRow>
                    <TableCell colSpan={4}>No users in the delivery team.</TableCell>
                  </TableRow>
                )}
                {deliveryTeam.users.map((dtusers) => (
                  <TableRow key={dtusers.id}>
                    <TableCell>{dtusers.name}</TableCell>
                    <TableCell>{dtusers.email}</TableCell>
                    <TableCell>{'Delivery Team Authoriser'}</TableCell>
                    <TableCell>
                      <PrimaryIconButton
                        icon="delete"
                        onClick={() => AppointedPartiesStore.setRemoveDeliveryTeamUser(deliveryTeam, dtusers)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>

              <div className={Style.gap} />

              <Accordion
                activePanelIds={Array.from(AppointedPartiesStore.activePanelIds)}
                onPanelToggle={AppointedPartiesStore.handlePanelToggle}
                verticalPanelGap="24px"
                headingBgColour="#F2F2F2"
                headingFontColour="#000000"
                panelBgColour="#FAFAFA"
                headingIconColour="#000000">
                {deliveryTeam.taskTeams.map((taskTeam) => (
                  <AccordionPanel
                    key={taskTeam.id}
                    label={`${taskTeam.title} (${taskTeam.transmittalCode})`}
                    panelId={`T-${taskTeam.id}`}
                    rightLabelContents={
                      <Tooltip show={<span className={Style.tooltip}>Add Task Team Member</span>} defaultUp>
                        <Button
                          type="icon-square"
                          cssClass={Style.addTaskTeamMember}
                          icon="person_add"
                          onClick={() => AddTaskTeamMemberStore.show(deliveryTeam, taskTeam)}
                        />
                      </Tooltip>
                    }>
                    <div key={taskTeam.id}>
                      <Table headers={headers} breakpoint={10} hoverable>
                        {!taskTeam.users.length && (
                          <TableRow>
                            <TableCell colSpan={4}>No users in the task team.</TableCell>
                          </TableRow>
                        )}
                        {taskTeam.users.map((taskTeamUser) => (
                          <TableRow key={taskTeamUser.id}>
                            <TableCell>{taskTeamUser.name}</TableCell>
                            <TableCell>{taskTeamUser.email}</TableCell>
                            <TableCell>
                              <Dropdown
                                placeholder="Select Additional Role"
                                items={AppointedPartiesStore.appointedTaskTeamRoles}
                                selectedMultipleItems={taskTeamUser.taskTeamRoleIds}
                                onSelectMultipleItems={(value) =>
                                  AppointedPartiesStore.changeTaskTeamUserRole(taskTeam, taskTeamUser, value)
                                }
                                multiple={true}
                                multipleDisplayValues={true}
                              />
                            </TableCell>
                            <TableCell>
                              <PrimaryIconButton
                                icon="delete"
                                onClick={() =>
                                  AppointedPartiesStore.setRemoveTaskTeamUser({
                                    deliveryTeam,
                                    taskTeam,
                                    taskTeamUser,
                                  })
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </div>
                  </AccordionPanel>
                ))}
              </Accordion>
            </div>
          </AccordionPanel>
        ))}
      </Accordion>
    </div>
  );
};

export default observer(AppointedPartiesUserTable);
