import { serverAxios } from '../api';

export interface IDeleteFilesRequest {
  taskTeamId: number;
  fileId: number;
}

export interface IDeleteFilesResponse {
  message: string;
}

export async function deleteFile(
  request: IDeleteFilesRequest,
  abortSignal?: AbortSignal
): Promise<IDeleteFilesResponse> {
  const result = await serverAxios.post('api/cms/deletefile', request, {
    signal: abortSignal,
  });
  return result.data;
}
