import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableRow, TableCell, Checkbox, IHeader } from '@aurecon-creative-technologies/styleguide';
import UploadStore, { IFileMetadataValue, IMetadataSelectedValue, ISelectedFile, TextFilterField } from './UploadStore';
import Style from './styles/FileUpload.module.scss';
import { IMetadataField } from '../../../../api/authenticated/cms/getProjectMetadata';
import DropDownMenuForTableCell from '../../../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../../../shared/DropDownMenuButton';
import SecondaryButton from '../../../shared/SecondaryButton';
import { MetadataFieldTitle, MetadataFieldType } from '../../../../common/enums/MetadataFieldType';
import { ITableColumn } from '../../fileTable/TableColumn';
import ListFilter from '../../fileTable/ListFilter';
import UserTextFilter from '../../fileTable/UserTextFilter';
import { SortType } from '../../../../common/enums/SortType';
import SupersedeModal, { ISupersedeFileModel } from './SupersedeModal';
import FileStatus from './FileStatus';
import { UploadFileStatusEnum } from '../../../../common/enums/UploadFileStatus';
import FilesStore from '../../FilesStore';
import classNames from 'classnames';

interface IFileSelectedProps {
  onFileListChange?: (files: File[]) => void;
}

const UploadFilesForm: FC<IFileSelectedProps> = (props) => {
  const [openSupersedeModal, setOpenSupersedeModal] = useState(false);
  const [selectedSupersedeFiles, setSelectedSupersedeFiles] = useState<ISupersedeFileModel[]>([]);

  const removeSelectedFile = (fileName): void => {
    UploadStore.removeFile(fileName);
    props.onFileListChange && props.onFileListChange(UploadStore.selectedfiles.map((f) => f.file));
  };

  const getFileSizeString = (size: number): string => {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < mb) return `${(size / kb).toFixed(2)} KB`;

    if (size < gb) return `${(size / mb).toFixed(2)} MB`;

    return `${(size / gb).toFixed(2)} GB`;
  };

  const handleTableApplyFilter = (tableColumn: ITableColumn | null, value?: string) => {
    UploadStore.applyFilter(tableColumn, value);
    UploadStore.unCheckNotMatchFileAfterFiltered();
  };

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.valueField === TextFilterField.FileSize) return <span className={Style.fileSizeHeader}></span>;
    if (tableColumn.listFilter)
      return (
        <ListFilter
          onSelectItem={(value) => handleTableApplyFilter(tableColumn, value)}
          listFilter={tableColumn.listFilter}
        />
      );

    if (tableColumn.textFilter)
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          onChange={(value) => handleTableApplyFilter(tableColumn, value ?? '')}
        />
      );
  };

  const headers: IHeader[] = [
    {
      label: '',
      onCheckbox: (checked) => UploadStore.onSelectedFiles(null, checked, true),
      checked: UploadStore.filteredFiles.every((f) => f.isSelected),
    },
    ...UploadStore.tableColumns.map(
      (col) =>
        ({
          label: col.label,
          sort: col.sort ?? 'none',
          filter: getFilterComponent(col),
          onSort: (sort) => UploadStore.applySort(col, sort as SortType),
        } as IHeader)
    ),
    {
      label: '',
    },
  ];

  const getMetadataValue = (metaData: IMetadataSelectedValue[], field: IMetadataField) => {
    if (!metaData || !field.dataType.fieldType) return null;
    const metaDataValue = metaData.find((mt) => mt.fieldValueIndex === field.fieldValueIndex);
    if (metaDataValue && field.dataType?.fieldType === MetadataFieldType.List) return metaDataValue?.title;
    if (metaDataValue && field.dataType?.fieldType === MetadataFieldType.UserText) return metaDataValue?.value;
  };

  const deleteFiles = () => {
    const filesDeleted = UploadStore.selectedfiles.filter((f) => f.isSelected)?.length > 0;
    UploadStore.removeFiles();
    if (filesDeleted) props.onFileListChange && props.onFileListChange(UploadStore.selectedfiles.map((f) => f.file));
  };

  const supersedeFiles = (files: IFileMetadataValue[]) => {
    setSelectedSupersedeFiles(
      files.map((f) => {
        return {
          fileName: f.file.name,
          status: f.status,
          missingMetadata: f.missingMetadata,
        };
      })
    );
    setOpenSupersedeModal(true);
  };

  const showMultipleSupersedeButton =
    UploadStore.selectedfiles.some((s) => s.isSelected) &&
    UploadStore.selectedfiles.filter((f) => f.isSelected).every((s) => s.status === UploadFileStatusEnum.Duplicate);

  return (
    <div className={Style.uploadedFiles}>
      {!!UploadStore.selectedfiles.length && (
        <div className={Style.uploadedFilesContainer}>
          <div className={Style.uploadedFileHeading}>
            <div className={Style.numberUploadFiles}>Files to Upload ({UploadStore.selectedfiles.length})</div>
            <div className={Style.actionButtons}>
              {showMultipleSupersedeButton && (
                <SecondaryButton
                  onClick={() => {
                    supersedeFiles(UploadStore.selectedfiles.filter((f) => f.isSelected));
                  }}>
                  Supersede
                </SecondaryButton>
              )}
              <SecondaryButton onClick={deleteFiles}>Remove</SecondaryButton>
            </div>
          </div>
          <Table cssClass={Style.filesTable} headers={headers} hoverable>
            {UploadStore.filteredFiles.map((f) => (
              <TableRow key={f.file.name}>
                <TableCell>
                  <Checkbox
                    onChange={(checked) => UploadStore.onSelectedFiles(f.file.name, checked)}
                    checked={f.isSelected}
                  />
                </TableCell>
                <TableCell>{f.file.name}</TableCell>
                <TableCell>
                  <FileStatus
                    missingMetadata={f.missingMetadata}
                    status={f.status}
                    missingFileExtension={f.missingFileExtension}
                  />
                </TableCell>
                {UploadStore.fileMetadata
                  .filter((metaData) => metaData.title === MetadataFieldTitle.Description)
                  .map((field) => (
                    <TableCell
                      cellClass={classNames({ [Style.supersedeCell]: f.status === UploadFileStatusEnum.Supersede })}
                      key={field.id}>
                      {getMetadataValue(f.metadata, field) ?? '--'}
                    </TableCell>
                  ))}
                {UploadStore.fileMetadata
                  .filter((metaData) => metaData.title !== MetadataFieldTitle.Description)
                  .map((field) => (
                    <TableCell
                      cellClass={classNames({ [Style.supersedeCell]: f.status === UploadFileStatusEnum.Supersede })}
                      key={field.id}>
                      {getMetadataValue(f.metadata, field) ?? '--'}
                    </TableCell>
                  ))}
                <TableCell>{getFileSizeString(f.file.size)}</TableCell>
                <DropDownMenuForTableCell>
                  {f.status === UploadFileStatusEnum.Duplicate && (
                    <DropDownMenuButton onClick={() => supersedeFiles([f])}>Supersede</DropDownMenuButton>
                  )}
                  <DropDownMenuButton onClick={() => removeSelectedFile(f.file.name)}>Remove</DropDownMenuButton>
                </DropDownMenuForTableCell>
              </TableRow>
            ))}
          </Table>
          <div className={Style.uploadedFilesEnd}>
            <span className={Style.totalFiles}>
              {UploadStore.selectedfiles.length} files, total of (
              {getFileSizeString(UploadStore.selectedfiles.reduce((size, file) => size + file.file.size, 0))})
            </span>
          </div>
        </div>
      )}
      {openSupersedeModal && (
        <SupersedeModal
          onClose={() => setOpenSupersedeModal(false)}
          files={selectedSupersedeFiles}
          onConfirm={async (selected) => {
            const supersedingFiles = await Promise.all(
              selected.map(async (s) => {
                const canLock = await FilesStore.lockSupersedeFile(s.selectedSupersededFileId);
                if (!canLock) return {} as ISelectedFile;
                return {
                  ...s,
                  fileId: s.selectedSupersededFileId,
                  fileName: s.uploadedFileName,
                  status: UploadFileStatusEnum.Supersede,
                  isLocked: canLock,
                } as ISelectedFile;
              })
            );
            UploadStore.updateSelectedFilesStatus(supersedingFiles);
          }}
        />
      )}
    </div>
  );
};

export default observer(UploadFilesForm);
