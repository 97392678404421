import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TaskInformationStore from './TaskInformationStore';
import { ITaskFile } from '../../api/authenticated/tasks/getTask';
import DropDownMenuForTableCell from '../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { getFileSizeDetails } from '../../utils/miscUtils';
import DownloadModal from '../shared/DownloadModal';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import ForgeViewerModal from '../visualisation/forgeViewer/ForgeViewerModal';
import Loading from '../shared/Loading';
import ErrorModal from '../shared/ErrorModal';
import FilesStore from '../files/FilesStore';
import NavBarSelectorStore from '../tasks/navBarSelector/NavBarSelectorStore';

export interface ITaskInformationFileActionProps {
  taskFile: ITaskFile;
}

const TaskInformationFileAction: FC<ITaskInformationFileActionProps> = ({ taskFile }) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(taskFile.fileSize);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleForgeViewer = async () => {
    await ForgeViewerStore.getForgeViewer(
      taskFile.fileRevisionId,
      NavBarSelectorStore.selectedItem!.project!.projectNumber
    );
    if (ForgeViewerStore.errorMessage !== null) setShowErrorModal(true);
  };

  const task = TaskInformationStore.task;

  if (!task) return null;

  const mapToFile = (taskFile: ITaskFile) => ({
    id: taskFile.fileId,
    title: taskFile.fileTitle,
    extension: taskFile.extension,
    originalFilename: taskFile.originalFilename,
    fieldValue1: taskFile.fieldValue1,
    fieldValue2: taskFile.fieldValue2,
    fieldValue3: taskFile.fieldValue3,
    fieldValue4: taskFile.fieldValue4,
    fieldValue5: taskFile.fieldValue5,
    fieldValue6: taskFile.fieldValue6,
    fieldValue7: taskFile.fieldValue7,
    fieldValue8: taskFile.fieldValue8,
    fieldValue9: taskFile.fieldValue9,
    fieldValue10: taskFile.fieldValue10,
    fieldValue11: taskFile.fieldValue11,
    fieldValue12: taskFile.fieldValue12,
    fieldValue13: taskFile.fieldValue13,
    fieldValue14: taskFile.fieldValue14,
    fieldValue15: taskFile.fieldValue15,
    fieldValue16: taskFile.fieldValue16,
    fieldValue17: taskFile.fieldValue17,
    fieldValue18: taskFile.fieldValue18,
    fieldValue19: taskFile.fieldValue19,
    fieldValue20: taskFile.fieldValue20,
    taskTeamId: 0,
    uploadedSize: taskFile.uploadedSize,
    uploadedByUserName: taskFile.uploadedByUserName,
    modifiedDate: taskFile.uploadedDate,
    formattedRevision: taskFile.formattedRevision,
    suitabilityCode: taskFile.suitabilityCode,
    isSuitabilityRefCode: taskFile.isSuitabilityRefCode,
    suitabilityTitle: taskFile.suitabilityTitle,
    fileRevisionId: taskFile.fileRevisionId,
    inSharePoint: null,
    isLocked: false,
    isForgeFile: false,
    taskTypeId: null,
    uploadedByUserId: 0,
    canTaskTeamReview: false,
    canShareReview: false,
    canDeliveryTeamReview: false,
    canPublishReview: false,
    canCollaborate: false,
    canSubmitForge: false,
    canDelete: false,
    hasDocumentViewer: false,
    lastModifiedUserName: null,
    releasedDate: null,
    revision: null,
    suitabilityId: null,
    releasedFileId: null,
    deliveryTeamTitle: taskFile.deliveryTeamTitle,
    taskTeamTitle: taskFile.taskTeamTitle,
  });

  const openFileInfo = async (taskFile: ITaskFile) => {
    const projectNumber = NavBarSelectorStore.selectedItem?.project?.projectNumber ?? task.projectNumber;
    const contentSelection = FilesStore.getContentSelection(taskFile.fileStateId);
    FilesStore.setSelectedSection(contentSelection);
    FilesStore.showFileInformation(mapToFile(taskFile), projectNumber);
  };

  return (
    <>
      <DropDownMenuForTableCell>
        <DropDownMenuButton onClick={() => openFileInfo(taskFile)}>Info</DropDownMenuButton>
        <DropDownMenuButton
          onClick={() => {
            if (isOverLimit) {
              setShowDownloadModal(true);
            } else {
              TaskInformationStore.downloadTaskFile(taskFile);
            }
          }}
          disabled={TaskInformationStore.taskFilesBusy[taskFile.fileId]}>
          Download
        </DropDownMenuButton>
        {taskFile.hasDocumentViewer && (
          <DropDownMenuButton
            onClick={() => TaskInformationStore.openTaskFile(taskFile)}
            disabled={TaskInformationStore.taskFilesBusy[taskFile.fileId]}>
            Open File
          </DropDownMenuButton>
        )}
        {taskFile.isForgeFile && (
          <DropDownMenuButton
            disabled={TaskInformationStore.taskFilesBusy[taskFile.fileId]}
            onClick={() => handleForgeViewer()}>
            Open File
          </DropDownMenuButton>
        )}
      </DropDownMenuForTableCell>
      <DownloadModal
        closeModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        downloadAction={() => {
          TaskInformationStore.downloadTaskFile(taskFile);
          setShowDownloadModal(false);
        }}
        fileSize={totalFileSize}
      />
      <ForgeViewerModal
        closeModal={() => ForgeViewerStore.forgeViewerModalClose()}
        showModal={ForgeViewerStore.showForgeViewerModal}></ForgeViewerModal>
      <Loading isLoading={ForgeViewerStore.isLoading}></Loading>
      {showErrorModal && <ErrorModal closeModal={() => setShowErrorModal(false)} />}
    </>
  );
};

export default observer(TaskInformationFileAction);
