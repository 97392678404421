import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import Style from './styles/ProjectCard.module.scss';
import DropDownMenu from '../shared/DropDownMenu';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { IProjectInfo } from './ProjectInfo';
import { Pages } from '../../common/constants/Pages';
import { formatDate } from '../../utils/dateUtils';

export interface IProjectCardProps {
  project: IProjectInfo;
}

const ProjectCard: FC<IProjectCardProps> = ({ project }) => {
  const navigate = useNavigate();

  const handleUserManagementClick = () => {
    navigate(`${Pages.Project.Route}/${project.projectNumber}/users`);
  };

  const handleProjectInformationClick = () => {
    navigate(`${Pages.Project.Route}/${project.projectNumber}`);
  };

  return (
    <li className={Style.cardContainer}>
      <div className={Style.cardContent}>
        <div className={Style.projectInfo}>{project.programmeTitle}</div>
        <div className={Style.cardId}>{project.projectNumber}</div>
        <div className={Style.cardTitle}>
          <span>{project.projectTitle}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span className={Style.boldLabel}>Owner</span>
          <span>{project.projectOwnerName}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span className={Style.boldLabel}>Start Date</span>
          <span>{project.startDate ? formatDate(project.startDate) : ''}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span className={Style.boldLabel}>End Date</span>
          <span>{project.endDate ? formatDate(project.endDate) : ''}</span>
        </div>
        <div className={Style.lineWrapper}>
          <span className={Style.boldLabel}>Project Phase</span>
          <span className={Style.version}>{project.projectPhase}</span>
        </div>
      </div>
      <div className={Style.actionButtons}>
        <DropDownMenu icon="edit" iconClassName={Style.menuIcon} itemsContainerClassName={Style.menuItemsContainer}>
          <DropDownMenuButton onClick={handleProjectInformationClick}>Project Information</DropDownMenuButton>
          <DropDownMenuButton onClick={handleUserManagementClick}>User Management</DropDownMenuButton>
        </DropDownMenu>
      </div>
    </li>
  );
};

export default observer(ProjectCard);
