import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { serverAxios } from '../api';
import { ITaskTeamUser } from './interfaces/user.interface';

export interface IDeliveryTeam {
  id: number;
  title: string;
  transmittalCode: string;
  taskTeams: ITaskTeam[];
  users: IDeliveryTeamUser[];
}

export interface IDeliveryTeamUser {
  id: number;
  name: string;
  email: string;
  deliveryTeamRoleIds: DeliveryTeamRole[];
  deliveryTeamRoleTitle: string | null;
}

export interface ITaskTeam {
  id: number;
  title: string;
  transmittalCode: string;
  users: ITaskTeamUser[];
}

export async function getDeliveryTeamsTaskTeamsUsers(
  projectNumber: string,
  abortSignal?: AbortSignal
): Promise<IDeliveryTeam[]> {
  const result = await serverAxios.get<{ deliveryTeams: IDeliveryTeam[] }>('api/um/deliveryteamstaskteamsusers', {
    params: { projectNumber },
    signal: abortSignal,
  });
  return result.data.deliveryTeams;
}
