import React, { useEffect } from 'react';
import { Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import NavBarSelectorStore from './NavBarSelectorStore';
import NavBarSelectorProgramme from './NavBarSelectorProgramme';
import NavBarSelectorProject from './NavBarSelectorProject';
import NavBarSelectorTaskTeam from './NavBarSelectorTaskTeam';

interface INavBarSelectorProp {
  disable: boolean;
}

const NavBarSelector: React.FC<INavBarSelectorProp> = (props) => {
  useEffect(() => {
    NavBarSelectorStore.initSelector();
  }, []);

  const handleOnSelectItem = (id: string | number): void => {
    NavBarSelectorStore.setSelectedSelectorItem(id.toString());
  };

  const items: IDropdownItemProps[] = NavBarSelectorStore.items.map((item) => {
    if (item.type === 'Programme') return { id: item.id, label: <NavBarSelectorProgramme item={item} /> };
    if (item.type === 'Project') return { id: item.id, label: <NavBarSelectorProject item={item} /> };
    return { id: item.id, label: <NavBarSelectorTaskTeam item={item} /> };
  });

  return (
    <Dropdown
      size={'small'}
      selectedItem={NavBarSelectorStore.selectedItem?.id}
      items={items}
      onSelectItem={handleOnSelectItem}
      disabled={props.disable}
    />
  );
};

export default observer(NavBarSelector);
