import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableRow, TableCell, Text } from '@aurecon-creative-technologies/styleguide';
import UploadStore from './UploadStore';
import Style from './styles/SupersedeSelectedFiles.module.scss';
import PrimaryIconButton from '../../../shared/PrimaryIconButton';
import { getFileSizeString } from '../../../../utils/miscUtils';

interface IFileSelectedProps {
  onFileListChange?: (files: File[]) => void;
}

const SupersedeSelectedFiles: FC<IFileSelectedProps> = (props) => {
  const removeSelectedFile = (fileName): void => {
    UploadStore.removeFile(fileName);
    props.onFileListChange && props.onFileListChange(UploadStore.selectedfiles.map((f) => f.file));
  };

  return (
    <div className={Style.uploadedFiles}>
      {UploadStore.selectedfiles.length > 0 && (
        <>
          <Text type="n1" cssClass={Style.headerText}>
            Uploaded Files
          </Text>
          <Table
            cssClass={Style.filesTable}
            headers={[
              {
                label: 'File Name',
              },
              {
                label: 'File Size',
              },
              {
                label: 'Actions',
              },
            ]}
            hoverable>
            {UploadStore.selectedfiles.map((f) => (
              <TableRow key={f.file.name}>
                <TableCell>{f.file.name}</TableCell>
                <TableCell>{getFileSizeString(f.file.size)}</TableCell>
                <TableCell>
                  <PrimaryIconButton icon="delete" onClick={() => removeSelectedFile(f.file.name)} />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </>
      )}
    </div>
  );
};

export default observer(SupersedeSelectedFiles);
