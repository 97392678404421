import { serverAxios } from '../api';

export interface IAppConfiguration {
  appClientId: string;
  appTenantId: string;
  appApiScope: string;
  appAuthority: string;
  version: string;
  appInsightsKey: string;
}

export async function getAppSettings(abortSignal?: AbortSignal): Promise<IAppConfiguration> {
  const result = await serverAxios.get<IAppConfiguration>('api/config/app/settings', { signal: abortSignal });
  return result.data;
}
