import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';
import PrimaryButton from '../../shared/PrimaryButton';
import ModalActions from '../../shared/ModalActions';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import SecondaryButton from '../../shared/SecondaryButton';

import Style from './styles/DeleteModal.module.scss';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import ErrorModal from '../../shared/ErrorModal';

interface IDeleteModalProps {
  closeModal: () => void;
  deleteAction: () => void;
  showModal: boolean;
  loading?: boolean;
  message?: string;
  done?: { deleting: number; deleted: number };
}

const DeleteModal: FC<IDeleteModalProps> = (props) => {
  const { closeModal, deleteAction, showModal, loading, done, message } = props;

  if (!showModal) return null;

  if (done && done.deleted == 0) {
    return (
      <ErrorModal title={'Failed to delete any files'} closeModal={() => closeModal()} message={message ?? <></>} />
    );
  }
  if (done && done.deleted != done.deleting) {
    return (
      <ErrorModal
        title={'Failed to delete all files'}
        message={
          <p>
            {done.deleted} files successfully deleted, {done.deleting - done.deleted} failed to delete.
          </p>
        }
        closeModal={() => closeModal()}
      />
    );
  }

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        {!loading && <CloseButton onClick={() => closeModal()} />}
        <div className={Style.info}>
          <h1>Delete files</h1>
          <p>This action cannot be undone. Are you sure you want to continue?</p>
        </div>
        {loading ? (
          <Loader label="Deleting files" />
        ) : (
          <ModalActions>
            <SecondaryButton onClick={() => closeModal()}>Cancel</SecondaryButton>
            <PrimaryButton onClick={() => deleteAction()} disabled={loading}>
              Delete
            </PrimaryButton>
          </ModalActions>
        )}
      </Modal>
    </CentreOnPage>
  );
};

export default observer(DeleteModal);
