import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from './FilesStore';
import SecondaryButton from '../shared/SecondaryButton';
import ReviewModal from './reviewModal/ReviewModal';
import { getWipUsersToCollaborateWith } from '../../api/authenticated/cms/getWipUsersToCollaborateWith';
import { getNonWipUsersToCollaborateWith } from '../../api/authenticated/cms/getNonWipUsersToCollaborateWith';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { ContentSelection } from './ContentSelection';
import { FileState } from '../../common/enums/FileState';
import { IReviewer, ISuitability } from '../../api/authenticated/tasks/getAddData';
import { sendWipCollaborationNotification } from '../../api/authenticated/cms/sendWipCollaborationNotification';
import { sendNonWipCollaborationNotification } from '../../api/authenticated/cms/sendNonWipCollaborationNotification';
import { IFile } from '../../api/authenticated/cms/FileModel';

interface CollaborateButtonProps {
  taskTeamId: number;
}

const CollaborateButton: FC<CollaborateButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);

  let fileStateId = FileState.Wip;
  if (FilesStore.selectedSection === ContentSelection.Shared) fileStateId = FileState.Shared;
  if (FilesStore.selectedSection === ContentSelection.Published) fileStateId = FileState.Published;

  const getAddTaskData = async (): Promise<{ reviewers: IReviewer[]; suitabilities: ISuitability[] }> => {
    if (!NavBarSelectorStore.selectedItem) return { reviewers: [], suitabilities: [] };
    const users =
      fileStateId === FileState.Wip
        ? await getWipUsersToCollaborateWith(taskTeamId)
        : await getNonWipUsersToCollaborateWith(NavBarSelectorStore.selectedItem.project.projectNumber, fileStateId);
    return { reviewers: users.map((u) => ({ userId: u.id, name: u.name, email: u.email })), suitabilities: [] };
  };

  const saveTask = async (
    files: IFile[],
    reviewers: IReviewer[],
    suitability: ISuitability | null,
    selectedMessage: string
  ) => {
    if (!NavBarSelectorStore.selectedItem) return;
    if (fileStateId === FileState.Wip)
      await sendWipCollaborationNotification({
        taskTeamId: taskTeamId,
        files: files.map((f) => ({ fileId: f.id, releasedFileId: f.releasedFileId })),
        userIds: reviewers.map((r) => r.userId),
        message: selectedMessage,
      });
    else
      await sendNonWipCollaborationNotification({
        projectNumber: NavBarSelectorStore.selectedItem.project.projectNumber,
        releasedFileIds: files.map((f) => f.releasedFileId!),
        fileStateId,
        userIds: reviewers.map((r) => r.userId),
        message: selectedMessage,
      });
  };

  return (
    <>
      <SecondaryButton
        disabled={!FilesStore.canCollaborateOnSelectedFiles || taskTeamId === 0}
        onClick={() => setIsShowing(true)}>
        Collaborate
      </SecondaryButton>
      {isShowing && (
        <ReviewModal
          title="Collaborate"
          description="Recipients will receive an email notification with a link to view the files."
          closeModal={() => setIsShowing(false)}
          approversLabel="Recipients"
          approversSearchLabel="Add Recipients"
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Collaborate"
        />
      )}
    </>
  );
};

export default observer(CollaborateButton);
