import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import CloseButton from '../../shared/CloseButton';
import ModalInfoPanel from './ModalInfoPanel';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';
import AddDeliveryTeamAuthoriserStore from './AddDeliveryTeamAuthoriserStore';
import AppointedPartiesStore from './AppointedPartiesStore';
import Styles from './styles/AddDeliveryTeamAuthoriserModal.module.scss';

const AddDeliveryTeamAuthoriserModal: FC = () => {
  if (!AddDeliveryTeamAuthoriserStore.deliveryTeam) return null;
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Styles.modal}>
        <CloseButton onClick={AppointedPartiesStore.closeModal} disabled={AddDeliveryTeamAuthoriserStore.isSaving} />
        <h1>Adding Delivery Team Authoriser</h1>
        <p>
          You can only add a Delivery Team Authoriser if they are already a Task Team member for{' '}
          {AddDeliveryTeamAuthoriserStore.deliveryTeam.title}.
        </p>
        <ModalInfoPanel
          deliveryTeamName={`${AddDeliveryTeamAuthoriserStore.deliveryTeam.title} (${AddDeliveryTeamAuthoriserStore.deliveryTeam.transmittalCode})`}
        />
        <UserSelectorExpandable
          label="User Name"
          required
          searchPlaceholder="Add User name"
          isMultiUser
          getUsers={AddDeliveryTeamAuthoriserStore.getMatchedUsers}
          onSelectedUsersUpdated={AddDeliveryTeamAuthoriserStore.setSelectedUsers}
          disabled={AddDeliveryTeamAuthoriserStore.isSaving}
        />
        <ModalActions>
          <SecondaryButton
            onClick={AppointedPartiesStore.closeModal}
            disabled={AddDeliveryTeamAuthoriserStore.isSaving}>
            Cancel
          </SecondaryButton>
          <ButtonSavingSpinner isSaving={AddDeliveryTeamAuthoriserStore.isSaving}>
            <PrimaryButton
              onClick={AddDeliveryTeamAuthoriserStore.save}
              disabled={AddDeliveryTeamAuthoriserStore.selectedUsers.length === 0}>
              Add Members
            </PrimaryButton>
          </ButtonSavingSpinner>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddDeliveryTeamAuthoriserModal);
