import { serverAxios } from '../api';

export interface IResendForgeFileRequest {
  projectNumber: string;
  fileRevisionId: number | null;
}

export async function resendForgeFile(request: IResendForgeFileRequest, abortSignal?: AbortSignal): Promise<void> {
  return await serverAxios.post('api/cms/resendforgefile', request, {
    signal: abortSignal,
  });
}
