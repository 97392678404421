import React, { FC, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import BaseMapToggleWidget from './widgets/BaseMapToggleWidget';
import HomeWidget from './widgets/HomeWidget';
import ZoomWidget from './widgets/ZoomWidget';
import CompassWidget from './widgets/CompassWidget';
import FullScreenWidget from './widgets/FullScreenWidget';
import VisualisationStore from './VisualisationStore';
import esriConfig from '@arcgis/core/config';
import WebScene from '@arcgis/core/WebScene';
import SceneView from '@arcgis/core/views/SceneView';
import Layer from '@arcgis/core/layers/Layer';
import Style from './styles/Visualisation.module.scss';
import AppStore from '../../stores/AppStore';

const WebsceneViewer: FC = () => {
  const sceneViewRef = useRef(null);

  useEffect(() => {
    if (!sceneViewRef.current) return;

    if (!AppStore.client?.arcGisUseOauth) {
      esriConfig.apiKey = VisualisationStore.arcGisApiKey;
    }

    const map = new WebScene({
      portalItem: {
        id: VisualisationStore.webSceneId!,
      },
      basemap: 'arcgis-imagery',
    });

    const sView = new SceneView({
      container: sceneViewRef.current,
      map: map,
      ui: {
        components: ['attribution'],
      },
    });

    sView.when(() => {
      const mapLayers: Layer[] = [];
      const buildingLayers: Layer[] = [];
      const pointCloudLayers: Layer[] = [];

      map.layers.forEach((l) => {
        mapLayers.push(l);

        if (l.type && l.type === 'building-scene') buildingLayers.push(l);
        else if (l.type && l.type === 'point-cloud') pointCloudLayers.push(l);
      });

      VisualisationStore.setMapLayers(mapLayers);
      VisualisationStore.setBuildingLayers(buildingLayers);
      VisualisationStore.setPointCloudLayers(pointCloudLayers);
    });

    VisualisationStore.setWebScene(map);
    VisualisationStore.setMapView(sView);
  }, []);

  return (
    <div className={Style.mapViewContainer}>
      <div className={Style.mapView} ref={sceneViewRef}>
        <div className={Style.rightTopWidgets}>
          <FullScreenWidget />
          <CompassWidget />
          <BaseMapToggleWidget />
          <HomeWidget />
          <ZoomWidget />
        </div>
      </div>
    </div>
  );
};

export default observer(WebsceneViewer);
