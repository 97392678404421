import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import AreaMeasurement2D from '@arcgis/core/widgets/AreaMeasurement2D';
import AreaMeasurement3D from '@arcgis/core/widgets/AreaMeasurement3D';
import VisualisationStore from '../VisualisationStore';

import WidgetBase from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';

const MeasurementAreaWidget: FC = () => {
  return VisualisationStore.mapView.type === '2d'
    ? WidgetBase(AreaMeasurement2D, { visible: true, unit: 'square-meters' }, Style.measurementWidgetWrapper)
    : WidgetBase(AreaMeasurement3D, { visible: true, unit: 'square-meters' }, Style.measurementWidgetWrapper);
};

export default observer(MeasurementAreaWidget);
