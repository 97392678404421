import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchBox, Pill, Icon, ISuggestion } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/AddTransmittalMessageVisibilitySelector.module.scss';
import {
  IProjectMembersAndTeams,
  IUser,
  ITaskTeam,
  IDeliveryTeam,
  IAppointingParty,
} from '../../../api/authenticated/um/getProjectMembersAndTeams';
import TaskTeamTag from '../../shared/TaskTeamTag';
import DeliveryTeamTag from '../../shared/DeliveryTeamTag';
import AppointingPartyTag from '../../shared/AppointingPartyTag';

export interface IItemSelectorProps {
  label?: string;
  required?: boolean;
  searchPlaceholder: string;
  disabled?: boolean;
  isMultiUser?: boolean;
  selectedVisibleTos?: IProjectMembersAndTeams;
  getItems(text: string): IProjectMembersAndTeams;
  onSelectedItemUpdated(setSelectedItems: IProjectMembersAndTeams): void;
}

export interface ISelectedItem {
  id: string;
  value: JSX.Element;
}

export enum SelectorType {
  User,
  DeliveryTeam,
  TaskTeam,
  AppointingParty,
}

const AddTransmittalMessageVisibilitySelector: FC<IItemSelectorProps> = ({
  label,
  required,
  searchPlaceholder,
  disabled,
  isMultiUser,
  selectedVisibleTos,
  getItems,
  onSelectedItemUpdated,
}) => {
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [deliveryTeams, setDeliveryTeams] = useState<IDeliveryTeam[]>([]);
  const [taskTeams, setTaskTeams] = useState<ITaskTeam[]>([]);
  const [appointingParties, setAppointingParties] = useState<IAppointingParty[]>([]);
  const [selectedItems, setSelectedItems] = useState<ISelectedItem[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [selectedDeliveryTeams, setSelectedDeliveryTeams] = useState<IDeliveryTeam[]>([]);
  const [selectedTaskTeams, setSelectedTaskTeams] = useState<ITaskTeam[]>([]);
  const [selectedAppointingParties, setSelectedAppointingParties] = useState<IAppointingParty[]>([]);

  useEffect(() => {
    selectedVisibleTos?.users.forEach((u) => {
      if (selectedUsers.some((su) => su.userId === u.userId)) return;
      selectedUsers.push(u);
    });
  }, [selectedUsers, selectedVisibleTos?.users]);

  const userPill = (user: IUser): ISelectedItem => ({
    id: `${SelectorType.User}/${user.userId}/${user.taskTeamId}/${user.deliveryTeamId}`,
    value: (
      <span>
        <Icon type="person" /> {user.userName} <b>{user.isExternal ? 'Ext.' : user.taskTeamCode}</b>
      </span>
    ),
  });

  const taskTeamPill = (taskTeam: ITaskTeam): ISelectedItem => ({
    id: `${SelectorType.TaskTeam}/${taskTeam.taskTeamId}`,
    value: (
      <TaskTeamTag
        deliveryTeamTitle={taskTeam.deliveryTeamTitle}
        taskTeamTitle={taskTeam.taskTeamTitle}
        taskTeamCode={taskTeam.taskTeamCode}
        userCount={taskTeam.userCount}
      />
    ),
  });

  const deliveryTeamPill = (deliveryTeam: IDeliveryTeam): ISelectedItem => ({
    id: `${SelectorType.DeliveryTeam}/${deliveryTeam.deliveryTeamId}`,
    value: (
      <DeliveryTeamTag
        deliveryTeamTitle={deliveryTeam.deliveryTeamTitle}
        deliveryTeamCode={deliveryTeam.deliveryTeamCode}
      />
    ),
  });

  const appointingPartyPill = (appointingParty: IAppointingParty): ISelectedItem => ({
    id: `${SelectorType.AppointingParty}/${appointingParty.appointingPartyId}`,
    value: (
      <AppointingPartyTag
        appointingPartyCode={appointingParty.appointingPartyCode}
        appointingPartyTitle={appointingParty.appointingPartyTitle}
      />
    ),
  });

  const selectedVisibleToItems = selectedVisibleTos
    ? [
        ...(selectedVisibleTos.users?.map(userPill) || []),
        ...(selectedVisibleTos.deliveryTeams?.map(deliveryTeamPill) || []),
        ...(selectedVisibleTos.taskTeams?.map(taskTeamPill) || []),
        ...(selectedVisibleTos.appointingParties?.map(appointingPartyPill) || []),
      ]
    : [];

  const removeSelectedItem = (item: ISelectedItem) => {
    if (disabled) return;

    setSelectedItems(selectedItems.filter((r) => r.id !== item.id));
    const selectedType = Number(item.id.split('/')[0]);

    if (selectedType === SelectorType.User) {
      const userId = Number(item.id.split('/')[1]);
      const updatedSelectedUsers = selectedUsers.filter((u) => u.userId !== userId);
      setSelectedUsers(updatedSelectedUsers);
      onSelectedItemUpdated({
        users: updatedSelectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
      });
    } else if (selectedType === SelectorType.DeliveryTeam) {
      const deliveryTeamId = Number(item.id.split('/')[1]);
      const updatedSelectedDeliveryTeams = selectedDeliveryTeams.filter((u) => u.deliveryTeamId !== deliveryTeamId);
      setSelectedDeliveryTeams(updatedSelectedDeliveryTeams);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: updatedSelectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
      });
    } else if (selectedType === SelectorType.TaskTeam) {
      const taskTeamId = Number(item.id.split('/')[1]);
      const updatedSelectedTaskTeams = selectedTaskTeams.filter((u) => u.taskTeamId !== taskTeamId);
      setSelectedTaskTeams(updatedSelectedTaskTeams);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: updatedSelectedTaskTeams,
        appointingParties: selectedAppointingParties,
      });
    } else {
      const appointingPartyId = Number(item.id.split('/')[1]);
      const updatedSelectedAppointingParties = selectedAppointingParties.filter(
        (u) => u.appointingPartyId !== appointingPartyId
      );
      setSelectedAppointingParties(updatedSelectedAppointingParties);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: updatedSelectedAppointingParties,
      });
    }
  };

  const addSelectedItem = (id: string) => {
    if (disabled || id === '-1' || id === '-2') {
      setUsers([]);
      setDeliveryTeams([]);
      setTaskTeams([]);
      setAppointingParties([]);
      setTriggerCleanup(triggerCleanup + 1);
      return;
    }

    const type = Number(id.split('/')[0]);

    if (type === SelectorType.User) {
      const userId = Number(id.split('/')[1]);
      const taskTeamId = Number(id.split('/')[2]);
      const deliveryTeamId = Number(id.split('/')[3]);
      const user = users.find(
        (u) => u.userId === userId && u.taskTeamId === taskTeamId && u.deliveryTeamId === deliveryTeamId
      );
      const exists = selectedUsers.find((u) => u.userId === userId);
      if (!user || exists) return;
      const updatedSelectedUsers = [...selectedUsers, user];
      setSelectedUsers(updatedSelectedUsers);

      setSelectedItems([...selectedItems, userPill(user)]);
      onSelectedItemUpdated({
        users: updatedSelectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
      });
    } else if (type === SelectorType.DeliveryTeam) {
      const deliveryTeamId = Number(id.split('/')[1]);
      const deliveryTeam = deliveryTeams.find((t) => t.deliveryTeamId === deliveryTeamId);
      const exists = selectedDeliveryTeams.find((t) => t.deliveryTeamId === deliveryTeamId);
      if (!deliveryTeam || exists) return;
      const updatedSelectedDeliveryTeams = [...selectedDeliveryTeams, deliveryTeam];
      setSelectedDeliveryTeams(updatedSelectedDeliveryTeams);

      setSelectedItems([...selectedItems, deliveryTeamPill(deliveryTeam)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: updatedSelectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: selectedAppointingParties,
      });
    } else if (type === SelectorType.TaskTeam) {
      const taskTeamId = Number(id.split('/')[1]);
      const taskTeam = taskTeams.find((t) => t.taskTeamId === taskTeamId);
      const exists = selectedTaskTeams.find((t) => t.taskTeamId === taskTeamId);
      if (!taskTeam || exists) return;
      const updatedSelectedTaskTeams = [...selectedTaskTeams, taskTeam];
      setSelectedTaskTeams(updatedSelectedTaskTeams);

      setSelectedItems([...selectedItems, taskTeamPill(taskTeam)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: updatedSelectedTaskTeams,
        appointingParties: selectedAppointingParties,
      });
    } else {
      const appointingPartyId = Number(id.split('/')[1]);
      const appointingParty = appointingParties.find((t) => t.appointingPartyId === appointingPartyId);
      const exists = selectedAppointingParties.find((t) => t.appointingPartyId === appointingPartyId);
      if (!appointingParty || exists) return;
      const updatedSelectedAppointingParties = [...selectedAppointingParties, appointingParty];
      setSelectedAppointingParties(updatedSelectedAppointingParties);

      setSelectedItems([...selectedItems, appointingPartyPill(appointingParty)]);
      onSelectedItemUpdated({
        users: selectedUsers,
        deliveryTeams: selectedDeliveryTeams,
        taskTeams: selectedTaskTeams,
        appointingParties: updatedSelectedAppointingParties,
      });
    }

    setUsers([]);
    setDeliveryTeams([]);
    setTaskTeams([]);
    setAppointingParties([]);
    setTriggerCleanup(triggerCleanup + 1);

    if (!isMultiUser) setDisableSearchBox(true);
  };

  const loadItems = (text: string) => {
    if (disabled) return;
    if (text === '') {
      setUsers([]);
      setDeliveryTeams([]);
      setTaskTeams([]);
      setAppointingParties([]);
    } else {
      const items = getItems(text);
      setUsers(items.users);
      setDeliveryTeams(items.deliveryTeams);
      setTaskTeams(items.taskTeams);
      setAppointingParties(items.appointingParties);
    }
  };

  const filteredItems = useMemo(() => {
    let dropdownList = [] as ISuggestion[];
    const userList = users
      .filter(
        (user) =>
          !selectedUsers.find(({ userId, taskTeamId, deliveryTeamId }) => {
            return user.userId === userId && user.taskTeamId === taskTeamId && user.deliveryTeamId === deliveryTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.User}/${r.userId}/${r.taskTeamId}/${r.deliveryTeamId}`,
        value: `${r.userName} (${r.userEmail})`,
        display: (
          <>
            <span>
              {r.userName} ({r.userEmail})
            </span>
            <span className={Style.teamDetails}>
              <Icon type="people" /> {r.isExternal && 'External User'}
              {!r.isExternal && (
                <>
                  {r.deliveryTeamTitle} | <b>{r.taskTeamTitle}</b>
                </>
              )}
            </span>
          </>
        ),
      }));
    if (userList.length > 0)
      dropdownList = [
        ...dropdownList,
        { id: '-1', value: 'Users', display: <span className={Style.disable}>Users</span> },
        ...userList,
      ];

    const deliveryTeamList = deliveryTeams
      .filter(
        (deliveryTeam) =>
          !selectedDeliveryTeams.find(({ deliveryTeamId }) => {
            return deliveryTeam.deliveryTeamId === deliveryTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.DeliveryTeam}/${r.deliveryTeamId}`,
        value: `${r.deliveryTeamTitle}`,
        display: (
          <span>
            <Icon type="people" /> {r.deliveryTeamTitle}
          </span>
        ),
      }));

    const taskTeamList = taskTeams
      .filter(
        (taskTeam) =>
          !selectedTaskTeams.find(({ taskTeamId }) => {
            return taskTeam.taskTeamId === taskTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.TaskTeam}/${r.taskTeamId}`,
        value: `${r.taskTeamTitle}`,
        display: (
          <span>
            <Icon type="people" /> {r.deliveryTeamTitle} | <b>{r.taskTeamTitle}</b>
            <span className={Style.userCount}>
              <Pill background="light" size="mini" colour={2}>
                {r.userCount}
              </Pill>
            </span>
          </span>
        ),
      }));

    const appointingPartyList = appointingParties
      .filter(
        (appointingParty) =>
          !selectedAppointingParties.find(({ appointingPartyId }) => {
            return appointingParty.appointingPartyId === appointingPartyId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.AppointingParty}/${r.appointingPartyId}`,
        value: `${r.appointingPartyTitle}`,
        display: (
          <span>
            <Icon type="folder" /> {r.appointingPartyTitle} (Appointing Party)
            <span className={Style.userCount}>
              <Pill background="light" size="mini" colour={2}>
                {r.userCount}
              </Pill>
            </span>
          </span>
        ),
      }));

    if (deliveryTeamList.length > 0 || taskTeamList.length > 0 || appointingPartyList.length > 0)
      dropdownList = [
        ...dropdownList,
        { id: '-2', value: 'Teams', display: <span className={Style.disable}>Teams</span> },
        ...deliveryTeamList,
        ...taskTeamList,
        ...appointingPartyList,
      ];

    return dropdownList;
  }, [
    appointingParties,
    deliveryTeams,
    selectedAppointingParties,
    selectedDeliveryTeams,
    selectedTaskTeams,
    selectedUsers,
    taskTeams,
    users,
  ]);

  return (
    <>
      {label && (
        <label className={Style.label}>
          {label}
          {required && <span className={Style.required}>*</span>}
        </label>
      )}
      <div className={Style.searchBoxContainer}>
        {(selectedVisibleToItems.length ? selectedVisibleToItems : selectedItems).map((i) => {
          if (!i) return null;
          return (
            <Pill key={i.id} colour={1} onClose={() => removeSelectedItem(i)} cssClass={Style.userPill}>
              {i.value}
            </Pill>
          );
        })}
        <SearchBox
          placeholder={searchPlaceholder}
          hideSearchButton
          disableDefaultMatching
          suggestions={filteredItems}
          onChange={loadItems}
          onSearch={loadItems}
          onSelect={(s) => addSelectedItem(String(s.id))}
          triggerCleanup={triggerCleanup}
          disabled={disabled || disableSearchBox}
        />
      </div>
    </>
  );
};

export default observer(AddTransmittalMessageVisibilitySelector);
