import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import PrimaryIconButton from '../shared/PrimaryIconButton';
import Styles from './styles/TransmittalDetailsActions.module.scss';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import ConfirmationModal from '../shared/ConfirmationModal';
import AuthStore from '../../stores/AuthStore';
import SecondaryIconButton from '../shared/SecondaryIconButton';
import LayoutStore from '../layout/LayoutStore';

interface ITransmittalDetailsActionsProps {
  closed: boolean;
  flagged: boolean;
  initiator: string;
  onClose: () => void;
  onFlagged: (boolean) => void;
}
const TransmittalDetailsActions: FC<ITransmittalDetailsActionsProps> = (props) => {
  const { initiator, closed, flagged, onClose, onFlagged } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const handleAddTransmittalResponseClick = () => {
    navigate(`${Pages.Transmittals.Route}/${TransmittalDetailsStore.transmittal?.title}/AddResponse`);
  };

  const handleFlaggedTransmittal = () => {
    onFlagged(!flagged);
  };

  const handleAddTransmittalCopyLinkClick = () => {
    const url = location.href;
    if (url) {
      navigator.clipboard.writeText(url);
      LayoutStore.displayToast('success', 'Link copied to clipboard!');
    }
  };
  return (
    <>
      <ActionBar className={Styles.actionBar}>
        <ActionBarSection>
          <PrimaryButton
            disabled={closed}
            className={Styles.responseButton}
            onClick={handleAddTransmittalResponseClick}>
            Respond
          </PrimaryButton>
          <SecondaryButton onClick={() => handleFlaggedTransmittal()}>{flagged ? 'Unflag' : 'Flag'}</SecondaryButton>
          {initiator?.toLocaleLowerCase() === AuthStore.email?.toLocaleLowerCase() && (
            <SecondaryButton disabled={closed} onClick={() => setShowConfirmation(true)}>
              Close Out
            </SecondaryButton>
          )}
          <SecondaryButton onClick={handleAddTransmittalCopyLinkClick}>Copy Link</SecondaryButton>
        </ActionBarSection>
        <ActionBarSection>
          {TransmittalDetailsStore.showResponse ? (
            <SecondaryIconButton icon="chat_bubble" onClick={() => TransmittalDetailsStore.setShowResponse()} />
          ) : (
            <PrimaryIconButton
              icon="chat_bubble"
              onClick={() => TransmittalDetailsStore.setShowResponse()}
              className={Styles.chatBubbleButton}
            />
          )}
        </ActionBarSection>
      </ActionBar>
      <ConfirmationModal
        showModal={showConfirmation}
        cancelText="No"
        confirmText="Yes"
        heading="Close Transmittal"
        message="Once the Transmittal is closed, you cannot respond or make any changes to it. This action cannot be reverted. Are you sure you want to close Transmittal?"
        onConfirm={() => {
          setShowConfirmation(false);
          onClose();
        }}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
};

export default observer(TransmittalDetailsActions);
