import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, IHeader, Pagination } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/FileTable.module.scss';
import FileTableRow from './FileTableRow';
import FilesStore from '../FilesStore';
import { IDateRangeFilter, ITableColumn } from './TableColumn';
import ListFilter from './ListFilter';
import UserTextFilter from './UserTextFilter';
import DateFilter from './DateFilter';
import { IFile } from '../../../api/authenticated/cms/FileModel';
import { SortType } from '../../../common/enums/SortType';
import ErrorModal from '../../shared/ErrorModal';
export interface IFileTableProps {
  tableColumns: ITableColumn[];
  tableFiles: IFile[];
  onFilter?: (column: string, value?: string | IDateRangeFilter) => void;
  onSortColumnChange?: (column: string, direction?: SortType) => void;
}

const FileTable: FC<IFileTableProps> = ({ tableColumns, tableFiles, onFilter, onSortColumnChange }) => {
  const [columns, setColumns] = useState(tableColumns);

  useEffect(() => {
    setColumns(tableColumns);
  }, [tableColumns]);

  const filterChangeHandler = (tableColumn: ITableColumn, filter?: string | IDateRangeFilter) => {
    onFilter && onFilter(tableColumn.valueField, filter);
  };

  const sortColumnClickHandler = (tableColumn: ITableColumn, direction: SortType) => {
    onSortColumnChange && onSortColumnChange(tableColumn.valueField, direction);
  };

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.listFilter)
      return (
        <ListFilter
          onSelectItem={(value) => filterChangeHandler(tableColumn, value)}
          listFilter={tableColumn.listFilter}
          disabled={FilesStore.showSelectedFiles}
          key={tableColumn.valueField}
        />
      );

    if (tableColumn.textFilter)
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          disabled={FilesStore.showSelectedFiles}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
        />
      );

    if (tableColumn.dateFilter)
      return (
        <DateFilter
          dateFilter={tableColumn.dateFilter}
          disabled={FilesStore.showSelectedFiles}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
        />
      );
  };
  const headers: IHeader[] = [
    {
      label: '',
      onCheckbox: (checked) => FilesStore.setSelectedForAllFiles(checked),
      checked: FilesStore.areAllFilesSelected(),
      style: { width: '35px' },
    },
    ...columns.map((tableColumn) => {
      return {
        label: tableColumn.label,
        sort: tableColumn.sort ? tableColumn.sort : 'none',
        onSort: (sort) => sortColumnClickHandler(tableColumn, sort as SortType),
        filter: getFilterComponent(tableColumn),
      } as IHeader;
    }),
    {
      label: '',
    },
  ];

  return (
    <>
      {
        <Table cssClass={Style.fileTable} headers={headers} breakpoint={10} hoverable>
          {tableFiles.map((row) => (
            <FileTableRow key={FilesStore.getFileKey(row)} headers={headers} file={row} tableColumns={columns} />
          ))}
        </Table>
      }

      {!FilesStore.showSelectedFiles && FilesStore.pagingMetaData && FilesStore.pagingMetaData.totalCount > 0 ? (
        <div className={Style.paginationWrapper}>
          <div className={Style.paginationContainer}>
            <Pagination
              page={FilesStore.pagingMetaData.pageNumber}
              pageCount={FilesStore.pagingMetaData.pageCount}
              onChange={(page: number) => {
                FilesStore.setCurrentPage(page);
              }}
            />
            <div className={Style.paginationFlexGrow}>
              {FilesStore.pagingMetaData.firstItemOnPage + 1} - {FilesStore.pagingMetaData.lastItemOnPage + 1} of{' '}
              {FilesStore.pagingMetaData.totalCount} Files
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {FilesStore.isLockSupersedeFileError && (
        <ErrorModal
          closeModal={() => {
            FilesStore.closeLockSupersedeFileErrorModal();
          }}
          message={FilesStore.errorMessage ?? ''}
        />
      )}
    </>
  );
};

export default observer(FileTable);
