import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import {
  Accordion,
  AccordionPanel,
  Table,
  TableCell,
  TableRow,
  Button,
} from '@aurecon-creative-technologies/styleguide';
import { ReactComponent as FileIcon } from './images/FileIcon.svg';
import DropDownMenuForTableCell from '../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { getFileSizeDetails } from '../../utils/miscUtils';
import Styles from './styles/TransmittalDetailsFiles.module.scss';
import FilesStore from '../files/FilesStore';
import {
  IReleasedFile,
  IAttachmentFile,
  ITransmittalContentFile,
} from '../../api/authenticated/transmittals/getTransmittal';
import DownloadModal from '../shared/DownloadModal';
import TransmittalDetailsFilesStore from './TransmittalDetailsFilesStore';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import ErrorModal from '../shared/ErrorModal';
import ForgeViewerModal from '../visualisation/forgeViewer/ForgeViewerModal';
import NavBarSelectorStore from '../transmittals/navBarSelector/NavBarSelectorStore';

const TransmittalDetailsFiles: FC = () => {
  const [downloadingContentFile, setDownloadingContentFile] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState('');
  const [contentFile, setContentFile] = useState<ITransmittalContentFile | null>(null);
  const [attachmentFile, setAttachmentFile] = useState<IAttachmentFile | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const transmittal = TransmittalDetailsStore.transmittal;

  if (!transmittal) return null;

  const openFileInfo = async (releasedFile: IReleasedFile) => {
    const contentSelection = FilesStore.getContentSelection(releasedFile.fileStateId);
    FilesStore.setSelectedSection(contentSelection);
    TransmittalDetailsFilesStore.showFileInformation(contentSelection, releasedFile);
  };

  const onDownLoadContentFiles = async () => {
    setDownloadingContentFile(true);
    await TransmittalDetailsFilesStore.downloadFiles(files.map((releasedFile) => releasedFile));
    setDownloadingContentFile(false);
  };

  const handleOpenFile = async (releasedFile: ITransmittalContentFile) => {
    if (releasedFile.hasDocumentViewer) {
      TransmittalDetailsFilesStore.openFile(releasedFile);
    }

    if (releasedFile.isForgeFile) {
      await ForgeViewerStore.getForgeViewer(
        releasedFile.fileRevisionId,
        NavBarSelectorStore.selectedItem!.project.projectNumber
      );
      if (ForgeViewerStore.errorMessage) setShowErrorModal(true);
    }
  };

  const handleContentFileDownload = (releasedFile: ITransmittalContentFile) => {
    const { totalFileSize, isOverLimit } = getFileSizeDetails(releasedFile.uploadedSize);

    if (isOverLimit) {
      setContentFile(releasedFile);
      setTotalFileSize(totalFileSize);
      setShowDownloadModal(true);
    } else {
      TransmittalDetailsFilesStore.downloadFile(releasedFile);
    }
  };

  const handleAttachmentFileDownload = (transmittalId: number, transmittalMessageId: number, file: IAttachmentFile) => {
    const { totalFileSize, isOverLimit } = getFileSizeDetails(file.uploadedSize);

    if (isOverLimit) {
      setAttachmentFile(file);
      setTotalFileSize(totalFileSize);
      setShowDownloadModal(true);
    } else {
      TransmittalDetailsFilesStore.downloadAttachmentFile(transmittalId, transmittalMessageId, file);
    }
  };

  const files = TransmittalDetailsFilesStore.getTransmittalReleasedFiles(transmittal.id);

  const attachmentFiles = TransmittalDetailsFilesStore.getTransmittalAttachmentFiles(transmittal.id);

  return (
    <>
      <Accordion
        activePanelIds={Array.from(TransmittalDetailsStore.openPanelIds)}
        onPanelToggle={TransmittalDetailsStore.panelToggle}
        verticalPanelGap="24px"
        headingBgColour="#FAFAFA"
        headingFontColour="#121212"
        panelBgColour="#FAFAFA"
        headingIconColour="#27524E">
        <AccordionPanel
          key="content-files"
          label={
            <div className={Styles.accordianHeading}>
              <FileIcon />
              {`Content Files (${files.length})`}
            </div>
          }
          panelId="content-files">
          <div className={Styles.contentFiles}>
            <Table
              headers={[
                { label: 'Filename' },
                { label: 'Original Filename' },
                { label: 'File Size' },
                { label: 'Action', align: 'right' },
              ]}
              breakpoint={10}
              hoverable>
              {files.map((releasedFile) => (
                <TableRow key={releasedFile.id}>
                  <TableCell>{releasedFile.title}</TableCell>
                  <TableCell>{releasedFile.originalFilename}</TableCell>
                  <TableCell>{getFileSizeDetails(releasedFile.uploadedSize).totalFileSize}</TableCell>
                  <DropDownMenuForTableCell>
                    <DropDownMenuButton onClick={() => openFileInfo(releasedFile)}>Info</DropDownMenuButton>
                    <DropDownMenuButton onClick={() => handleContentFileDownload(releasedFile)}>
                      Download
                    </DropDownMenuButton>
                    {(releasedFile.hasDocumentViewer || releasedFile.isForgeFile) && (
                      <DropDownMenuButton onClick={() => handleOpenFile(releasedFile)}>Open File</DropDownMenuButton>
                    )}
                  </DropDownMenuForTableCell>
                </TableRow>
              ))}
            </Table>
            <Button
              type="secondary"
              cssClass={Styles.downloadAllButton}
              disabled={downloadingContentFile}
              loading={downloadingContentFile}
              label={`Download All (${
                getFileSizeDetails(files.reduce((fileSize, file) => fileSize + file.uploadedSize, 0)).totalFileSize
              })`}
              onClick={onDownLoadContentFiles}
            />
          </div>
        </AccordionPanel>
        <AccordionPanel
          key="support-files"
          label={
            <div className={Styles.accordianHeading}>
              <FileIcon />
              {`Support Files (${attachmentFiles.length})`}
            </div>
          }
          panelId="support-files">
          <Table
            headers={[{ label: 'Filename' }, { label: 'File Size' }, { label: 'Action', align: 'right' }]}
            breakpoint={10}
            hoverable>
            {attachmentFiles.map((attachmentFile) => (
              <TableRow key={attachmentFile.id}>
                <TableCell>{attachmentFile.originalFilename}</TableCell>
                <TableCell>{getFileSizeDetails(attachmentFile.uploadedSize).totalFileSize}</TableCell>
                <DropDownMenuForTableCell>
                  <DropDownMenuButton
                    onClick={() =>
                      handleAttachmentFileDownload(
                        attachmentFile.transmittalId,
                        attachmentFile.transmittalMessageId,
                        attachmentFile
                      )
                    }>
                    Download
                  </DropDownMenuButton>
                </DropDownMenuForTableCell>
              </TableRow>
            ))}
          </Table>
        </AccordionPanel>
      </Accordion>
      <ForgeViewerModal
        closeModal={() => ForgeViewerStore.forgeViewerModalClose()}
        showModal={ForgeViewerStore.showForgeViewerModal}></ForgeViewerModal>
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal && !!contentFile}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          contentFile && TransmittalDetailsFilesStore.downloadFile(contentFile);
          setShowDownloadModal(false);
        }}
      />
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal && !!attachmentFile}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          attachmentFile &&
            TransmittalDetailsFilesStore.downloadAttachmentFile(
              transmittal.id,
              transmittal.transmittalMessages[0].id,
              attachmentFile
            );
          setShowDownloadModal(false);
        }}
      />
      {showErrorModal && <ErrorModal closeModal={() => setShowErrorModal(false)} />}
    </>
  );
};

export default observer(TransmittalDetailsFiles);
