import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Home from '@arcgis/core/widgets/Home';

import WidgetBase from './WidgetBase';

const HomeWidget: FC = () => {
  return WidgetBase(Home, { visible: true });
};

export default observer(HomeWidget);
