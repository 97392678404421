import { serverAxios } from '../api';

export interface IUser {
  id: number;
  name: string;
  email: string;
}

export async function getWipUsersToCollaborateWith(
  taskTeamId: number | null,
  abortSignal?: AbortSignal
): Promise<IUser[]> {
  const result = await serverAxios.get<{ users: IUser[] }>('api/cms/wipuserstocollaboratewith', {
    params: {
      taskTeamId,
    },
    signal: abortSignal,
  });
  return result.data?.users;
}
