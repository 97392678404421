import { TaskStatus } from '../../../common/enums/TaskStatus';
import { TaskType } from '../../../common/enums/TaskType';
import { TaskUserStatus } from '../../../common/enums/TaskUserStatus';
import { serverAxios } from '../api';

export interface IGetTasksResult {
  stats: IStats;
  tasks: ITask[];
}

export interface IStats {
  availableTaskCount: number;
  returnedTaskCount: number;
}
export interface ITask {
  id: number;
  message?: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  completedDate?: Date;
  taskStatusId: TaskStatus;
  taskTypeId: TaskType;
  createdByUserId: number;
  createdByUserName: string;
  createdByUserEmail: string;
  createdByUserInitials: string;
  suitabilityId: number;
  suitabilityCode: string;
  suitabilityTitle: string;
  projectNumber: string;
  taskFiles: ITaskFile[];
  taskUsers: ITaskUser[];
}

export interface ITaskFile {
  fileId: number;
  fileRevisionId: number;
  releasedFileId: number | null;
  fileTitle: string;
  originalFilename: string;
  hasDocumentViewer: boolean;
}

export interface ITaskUser {
  userId: number;
  name: string;
  email: string;
  initials: string;
  taskUserStatusId: TaskUserStatus;
  message?: string;
  completedDate?: Date;
}

export async function getTasks(
  parameters: {
    filter: {
      createdByUser?: boolean;
      awaitingReviewByUser?: boolean;
      programmeId?: number;
      projectNumber?: string;
      pending?: boolean;
      filterByUserTaskTeam?: boolean;
      filterByUserDeliveryTeam?: boolean;
    };
    status: {
      awaitingReview?: boolean;
      inProgress?: boolean;
      approved?: boolean;
      rejected?: boolean;
    };
    type: {
      taskTeamReview?: boolean;
      shareApproval?: boolean;
      deliveryTeamReview?: boolean;
      publishAuthorise?: boolean;
      publishAccept?: boolean;
    };
    search?: string;
    sorting: {
      sortProgrammeTitle?: boolean;
      sortProjectNumber?: boolean;
      sortProjectTitle?: boolean;
      sortLastUpdatedDate?: boolean;
      sortCreatedDate?: boolean;
      sortTaskTypeId?: boolean;
    };
  },
  abortSignal?: AbortSignal
): Promise<IGetTasksResult> {
  const result = await serverAxios.get<IGetTasksResult>('api/task/list', {
    params: {
      ...parameters.filter,
      ...parameters.status,
      ...parameters.type,
      search: parameters.search,
      ...parameters.sorting,
    },
    signal: abortSignal,
  });
  return result.data;
}
