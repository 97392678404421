import { serverAxios } from '../api';

export interface IAddUsersToAppointingPartyRequest {
  projectNumber: string;
  userIds: number[];
}

export function addUsersToAppointingParty(
  request: IAddUsersToAppointingPartyRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/appointingparty/users/add', request, {
    signal: abortSignal,
  });
}
