import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import Style from './styles/AddAppointingPartyUserModal.module.scss';
import ModalActions from '../../shared/ModalActions';
import CloseButton from '../../shared/CloseButton';
import UserSelector from '../../shared/UserSelector';
import AppointingPartyStore from './AppointingPartyStore';
import { Checkbox } from '@aurecon-creative-technologies/styleguide';
import ProjectUserManagementStore from '../ProjectUserManagementStore';

interface IAddAppointingPartyUserModalProps {
  closeModal: () => void;
}

const AddAppointingPartyUserModal: FC<IAddAppointingPartyUserModalProps> = (props) => {
  const { closeModal } = props;

  useEffect(() => {
    // Get select option on component did mount
    AppointingPartyStore.getOptionUsers();

    // Reset selected users on unmount
    return () => {
      AppointingPartyStore.resetSelectedUsers();
    };
  }, []);

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Adding Members</h1>
        <CloseButton onClick={() => closeModal()} disabled={false} />
        {AppointingPartyStore.selectedRoleIds.length > 0 && (
          <p>Members with an Acceptor role will automatically be an Appointing Party member as well.</p>
        )}
        <ul className={Style.projectInfo}>
          <li>
            <span className={Style.projectLabel}>Programme</span>
            <span>{ProjectUserManagementStore.project?.programmeTitle}</span>
          </li>
          <li>
            <span className={Style.projectLabel}>Project</span>
            <span>{ProjectUserManagementStore.project?.title}</span>
          </li>
        </ul>

        <div className={Style.formContent}>
          <div className={Style.userSelector}>
            <UserSelector
              label="User Name"
              required
              getUsers={(text) => AppointingPartyStore.getMatchedUsers(text)}
              onSelectedUsersUpdated={(users) => AppointingPartyStore.onSelectedUsersUpdated(users)}
              isMultiUser={true}
              disabled={false}
              searchPlaceholder={'Add User name'}
            />
          </div>
          <div className={Style.projectLabel}>{'Additional Role'}</div>
          {AppointingPartyStore.appointingPartyRoles.map((role) => {
            return (
              <Checkbox
                cssClass={Style.partyRolesCheckBox}
                key={role.id}
                label={role.label?.toString()}
                onChange={(checked) => AppointingPartyStore.setSelectedRole(role, checked)}
                checked={AppointingPartyStore.isRoleSelected(role)}
              />
            );
          })}
        </div>
        <ModalActions>
          <SecondaryButton onClick={() => closeModal()}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={!AppointingPartyStore.isEnabledAddMemberBtn}
            onClick={AppointingPartyStore.addMembersToAppointingParty}>
            Add Members
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddAppointingPartyUserModal);
