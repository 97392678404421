import { serverAxios } from '../api';

export interface IHistoryUserItem {
  name: string;
  initials: string;
}

export enum HistoryItemType {
  AssignedBy = 1,
  ReassignedTo = 2,
  ApprovedBy = 3,
  RejectedBy = 4,
}

export interface IHistoryItem {
  dateTime: Date;
  historyItemType: HistoryItemType;
  message?: string;
  byUser: IHistoryUserItem;
  toUsers?: IHistoryUserItem[];
}

export async function getTaskHistory(taskId: number, abortSignal?: AbortSignal): Promise<IHistoryItem[]> {
  const result = await serverAxios.get<{ historyItems: IHistoryItem[] }>('api/task/history', {
    params: { taskId },
    signal: abortSignal,
  });
  return result.data?.historyItems;
}
