import { serverAxios } from '../api';

export interface IAddUsersToTaskTeamRequest {
  projectNumber: string;
  taskTeamId: number;
  userIds: number[];
}

export function addUsersToTaskTeam(request: IAddUsersToTaskTeamRequest, abortSignal?: AbortSignal): Promise<void> {
  return serverAxios.post('api/um/taskteam/users/add', request, {
    signal: abortSignal,
  });
}
