import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import TaskCard from './TaskCard';
import TabContainerActionBar from './TabContainerActionBar';
import Style from './styles/TabContainer.module.scss';
import { TabStore } from './TabStore';

export interface ITabContainerProps {
  store: TabStore;
}

const TabContainer: FC<ITabContainerProps> = ({ store }) => {
  return (
    <>
      <TabContainerActionBar store={store} />
      <div className={Style.container}>
        <ol className={Style.list}>
          {store.tasks.map((task) => (
            <TaskCard key={task.id} task={task} />
          ))}
        </ol>
      </div>
    </>
  );
};

export default observer(TabContainer);
