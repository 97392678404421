import { makeAutoObservable, runInAction } from 'mobx';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { msalInstance } from '../auth/MsalInstance';
import { tokenRequest } from '../auth/MsalConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import AppStore from './AppStore';

interface Jwtp extends JwtPayload {
  name: string;
  email: string;
  preferred_username: string;
}

class AuthStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private isRequestingToken = false;
  private accessTokenExpiresOn = 0;
  public accessToken = '';
  public name? = '';
  public email = '';
  public unauthorized = false;

  public async refreshToken(): Promise<void> {
    const tokenReq = {
      ...tokenRequest,
      scopes: [...tokenRequest.scopes, ...[AppStore.settings?.appApiScope ?? '']],
      forceRefresh: true,
    };

    if (Date.now() < this.accessTokenExpiresOn) return;

    if (this.isRequestingToken) return;

    runInAction(() => {
      this.isRequestingToken = true;
    });
    try {
      const result = await msalInstance.acquireTokenSilent(tokenReq);
      runInAction(() => {
        const decodedAccessToken = jwtDecode<Jwtp>(result.accessToken);
        this.accessToken = result.accessToken;
        this.name = decodedAccessToken.name;
        this.email = decodedAccessToken.email ?? decodedAccessToken.preferred_username;
        this.accessTokenExpiresOn = ((decodedAccessToken.exp ?? 0) - 15 * 60) * 1000;

        this.isRequestingToken = false;
        this.unauthorized = false;
      });
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(tokenReq);
      }
      runInAction(() => {
        this.isRequestingToken = false;
        this.unauthorized = true;
      });
    }
  }
}

export default new AuthStore();
