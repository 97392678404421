import { AppointingPartyRole } from '../../../common/enums/AppointingPartyRole';
import { serverAxios } from '../api';

export interface IRemoveUserFromAppointingPartyRoleRequest {
  projectNumber: string;
  userId: number;
  appointingPartyRoleId: AppointingPartyRole;
}

export function removeUserFromAppointingPartyRole(
  request: IRemoveUserFromAppointingPartyRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/appointingparty/users/removerole', request, {
    signal: abortSignal,
  });
}
