import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Slice from '@arcgis/core/widgets/Slice';

import WidgetBase from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';

const SliceWidget: FC = () => {
  return WidgetBase(Slice, { visible: true }, Style.sliceWidgetWrapper);
};

export default observer(SliceWidget);
