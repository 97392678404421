import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from './ContentSelection';
import { classNames } from '../../utils/miscUtils';
import FilesStore from './FilesStore';
import AddContent from './wipTab/upload/AddContent';
import ShareButton from './wipTab/ShareButton';
import FileTable from './fileTable/FileTable';
import FileUpload from './wipTab/upload/FileUpload';
import NewFileFromTemplate from './wipTab/upload/NewFileFromTemplate';
import TaskTeamReviewButton from './wipTab/TaskTeamReviewButton';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import DownloadButton from './DownloadButton';
import SelectedFilesLabel from './SelectedFilesLabel';
import DeliveryTeamReview from './sharedTab/DeliveryTeamReview';
import PublishButton from './sharedTab/PublishButton';
import CollaborateButton from './CollaborateButton';
import FileInformation from './fileInformation/FileInformation';
import Style from './styles/TabContainer.module.scss';
import DeleteOverflowButton from './DeleteOverflowButton';
import Overlay from '../shared/Overlay';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../common/models/ItemType';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import { IFile } from '../../api/authenticated/cms/FileModel';
import { FromModuleEnum } from '../../enums/FromModuleEnum';
import CreateTransmittalStore from '../transmittals/CreateTransmittalStore';
import AddTransmittalMessageStore from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageStore';

export interface ITabContainerProps {
  isOpenFiles?: boolean;
  onAddFilesToTransmittal?: (files: IFile[]) => void;
  onCancelAddFilesToTransmittal?: () => void;
  fromModule?: FromModuleEnum;
}

const TabContainer: FC<ITabContainerProps> = (props) => {
  useEffect(() => {
    FilesStore.setFromModule(props.fromModule);
  }, [props.fromModule]);

  const showFileTable =
    FilesStore.selectedSection == ContentSelection.WIP ||
    FilesStore.selectedSection === ContentSelection.Shared ||
    FilesStore.selectedSection === ContentSelection.Published;

  const showSelectedState =
    FilesStore.hasSelectedFile() ||
    FilesStore.selectedSection === ContentSelection.Shared ||
    FilesStore.selectedSection === ContentSelection.Published;

  const selectedTeamItemId =
    NavBarSelectorStore.selectedItem && NavBarSelectorStore.selectedItem.type === NavigationItemTypes.TaskTeam
      ? NavBarSelectorStore.selectedItem.taskTeam.id
      : undefined;

  const handleAddToTransmittalClick = () => {
    props.onAddFilesToTransmittal && props.onAddFilesToTransmittal(FilesStore.selectedFiles);
  };

  const handleCancelAddToTransmittalClick = () => {
    props.onCancelAddFilesToTransmittal && props.onCancelAddFilesToTransmittal();
  };

  return (
    <div
      className={classNames(Style.container, [
        showFileTable && FilesStore.isShowingFileInformation,
        Style.showFileInformation,
      ])}>
      {FilesStore.isLoading && (
        <div>
          <Overlay className={Style.modalOverlay}></Overlay>
          <Loader cssClass={Style.savingSpinner} size="small" label={FilesStore.loadingLabel} />
        </div>
      )}
      <ActionBar className={Style.actionBar} selectedState={showSelectedState}>
        {showSelectedState && !props.isOpenFiles ? (
          <ActionBarSection>
            {FilesStore.selectedSection === ContentSelection.WIP && (
              <>
                <TaskTeamReviewButton taskTeamId={selectedTeamItemId || 0} />
                <ShareButton taskTeamId={selectedTeamItemId || 0} />
                <CollaborateButton taskTeamId={selectedTeamItemId || 0} />
              </>
            )}
            {FilesStore.selectedSection === ContentSelection.Shared && (
              <>
                <DeliveryTeamReview taskTeamId={selectedTeamItemId || 0} />
                <PublishButton taskTeamId={selectedTeamItemId || 0} />
                <CollaborateButton taskTeamId={selectedTeamItemId || 0} />
              </>
            )}
            {FilesStore.selectedSection === ContentSelection.Published && (
              <CollaborateButton taskTeamId={selectedTeamItemId || 0} />
            )}
            <DownloadButton />
            <DeleteOverflowButton />
            <SelectedFilesLabel />
          </ActionBarSection>
        ) : (
          <ActionBarSection>
            {!props.isOpenFiles && <AddContent />}
            {props.isOpenFiles && (
              <>
                <PrimaryButton
                  disabled={
                    !FilesStore.selectedFiles.length ||
                    (FilesStore.isAnySelectedFileLocked &&
                      !(CreateTransmittalStore.isOpenFiles || AddTransmittalMessageStore.isOpenFiles))
                  }
                  onClick={handleAddToTransmittalClick}>
                  Add to Transmittal
                </PrimaryButton>
                <SecondaryButton onClick={handleCancelAddToTransmittalClick}>Cancel</SecondaryButton>
                <SelectedFilesLabel />
              </>
            )}
          </ActionBarSection>
        )}
      </ActionBar>
      <div className={Style.content}>
        {showFileTable && (
          <FileTable
            tableColumns={FilesStore.getTableColumns}
            tableFiles={FilesStore.filteredFiles}
            onFilter={(column, filter) => {
              FilesStore.applyFilter(column, filter);
            }}
            onSortColumnChange={(column, direction) => {
              FilesStore.applySort(column, direction);
            }}
          />
        )}
        {FilesStore.selectedSection == ContentSelection.UploadFile && <FileUpload />}
        {FilesStore.selectedSection == ContentSelection.AddBlankDocument && <NewFileFromTemplate />}
        {FilesStore.selectedSection == ContentSelection.AddTemplateDocument && <NewFileFromTemplate />}
      </div>
      {showFileTable && FilesStore.isShowingFileInformation && (
        <div className={Style.fileInformation}>
          <FileInformation />
        </div>
      )}
    </div>
  );
};

export default observer(TabContainer);
