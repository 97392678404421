import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import TeamChartStore from './TeamChartStore';
import Style from './styles/TeamChart.module.scss';
import { ITaskTeamUserGroup } from './interface/TeamChart.interface';
import classNames from 'classnames';
import TaskUserTooltip from './TaskUserTooltip';
import { useTeamChartContext } from './TeamChartStoreContext';

interface ITaskTeamUserGroupItemProps {
  taskTeamId: number;
  deliveryTeamId: number;
  initialKey: string;
  values: ITaskTeamUserGroup[];
}

const TaskTeamUserGroupItem: FC<ITaskTeamUserGroupItemProps> = (props) => {
  const context = useTeamChartContext();
  const { initialKey, values, deliveryTeamId, taskTeamId } = props;
  return (
    <>
      <ul className={Style.taskTeamWrapper}>
        <li className={Style.groupTileItem}>{initialKey}</li>
        {values.map((user: ITaskTeamUserGroup) => (
          <li
            key={user.id}
            className={classNames(Style.taskTeamItem, {
              [Style.active]: user.check,
              [Style.disabled]: user.disabled,
            })}
            onClick={() =>
              !context.disableTaskTeam &&
              TeamChartStore.addOrRemoveTaskTeamUserById(deliveryTeamId, taskTeamId, user, initialKey)
            }>
            <TaskUserTooltip user={user} />
          </li>
        ))}
      </ul>
    </>
  );
};
export default observer(TaskTeamUserGroupItem);
