import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/TransmittalItem.module.scss';
import Icon from '../shared/Icon';
import { ITransmittalListItem } from '../../api/authenticated/transmittals/getTransmittal';
import { formatDate } from '../../utils/dateUtils';
import Dot from '../shared/Dot';
import FlexFiller from '../shared/FlexFiller';
import TransmittalInitiator from './TransmittalInitiator';
import TransmittalNotifiers from './TransmittalNotifiers';
import TransmittalsStore from './TransmittalsStore';
import { Pages } from '../../common/constants/Pages';
import parse from 'html-react-parser';

const shiftKey = 'Shift';
const bodyTagName = 'body';

export interface TransmittalItemProps {
  transmittal: ITransmittalListItem;
}

const TransmittalItem: FC<TransmittalItemProps> = (props) => {
  const { transmittal } = props;
  const [shiftOn, setShiftOn] = useState(false);

  useEffect(() => {
    const handleKeyPressDown = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== bodyTagName) return;
      if (e.key === shiftKey) {
        window?.getSelection()?.removeAllRanges();
        setShiftOn(true);
      }
    };
    const handleKeyPressUp = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== bodyTagName) return;
      if (e.key === shiftKey) {
        window?.getSelection();
        setShiftOn(false);
      }
    };
    window.addEventListener('keydown', handleKeyPressDown, false);
    window.addEventListener('keyup', handleKeyPressUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyPressUp);
      window.removeEventListener('keydown', handleKeyPressDown);
    };
  }, []);

  const onSelectTransmittal = (checked: boolean, shiftOn: boolean) => {
    TransmittalsStore.setSelectedTransmittal(transmittal, checked, shiftOn);
  };

  const transmittalMessages = transmittal.transmittalMessages
    .slice()
    .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());

  return (
    <div className={Style.transmittalItem}>
      <div className={Style.checkboxColumn}>
        <Checkbox onChange={(checked) => onSelectTransmittal(checked, shiftOn)} checked={transmittal.checked} />
      </div>
      <div className={Style.itemDetailWrapper}>
        <div className={Style.subHeading}>
          <div className={`${Style.messageGroup} ${Style.subject}`}>
            <label className={Style.propertyLabel}>Subject</label>
            <span>{transmittalMessages[0].subject}</span>
          </div>

          <FlexFiller />
          <a
            className={Style.linkBtn}
            href={`#${Pages.Transmittals.Route}/${transmittal.title}/${transmittal.projectNumber}`}>
            <div className={Style.linkContent}>View Transmittal</div>
          </a>
        </div>
        <div className={Style.transmittalMessage}>
          <div className={Style.messageGroup}>
            <div className={Style.titleItem}>
              <div className={Style.transmittalId}>
                <label className={Style.propertyLabel}>Transmittal ID</label>
                <span>{transmittal.title}</span>
              </div>
              {transmittal.closed && (
                <div className={Style.iconWrapper}>
                  <Dot className={Style.closed} />
                  <small>Closed</small>
                </div>
              )}
              {transmittal.overdue && !transmittal.closed && (
                <div className={Style.iconWrapper}>
                  <Dot className={Style.overdue} />
                  <small>Overdue</small>
                </div>
              )}
              {transmittal.flagged && (
                <div className={Style.iconWrapper}>
                  <Icon className={Style.flagged} name="flag" />
                </div>
              )}
            </div>
          </div>
          <div className={Style.messageGroup}>
            <label className={Style.propertyLabel}>Message</label>
            <span>{parse(transmittalMessages[0].message)}</span>
          </div>
        </div>
        <div className={Style.transmittalProperties}>
          <div className={Style.propertyGroup}>
            <div className={Style.itemProperty}>
              <label className={Style.propertyLabel}>Type</label>
              <span>{transmittal.transmittalTypeTitle}</span>
            </div>
            <div className={Style.itemProperty}>
              <label className={Style.propertyLabel}>Initiated Date</label>
              <div>{formatDate(transmittal.createdDate)}</div>
            </div>
            <div className={`${Style.itemProperty} ${Style.lastItem}`}>
              <label className={Style.propertyLabel}>Initiator</label>
              <div className={Style.initiatorGroup}>
                <TransmittalInitiator
                  createdByUserName={transmittal.createdByEmail}
                  userInitials={transmittal.createdByInitials}
                  taskTeamTitle={transmittal.taskTeamTitle}
                  taskTeamCode={transmittal.taskTeamCode}
                  deliveryTeamTitle={transmittal.deliveryTeamTitle}></TransmittalInitiator>
              </div>
            </div>
          </div>

          <div className={Style.propertyGroup}>
            <div className={Style.itemProperty}>
              <label className={Style.propertyLabel}>Reason</label>
              <span>{transmittal.transmittalReasonTitle}</span>
            </div>
            <div className={Style.itemProperty}>
              <label className={Style.propertyLabel}>Last Updated</label>
              <span>{formatDate(transmittal.lastUpdated)}</span>
            </div>
            <div className={`${Style.itemProperty} ${Style.lastItem}`}>
              <label className={Style.propertyLabel}>Notified</label>
              <TransmittalNotifiers notifiedUsers={transmittal.allNotifiedUsers}></TransmittalNotifiers>
            </div>
          </div>

          <div className={`${Style.propertyGroup} ${Style.statusSummary}`}>
            <div className={Style.summaryItem}>
              <Tooltip show="Responses" defaultUp={false}>
                <Icon className={Style.infoIcon} name="chat" />
                <span>{transmittal.transmittalMessages.length}</span>
              </Tooltip>
            </div>
            <div className={Style.summaryItem}>
              <Tooltip show="Visible to" defaultUp={false}>
                <Icon className={Style.infoIcon} name="visibility" />
                <span>{transmittal.totalVisibleUser}</span>
              </Tooltip>
            </div>
            <div className={Style.summaryItem}>
              <Tooltip show="Files" defaultUp={false}>
                <Icon className={Style.infoIcon} name="description" />
                <span>
                  {TransmittalsStore.countLinkedAndAttachmentFilesFromMessages(transmittal.transmittalMessages)}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TransmittalItem);
