import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableRow, TableCell } from '@aurecon-creative-technologies/styleguide';
import { useReviewStore } from './ReviewStoreContext';
import FilesStore from '../FilesStore';
import Icon from '../../shared/Icon';
import Style from './styles/FileList.module.scss';

const FileList: FC = () => {
  const store = useReviewStore();

  return (
    <Table headers={[{ label: 'Files' }]} breakpoint={10} hoverable cssClass={Style.fileTables}>
      {store.selectedFiles.map((file) => (
        <TableRow key={FilesStore.getFileKey(file)}>
          <TableCell>
            <div className={Style.filenameCell}>
              <span>{`${file.title} | ${file.originalFilename}`}</span>
              <button
                className={Style.filenameRemove}
                onClick={() => store.removeSelectedFile(file.id)}
                disabled={store.isSaving}>
                <Icon className={Style.filenameRemoveIcon} name="close" />
              </button>
            </div>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default observer(FileList);
