import * as React from 'react';
import { observer } from 'mobx-react-lite';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';

export interface INavBarSelectorProgrammeProps {
  title: string;
}

const NavBarSelectorProgramme: React.FC<INavBarSelectorProgrammeProps> = ({ title }) => {
  return (
    <NavBarSelectorItemContainer>
      <span>{title}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorProgramme);
