import { serverAxios } from '../api';

export interface IUploadFileRequest {
  blob: Blob;
  projectNumber: string;
  fileUploadId: number;
  index: number;
  offset: number;
  isLastChunk: boolean;
  isSupersede?: boolean;
  fileName: string;
}

export async function upload(request: IUploadFileRequest, abortSignal?: AbortSignal): Promise<number> {
  const formData = new FormData();
  formData.append('file', request.blob, request.fileName);
  formData.append('projectNumber', request.projectNumber);
  formData.append('fileUploadId', request.fileUploadId.toString());
  formData.append('index', request.index.toString());
  formData.append('offset', request.offset.toString());
  formData.append('isLastChunk', request.isLastChunk.toString());
  formData.append('isSupersede', request.isSupersede !== undefined && request.isSupersede ? 'true' : 'false');
  const result = await serverAxios.post<number>('api/cms/upload', formData, { signal: abortSignal });
  return result.data;
}
