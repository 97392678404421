import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import unregister from './registerServiceWorker';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, initAppInsights } from './utils/appInsights';
import { MsalProvider } from '@azure/msal-react';
import { initMsalClient, msalInstance } from './auth/MsalInstance';
import AppStore from './stores/AppStore';

AppStore.loadSettings()
  .then((settings) => {
    initMsalClient(settings.appClientId, settings.appAuthority);
    initAppInsights(settings.appInsightsKey);

    ReactDOM.render(
      <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </AppInsightsContext.Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
    unregister();
  })
  .catch((err) => {
    console.error(err);
    ReactDOM.render(
      <div>
        <h3>Error loading application configuration</h3>
        <div>{JSON.stringify(err)}</div>
      </div>,
      document.getElementById('root')
    );
  });
