import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Fullscreen from '@arcgis/core/widgets/Fullscreen';

import WidgetBase from './WidgetBase';

const FullscreenWidget: FC = () => {
  return WidgetBase(Fullscreen, { visible: true });
};

export default observer(FullscreenWidget);
