import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DateFilterOperator, IDateRangeFilter } from './TableColumn';
import { DateInput, Dropdown, IDateInputDates, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { dateOnly, getDateFormatPattern } from '../../../utils/dateUtils';
import Style from './styles/DateFilter.module.scss';

export interface IDateFilterProps {
  dateFilter: IDateRangeFilter;
  disabled?: boolean;
  onChange?: (value: IDateRangeFilter) => void;
}

const DateFilter: FC<IDateFilterProps> = ({ dateFilter, disabled, onChange }) => {
  const [rangeType, setRangeType] = useState<boolean>(dateFilter.operator === DateFilterOperator.BETWEEN);
  const [value, setValue] = useState<IDateRangeFilter>({
    operator: dateFilter.operator ?? DateFilterOperator.EQUAL_TO,
    endDate: null,
    startDate: null,
  });
  const [selectOption, setSelectedOption] = useState<string | number>(value.operator ?? DateFilterOperator.EQUAL_TO);

  const options: IDropdownItemProps[] = [
    { id: DateFilterOperator.EQUAL_TO, label: 'On' },
    { id: DateFilterOperator.LESS_THAN, label: 'Before' },
    { id: DateFilterOperator.GREATER_THAN, label: 'After' },
    { id: DateFilterOperator.BETWEEN, label: 'Between' },
  ];

  const onDateRangeChangeHandler = (val: IDateInputDates) => {
    const newValue = { ...value, startDate: val.startDate, endDate: val.endDate };
    setValue(newValue);
    onChange && onChange(newValue);
  };

  const handleOnSelectItem = (id: string | number): void => {
    setSelectedOption(id);
    setRangeType(id === DateFilterOperator.BETWEEN);
    const newValue = {
      ...value,
      startDate: value.startDate ? dateOnly(value.startDate) : null,
      endDate: value.endDate ? dateOnly(value.endDate) : null,
      operator: id as number,
    };
    setValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <div className={Style.wrapper}>
      <Dropdown
        cssClass={Style.dropdown}
        placeholder="-"
        size="extra small"
        disabled={disabled}
        items={options}
        selectedItem={selectOption}
        onSelectItem={handleOnSelectItem}
      />
      <DateInput
        cssClass={Style.datePicker}
        dates={dateFilter}
        onDateChange={onDateRangeChangeHandler}
        rangeType={rangeType}
        disabled={disabled}
        format={getDateFormatPattern()}
      />
    </div>
  );
};

export default observer(DateFilter);
