import { makeAutoObservable, runInAction } from 'mobx';
import { getExternalUserFileDownloadUrl } from '../../api/authenticated/cms/getExternalUserFileDownloadUrl';
import { getExternalUserFileDocumentViewerUrl } from '../../api/authenticated/cms/getExternalUserFileViewerUrl';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import { getProjectFileDocumentViewerUrl } from '../../api/authenticated/cms/getProjectFileViewerUrl';
import { IMetadataField } from '../../api/authenticated/cms/getProjectMetadata';
import {
  IAttachmentFile,
  IReleasedFile,
  ITransmittalContentFile,
} from '../../api/authenticated/transmittals/getTransmittal';
import { getTransmittalFileDownloadUrl } from '../../api/authenticated/transmittals/getTransmittalFileDownloadUrl';
import { ClientUserRole } from '../../common/enums/ClientUserRole';
import { FileState } from '../../common/enums/FileState';
import AppStore from '../../stores/AppStore';
import { ContentSelection } from '../files/ContentSelection';
import FileInformationStore from '../files/fileInformation/FileInformationStore';
import NavBarSelectorStore from '../transmittals/navBarSelector/NavBarSelectorStore';
import TransmittalDetailsStore from './TransmittalDetailsStore';

export class TransmittalDetailsFilesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public metadataFields: IMetadataField[] = [];
  public selectedSection: ContentSelection = ContentSelection.WIP;
  public fileBusy: { [filedId: number]: boolean } = {};

  public mapToFile = (releasedFile: IReleasedFile) => ({
    id: releasedFile.id,
    releasedFileId: releasedFile.releasedFileId,
    title: releasedFile.title,
    extension: releasedFile.extension,
    originalFilename: releasedFile.originalFilename,
    fieldValue1: releasedFile.fieldValue1,
    fieldValue2: releasedFile.fieldValue2,
    fieldValue3: releasedFile.fieldValue3,
    fieldValue4: releasedFile.fieldValue4,
    fieldValue5: releasedFile.fieldValue5,
    fieldValue6: releasedFile.fieldValue6,
    fieldValue7: releasedFile.fieldValue7,
    fieldValue8: releasedFile.fieldValue8,
    fieldValue9: releasedFile.fieldValue9,
    fieldValue10: releasedFile.fieldValue10,
    fieldValue11: releasedFile.fieldValue11,
    fieldValue12: releasedFile.fieldValue12,
    fieldValue13: releasedFile.fieldValue13,
    fieldValue14: releasedFile.fieldValue14,
    fieldValue15: releasedFile.fieldValue15,
    fieldValue16: releasedFile.fieldValue16,
    fieldValue17: releasedFile.fieldValue17,
    fieldValue18: releasedFile.fieldValue18,
    fieldValue19: releasedFile.fieldValue19,
    fieldValue20: releasedFile.fieldValue20,
    taskTeamId: 0,
    uploadedSize: releasedFile.uploadedSize,
    uploadedByUserName: releasedFile.uploadedByUserName,
    modifiedDate: releasedFile.uploadedDate,
    formattedRevision: releasedFile.formattedRevision,
    suitabilityCode: releasedFile.suitabilityCode,
    isSuitabilityRefCode: releasedFile.isSuitabilityRefCode,
    suitabilityTitle: releasedFile.suitabilityTitle,
    fileRevisionId: releasedFile.fileRevisionId,
    inSharePoint: null,
    isLocked: false,
    isForgeFile: false,
    taskTypeId: null,
    uploadedByUserId: 0,
    canTaskTeamReview: false,
    canShareReview: false,
    canDeliveryTeamReview: false,
    canPublishReview: false,
    canCollaborate: false,
    canSubmitForge: false,
    canDelete: false,
    hasDocumentViewer: false,
    lastModifiedUserName: null,
    releasedDate: null,
    revision: null,
    suitabilityId: null,
    deliveryTeamTitle: releasedFile.deliveryTeamTitle,
    taskTeamTitle: releasedFile.taskTeamTitle,
  });

  public async showFileInformation(contentSelection: ContentSelection, file: IReleasedFile) {
    let fileStateId: FileState | null = null;

    if (contentSelection) this.selectedSection = contentSelection;
    switch (this.selectedSection) {
      case ContentSelection.WIP:
        fileStateId = FileState.Wip;
        break;
      case ContentSelection.Shared:
        fileStateId = FileState.Shared;
        break;
      case ContentSelection.Published:
        fileStateId = FileState.Published;
        break;
    }

    if (!fileStateId || !TransmittalDetailsStore.transmittal) return;
    FileInformationStore.init(
      this.mapToFile(file),
      fileStateId,
      NavBarSelectorStore.selectedItem!.project.projectNumber,
      TransmittalDetailsStore.transmittal.id
    );
  }

  public async downloadFile(file: ITransmittalContentFile) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });
    if (AppStore.client?.user.clientUserRole !== ClientUserRole.External) {
      await this.getDownloadFileUrlForProjectUser(
        file.id,
        file.fileRevisionId,
        file.releasedFileId,
        file.transmittalId,
        file.transmittalMessageId
      );
    } else {
      await this.getDownloadFileUrlForExternalUser(file.id, file.fileRevisionId, file.releasedFileId);
    }
  }

  private async getAttachmentDownloadUrl(
    fileId: number,
    transmittalId: number | null,
    transmittalMessageId: number | null
  ) {
    try {
      const url = await getTransmittalFileDownloadUrl(
        NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileId,
        transmittalId,
        transmittalMessageId
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.fileBusy[fileId] = false;
      });
    }
  }

  private async getDownloadFileUrlForProjectUser(
    fileId: number,
    fileRevisionId: number | null,
    releasedFileId: number | null,
    transmittalId: number | null,
    transmittalMessageId: number | null
  ) {
    try {
      const url = await getProjectFileDownloadUrl(
        NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileId,
        fileRevisionId,
        releasedFileId,
        transmittalId,
        transmittalMessageId
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.fileBusy[fileId] = false;
      });
    }
  }

  private async getDownloadFileUrlForExternalUser(
    fileId: number,
    fileRevisionId: number | null,
    releasedFileId: number | null
  ) {
    try {
      if (!TransmittalDetailsStore.transmittal) return;
      const url = await getExternalUserFileDownloadUrl(
        NavBarSelectorStore.selectedItem!.project.projectNumber,
        TransmittalDetailsStore.transmittal.id,
        fileId,
        fileRevisionId,
        releasedFileId
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.fileBusy[fileId] = false;
      });
    }
  }

  public async downloadFiles(files: ITransmittalContentFile[]) {
    if (!files || !files.length) return;
    await Promise.all(files.map((contentFile) => this.downloadFile(contentFile)));
  }

  public async downloadAttachmentFile(transmittalId: number, transmittalMessageId: number, file: IAttachmentFile) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });
    if (AppStore.client?.user.clientUserRole !== ClientUserRole.External) {
      await this.getAttachmentDownloadUrl(file.id, transmittalId, transmittalMessageId);
    } else {
      await this.getDownloadFileUrlForExternalUser(file.id, null, null);
    }
  }

  public async downloadAttachmentFiles(transmittalId: number, transmittalMessageId: number, files: IAttachmentFile[]) {
    if (!files || !files.length) return;
    await Promise.all(
      files.map((contentFile) => this.downloadAttachmentFile(transmittalId, transmittalMessageId, contentFile))
    );
  }

  public async openFile(file: ITransmittalContentFile) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });
    if (AppStore.client?.user.clientUserRole !== ClientUserRole.External) {
      this.getFileDocumentViewerUrlForProjectUser(file);
    } else {
      this.getFileDocumentViewerUrlForExternalUser(file.transmittalMessageId, file.id);
    }
  }

  private async getFileDocumentViewerUrlForProjectUser(file: ITransmittalContentFile) {
    try {
      const url = await getProjectFileDocumentViewerUrl(
        NavBarSelectorStore.selectedItem!.project.projectNumber,
        file.id,
        file.fileRevisionId,
        file.releasedFileId,
        file.transmittalId,
        file.transmittalMessageId
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.fileBusy[file.id] = false;
      });
    }
  }

  private async getFileDocumentViewerUrlForExternalUser(transmittalMessageId: number, fileId: number) {
    try {
      if (!TransmittalDetailsStore.transmittal) return;
      const url = await getExternalUserFileDocumentViewerUrl(
        NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileId,
        null,
        null,
        TransmittalDetailsStore.transmittal.id
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.fileBusy[fileId] = false;
      });
    }
  }

  public getTransmittalReleasedFiles(transmittalId: number) {
    if (!TransmittalDetailsStore.selectedResponse) return [];
    const messageId = TransmittalDetailsStore.selectedResponse.id;
    return TransmittalDetailsStore.selectedResponse.transmittalMessageReleasedFiles.map((f) => ({
      ...f,
      transmittalId: transmittalId,
      transmittalMessageId: messageId,
    }));
  }

  public getTransmittalAttachmentFiles(transmittalId: number) {
    if (!TransmittalDetailsStore.selectedResponse) return [];

    const messageId = TransmittalDetailsStore.selectedResponse.id;
    return TransmittalDetailsStore.selectedResponse.transmittalMessageAttachmentFiles.map((f) => ({
      ...f,
      transmittalId: transmittalId,
      transmittalMessageId: messageId,
    }));
  }
}

export default new TransmittalDetailsFilesStore();
