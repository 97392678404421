import { computed, makeAutoObservable, runInAction } from 'mobx';
import { ContentSelection, FileContentTabIds, FileStateNames } from './ContentSelection';
import { FileState } from '../../common/enums/FileState';
import { getWipFiles } from '../../api/authenticated/cms/getWipFiles';
import { getNonWipFiles } from '../../api/authenticated/cms/getNonWipFiles';
import { FileFilter } from '../../api/authenticated/cms/FileFilterClass';
import { IFile } from '../../api/authenticated/cms/FileModel';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import {
  getProjectMetadata,
  IFileMetadataFieldValue,
  IMetadataField,
} from '../../api/authenticated/cms/getProjectMetadata';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import FileInformationStore from './fileInformation/FileInformationStore';
import { getProjectFileDocumentViewerUrl } from '../../api/authenticated/cms/getProjectFileViewerUrl';
import AppStore from '../../stores/AppStore';
import { PagingArray } from '../../utils/pagingArray';
import { IPageMetaData, IPagedResponse } from '../../common/models/IPagedResponse';
import { IDateRangeFilter, ITableColumn } from './fileTable/TableColumn';
import { MetadataFieldTitle, MetadataFieldType } from '../../common/enums/MetadataFieldType';
import { uniq, orderBy } from 'lodash';
import { deleteFile } from '../../api/authenticated/cms/deleteFile';
import { resendForgeFile } from '../../api/authenticated/cms/resendForgeFile';
import config from '../../config';
import { SortType, SortTypes } from '../../common/enums/SortType';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { lockFile } from '../../api/authenticated/cms/lockFile';
import { unlockFile } from '../../api/authenticated/cms/unlockFile';
import { AxiosError } from 'axios';
import { ITab } from '@aurecon-creative-technologies/styleguide';
import { NavigationItemTypes } from '../../common/models/ItemType';
import { getSuitabilities, ISuitability } from '../../api/authenticated/tasks/getAddData';
import { getSelectedItems } from '../../utils/getSelectedItems';
import { Params } from 'react-router-dom';
import { FromModuleEnum } from '../../enums/FromModuleEnum';

export class FilesStore {
  constructor() {
    makeAutoObservable(
      this,
      {
        selectedFiles: computed,
        canTaskTeamReviewOnSelectedFiles: computed,
        canShareReviewOnSelectedFiles: computed,
        canDeliveryTeamReviewOnSelectedFiles: computed,
        canPublishReviewOnSelectedFiles: computed,
        canCollaborateOnSelectedFiles: computed,
        canDownloadSelectedFiles: computed,
        canDeleteSelectedFiles: computed,
        isAnySelectedFileLocked: computed,
        selectedFilesSize: computed,
      },
      { autoBind: true }
    );
  }

  private _selectedFileMap: { [key: string]: IFile } = {};
  private _lastSelectedIndexes: { [key: string]: number[] } = {};
  public files: IFile[] = [];
  public fileBusy: { [filedId: number]: boolean } = {};

  public selectedSection: ContentSelection = ContentSelection.WIP;
  public selectedFileType = '';
  public metadataFields: IMetadataField[] = [];
  public suitabilities: ISuitability[] = [];
  public selectedTabId = 1;
  public showSelectedFiles = false;
  public tableColumns: ITableColumn[] = [];
  public fileStateId: FileState = FileState.Wip;
  public pageSize = config.defaultPageSize;
  public pageNumber = 1;
  public errorMessage: string | null = null;
  public fileFilters: FileFilter = new FileFilter();
  public pagingMetaData: IPageMetaData = {
    pageNumber: 0,
    pageCount: 0,
  } as IPageMetaData;
  public isLockSupersedeFileError = false;
  public isLoading = false;
  public loadingLabel = 'Files loading';
  public isOpenFromUrl = false;
  public fromModule?: FromModuleEnum;
  public fileName?: string;

  public get tabs(): ITab[] {
    if (NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam) {
      if (this.selectedSection === ContentSelection.UploadFile) {
        return [{ id: 1, label: 'Work in Progress' }];
      }
      return [
        { id: 1, label: 'Work in Progress' },
        { id: 2, label: 'Shared' },
        { id: 3, label: 'Published' },
      ];
    } else {
      return [
        { id: 2, label: 'Shared' },
        { id: 3, label: 'Published' },
      ];
    }
  }

  public setQueryParametersFromUrl(params: Readonly<Params<string>>) {
    runInAction(() => {
      if (this.isValidRequestParam(params)) {
        this.isOpenFromUrl = true;
        AppStore.setProjectNumber(params.projectNumber);
        AppStore.setDeliveryTeamCode(params.deliveryTeamCode);
        AppStore.setTaskTeamCode(params.taskTeamCode);
        NavBarSelectorStore.initSelector(false);
        this.fileName = params.fileName ? params.fileName : undefined;
        if (params.stateId === FileStateNames.WIP) {
          this.selectedTabId = FileContentTabIds.WIP_TAB_ID;
          this.selectedSection = ContentSelection.WIP;
        } else if (params.stateId === FileStateNames.SHARED) {
          this.selectedTabId = FileContentTabIds.SHARED_TAB_ID;
          this.selectedSection = ContentSelection.Shared;
        } else if (params.stateId === FileStateNames.PUBLISHED) {
          this.selectedTabId = FileContentTabIds.PUBLISHED_TAB_ID;
          this.selectedSection = ContentSelection.Published;
        }
      } else {
        this.clearQueryParametersFromUrl();
      }
    });
  }

  public get getNavbarSelectedItem() {
    return NavBarSelectorStore.selectedItem;
  }

  public clearQueryParametersFromUrl() {
    runInAction(() => {
      this.isOpenFromUrl = false;
      this.fileName = undefined;
      this.fileFilters.setFileName();
    });
  }

  public setPagingMetaData(res: IPagedResponse<IFile>) {
    const paginatedListObject = new PagingArray(this.pageSize, this.pageNumber, res.totalCount, res.pageCount);
    runInAction(() => {
      this.pagingMetaData = paginatedListObject.getMetaData();
    });
  }

  public async setCurrentPage(page) {
    if (page === this.pageNumber) return;
    this.pageNumber = page;
    this.files = [];
    this.fileFilters.setPageCondition(this.pageNumber, this.pageSize);
    await this.loadFiles();
  }

  public setSelectedTabId(tabId: number) {
    runInAction(() => {
      this.selectedTabId = tabId;
    });
  }

  public setFromModule(fromModuleId?: FromModuleEnum) {
    runInAction(() => {
      this.fromModule = fromModuleId;
    });
  }

  public setDefaultSelectedTab() {
    runInAction(() => {
      if (!this.isOpenFromUrl) {
        this.selectedTabId = this.tabs[0].id;
        this.selectedSection =
          NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam
            ? ContentSelection.WIP
            : ContentSelection.Shared;
      }
    });
  }

  public cleanup(isClearSelectedFile = true) {
    runInAction(() => {
      this.pageNumber = 1;
      this.pagingMetaData = { pageNumber: 1, pageCount: 0 } as IPageMetaData;
      this.files = [];
      this.showSelectedFiles = false;
      this.suitabilities = [];
      if (isClearSelectedFile) {
        this._selectedFileMap = {};
        this._lastSelectedIndexes = {};
      }
    });
  }

  public async setSelectedSection(contentSelection: ContentSelection, selectedFileType?: string) {
    runInAction(() => {
      this.selectedSection = contentSelection;
      if (selectedFileType) this.selectedFileType = selectedFileType;
      this.fileStateId = this.getFileState(contentSelection);
      this.fileFilters.setFileState(this.fileStateId);
      this.fileFilters.setDefaultSort(this.fileStateId);
    });
    FileInformationStore.close();
    this.cleanup(this.fromModule !== FromModuleEnum.TRANSMITTALS);
    await this.getProjectSuitabilities();
    this.loadTableColumns();
    this.loadFiles();
  }

  public async loadProjectMetadata() {
    if (!NavBarSelectorStore.selectedItem) return;
    this.fileStateId = this.getFileState(this.selectedSection);
    const metadataFields = await getProjectMetadata(NavBarSelectorStore.selectedItem.project.projectNumber);
    runInAction(() => {
      this.tableColumns = [];
      this.metadataFields = metadataFields;
      this.loadTableColumns();
    });
  }

  public async loadProjectMetadataByProjectNumber(projectNumber: string) {
    if (!projectNumber) return;
    const metadataFields = await getProjectMetadata(projectNumber);
    this.metadataFields = metadataFields;

    runInAction(() => {
      this.metadataFields = metadataFields;
    });
  }

  public setDefaultFilter() {
    if (!NavBarSelectorStore.selectedItem) return;
    const fileStateId = this.getFileState(this.selectedSection);
    const taskTeamId =
      NavBarSelectorStore.selectedItem.type === NavigationItemTypes.TaskTeam
        ? NavBarSelectorStore.selectedItem.taskTeam.id
        : undefined;
    this.fileFilters = new FileFilter().setDefaultFilter(
      NavBarSelectorStore.selectedItem.project.projectNumber,
      fileStateId,
      this.pageNumber,
      this.pageSize,
      taskTeamId
    );
    this.fileFilters.setFileName(this.fileName);
    this.fileFilters.setDefaultSort(fileStateId);
  }

  public applyFilter(column: string, filter?: string | IDateRangeFilter) {
    runInAction(async () => {
      this.pageNumber = 1;
      const parameter = this.getFilterFromTableColumns(column, filter);
      this.fileFilters.setPageCondition(this.pageNumber, this.pageSize);
      this.fileFilters.setExtraConditions(parameter);
      await this.loadFiles();
    });
  }

  private async getProjectSuitabilities() {
    if (NavBarSelectorStore.selectedItem) {
      const projectSuitabilities = await getSuitabilities(
        NavBarSelectorStore.selectedItem.project.projectNumber,
        this.fileStateId
      );
      runInAction(() => {
        this.suitabilities = projectSuitabilities;
      });
    }
  }

  private getFilterFromTableColumns(column: string, filter?: string | IDateRangeFilter) {
    const parameter = {};

    this.tableColumns.forEach((c) => {
      if (c.valueField === column) {
        if (c.dateFilter) c.dateFilter = filter as IDateRangeFilter;
        if (c.textFilter) c.textFilter.filter = filter as string;
        if (c.listFilter) c.listFilter.filter = filter as string;
      }
      if (c.dateFilter) parameter[c.valueField] = c.dateFilter;
      if (c.textFilter) parameter[c.valueField] = c.textFilter.filter;
      if (c.listFilter) {
        let filterValue = c.listFilter.filter;
        if (c.valueField === 'taskTypeId') {
          filterValue = this.getTaskTypeValueFromText(filterValue);
        }
        if (c.valueField === 'suitabilityTitle') {
          filterValue = this.getSuitabilityCodeFromText(filterValue);
        }
        parameter[c.valueField] = filterValue;
      }
    });
    return parameter;
  }
  private getSuitabilityCodeFromText(filterValue?: string) {
    const selectedItem = this.suitabilities.find((x) => x.title === filterValue);
    return selectedItem?.code;
  }
  private getTaskTypeValueFromText(filterValue?: string) {
    let taskTypeValue = filterValue;
    Object.entries(TaskTypeText).forEach(([key, value]) => {
      if (value === filterValue) {
        taskTypeValue = key;
      }
    });
    return taskTypeValue;
  }

  public applySort(column?: string, direction?: SortType) {
    runInAction(async () => {
      this.pageNumber = 1;
      this.fileFilters.setPageCondition(this.pageNumber, this.pageSize);
      this.fileFilters.setSort(column, direction);
      this.tableColumns.forEach((c) => {
        if (c.valueField === column) {
          c.sort = direction;
        } else {
          c.sort = c.sort ?? 'none';
        }
      });
      await this.loadFiles();
    });
  }

  private getFileState(contentSelection: ContentSelection): FileState {
    let fileStateId = FileState.Wip;
    if (contentSelection === ContentSelection.Shared) {
      fileStateId = FileState.Shared;
    }
    if (contentSelection === ContentSelection.Published) {
      fileStateId = FileState.Published;
    }
    return fileStateId;
  }

  public getContentSelection(fileStateId: FileState): ContentSelection {
    if (fileStateId === FileState.Shared) return ContentSelection.Shared;

    if (fileStateId === FileState.Published) return ContentSelection.Published;

    return ContentSelection.WIP;
  }

  public closeLockSupersedeFileErrorModal() {
    runInAction(async () => {
      this.isLockSupersedeFileError = false;
      await this.loadFiles();
    });
  }

  public async loadFiles() {
    const allowRefreshFiles = [ContentSelection.WIP, ContentSelection.Shared, ContentSelection.Published];
    if (!AppStore.selectedProjectNumber || !allowRefreshFiles.some((item) => item === this.selectedSection)) return;

    runInAction(() => {
      this.isLoading = true;
      this.loadingLabel = 'Files loading';
    });

    try {
      const response = await (this.selectedSection === ContentSelection.WIP
        ? getWipFiles(this.fileFilters)
        : getNonWipFiles(this.fileFilters));
      this.setPagingMetaData(response);
      const files = response.data;
      runInAction(() => {
        this.files = files;
      });
    } catch {
      this.cleanup();
    }
    setTimeout(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    }, 500);
  }

  public loadTableColumns() {
    const tableColumns: ITableColumn[] = [
      {
        label: 'Filename',
        valueField: 'title',
        textFilter: { filter: this.getTableColumn('Filename')?.textFilter?.filter },
      },
      {
        label: 'Original Filename',
        valueField: 'originalFilename',
        textFilter: { filter: this.getTableColumn('Original Filename')?.textFilter?.filter },
      },
    ];

    const descMetadata = this.metadataFields.find((m) => m.title === MetadataFieldTitle.Description);
    if (descMetadata) {
      tableColumns.push({
        label: descMetadata.title,
        valueField: `fieldValue${descMetadata.fieldValueIndex}`,
        textFilter: { filter: this.getTableColumn(descMetadata.title)?.textFilter?.filter },
      } as ITableColumn);
    }

    if (this.fileStateId === FileState.Wip || this.fileStateId === FileState.Shared) {
      const listWF: IFileMetadataFieldValue[] = [];
      Object.entries(TaskTypeText).forEach(([key, value]) => {
        listWF.push({
          code: key,
          title: value,
        } as IFileMetadataFieldValue);
      });
      let fieldValues: string[] = [];
      fieldValues = listWF.map((v) => v.title);
      fieldValues = uniq(fieldValues);
      fieldValues = orderBy(fieldValues);
      tableColumns.push({
        label: 'Workflow',
        valueField: 'taskTypeId',
        listFilter: {
          fieldValues,
        },
      });
    }

    if (this.fileStateId === FileState.Shared || this.fileStateId === FileState.Published)
      tableColumns.push({
        label: 'Revision',
        valueField: 'formattedRevision',
        textFilter: { filter: this.getTableColumn('Revision')?.textFilter?.filter },
      });

    const metaDataColumns = this.buildFileMetadataColumns();
    tableColumns.push(...metaDataColumns);

    const modifiedDateFilter = this.getTableColumnByFieldId('modifiedDate')?.dateFilter;
    const releasedDateFilter = this.getTableColumnByFieldId('releasedDate')?.dateFilter;

    if (this.fileStateId === FileState.Shared || this.fileStateId === FileState.Published) {
      const suitabilityColumn = this.buildSuitabilityColumn();
      tableColumns.push(suitabilityColumn);

      tableColumns.push({
        label: 'Modified Date',
        valueField: 'releasedDate',
        dateFilter: {
          startDate: releasedDateFilter?.startDate ?? modifiedDateFilter?.startDate ?? null,
          endDate: releasedDateFilter?.endDate ?? modifiedDateFilter?.endDate ?? null,
          operator: releasedDateFilter?.operator ?? modifiedDateFilter?.operator ?? null,
        },
        sort: SortTypes.DESC,
      });
    } else {
      tableColumns.push({
        label: 'Modified Date',
        valueField: 'modifiedDate',
        sort: SortTypes.DESC,
        dateFilter: {
          startDate: modifiedDateFilter?.startDate ?? releasedDateFilter?.startDate ?? null,
          endDate: modifiedDateFilter?.endDate ?? releasedDateFilter?.endDate ?? null,
          operator: modifiedDateFilter?.operator ?? releasedDateFilter?.operator ?? null,
        },
      });
    }
    tableColumns.push({
      label: 'File Size',
      valueField: 'uploadedSize',
    });

    tableColumns.push({
      label: 'Task Team Originator',
      valueField: 'deliveryTeamTitle',
    });
    runInAction(() => {
      this.tableColumns = tableColumns;
    });
  }

  public async lockSupersedeFile(fileId: number) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    runInAction(() => {
      this.isLoading = true;
      this.loadingLabel = 'File locking';
    });

    let canlock: boolean;
    const response = await lockFile({
      fileId: fileId,
      taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
    });
    if (!response.message) {
      canlock = true;
    } else {
      canlock = false;
      runInAction(() => {
        this.errorMessage = response?.message ?? '';
        this.isLockSupersedeFileError = true;
      });
    }
    runInAction(() => {
      this.isLoading = false;
    });
    return canlock;
  }

  public async closeSupersedeModal(fileId: number) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    runInAction(() => {
      this.isLoading = true;
      this.loadingLabel = 'File unlocking';
    });

    await this.unlockFile(fileId);
    await this.loadFiles();
    runInAction(() => {
      this.isLoading = false;
    });
  }

  public async unlockFile(fileId: number) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    await unlockFile({
      fileId: fileId,
      taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
    });
  }

  public get getTaskTeamId() {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return undefined;
    return NavBarSelectorStore.selectedItem.taskTeam.id;
  }

  public get filteredFiles(): IFile[] {
    if (this.showSelectedFiles) return this.selectedFiles.filter((t) => t.fileStateId === this.fileStateId);
    return [...this.files];
  }
  public get getTableColumns(): ITableColumn[] {
    return [...this.tableColumns];
  }

  private getTableColumn(label: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.label === label);
  }

  private getTableColumnByFieldId(fieldId: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.valueField === fieldId);
  }

  public getFileKey(file: IFile) {
    return `${file.id}-${this.fileStateId}-${file.revision ?? 0}`;
  }

  public handleSelectStart(file: IFile, selected: boolean, shiftOn: boolean) {
    const currentSelectedItemIds = Object.entries(this._selectedFileMap)
      .filter(([, v]) => v.fileStateId === this.fileStateId)
      .map(([, v]) => this.getFileKey(v));
    const selectedItemIds = getSelectedItems(
      this.files.map((t) => ({ id: this.getFileKey(t) })),
      this.getFileKey(file),
      selected,
      shiftOn,
      currentSelectedItemIds,
      this._lastSelectedIndexes[this.selectedSection] || []
    );
    const selectedItems = this.files.filter((f) => selectedItemIds.some((s) => s === this.getFileKey(f)));
    const nonSelectedItems = Object.entries(this._selectedFileMap).filter(
      ([, v]) => !selectedItemIds.some((s) => this.getFileKey(v) === s)
    );

    for (const nonSelectedFile of nonSelectedItems) {
      const key = this.getFileKey(nonSelectedFile[1]);
      delete this._selectedFileMap[key];
    }

    for (const selectedFile of selectedItems) {
      this.setSelectedFile(selectedFile, true);
    }

    const selectedIndex = this.files.findIndex((t) => t.id === file.id);
    const lastSelectedIndexesOfSelectedSection = [
      ...(this._lastSelectedIndexes[this.selectedSection] ?? []),
      selectedIndex,
    ];
    this._lastSelectedIndexes[this.selectedSection] = lastSelectedIndexesOfSelectedSection;
  }

  public setSelectedFile(file: IFile, selected: boolean) {
    const key = this.getFileKey(file);
    const fileStateIdOfSelectedFile = this._selectedFileMap[key]?.fileStateId ?? this.fileStateId;
    runInAction(() => {
      if (selected) {
        this._selectedFileMap[key] = { ...file, fileStateId: fileStateIdOfSelectedFile };
      } else {
        delete this._selectedFileMap[key];
        if (!Object.keys(this._selectedFileMap).length) this.showSelectedFiles = false;
      }
    });
  }

  public resetSelectedFile() {
    if (this.selectedFiles.length)
      this.selectedFiles
        .map((file) => this.getFileKey(file))
        .forEach((key) => {
          delete this._selectedFileMap[key];
        });
  }

  public setShowLoading(showLoading: boolean, loadingMessage: string) {
    runInAction(() => {
      this.isLoading = showLoading;
      this.loadingLabel = loadingMessage;
    });
  }

  public setSelectedForAllFiles(selected: boolean) {
    this.files.forEach((file) => this.setSelectedFile(file, selected));
  }

  public hasSelectedFile(): boolean {
    return this.selectedFiles.length > 0;
  }

  public isFileSelected(file: IFile): boolean {
    return !!this._selectedFileMap[this.getFileKey(file)];
  }

  public areAllFilesSelected(): boolean {
    let allSelected = this.files.length > 0;
    this.files.forEach((file) => (allSelected = allSelected && this.isFileSelected(file)));
    return allSelected;
  }

  public filesSelectedCount(): number {
    return this.selectedFiles.length;
  }

  public get selectedFiles() {
    const selected: IFile[] = [];
    Object.entries(this._selectedFileMap).forEach(([key, value]) => {
      if (!selected.some((f) => this.getFileKey(f) === key)) {
        selected.push(value);
      }
    });
    return selected;
  }

  public get selectedFilesSize(): number {
    return this.selectedFiles.reduce((total, file) => total + file.uploadedSize, 0);
  }

  public get canTaskTeamReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return this.selectedFiles.length > 0 && !this.selectedFiles.find((file) => file.canTaskTeamReview === false);
  }

  public get canShareReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return this.selectedFiles.length > 0 && !this.selectedFiles.find((file) => file.canShareReview === false);
  }

  public get canDeliveryTeamReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return this.selectedFiles.length > 0 && !this.selectedFiles.find((file) => file.canDeliveryTeamReview === false);
  }

  public get canPublishReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return this.selectedFiles.length > 0 && !this.selectedFiles.find((file) => file.canPublishReview === false);
  }

  public get canCollaborateOnSelectedFiles(): boolean {
    return this.selectedFiles.length > 0 && !this.selectedFiles.find((file) => file.canCollaborate === false);
  }

  public get hasSelectedReferenceFiles(): boolean {
    return this.selectedFiles.some((file) => file.isSuitabilityRefCode);
  }

  public get canDownloadSelectedFiles(): boolean {
    return !this.hasSelectedFile() || this.isFilesLimitExceeded();
  }

  public async downloadFile(file: IFile, projectNumber?: string) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });

    try {
      const url = await getProjectFileDownloadUrl(
        projectNumber ?? NavBarSelectorStore.selectedItem!.project.projectNumber,
        file.id,
        file.fileRevisionId,
        file.releasedFileId,
        null,
        null
      );
      if (url) window.open(url, '_blank', 'noopener noreferrer');
    } finally {
      runInAction(() => {
        this.fileBusy[file.id] = false;
      });
    }
  }

  public async downloadFiles(files: IFile[], projectNumber: string) {
    if (!files || !files.length) return;
    return Promise.all(files.map((contentFile) => this.downloadFile(contentFile, projectNumber)));
  }

  public async downloadSelectedFiles() {
    if (!this.selectedFiles) return;
    await Promise.all(this.selectedFiles.map((contentFile) => this.downloadFile(contentFile)));
  }

  public get canDeleteSelectedFiles(): boolean {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return false;
    return (
      NavBarSelectorStore.selectedItem.taskTeam.canDelete &&
      this.selectedFiles.length > 0 &&
      !this.selectedFiles.find((file) => file.canDelete === false) &&
      !this.isFilesLimitExceeded()
    );
  }

  public async deleteFile(file: IFile) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    if (this.fileBusy[file.id]) return false;
    if (!NavBarSelectorStore.selectedItem.taskTeam.canDelete) return false;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });

    const response = await deleteFile({ fileId: file.id, taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id });

    runInAction(() => {
      this.fileBusy[file.id] = false;
      this.errorMessage = response?.message ?? '';
    });

    return !response?.message ? true : false;
  }

  public async deleteSelectedFiles(): Promise<number> {
    if (!this.selectedFiles) return 0;
    let deleted = 0;
    for (const contentFile of this.selectedFiles) {
      try {
        if (await this.deleteFile(contentFile)) {
          runInAction(() => {
            this.files = this.files.filter((f) => f.id != contentFile.id);
          });
          deleted++;
        }
      } catch {
        /* don't display the error*/
      }
    }
    return deleted;
  }

  public async openFile(file: IFile, projectNumber?: string) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });

    try {
      const url = await getProjectFileDocumentViewerUrl(
        projectNumber ?? NavBarSelectorStore.selectedItem!.project.projectNumber,
        file.id,
        file.fileRevisionId,
        file.releasedFileId,
        null,
        null
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.fileBusy[file.id] = false;
      });
    }
  }

  public async resendToForge(fileRevisionId: number | null) {
    if (!fileRevisionId) return;
    runInAction(() => {
      this.errorMessage = null;
    });
    try {
      await resendForgeFile({
        projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileRevisionId: fileRevisionId,
      });
    } catch (errors) {
      runInAction(() => {
        this.errorMessage = (errors as AxiosError<string>)?.response?.data ?? 'Resend failed.';
      });
    }
  }

  public isFilesLimitExceeded(): boolean {
    return this.filesSelectedCount() > (AppStore.client?.fileBatchLimit ?? 0);
  }

  public get isAnySelectedFileLocked(): boolean {
    let fileIsLocked = false;
    this.selectedFiles.forEach((file) => {
      if (file.isLocked === true) fileIsLocked = true;
    });
    return fileIsLocked;
  }

  public toggleShowSelectedFiles() {
    this.showSelectedFiles = !this.showSelectedFiles;
  }

  public showFileInformation(file: IFile, projectNumber: string | null) {
    let fileStateId: FileState | null = null;

    switch (this.selectedSection) {
      case ContentSelection.WIP:
        fileStateId = FileState.Wip;
        break;
      case ContentSelection.Shared:
        fileStateId = FileState.Shared;
        break;
      case ContentSelection.Published:
        fileStateId = FileState.Published;
        break;
    }

    if (!fileStateId) return;

    FileInformationStore.init(file, fileStateId, projectNumber, null);
  }

  public get isShowingFileInformation() {
    return !!FileInformationStore.file;
  }

  private buildFileMetadataColumns(): ITableColumn[] {
    const metaDataColumns: ITableColumn[] = [];
    this.metadataFields
      .filter((metaData) => metaData.title !== MetadataFieldTitle.Description)
      .forEach((f) => {
        const valueField = `fieldValue${f.fieldValueIndex}`;
        let fieldValues: string[] = [];
        if (f.dataType?.fieldType === MetadataFieldType.List) {
          fieldValues = f.dataType.fieldValues.map((v) => v.title);
          fieldValues = uniq(fieldValues);
          fieldValues = fieldValues.sort((a, b) => (a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1));

          metaDataColumns.push({
            label: f.title,
            valueField,
            listFilter: {
              fieldValues,
              filter: this.getTableColumn(f.title)?.listFilter?.filter,
            },
          } as ITableColumn);
        } else {
          metaDataColumns.push({
            label: f.title,
            valueField,
            textFilter: { filter: this.getTableColumn(f.title)?.textFilter?.filter },
          } as ITableColumn);
        }
      });
    return metaDataColumns;
  }

  private buildSuitabilityColumn(): ITableColumn {
    let suitabilityValues: string[] = [];
    suitabilityValues = this.suitabilities.map((v) => v.title);
    suitabilityValues = uniq(suitabilityValues);
    suitabilityValues = [...suitabilityValues].sort((a, b) => (a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1));

    return {
      label: 'Suitability',
      valueField: 'suitabilityTitle',
      listFilter: {
        fieldValues: suitabilityValues,
      },
    } as ITableColumn;
  }

  public applyFileFilterForTransmittals(
    contentSelection: ContentSelection,
    projectNumber?: string,
    taskTeamId?: number
  ) {
    if (!projectNumber) return;
    const fileStateId = this.getFileState(contentSelection);
    runInAction(() => {
      this.fileFilters = new FileFilter();
      this.fileFilters.setDefaultFilter(projectNumber, fileStateId, this.pageNumber, this.pageSize, taskTeamId);
    });
  }

  public isValidRequestParam(params: Readonly<Params<string>>) {
    return (
      !!params.projectNumber &&
      !!params.deliveryTeamCode &&
      !!params.taskTeamCode &&
      !!params.stateId &&
      !!params.fileName
    );
  }
}

export default new FilesStore();
