import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';

import WidgetBase from './WidgetBase';

const BaseMapToggleWidget: FC = () => {
  return WidgetBase(BasemapToggle, { visible: true, nextBasemap: 'arcgis-community' });
};
export default observer(BaseMapToggleWidget);
