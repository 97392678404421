import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Zoom from '@arcgis/core/widgets/Zoom';

import Style from './styles/WidgetBase.module.scss';

import WidgetBase from './WidgetBase';

const ZoomWidget: FC = () => {
  return WidgetBase(Zoom, { visible: true }, Style.zoomWidgetWrapper);
};

export default observer(ZoomWidget);
