import { makeAutoObservable, runInAction } from 'mobx';
import AppStore from '../../../stores/AppStore';
import { flatMap, orderBy } from 'lodash';
import { IProgramme, IProject } from '../../../api/authenticated/config/getClientInfo';

const localStorageSelectorKey = 'tasks-selector-id';

export interface IItem {
  id: string;
  all?: boolean;
  programme?: IProgramme;
  project?: IProject;
}

export class NavBarSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public items: IItem[] = [];
  public selectedItem?: IItem;

  public initSelector() {
    runInAction(() => {
      this.items = [
        {
          id: 'all',
          all: true,
        },
        ...flatMap(orderBy(AppStore.client?.programmes, 'title', 'asc'), (programme) => [
          {
            id: `programmeId:${programme.id}`,
            programme,
          },
          ...orderBy(programme.projects, 'title', 'asc').map((project) => ({
            id: `projectNumber:${project.projectNumber}`,
            project,
          })),
        ]),
      ];

      this.setDefaultItemSelected();
    });
  }

  private setDefaultItemSelected() {
    let id: string | null = null;
    if (AppStore.selectedProgrammeId && AppStore.selectedProjectNumber) {
      const globalItem = this.items.filter(
        (x) =>
          x.programme?.id === AppStore.selectedProgrammeId &&
          x.project?.projectNumber === AppStore.selectedProjectNumber
      );
      id = globalItem[0]?.id ?? null;
    }
    if (!id) {
      id = localStorage.getItem(localStorageSelectorKey) ?? this.items[0]?.id;
    }

    runInAction(() => {
      this.selectedItem = this.items.find((item) => item.id === id);
      if (!this.selectedItem && this.items.length) {
        this.selectedItem = this.items[0];
      }
      if (this.selectedItem) {
        this.updateGlobalNavItemSelected(this.selectedItem);
      }
    });
  }

  public setSelectedSelectorItem(id: string) {
    const selectedItem = this.items.find((item) => item.id === id);
    if (!selectedItem) return;
    this.updateGlobalNavItemSelected(selectedItem);
    runInAction(() => {
      this.selectedItem = selectedItem;
    });
  }

  private updateGlobalNavItemSelected(item: IItem) {
    AppStore.setProgrammeId(item.programme?.id);
    AppStore.setProjectNumber(item.project?.projectNumber);
    AppStore.setDeliveryTeamId();
    AppStore.setTaskTeamId();

    localStorage.setItem(localStorageSelectorKey, item.id);
  }
}

export default new NavBarSelectorStore();
