export enum MetadataFieldType {
  UserText = 'UserText',
  List = 'List',
}

export const MetadataFieldTitle = {
  Description: 'Description',
};

export enum MetadataFieldIndex {
  Discipline = 1,
  ProjectPhase = 2,
  DocumentType = 3,
  DocumentSubtype = 4,
  Zone = 5,
  Area = 6,
  Asset = 7,
  Workstream = 8,
  Description = 9,
}
