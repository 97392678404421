import { FileState } from '../../../common/enums/FileState';
import { TaskStatus } from '../../../common/enums/TaskStatus';
import { TaskType } from '../../../common/enums/TaskType';
import { serverAxios } from '../api';

export interface IReleasedHistoryItem {
  releasedFileId: number;
  fileRevisionId: number;
  formattedRevision: string;
  suitabilityCode?: string;
  suitabilityTitle?: string;
  hasDocumentViewer: boolean;
  isForgeFile: boolean;
}

export interface IUploadHistoryItem {
  userName: string;
}

export interface ITaskUser {
  userId: number;
  userName: string;
}

export interface ITaskCreatedEvent {
  createdBy: ITaskUser;
  reviewers: ITaskUser[];
}

export interface ITaskApprovedEvent {
  approvedBy: ITaskUser;
}

export interface ITaskRejectedEvent {
  rejectedBy: ITaskUser;
}

export interface ITaskReassignedEvent {
  reassignedBy: ITaskUser;
  reassignedTo: ITaskUser;
}

export interface ITaskEvent {
  dateTime: Date;
  taskCreatedEvent?: ITaskCreatedEvent;
  taskApprovedEvent?: ITaskApprovedEvent;
  taskRejectedEvent?: ITaskRejectedEvent;
  taskReassignedEvent?: ITaskReassignedEvent;
}

export interface ITaskHistoryItem {
  taskId: number;
  taskTypeId: TaskType;
  taskStatusId: TaskStatus;
  taskEvents: ITaskEvent[];
}

export interface IHistoryItem {
  dateTime: Date;
  releaseHistoryItem?: IReleasedHistoryItem;
  uploadHistoryItem?: IUploadHistoryItem;
  taskHistoryItem?: ITaskHistoryItem;
}

export async function getFileHistory(
  projectNumber: string,
  fileId: number,
  fileStateId: FileState,
  transmittalId: number | null,
  abortSignal?: AbortSignal
): Promise<IHistoryItem[]> {
  const result = await serverAxios.get<{ historyItems: IHistoryItem[] }>('api/cms/history', {
    params: {
      projectNumber,
      fileId,
      fileStateId,
      transmittalId,
    },
    signal: abortSignal,
  });
  return result.data?.historyItems ?? [];
}
