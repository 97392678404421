import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/Overlay.module.scss';

interface IOverlayProps {
  className?: string;
  onClick?: () => void;
}

const Overlay: FC<IOverlayProps> = ({ className, onClick }) => {
  return <div className={classNames(Style.overlay, className)} onClick={onClick} />;
};

export default observer(Overlay);
