import { makeAutoObservable, runInAction } from 'mobx';
import { addDeliveryTeam } from '../../../../api/authenticated/um/addDeliveryTeam';
import { addTaskTeam } from '../../../../api/authenticated/um/addTaskTeam';
import { IDeliveryTeam, ITaskTeam } from '../../../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import AppStore from '../../../../stores/AppStore';
import { isAlphanumericSpace, isAlphanumericUpper } from '../../../../utils/miscUtils';
import LayoutStore from '../../../layout/LayoutStore';
import ProjectUserManagementStore from '../../ProjectUserManagementStore';
import AppointedPartiesStore from '../AppointedPartiesStore';

const INITIAL_NEW_ID = -1;

export class TeamSettingsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _nextId = INITIAL_NEW_ID;

  public deliveryTeams: IDeliveryTeam[] = [];
  public isSaving = false;

  public newTaskTeamDeliveryTeamId: number | null = null;
  public newTaskTeamTitle = '';
  public newTaskTeamTransmittalCode = '';
  public newTaskTeamTitleError: string | null = null;
  public newTaskTeamTransmittalCodeError: string | null = null;

  public showNewDeliveryTeam = false;
  public newDeliveryTeamTitle = '';
  public newDeliveryTeamTransmittalCode = '';
  public newDeliveryTeamTitleError: string | null = null;
  public newDeliveryTeamTransmittalCodeError: string | null = null;

  public show() {
    AppointedPartiesStore.showModal('TeamSettings');
    runInAction(() => {
      this.deliveryTeams = AppointedPartiesStore.appointedPartiesUsers.map((dt) => ({
        ...dt,
        taskTeams: dt.taskTeams.map((tt) => ({ ...tt })),
      }));
      this._nextId = INITIAL_NEW_ID;
      this.isSaving = false;
    });
    this.clearNewTaskTeam();
    this.clearNewDeliveryTeam();
  }

  private get newId() {
    return this._nextId--;
  }

  public setNewTaskTeamDeliveryTeamId(id: number) {
    this.clearNewDeliveryTeam();
    runInAction(() => {
      this.newTaskTeamDeliveryTeamId = id;
    });
  }

  public setNewTaskTeamTitle(value: string) {
    runInAction(() => {
      this.newTaskTeamTitle = value;
    });
    this.validateNewTaskTeam();
  }

  public setNewTaskTeamTransmittalCode(value: string) {
    runInAction(() => {
      this.newTaskTeamTransmittalCode = value;
    });
    this.validateNewTaskTeam();
  }

  public get disableNewTaskTeamAddButton() {
    return (
      this.isSaving ||
      !this.newTaskTeamTitle ||
      !this.newTaskTeamTransmittalCode ||
      !!this.newTaskTeamTitleError ||
      !!this.newTaskTeamTransmittalCodeError
    );
  }

  private validateNewTaskTeam() {
    runInAction(() => {
      this.newTaskTeamTitleError = !isAlphanumericSpace(this.newTaskTeamTitle)
        ? 'Team name must be only alphanumerical'
        : this.deliveryTeams.some((dt) =>
            dt.taskTeams.some((tt) => tt.title.toLowerCase().trim() === this.newTaskTeamTitle.toLowerCase().trim())
          )
        ? 'Team name already exists'
        : this.newTaskTeamTitle.length > 100
        ? 'Team name is longer than 100 characters'
        : null;

      this.newTaskTeamTransmittalCode = this.newTaskTeamTransmittalCode.toUpperCase();

      this.newTaskTeamTransmittalCodeError = !isAlphanumericUpper(this.newTaskTeamTransmittalCode.trim())
        ? 'Abbreviation must be only upper case alphanumerical'
        : this.deliveryTeams.some((dt) =>
            dt.taskTeams.some((tt) => tt.transmittalCode.toUpperCase() === this.newTaskTeamTransmittalCode.trim())
          )
        ? 'Abbreviation already exists'
        : this.newTaskTeamTransmittalCode.length > 3
        ? 'Abbreviation is longer than 3 characters'
        : null;
    });

    return !this.newTaskTeamTitleError && !this.newTaskTeamTransmittalCodeError;
  }

  private clearNewTaskTeam() {
    runInAction(() => {
      this.newTaskTeamDeliveryTeamId = null;
      this.newTaskTeamTitle = '';
      this.newTaskTeamTransmittalCode = '';
      this.newTaskTeamTitleError = null;
      this.newTaskTeamTransmittalCodeError = null;
    });
  }

  public addNewTaskTeam() {
    if (this.disableNewTaskTeamAddButton) return;

    const deliveryTeam = this.deliveryTeams.find((d) => d.id === this.newTaskTeamDeliveryTeamId);
    if (!deliveryTeam) return;
    runInAction(() => {
      const id = this.newId;
      deliveryTeam.taskTeams.push({
        id,
        title: this.newTaskTeamTitle.trim(),
        transmittalCode: this.newTaskTeamTransmittalCode.trim(),
        users: [],
      });
    });
    this.clearNewTaskTeam();
  }

  public setShowNewDeliveryTeam() {
    this.clearNewTaskTeam();
    runInAction(() => {
      this.showNewDeliveryTeam = true;
    });
  }

  public setNewDeliveryTeamTitle(value: string) {
    runInAction(() => {
      this.newDeliveryTeamTitle = value;
    });
    this.validateNewDeliveryTeam();
  }

  public setNewDeliveryTeamTransmittalCode(value: string) {
    runInAction(() => {
      this.newDeliveryTeamTransmittalCode = value;
    });
    this.validateNewDeliveryTeam();
  }

  public get disableNewDeliveryTeamAddButton() {
    return (
      this.isSaving ||
      !this.newDeliveryTeamTitle ||
      !this.newDeliveryTeamTransmittalCode ||
      !!this.newDeliveryTeamTitleError ||
      !!this.newDeliveryTeamTransmittalCodeError
    );
  }

  private validateNewDeliveryTeam() {
    runInAction(() => {
      this.newDeliveryTeamTitleError = !isAlphanumericSpace(this.newDeliveryTeamTitle)
        ? 'Team name must be only alphanumerical'
        : this.deliveryTeams.some(
            (dt) => dt.title.toLowerCase().trim() === this.newDeliveryTeamTitle.toLowerCase().trim()
          )
        ? 'Team name already exists'
        : this.newDeliveryTeamTitle.length > 100
        ? 'Team name is longer than 100 characters'
        : null;
      this.newDeliveryTeamTransmittalCode = this.newDeliveryTeamTransmittalCode.toUpperCase();

      this.newDeliveryTeamTransmittalCodeError = !isAlphanumericUpper(this.newDeliveryTeamTransmittalCode.trim())
        ? 'Abbreviation must be only alphanumerical'
        : this.deliveryTeams.some(
            (dt) => dt.transmittalCode.toUpperCase().trim() === this.newDeliveryTeamTransmittalCode.toUpperCase().trim()
          )
        ? 'Abbreviation already exists'
        : this.newDeliveryTeamTransmittalCode.length > (AppStore.client?.namingCodeMaximumLength || 0)
        ? `Abbreviation is longer than ${AppStore.client?.namingCodeMaximumLength} characters`
        : null;
    });

    return !this.newDeliveryTeamTitleError && !this.newDeliveryTeamTransmittalCodeError;
  }
  private clearNewDeliveryTeam() {
    runInAction(() => {
      this.showNewDeliveryTeam = false;
      this.newDeliveryTeamTitle = '';
      this.newDeliveryTeamTransmittalCode = '';
      this.newDeliveryTeamTitleError = null;
      this.newDeliveryTeamTransmittalCodeError = null;
    });
  }

  public addNewDeliveryTeam() {
    if (this.disableNewDeliveryTeamAddButton) return;

    runInAction(() => {
      const id = this.newId;
      this.deliveryTeams.push({
        id,
        title: this.newDeliveryTeamTitle.trim(),
        transmittalCode: this.newDeliveryTeamTransmittalCode.trim(),
        taskTeams: [],
        users: [],
      });
    });
    this.clearNewDeliveryTeam();
  }

  public get saveDisabled() {
    return this.isSaving || this._nextId === INITIAL_NEW_ID;
  }

  public async save() {
    if (!ProjectUserManagementStore.project || this.isSaving) return;

    runInAction(() => {
      this.isSaving = true;
    });
    this.clearNewTaskTeam();
    this.clearNewDeliveryTeam();

    try {
      let deliveryTeamAdded = false;
      let taskTeamAdded = false;
      let hasError = false;

      for await (const deliveryTeam of this.deliveryTeams) {
        if (deliveryTeam.id <= INITIAL_NEW_ID) {
          if (await this.saveDeliveryTeam(ProjectUserManagementStore.project.projectNumber, deliveryTeam)) {
            deliveryTeamAdded = true;
          } else {
            hasError = true;
          }
        }

        for await (const taskTeam of deliveryTeam.taskTeams) {
          if (taskTeam.id <= INITIAL_NEW_ID && deliveryTeam.id > INITIAL_NEW_ID) {
            if (await this.saveTaskTeam(ProjectUserManagementStore.project.projectNumber, deliveryTeam, taskTeam)) {
              taskTeamAdded = true;
            } else {
              hasError = true;
            }
          }
        }
      }

      if (deliveryTeamAdded) {
        LayoutStore.displayToast('success', 'Delivery team has been added successfully.');
      }

      if (taskTeamAdded) {
        LayoutStore.displayToast('success', 'Task team has been added successfully.');
      }

      await AppointedPartiesStore.loadAppointedPartiesUsers();

      if (hasError) {
        AppointedPartiesStore.showModal('Error');
      } else {
        AppointedPartiesStore.closeModal();
      }
    } catch {
      AppointedPartiesStore.showModal('Error');
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  }

  private async saveDeliveryTeam(projectNumber: string, deliveryTeam: IDeliveryTeam) {
    try {
      const deliveryTeamId = await addDeliveryTeam({
        projectNumber,
        title: deliveryTeam.title,
        transmittalCode: deliveryTeam.transmittalCode,
      });

      runInAction(() => {
        deliveryTeam.id = deliveryTeamId;
      });

      return true;
    } catch {
      return false;
    }
  }

  private async saveTaskTeam(projectNumber: string, deliveryTeam: IDeliveryTeam, taskTeam: ITaskTeam) {
    try {
      await addTaskTeam({
        projectNumber,
        deliveryTeamId: deliveryTeam.id,
        title: taskTeam.title,
        transmittalCode: taskTeam.transmittalCode,
      });

      return true;
    } catch {
      return false;
    }
  }
}

export default new TeamSettingsStore();
