import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TabContent } from '@aurecon-creative-technologies/styleguide';
import { ContentSelection, FileContentTabIds, FileStateNames } from './ContentSelection';
import { scrollToTop } from '../../utils/miscUtils';
import FilesStore from './FilesStore';
import TabContainer from './TabContainer';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';
import ForgeViewerModal from '../visualisation/forgeViewer/ForgeViewerModal';
import Loading from '../shared/Loading';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import { useParams } from 'react-router-dom';
import { FromModuleEnum } from '../../enums/FromModuleEnum';

const Files: FC = () => {
  const params = useParams();
  const [selectedTabId, setSelectedTabId] = useState(FilesStore.selectedTabId);
  const [openFromUrl, setOpenFromUrl] = useState(false);
  const selectedSection = FilesStore.selectedSection;
  const selectedItem = NavBarSelectorStore.selectedItem;

  useEffect(() => {
    if (FilesStore.isValidRequestParam(params)) {
      setOpenFromUrl(true);
      FilesStore.setQueryParametersFromUrl(params);
      if (params.stateId) {
        const tabId = getTabIdByFileState(params.stateId);
        if (tabId) {
          setSelectedTabId(tabId);
        }
      }
    } else {
      FilesStore.clearQueryParametersFromUrl();
    }
  }, [params]);

  useEffect(() => {
    let setTabDefault = true;
    if (openFromUrl && selectedItem?.navbarSelected) {
      setTabDefault = false;
      FilesStore.clearQueryParametersFromUrl();
      setOpenFromUrl(false);
      window.history.replaceState(null, '', '/#/files');
    }

    FilesStore.cleanup();
    if (setTabDefault) {
      FilesStore.setDefaultSelectedTab();
      FilesStore.setDefaultFilter();
    }
    FilesStore.loadProjectMetadata();
    FilesStore.loadFiles();
  }, [openFromUrl, selectedItem]);

  useEffect(() => {
    scrollToTop();
  }, [selectedSection]);

  const onSelectTab = (id: number) => {
    if (FilesStore.isValidRequestParam(params) && !!params.stateId) {
      const tabId = getTabIdByFileState(params.stateId);
      if (tabId && tabId !== id) {
        window.history.replaceState(null, '', '/#/files');
        FilesStore.clearQueryParametersFromUrl();
      }
    }
    if (id === FileContentTabIds.WIP_TAB_ID) FilesStore.setSelectedSection(ContentSelection.WIP);
    if (id === FileContentTabIds.SHARED_TAB_ID) FilesStore.setSelectedSection(ContentSelection.Shared);
    if (id === FileContentTabIds.PUBLISHED_TAB_ID) FilesStore.setSelectedSection(ContentSelection.Published);
    setSelectedTabId(id);
    FilesStore.setSelectedTabId(id);
  };

  const getTabIdByFileState = (stateId: string) => {
    if (stateId === FileStateNames.WIP) return FileContentTabIds.WIP_TAB_ID;
    if (stateId === FileStateNames.SHARED) return FileContentTabIds.SHARED_TAB_ID;
    if (stateId === FileStateNames.PUBLISHED) return FileContentTabIds.PUBLISHED_TAB_ID;
  };

  return (
    <>
      <PageHeading>Files</PageHeading>
      <PageTab defaultActiveTab={selectedTabId} tabs={FilesStore.tabs} onSelectTab={onSelectTab}>
        {FilesStore.tabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <TabContainer fromModule={FromModuleEnum.FILES} />
            <ForgeViewerModal
              closeModal={() => ForgeViewerStore.forgeViewerModalClose()}
              showModal={ForgeViewerStore.showForgeViewerModal}></ForgeViewerModal>
            <Loading isLoading={ForgeViewerStore.isLoading}></Loading>
          </TabContent>
        ))}
      </PageTab>
    </>
  );
};

export default observer(Files);
