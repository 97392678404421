import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

export interface IPrimaryButtonProps {
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PrimaryButton: FC<IPrimaryButtonProps> = ({ className, disabled, onClick, children }) => {
  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

export default observer(PrimaryButton);
