import { Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import 'react-quill/dist/quill.snow.css';
import Styles from '../transmittals/styles/CreateTransmittalContentFile.module.scss';
import Icon from '../shared/Icon';
import PrimaryIconButton from '../shared/PrimaryIconButton';
import { getFileSizeString } from '../../utils/miscUtils';
import { IFile } from '../../api/authenticated/cms/FileModel';
import FilesStore from '../files/FilesStore';

export interface IContentFileProps {
  contentFiles: IFile[];
  onRemoveContentFile: (id: number) => void;
}

const CreateTransmittalContentFile: FC<IContentFileProps> = (props) => {
  if (!props.contentFiles.length) return null;

  const handleDeleteFile = (id: number): void => {
    props.onRemoveContentFile(id);
  };

  return (
    <div className={Styles.contentFiles}>
      <div className={Styles.contentFilesHeading}>
        <Icon name="description" />
        <span className={Styles.headingText}>Content Files ({props.contentFiles.length})</span>
      </div>
      <Table
        headers={[
          {
            label: 'Filename',
          },
          {
            label: 'Original Filename',
          },
          {
            label: 'File Size',
          },
          {
            label: 'Actions',
          },
        ]}>
        {props.contentFiles.map((file) => (
          <TableRow key={FilesStore.getFileKey(file)}>
            <TableCell>{file.title}</TableCell>
            <TableCell>{file.originalFilename}</TableCell>
            <TableCell>{getFileSizeString(file.uploadedSize)}</TableCell>
            <TableCell>
              <PrimaryIconButton icon="delete" onClick={() => handleDeleteFile(file.id)} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

export default observer(CreateTransmittalContentFile);
