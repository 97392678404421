import React, { FC } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Login from './components/login/Login';
import { Pages } from './common/constants/Pages';
import Layout from './components/layout/Layout';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import PageNotFound from './components/shared/PageNotFound';
import Files from './components/files/Files';
import Tasks from './components/tasks/Tasks';
import Visualisation from './components/visualisation/Visualisation';
import TaskInformation from './components/taskInformation/TaskInformation';
import ProjectSettings from './components/projectSettings/ProjectSettings';
import SupportHub from './components/login/SupportHub';
import { Toast, ToastProvider } from '@aurecon-creative-technologies/styleguide';
import ProjectUserManagement from './components/projectUserManagement/ProjectUserManagement';
import AppStore from './stores/AppStore';
import ProjectInformation from './components/projectInformation/ProjectInformation';
import Transmittals from './components/transmittals/Transmittals';
import TransmittalDetails from './components/transmittalDetails/TransmittalDetails';
import AddTransmittalMessage from './components/transmittalDetails/AddTransmittalMessage/AddTransmittalMessage';
import CreateTransmittal from './components/transmittals/CreateTransmittal';
import { ClientUserRole } from './common/enums/ClientUserRole';
import TermsConditions from './components/shared/TermsConditions';
import PrivacyPolicy from './components/shared/PrivacyPolicy';
import AuthStore from './stores/AuthStore';
import Unauthorized from './components/login/Unauthorized';

const AppRouting: FC = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Router>
          <ToastProvider>
            <Layout>
              {!AppStore.projects.length || AppStore.hasClientInfoError ? (
                <>{AuthStore.unauthorized ? <Unauthorized /> : <SupportHub />}</>
              ) : (
                <Routes>
                  <Route path={Pages.Login.Route} element={<Navigate to={{ pathname: Pages.Home.Route }} replace />} />
                  <Route
                    path={Pages.Home.Route}
                    element={
                      <Navigate
                        to={{
                          pathname:
                            AppStore.client?.user.clientUserRole !== ClientUserRole.External
                              ? Pages.Files.Route
                              : Pages.Transmittals.Route,
                        }}
                        replace
                      />
                    }
                  />
                  {AppStore.client?.user.clientUserRole !== ClientUserRole.External && (
                    <>
                      <Route path={Pages.Files.Route} element={<Files />} />
                      <Route
                        path={`${Pages.Files.Route}/:projectNumber/:deliveryTeamCode/:taskTeamCode/:stateId/:fileName`}
                        element={<Files />}
                      />
                      <Route path={`${Pages.Tasks.Route}/:taskId`} element={<TaskInformation />} />
                      <Route path={Pages.Tasks.Route} element={<Tasks />} />
                      <Route path={Pages.Visualisation.Route} element={<Visualisation />} />
                      <Route path={Pages.ProjectSettings.Route} element={<ProjectSettings />} />
                      <Route path={`${Pages.Project.Route}/:projectNumber/users`} element={<ProjectUserManagement />} />
                      <Route path={`${Pages.Project.Route}/:projectNumber`} element={<ProjectInformation />} />
                    </>
                  )}
                  <Route path={Pages.Transmittals.Route} element={<Transmittals />} />
                  <Route
                    path={`${Pages.Transmittals.Route}/:transmittalTitle/:projectNumber`}
                    element={<TransmittalDetails />}
                  />
                  <Route path={`${Pages.Transmittals.Route}/:transmittalTitle`} element={<TransmittalDetails />} />
                  <Route path={`${Pages.Transmittals.Route}/new`} element={<CreateTransmittal />} />
                  <Route
                    path={`${Pages.Transmittals.Route}/:transmittalTitle/AddResponse`}
                    element={<AddTransmittalMessage />}
                  />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path={Pages.TermsConditions.Route} element={<TermsConditions />} />
                  <Route path={Pages.PrivacyPolicy.Route} element={<PrivacyPolicy />} />
                </Routes>
              )}
            </Layout>
            <Toast />
          </ToastProvider>
        </Router>
      </AuthenticatedTemplate>
    </>
  );
};

export default observer(AppRouting);
