import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from '../../ContentSelection';
import CentreOnPage from '../../../shared/CentreOnPage';
import Overlay from '../../../shared/Overlay';
import PrimaryButton from '../../../shared/PrimaryButton';
import FilesStore from '../../FilesStore';
import Style from './styles/ProgressBar.module.scss';
import UploadStore from './UploadStore';
import UploadResultsModal from './UploadResultsModal';

const ProgressBar: FC = () => {
  const completed = UploadStore.uploadCompletePercentage;
  const uploadFailed = UploadStore.showUploadFailed;

  const closeWindowButtonClick = () => {
    UploadStore.clear();
    FilesStore.setSelectedSection(ContentSelection.WIP);
  };

  return (
    <>
      {completed < 100 && !uploadFailed && !UploadStore.openResultsModal && (
        <CentreOnPage>
          <Overlay className={Style.modalOverlay} />
          <div className={Style.modal}>
            <h1>
              Files currently uploading.
              <br />
              Don&apos;t close the browser.
            </h1>
            <p>{`${completed}%`}</p>
            <div className={Style.container}>
              <div className={Style.filler} style={{ width: `${completed}%` }}>
                <span className={Style.label}> </span>
              </div>
            </div>
            <p>To continue using Tucana, open a new browser window</p>
            <PrimaryButton onClick={() => window.open('/', '_blank')}>Open New Browser</PrimaryButton>
          </div>
        </CentreOnPage>
      )}

      <UploadResultsModal
        onConfirm={() => {
          UploadStore.setOpenResultsModal(false);
          closeWindowButtonClick();
        }}
      />
    </>
  );
};

export default observer(ProgressBar);
