import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import Icon from '../shared/Icon';
import { Accordion, AccordionPanel, Button, Checkbox } from '@aurecon-creative-technologies/styleguide';
import TeamChartStore from './TeamChartStore';
import { IGroupTaskTeamUser } from './interface/TeamChart.interface';
import TaskTeamUserGroup from './TaskTeamUserGroup';
import { useTeamChartContext } from './TeamChartStoreContext';

const TaskTeamAccordion: FC = () => {
  const context = useTeamChartContext();
  const renderGroupControls = (taskTeam: IGroupTaskTeamUser) => {
    return (
      <>
        {context.showAnyoneInTaskTeam && (
          <>
            <div className={`${Style.groupSelect} ${Style.taskTeamFilterGroup}`}>
              <div className={Style.label}>Filter</div>
              <Checkbox
                checked={taskTeam.isFilterApprovers}
                onChange={() => TeamChartStore.setFilterApprovers(taskTeam.deliveryTeamId, taskTeam.taskTeamId)}
                label="Task Team Approvers"
              />
            </div>
            <div className={Style.groupSelect}>
              <Icon name="people" className={Style.listIcon}></Icon>
              <Button
                type="text"
                label="All Task Team Approvers"
                onClick={() => TeamChartStore.selectAllTaskTeamApprovers(taskTeam.deliveryTeamId, taskTeam.taskTeamId)}
              />
            </div>
            <div className={Style.groupSelect}>
              <Icon name="people" className={Style.listIcon}></Icon>
              <Button
                type="text"
                label="All Task Team Members"
                onClick={() => TeamChartStore.selectAllTaskTeamMembers(taskTeam.deliveryTeamId, taskTeam.taskTeamId)}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!!TeamChartStore.filteredProjectDTTaskTeamTeamGroups.length && (
        <Accordion
          activePanelIds={Array.from(TeamChartStore.activeTTUserPanelIds)}
          onPanelToggle={(id) => TeamChartStore.onTTUserPanelToggle(id)}
          headingBgColour="#FAFAFA"
          panelBgColour="#FAFAFA"
          headingFontColour="#000000"
          cssClass={Style.taskTeamAccordion}>
          {TeamChartStore.filteredProjectDTTaskTeamTeamGroups.map((taskTeam: IGroupTaskTeamUser) => (
            <AccordionPanel
              key={`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`}
              panelId={`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`}
              label={`${taskTeam.taskTeamTitle} (${taskTeam.taskTeamCode})`}>
              <>
                {renderGroupControls(taskTeam)}
                <TaskTeamUserGroup taskTeamUser={taskTeam}></TaskTeamUserGroup>
              </>
            </AccordionPanel>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default observer(TaskTeamAccordion);
