import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/Projects.module.scss';
import { Pagination } from '@aurecon-creative-technologies/styleguide';
import ProjectCard from './ProjectCard';
import ProjectSettingsStore from './ProjectSettingsStore';
import SearchBar from '../shared/SearchBar';
import PageHeading from '../shared/PageHeading';

const ProjectSettings: FC = () => {
  return (
    <>
      <PageHeading>Project Settings</PageHeading>
      <div className={Style.topPane}>
        <SearchBar
          onSearch={(value) => ProjectSettingsStore.setSearchText(value)}
          placeHolderText={'Search for a project ID, name, owner name...'}
        />
      </div>
      <ol className={Style.cardView}>
        {ProjectSettingsStore.projects.map((project) => {
          return <ProjectCard key={project.projectNumber} project={project} />;
        })}
      </ol>
      {!!ProjectSettingsStore.projectsCount && (
        <div className={Style.pagination}>
          <Pagination
            page={ProjectSettingsStore.page}
            pageCount={ProjectSettingsStore.pageCount}
            onChange={(index) => ProjectSettingsStore.setPage(index)}
          />
          <div className={Style.paginationFlexGrow}>
            {`${ProjectSettingsStore.shownProjectStartIndex} - ${ProjectSettingsStore.shownProjectLastIndex} `}
            of {ProjectSettingsStore.projectsCount} Projects
          </div>
        </div>
      )}
    </>
  );
};

export default observer(ProjectSettings);
