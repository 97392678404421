import { computed, makeAutoObservable, reaction, runInAction } from 'mobx';
import { getTasks, ITask } from '../../api/authenticated/tasks/getTasks';
import { TaskStatus } from '../../common/enums/TaskStatus';
import { TaskType } from '../../common/enums/TaskType';
import { SortOption, StatusFilter, TabFilter, TypeFilter } from './Types';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { TaskStatusText } from '../../common/constants/TaskStatusText';
import { IOption } from '@aurecon-creative-technologies/styleguide';

export class TabStore {
  constructor(tab: TabFilter) {
    makeAutoObservable(this, { statusItems: computed, typeItems: computed, sortItems: computed }, { autoBind: true });

    this.tab = tab;

    reaction(() => NavBarSelectorStore.selectedItem, this.loadTasks);
  }

  public tab: TabFilter;
  public isLoadingTasks = false;
  public tasks: ITask[] = [];
  public availableTaskCount = 0;
  public selectedStatus: StatusFilter = -1;
  public selectedType: TypeFilter = -1;
  public selectedSort: SortOption = SortOption.LastUpdatedDate;
  public searchText = '';

  public async loadTasks() {
    runInAction(() => {
      this.isLoadingTasks = true;
    });
    try {
      const result = await getTasks({
        filter: {
          programmeId: NavBarSelectorStore.selectedItem?.programme?.id,
          projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber,
          createdByUser: this.tab === TabFilter.CreatedByMe,
          awaitingReviewByUser: this.tab === TabFilter.AwaitingMyAction,
          pending: this.tab === TabFilter.AllPendingTasks,
          filterByUserTaskTeam: this.tab === TabFilter.AllPendingTasks,
          filterByUserDeliveryTeam:
            this.tab !== TabFilter.CreatedByMe &&
            this.tab !== TabFilter.AwaitingMyAction &&
            this.tab !== TabFilter.AllPendingTasks,
        },
        status: {
          awaitingReview: this.selectedStatus === -1 || this.selectedStatus === TaskStatus.AwaitingReview,
          inProgress: this.selectedStatus === -1 || this.selectedStatus === TaskStatus.InProgress,
          approved: this.selectedStatus === -1 || this.selectedStatus === TaskStatus.Approved,
          rejected: this.selectedStatus === -1 || this.selectedStatus === TaskStatus.Rejected,
        },
        type: {
          taskTeamReview: this.selectedType === -1 || this.selectedType === TaskType.TaskTeamReview,
          shareApproval: this.selectedType === -1 || this.selectedType === TaskType.ShareApproval,
          deliveryTeamReview: this.selectedType === -1 || this.selectedType === TaskType.DeliveryTeamReview,
          publishAuthorise: this.selectedType === -1 || this.selectedType === TaskType.PublishAuthorise,
          publishAccept: this.selectedType === -1 || this.selectedType === TaskType.PublishAccept,
        },
        search: this.searchText ? this.searchText : undefined, // sets to undefined if empty string
        sorting: {
          sortProgrammeTitle: this.selectedSort === SortOption.ProgrammeTitle,
          sortProjectNumber: this.selectedSort === SortOption.ProjectNumber,
          sortProjectTitle: this.selectedSort === SortOption.ProjectTitle,
          sortLastUpdatedDate: this.selectedSort === SortOption.LastUpdatedDate,
          sortCreatedDate: this.selectedSort === SortOption.CreatedDate,
          sortTaskTypeId: this.selectedSort === SortOption.TaskType,
        },
      });
      runInAction(() => {
        this.tasks = result.tasks;
        this.availableTaskCount = result.stats.availableTaskCount;
      });
    } finally {
      runInAction(() => {
        this.isLoadingTasks = false;
      });
    }
  }

  public setSelectedStatus(newValue: StatusFilter) {
    const oldValue = this.selectedStatus;
    runInAction(() => {
      this.selectedStatus = newValue;
    });
    if (oldValue != newValue) this.loadTasks();
  }

  public setSelectedType(newValue: TypeFilter) {
    const oldValue = this.selectedType;
    runInAction(() => {
      this.selectedType = newValue;
    });
    if (oldValue != newValue) this.loadTasks();
  }

  public setSelectedSort(newValue: SortOption) {
    const oldValue = this.selectedSort;
    runInAction(() => {
      this.selectedSort = newValue;
    });
    if (oldValue != newValue) this.loadTasks();
  }

  public setSearchText(newValue: string) {
    const oldValue = this.searchText;
    runInAction(() => {
      this.searchText = newValue;
    });
    if (oldValue != newValue) this.loadTasks();
  }

  public get statusItems(): IOption[] {
    const statusItems: IOption[] = [
      { id: TaskStatus.AwaitingReview.toString(), value: TaskStatusText[TaskStatus.AwaitingReview] },
      { id: TaskStatus.InProgress.toString(), value: TaskStatusText[TaskStatus.InProgress] },
    ];

    if (this.tab === TabFilter.CreatedByMe || this.tab === TabFilter.AllTasks) {
      statusItems.push({ id: TaskStatus.Approved.toString(), value: TaskStatusText[TaskStatus.Approved] });
      statusItems.push({ id: TaskStatus.Rejected.toString(), value: TaskStatusText[TaskStatus.Rejected] });
    }

    return statusItems;
  }

  public get typeItems(): IOption[] {
    return [
      { id: TaskType.TaskTeamReview.toString(), value: TaskTypeText[TaskType.TaskTeamReview] },
      { id: TaskType.ShareApproval.toString(), value: TaskTypeText[TaskType.ShareApproval] },
      { id: TaskType.DeliveryTeamReview.toString(), value: TaskTypeText[TaskType.DeliveryTeamReview] },
      { id: TaskType.PublishAuthorise.toString(), value: TaskTypeText[TaskType.PublishAuthorise] },
      { id: TaskType.PublishAccept.toString(), value: TaskTypeText[TaskType.PublishAccept] },
    ];
  }

  public get sortItems(): IOption[] {
    const sortItems: IOption[] = [
      {
        id: SortOption.ProgrammeTitle.toString(),
        value: 'Programme Name',
        disabled: !NavBarSelectorStore.selectedItem?.all,
      },
      {
        id: SortOption.ProjectNumber.toString(),
        value: 'Project Number',
        disabled: !!NavBarSelectorStore.selectedItem?.project,
      },
      {
        id: SortOption.ProjectTitle.toString(),
        value: 'Project Name',
        disabled: !!NavBarSelectorStore.selectedItem?.project,
      },
      { id: SortOption.LastUpdatedDate.toString(), value: 'Last Updated Date' },
      { id: SortOption.CreatedDate.toString(), value: 'Start Date' },
      { id: SortOption.TaskType.toString(), value: 'Workflow' },
    ];

    return sortItems;
  }
}
