import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone';
import Style from '../../../shared/styles/FileDropZone.module.scss';
import { Loader } from '@aurecon-creative-technologies/styleguide';
interface IFileDropZoneProps {
  multiple: boolean;
  disabled?: boolean;
  loading?: boolean;
  onDropzoneChange: (acceptedFiles: File[]) => void;
}
const SupersedeFileDropZone: FC<IFileDropZoneProps> = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.onDropzoneChange && props.onDropzoneChange(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: props.multiple,
    disabled: props.disabled,
  });

  return (
    <div
      {...getRootProps({
        className: `${Style.dropzone} ${Style.supersede} ${props.disabled ? Style.disabled : ''}`,
      })}>
      <input {...getInputProps()} />
      {props.loading ? (
        <Loader cssClass={Style.loadingSpinner} size="small" />
      ) : (
        <div className={Style.dropzoneInfo}>
          <p>
            <b>Upload Files</b>
          </p>
          <p>
            Drag & drop or click to browse for files
            <br />
          </p>
        </div>
      )}
    </div>
  );
};

export default observer(SupersedeFileDropZone);
