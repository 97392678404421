import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from '../FilesStore';
import ReviewModal from '../reviewModal/ReviewModal';
import SecondaryButton from '../../shared/SecondaryButton';
import { TaskType } from '../../../common/enums/TaskType';
import { useSaveTask } from '../customHooks/SaveTaskHook';
import { useGetAddTaskData } from '../customHooks/GetAddTaskDataHook';

interface TaskTeamReviewButtonProps {
  taskTeamId: number;
}

const TaskTeamReviewButton: FC<TaskTeamReviewButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);
  const saveTask = useSaveTask(taskTeamId, TaskType.TaskTeamReview, FilesStore);
  const getAddTaskData = useGetAddTaskData(TaskType.TaskTeamReview, taskTeamId);
  return (
    <>
      <SecondaryButton
        disabled={!FilesStore.canTaskTeamReviewOnSelectedFiles || taskTeamId === 0}
        onClick={() => setIsShowing(true)}>
        Review
      </SecondaryButton>
      {isShowing && (
        <ReviewModal
          title="Task Team Review"
          description="If the 'Task Team Review' workflow is approved, files will be assigned the suitability code selected below."
          closeModal={() => setIsShowing(false)}
          approversLabel="Approvers"
          approversSearchLabel="Add Approvers"
          allApproversLabel="Require all approvers to approve?"
          isSingleApproverRequired={true}
          showSuitability
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Review"
        />
      )}
    </>
  );
};

export default observer(TaskTeamReviewButton);
