import { AxiosError } from 'axios';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { IFile } from '../../../api/authenticated/cms/FileModel';
import { IReviewer, ISuitability } from '../../../api/authenticated/tasks/getAddData';
import FilesStore from '../FilesStore';
import LayoutStore from '../../layout/LayoutStore';
import { IUser } from '../../../api/authenticated/um/interfaces/user.interface';
import { ReviewState } from '../../tasks/Types';

export interface IReviewStoreParams {
  closeModal: () => void;
  title: string;
  description: string;
  approversLabel: string;
  approversSearchLabel: string;
  allApproversLabel?: string;
  showSuitability?: boolean;
  isSingleApproverRequired?: boolean;
  state: ReviewState;
  getData: () => Promise<{ reviewers: IReviewer[]; suitabilities: ISuitability[] }>;
  saveReview: (
    files: IFile[],
    reviewers: IReviewer[],
    suitability: ISuitability | null,
    selectedMessage: string,
    isSingleApproverRequired: boolean
  ) => Promise<void>;
}

export class ReviewStore {
  constructor(params: IReviewStoreParams) {
    makeAutoObservable(this, { canSave: computed }, { autoBind: true });

    this.closeModal = params.closeModal;
    this.title = params.title;
    this.description = params.description;
    this.approversLabel = params.approversLabel;
    this.approversSearchLabel = params.approversSearchLabel;
    this.allApproversLabel = params.allApproversLabel;
    this.showSuitability = params.showSuitability ?? false;
    this.isSingleApproverRequired =
      params.isSingleApproverRequired === undefined ? false : params.isSingleApproverRequired;
    this.getData = params.getData;
    this.saveReview = params.saveReview;
    this.selectedFiles = Object.values(FilesStore.selectedFiles);
    this.state = params.state;

    this.loadData();
  }

  public closeModal: () => void;
  public title: string;
  public description: string;
  public approversLabel: string;
  public approversSearchLabel: string;
  public allApproversLabel?: string;
  public showSuitability: boolean;
  public getData: () => Promise<{ reviewers: IReviewer[]; suitabilities: ISuitability[] }>;
  public saveReview: (
    files: IFile[],
    reviewers: IReviewer[],
    suitability: ISuitability | null,
    selectedMessage: string,
    isSingleApproverRequired: boolean
  ) => Promise<void>;
  public isLoadingData = false;
  public isSaving = false;
  public reviewers: IReviewer[] = [];
  public suitabilities: ISuitability[] = [];
  public selectedFiles: IFile[] = [];
  public selectedReviewers: IReviewer[] = [];
  public selectedSuitability: ISuitability | null = null;
  public selectedMessage = '';
  public isSingleApproverRequired = false;
  public errorMessage: string | null = null;
  public state: ReviewState;

  private async loadData() {
    runInAction(() => {
      this.isLoadingData = true;
      this.reviewers = [];
      this.suitabilities = [];
    });
    try {
      const { reviewers, suitabilities } = await this.getData();
      runInAction(() => {
        this.reviewers = reviewers;
        this.suitabilities = suitabilities;
        this.sortData(this.suitabilities);
      });
    } finally {
      runInAction(() => {
        this.isLoadingData = false;
      });
    }
  }
  public sortData(suitabilities: ISuitability[]) {
    suitabilities.sort((a, b) => (a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1));
  }
  public getMatchedUsers(searchText: string) {
    return this.reviewers
      .filter(
        (r) =>
          r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .map((r) => ({
        id: r.userId,
        name: r.name,
        email: r.email,
      }));
  }

  public onSelectedUsersUpdated(users: IUser[]) {
    if (this.isSaving) return;
    const reviewers: IReviewer[] = [];
    users.forEach((u) => {
      const reviewer = this.reviewers.find((r) => r.userId === u.id);
      if (reviewer) reviewers.push(reviewer);
    });
    runInAction(() => {
      this.selectedReviewers = reviewers;
    });
  }

  public removeSelectedFile(fileId: number) {
    if (this.isSaving) return;
    runInAction(() => {
      this.selectedFiles = this.selectedFiles.filter((f) => f.id !== fileId);
    });
    if (this.selectedFiles.length === 0) this.closeModal();
  }

  public setSelectedSuitability(id?: number) {
    if (this.isSaving) return;
    const suitability = this.suitabilities.find((s) => s.id === id) ?? null;
    runInAction(() => {
      this.selectedSuitability = suitability;
    });
  }

  public setSelectedMessage(message: string) {
    if (this.isSaving) return;
    runInAction(() => {
      this.selectedMessage = message;
    });
  }

  public setIsSingleApproverRequired(value: boolean) {
    if (this.isSaving) return;
    runInAction(() => {
      this.isSingleApproverRequired = value;
    });
  }

  public get canSave(): boolean {
    return (
      this.selectedFiles.length > 0 &&
      this.selectedReviewers.length > 0 &&
      (!this.showSuitability || !!this.selectedSuitability)
    );
  }

  public async save() {
    if (this.isSaving) return;
    runInAction(() => {
      this.isSaving = true;
      this.errorMessage = null;
    });
    try {
      await this.saveReview(
        this.selectedFiles,
        this.selectedReviewers,
        this.selectedSuitability,
        this.selectedMessage,
        this.isSingleApproverRequired
      );
      this.closeModal();
      LayoutStore.displayToast('success', 'The Workflow was successfully initiated.');
    } catch (error) {
      runInAction(() => {
        this.errorMessage = (error as AxiosError<string>)?.response?.data ?? 'Submission failed.';
      });
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  }
}
