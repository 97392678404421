import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TabContent } from '@aurecon-creative-technologies/styleguide';
import { TabFilter } from './Types';
import TransmittalsStore from './TransmittalsStore';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';
import TransmittalTabContent from './TransmittalTabContent';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import ActionBarStore from './ActionBarStore';

const Transmittals: FC = () => {
  const selectedItem = NavBarSelectorStore.selectedItem;
  useEffect(() => {
    return () => {
      ActionBarStore.clear();
      TransmittalsStore.resetFilterParameters();
      TransmittalsStore.resetSearchParameters();
    };
  }, []);

  useEffect(() => {
    if (TransmittalsStore.isLoading) return;

    // Get select option on component did mount
    TransmittalsStore.fetchTransmittals();

    // Reset selected users on unmount
    return () => {
      TransmittalsStore.resetTransmittalContent();
    };
  }, [selectedItem]);

  const labelCount = (prefix: string, currentTransmittalTabCount: number, totalCount: number) => {
    if (TransmittalsStore.isLoading) return `${prefix} (loading)`;

    if (!currentTransmittalTabCount || currentTransmittalTabCount === totalCount) return `${prefix} (${totalCount})`;

    return `${prefix} (${currentTransmittalTabCount} of ${totalCount})`;
  };

  return (
    <>
      <PageHeading>Transmittals</PageHeading>
      <PageTab
        defaultActiveTab={TransmittalsStore.currentTab}
        onSelectTab={(id) => TransmittalsStore.setCurrentTab(id)}
        tabs={[
          {
            id: TabFilter.MyNotifications,
            label: labelCount(
              'My Notifications',
              TransmittalsStore.currentTab === TabFilter.MyNotifications
                ? TransmittalsStore.pagingMetaData.totalCount
                : 0,
              TransmittalsStore.transmittalSummary.totalMyNotifications
            ),
          },
          {
            id: TabFilter.InitiatedByMe,
            label: labelCount(
              'Initiated by Me',
              TransmittalsStore.currentTab === TabFilter.InitiatedByMe
                ? TransmittalsStore.pagingMetaData.totalCount
                : 0,
              TransmittalsStore.transmittalSummary.totalInitiatedByMe
            ),
          },
          {
            id: TabFilter.VisibleToMe,
            label: labelCount(
              'Visible to Me',
              TransmittalsStore.currentTab === TabFilter.VisibleToMe ? TransmittalsStore.pagingMetaData.totalCount : 0,
              TransmittalsStore.transmittalSummary.totalVisibleToMe
            ),
          },
        ]}>
        <TabContent for={TabFilter.MyNotifications}>
          <TransmittalTabContent></TransmittalTabContent>
        </TabContent>
        <TabContent for={TabFilter.InitiatedByMe}>
          <TransmittalTabContent></TransmittalTabContent>
        </TabContent>
        <TabContent for={TabFilter.VisibleToMe}>
          <TransmittalTabContent></TransmittalTabContent>
        </TabContent>
      </PageTab>
    </>
  );
};

export default observer(Transmittals);
