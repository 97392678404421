import { IOption } from '@aurecon-creative-technologies/styleguide';
import { cloneDeep, groupBy, orderBy, startCase, toLower } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { IUserDetail } from '../../api/authenticated/transmittals/createTransmittal';
import {
  getDeliveryTeamsTaskTeamsUsers,
  IDeliveryTeam,
  ITaskTeam,
} from '../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import { getExternalUsers } from '../../api/authenticated/um/getExternalUsers';
import { IUser } from '../../api/authenticated/um/interfaces/user.interface';
import { multiGroupBy } from '../../utils/miscUtils';
import { DeliveryTeamRole } from '../../common/enums/DeliveryTeamRole';
import {
  IDeliveryUserGroup,
  IDTUser,
  IExternalUserGroup,
  IGroupTaskTeamUser,
  IDeliveryTeamUserGroup,
  IGroupExUser,
  ITaskTeamUserGroup,
  ITaskTeamPill,
  IDeliveryPill,
  IDeliveryTeamGroup,
  IProjectTaskTeam,
} from './interface/TeamChart.interface';
import { TaskTeamRole } from '../../common/enums/TaskTeamRole';

export class ProjectTeamSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  public selectedProjectNumber = 0;
  public selectedDeliveryTeamId?: number;
  public selectedTaskTeamId?: number;
  public keywords = '';
  public projectDeliveryTeams: IDeliveryTeam[] = [];
  public projectExternalUsers: IUser[] = [];
  public deliveryTeamOptions: IOption[] = [];
  public selectDeliveryTeam?: IDeliveryTeam;
  public allProjectTaskTeams: IProjectTaskTeam[] = [];

  public activeDTPanelIds = new Set<string>();
  public activeExUserPanelIds = new Set<string>();
  public activeTTUserPanelIds = new Set<string>();

  public selectedTaskTeamUsers: IDTUser[] = [];
  public selectedDeliveryTeamUsers: IDTUser[] = [];
  public groupExUsers: IGroupExUser[] = [];

  public taskTeamPillItems: ITaskTeamPill[] = [];
  public externalPillItems: IUser[] = [];
  public deliveryPillItems: IDeliveryPill[] = [];

  public projectDeliveryTeamGroups: IDeliveryTeamGroup[] = [];
  public projectDTTaskTeamTeamGroups: IGroupTaskTeamUser[] = [];

  public filterProjectDeliveryTeamGroups: IDeliveryTeamGroup[] = [];
  public filteredProjectDTTaskTeamTeamGroups: IGroupTaskTeamUser[] = [];
  public filteredGroupExUser: IGroupExUser[] = [];
  public inputSelectedUsers: IUserDetail[] = [];

  public resetForm() {
    runInAction(() => {
      this.selectedDeliveryTeamId = undefined;
      this.selectedTaskTeamId = undefined;
      this.keywords = '';
      this.projectDeliveryTeams = [];
      this.projectExternalUsers = [];
      this.deliveryTeamOptions = [];
      this.selectDeliveryTeam = undefined;
      this.activeDTPanelIds = new Set<string>();
      this.activeExUserPanelIds = new Set<string>();
      this.activeTTUserPanelIds = new Set<string>();
      this.selectedTaskTeamUsers = [];
      this.selectedDeliveryTeamUsers = [];
      this.groupExUsers = [];
      this.taskTeamPillItems = [];
      this.externalPillItems = [];
      this.deliveryPillItems = [];
      this.projectDeliveryTeamGroups = [];
      this.projectDTTaskTeamTeamGroups = [];
      this.allProjectTaskTeams = [];
      this.filterProjectDeliveryTeamGroups = [];
      this.filteredProjectDTTaskTeamTeamGroups = [];
      this.filteredGroupExUser = [];
      this.inputSelectedUsers = [];
    });
  }

  public setInputSelected(selectedUsers?: IUserDetail[]) {
    runInAction(() => {
      this.inputSelectedUsers = selectedUsers ?? [];
    });
  }

  public setSearchText(keywords: string) {
    runInAction(() => {
      this.keywords = keywords;
      this.filterData();
    });
  }

  public resetFilterData() {
    runInAction(() => {
      this.filterProjectDeliveryTeamGroups = [];
      this.filteredProjectDTTaskTeamTeamGroups = [];
      this.filteredGroupExUser = [];
    });
  }

  public filterData() {
    this.resetFilterData();
    this.filterTaskTeams();
    this.filterDeliveryTeams();
    this.filterExternalUsers();
  }

  public setSelectedDeliveryId(deliveryTeamId?: number) {
    runInAction(() => {
      this.selectedDeliveryTeamId = deliveryTeamId;
      this.activeDTPanelIds = new Set<string>();
      this.selectedTaskTeamId = undefined;
      this.selectDeliveryTeamUser();
      if (this.getTaskTeamDropdownOptions.length > 0) {
        this.onTTUserPanelToggle(`${deliveryTeamId}-${this.getTaskTeamDropdownOptions[0].id}`);
      }
    });
    this.filterData();
  }

  public setSelectedTaskTeamId(taskTeamId?: number) {
    runInAction(() => {
      this.selectedTaskTeamId = taskTeamId;
      if (taskTeamId) {
        this.activeTTUserPanelIds = new Set<string>();
        this.activeDTPanelIds = new Set<string>();
        const selectedTaskTeam = this.allProjectTaskTeams.find((x) => x.id === taskTeamId);
        if (selectedTaskTeam) {
          this.selectedDeliveryTeamId = selectedTaskTeam.deliveryTeamId;
          this.onDTPanelToggle(`DT_${this.selectedDeliveryTeamId}`);
          this.onTTUserPanelToggle(`${selectedTaskTeam.deliveryTeamId}-${selectedTaskTeam.id}`);
        }
      }
    });
    this.filterData();
  }

  public selectDeliveryTeamUser() {
    runInAction(() => {
      this.selectDeliveryTeam = this.projectDeliveryTeams.find((x) => x.id === this.selectedDeliveryTeamId);
      if (this.selectDeliveryTeam) {
        this.onDTPanelToggle(`DT_${this.selectDeliveryTeam.id}`);
      }
    });
  }

  public groupAllDeliveryTeamUsers() {
    const allDTGroups = this.projectDeliveryTeams.map((deliveryTeam) => {
      return {
        deliveryTeamId: deliveryTeam.id,
        deliveryTeamTitle: `${deliveryTeam.title} ${deliveryTeam.transmittalCode}`,
        values: [...this.groupSelectedDTUsers(deliveryTeam)],
        check: false,
      } as IDeliveryTeamGroup;
    });

    runInAction(() => {
      this.projectDeliveryTeamGroups = [...allDTGroups];
    });
  }

  private isMatchedKeywords(user, keywords: string): boolean {
    return (
      user.name.toLowerCase().includes(keywords.toLowerCase()) ||
      user.email.toLowerCase().includes(keywords.toLowerCase())
    );
  }

  public groupAllProjectTaskTeamUser() {
    const groupsTaskTeam: IGroupTaskTeamUser[] = [];
    this.projectDeliveryTeams.forEach((dTeam) => {
      groupsTaskTeam.push(...this.setGroupDTTaskTeamUsers(dTeam));
    });
    runInAction(() => {
      this.projectDTTaskTeamTeamGroups = [...groupsTaskTeam.filter((x) => !!x.taskTeamUserGroups.length)];
    });
  }

  public filterDeliveryTeams() {
    const tmpArr = cloneDeep(this.projectDeliveryTeamGroups);
    const results = tmpArr.filter(
      (x) =>
        (!this.selectedDeliveryTeamId || x.deliveryTeamId === this.selectedDeliveryTeamId) &&
        (!this.keywords.length ||
          (!!this.keywords.length &&
            x.values.some((g) => g.values.some((u) => this.isMatchedKeywords(u, this.keywords)))))
    );

    if (!this.keywords.length) {
      runInAction(() => {
        this.filterProjectDeliveryTeamGroups = [...results];
      });
      return;
    }

    for (const x of results) {
      x.values = [...x.values.filter((g) => g.values.some((u) => this.isMatchedKeywords(u, this.keywords)))];
    }

    for (const x of results) {
      for (const g of x.values) {
        g.values = [...g.values.filter((u) => this.isMatchedKeywords(u, this.keywords))];
      }
    }

    const newIds = new Set<string>();
    for (const dt of results) {
      newIds.add(`DT_${dt.deliveryTeamId}`);
    }

    runInAction(() => {
      this.filterProjectDeliveryTeamGroups = [...results];
      this.activeDTPanelIds = newIds;
    });
  }

  public filterExternalUsers() {
    const predicate = (values: IExternalUserGroup[]) => values.some((u) => this.isMatchedKeywords(u, this.keywords));
    const tmpArr = cloneDeep(this.groupExUsers);
    let results: IGroupExUser[] = [];
    if (!this.keywords) {
      results = [...tmpArr];
    }
    if (this.keywords) {
      results = [...tmpArr.filter((x) => predicate(x.values))];
      for (const group of results) {
        group.values = [...group.values.filter((u) => this.isMatchedKeywords(u, this.keywords))];
      }
    }
    runInAction(() => {
      this.filteredGroupExUser = [...results];
      const newIds = new Set<string>();
      newIds.add('exPanel');
      this.activeExUserPanelIds = newIds;
    });
  }

  public filterTaskTeams(): IGroupTaskTeamUser[] {
    const tmpArr = cloneDeep(this.projectDTTaskTeamTeamGroups);
    let results: IGroupTaskTeamUser[] = [
      ...tmpArr.filter(
        (x) =>
          (!this.selectedDeliveryTeamId ||
            this.selectedDeliveryTeamId <= 0 ||
            this.selectedDeliveryTeamId === x.deliveryTeamId) &&
          (!this.selectedTaskTeamId || x.taskTeamId === this.selectedTaskTeamId)
      ),
    ];
    if (!this.keywords || !this.keywords.length) {
      runInAction(() => {
        this.filteredProjectDTTaskTeamTeamGroups = [...results];
      });
    }
    const predicate = (values: ITaskTeamUserGroup[]) => values.some((u) => this.isMatchedKeywords(u, this.keywords));
    results = [...results.filter((x) => x.taskTeamUserGroups.some((g) => predicate(g.values)))];
    for (const x of results) {
      x.taskTeamUserGroups = [...x.taskTeamUserGroups.filter((g) => predicate(g.values))];
    }

    for (const x of results) {
      for (const g of x.taskTeamUserGroups) {
        g.values = [...g.values.filter((u) => this.isMatchedKeywords(u, this.keywords))];
      }
    }

    const newIds = new Set<string>();
    for (const taskTeam of results) {
      newIds.add(`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`);
    }

    runInAction(() => {
      this.activeTTUserPanelIds = newIds;
      this.filteredProjectDTTaskTeamTeamGroups = [...results];
    });
    return results;
  }

  public groupSelectedDTUsers(deliveryTeam?: IDeliveryTeam) {
    if (!deliveryTeam) return [];
    const grouped = groupBy(
      deliveryTeam.users.map((user) => {
        return {
          id: user.id,
          email: user.email,
          name: startCase(toLower(user.name)),
          initials: this.getUerInitials(user.name),
          group: user.name.charAt(0).toUpperCase(),
          deliveryTeamId: deliveryTeam.id,
          deliveryTeamTitle: deliveryTeam.title,
          transmittalCode: deliveryTeam.transmittalCode,
          deliveryTeamRoleTitle: user.deliveryTeamRoleTitle,
          type: 'DeliveryUser',
          check: false,
        };
      }),
      'group'
    );
    const groups: IDeliveryTeamUserGroup[] = Object.keys(grouped).map((i) => ({
      key: i,
      values: orderBy(grouped[i], 'name', 'asc') as IDeliveryUserGroup[],
    }));
    return [...orderBy(groups, 'key', 'asc')];
  }

  public setGroupDTTaskTeamUsers(deliveryTeam: IDeliveryTeam) {
    const taskTeam: IGroupTaskTeamUser[] = deliveryTeam.taskTeams.map((team: ITaskTeam) => {
      return {
        taskTeamId: team.id,
        taskTeamTitle: team.title,
        taskTeamCode: team.transmittalCode,
        deliveryTeamId: deliveryTeam.id,
        deliveryTeamTitle: deliveryTeam.title,
        deliveryTeamCode: deliveryTeam.transmittalCode,
        isFilterApprovers: false,
        taskTeamUserGroups: this.groupByTaskTeamUsers(team),
      };
    });

    return [...orderBy(taskTeam, 'taskTeamId', 'asc')];
  }

  private getUerInitials(name: string) {
    const arr = name.split(' ').map((w) => w.charAt(0));
    return [...arr[0], ...arr[arr.length - 1]].join('').toUpperCase();
  }

  private groupByTaskTeamUsers(taskTeam: ITaskTeam) {
    const grouped = groupBy(
      taskTeam.users.map((user) => {
        return {
          id: user.id,
          email: user.email,
          taskTeamRoleIds: user.taskTeamRoleIds,
          taskTeamId: taskTeam.id,
          taskTeamTitle: taskTeam.title,
          transmittalCode: taskTeam.transmittalCode,
          check: false,
          type: 'TaskTeamUser',
          name: startCase(toLower(user.name)),
          initials: this.getUerInitials(user.name),
          group: user.name.charAt(0).toUpperCase(),
        };
      }),
      'group'
    );
    const groups = Object.keys(grouped).map((i) => ({
      key: i,
      values: orderBy(grouped[i], 'name', 'asc') as ITaskTeamUserGroup[],
    }));
    return [...orderBy(groups, 'key', 'asc')];
  }

  public groupExternalUsers() {
    if (!this.projectExternalUsers) return [];

    const grouped = groupBy(
      this.projectExternalUsers.map((user) => {
        return {
          id: user.id,
          email: user.email,
          check: false,
          type: 'ExternalUser',
          name: startCase(toLower(user.name)),
          group: user.name.charAt(0).toUpperCase(),
          initials: this.getUerInitials(user.name),
        };
      }),
      'group'
    );
    const groups: IGroupExUser[] = Object.keys(grouped).map((i) => ({
      key: i,
      values: orderBy(grouped[i], 'name', 'asc') as IExternalUserGroup[],
    }));
    runInAction(() => {
      this.groupExUsers = [...orderBy(groups, 'key', 'asc')];
    });
  }

  public selectAllAuthoriserUserInDT(deliveryTeamId: number) {
    runInAction(() => {
      this.setAllAuthoriserUserInDTToSelected(deliveryTeamId);
      this.setAllAuthoriserUserInDTDisplayToSelected(deliveryTeamId);
    });
    this.setDisableItem();
  }

  private setAllAuthoriserUserInDTDisplayToSelected(deliveryTeamId: number) {
    const selectedDT = this.filterProjectDeliveryTeamGroups.find((x) => x.deliveryTeamId === deliveryTeamId);
    selectedDT?.values.forEach((x) => {
      x.values.forEach((user) => {
        if (!user.disabled && user.deliveryTeamRoleTitle) {
          user.check = true;
        }
      });
    });
  }

  private setAllAuthoriserUserInDTToSelected(deliveryTeamId: number) {
    const selectedDT = this.projectDeliveryTeamGroups.find((x) => x.deliveryTeamId === deliveryTeamId);
    selectedDT?.values.forEach((x) => {
      x.values.forEach((user) => {
        if (!user.disabled && user.deliveryTeamRoleTitle) {
          user.check = true;
          this.addItemToSelectedDeliveryTeamUsers(user);
        }
      });
    });
    this.setGroupDeliveryUserPillItems();
  }

  public addOrRemoveDeliveryTeamUserById(selectUser: IDeliveryUserGroup, groupKey: string) {
    if (selectUser.disabled) return;

    this.addOrRemoveSelectedUserByIdForFilteredDT(selectUser.id, selectUser.deliveryTeamId, groupKey);
    const selectedDT = this.projectDeliveryTeamGroups.find((x) => x.deliveryTeamId === selectUser.deliveryTeamId);
    const group = selectedDT?.values.find((x) => x.key === groupKey);
    if (group) {
      const user = group.values.find((x) => x.id === selectUser.id);
      if (user) {
        user.check = !user.check;
        runInAction(() => {
          if (user.check) {
            this.addItemToSelectedDeliveryTeamUsers(user);
          } else {
            this.removeItemToSelectedDeliveryTeamUsers(user);
          }
        });
        this.setGroupDeliveryUserPillItems();
        this.setDisableItem();
      }
    }
  }

  private addOrRemoveSelectedUserByIdForFilteredDT(id: number, deliveryTeamId: number, groupKey: string) {
    const selectedDT = this.filterProjectDeliveryTeamGroups.find((x) => x.deliveryTeamId === deliveryTeamId);
    const group = selectedDT?.values.find((x) => x.key === groupKey);
    if (group) {
      const user = group.values.find((x) => x.id === id);
      if (user) {
        user.check = !user.check;
      }
    }
  }

  private addItemToSelectedDeliveryTeamUsers(user) {
    if (!this.selectedDeliveryTeamUsers.some((x) => x.id === user.id && x.deliveryTeamId === user.deliveryTeamId)) {
      this.selectedDeliveryTeamUsers.push({
        ...user,
        deliveryTeamCode: user.transmittalCode,
        taskTeamId: undefined,
        taskTeamTitle: undefined,
        taskTeamCode: undefined,
        type: 'DeliveryUser',
      });
    }
  }

  private removeItemToSelectedDeliveryTeamUsers(user) {
    const index = this.selectedDeliveryTeamUsers.findIndex(
      (x) => x.deliveryTeamId === user.deliveryTeamId && user.id === x.id
    );
    if (index >= 0) {
      this.selectedDeliveryTeamUsers.splice(index, 1);
    }
  }

  public setGroupDeliveryUserPillItems() {
    const userGroups = groupBy(this.selectedDeliveryTeamUsers, 'deliveryTeamId');
    const groups = Object.keys(userGroups).map((i) => ({
      deliveryTeamId: i,
      users: userGroups[i],
    }));

    runInAction(() => {
      this.deliveryPillItems = groups.map((g) => {
        return {
          ...g.users[0],
          users: g.users,
          count: g.users.length,
        };
      });
    });
  }

  public setGroupTaskTeamPillItems() {
    const taskTeamGroups = multiGroupBy(this.selectedTaskTeamUsers, ['deliveryTeamId', 'taskTeamId']);
    const groups = Object.keys(taskTeamGroups).map((dt) => ({
      deliveryTeamId: dt,
      taskTeams: taskTeamGroups[dt],
    }));
    const tGroups = groups.map((dtGroup) => {
      return {
        deliveryTeamId: dtGroup.deliveryTeamId,
        taskTeams: Object.keys(dtGroup.taskTeams).map((tt) => ({
          taskTeamId: tt,
          users: dtGroup.taskTeams[tt],
        })),
      };
    });
    const pills: ITaskTeamPill[] = [];
    tGroups.forEach((dtGroup) => {
      const tPills = dtGroup.taskTeams.map((tg) => {
        return {
          taskTeamId: Number(tg.taskTeamId),
          taskTeamCode: tg.users[0].taskTeamCode,
          deliveryTeamTitle: tg.users[0].deliveryTeamTitle,
          deliveryTeamId: tg.users[0].deliveryTeamId,
          deliveryTeamCode: tg.users[0].deliveryTeamCode,
          count: tg.users.length,
          users: tg.users,
        };
      });
      pills.push(...tPills);
    });

    runInAction(() => {
      this.taskTeamPillItems = [...pills];
    });
  }

  public addOrRemoveExUserById(id: number, groupKey: string) {
    this.addOrRemoveUserByIdInFilterInExGroup(id, groupKey);
    const group = this.groupExUsers.find((x) => x.key === groupKey);
    if (!group) return;

    const user = group.values.find((x) => x.id === id);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
        if (user.check) {
          if (!this.externalPillItems.some((x) => x.id === user.id)) {
            this.externalPillItems.push({ ...user });
          }
        } else {
          const index = this.externalPillItems.findIndex((x) => x.id === user.id);
          if (index >= 0) {
            this.externalPillItems.splice(index, 1);
          }
        }
      });
    }
  }

  private addOrRemoveUserByIdInFilterInExGroup(id: number, groupKey: string) {
    const group = this.filteredGroupExUser.find((x) => x.key === groupKey);
    if (!group) return;

    const user = group.values.find((x) => x.id === id);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
      });
    }
  }

  public setFilterApprovers(deliveryTeamId: number, taskTeamId: number) {
    const taskTeam = this.filteredProjectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (taskTeam) {
      runInAction(() => {
        taskTeam.isFilterApprovers = !taskTeam.isFilterApprovers;
      });
    }
  }

  public selectAllTaskTeamApprovers(deliveryTeamId: number, taskTeamId: number) {
    this.selectAllFilterTaskTeamApprovers(deliveryTeamId, taskTeamId);
    const taskTeam = this.projectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUserGroups.forEach((g) => {
        g.values.forEach((user) => {
          if (!user.disabled && !!user.taskTeamRoleIds.length) {
            user.check = true;
            this.addSelectedTaskTeamUsers(user, taskTeam);
          }
        });
      });
    });
    this.setGroupTaskTeamPillItems();
  }

  private selectAllFilterTaskTeamApprovers(deliveryTeamId: number, taskTeamId: number) {
    const taskTeam = this.filteredProjectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUserGroups.forEach((g) => {
        g.values.forEach((user) => {
          if (!user.disabled && !!user.taskTeamRoleIds.length) {
            user.check = true;
          }
        });
      });
    });
  }

  public selectAllTaskTeamMembers(deliveryTeamId: number, taskTeamId: number) {
    this.selectAllFilterTaskTeamMembers(deliveryTeamId, taskTeamId);
    const taskTeam = this.projectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUserGroups.forEach((g) => {
        g.values.forEach((user) => {
          if (!user.taskTeamRoleIds.length) {
            user.check = true;
            this.addSelectedTaskTeamUsers(user, taskTeam);
          }
        });
      });
    });
    this.setGroupTaskTeamPillItems();
    this.setDisableItem();
  }

  private selectAllFilterTaskTeamMembers(deliveryTeamId: number, taskTeamId: number) {
    const taskTeam = this.filteredProjectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUserGroups.forEach((g) => {
        g.values.forEach((user) => {
          if (!user.taskTeamRoleIds.length) {
            user.check = true;
          }
        });
      });
    });
    this.setDisableItem();
  }

  private addSelectedTaskTeamUsers(user, taskTeam) {
    if (
      !this.selectedTaskTeamUsers.some(
        (x) => x.id === user.id && x.taskTeamId === user.taskTeamId && x.deliveryTeamId === taskTeam.deliveryTeamId
      )
    ) {
      this.selectedTaskTeamUsers.push({ ...user, ...taskTeam, type: 'TaskTeamUser' });
    }
  }

  private removeSelectedTaskTeamUsers(user, taskTeam) {
    const index = this.selectedTaskTeamUsers.findIndex(
      (x) => x.id === user.id && x.taskTeamId === user.taskTeamId && x.deliveryTeamId === taskTeam.deliveryTeamId
    );
    this.selectedTaskTeamUsers.splice(index, 1);
    this.setDisableItem();
  }

  public addOrRemoveTaskTeamUserById(
    deliveryTeamId: number,
    taskTeamId: number,
    selectUser: ITaskTeamUserGroup,
    groupKey: string
  ) {
    if (selectUser.disabled) return;

    this.addOrRemoveFilterTaskTeamUserById(deliveryTeamId, taskTeamId, selectUser.id, groupKey);
    const taskTeam = this.projectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    const group = taskTeam.taskTeamUserGroups.find((x) => x.key === groupKey);
    if (!group) return;
    const user = group.values.find((user) => user.id === selectUser.id);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
        if (user.check) {
          this.addSelectedTaskTeamUsers(user, taskTeam);
        } else {
          this.removeSelectedTaskTeamUsers(user, taskTeam);
        }
      });
    }
    this.setGroupTaskTeamPillItems();
    this.setDisableItem();
  }

  private addOrRemoveFilterTaskTeamUserById(deliveryTeamId: number, taskTeamId: number, uId: number, groupKey: string) {
    const taskTeam = this.filteredProjectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskTeamId && x.deliveryTeamId === deliveryTeamId
    );
    if (!taskTeam) return;
    const group = taskTeam.taskTeamUserGroups.find((x) => x.key === groupKey);
    if (!group) return;
    const user = group.values.find((user) => user.id === uId);
    if (user) {
      runInAction(() => {
        user.check = !user.check;
      });
    }
  }

  public clearAllSelectedUsers() {
    runInAction(() => {
      this.externalPillItems = [];
      this.deliveryPillItems = [];
      this.selectedDeliveryTeamUsers = [];
      this.taskTeamPillItems = [];
      this.selectedTaskTeamUsers = [];

      this.groupExUsers.forEach((g) => {
        g.values.forEach((user) => {
          user.check = false;
        });
      });
      this.filteredGroupExUser.forEach((g) => {
        g.values.forEach((user) => {
          user.check = false;
        });
      });
      this.projectDTTaskTeamTeamGroups.forEach((tt) => {
        tt.taskTeamUserGroups.forEach((g) => {
          g.values.forEach((user) => (user.check = false));
        });
      });
      this.filteredProjectDTTaskTeamTeamGroups.forEach((tt) => {
        tt.taskTeamUserGroups.forEach((g) => {
          g.values.forEach((user) => (user.check = false));
        });
      });
      this.projectDeliveryTeamGroups.forEach((dg) => {
        dg.values.forEach((tg) => {
          tg.values.forEach((u) => {
            u.check = false;
          });
        });
      });
      this.filterProjectDeliveryTeamGroups.forEach((dg) => {
        dg.values.forEach((tg) => {
          tg.values.forEach((u) => {
            u.check = false;
          });
        });
      });
      this.setDisableItem();
    });
  }

  public get isShowSelectedUser() {
    return !!this.taskTeamPillItems.length || !!this.deliveryPillItems.length || !!this.externalPillItems.length;
  }

  public isShowFilterResult() {
    return this.selectedDeliveryTeamId || !!this.keywords.length;
  }

  public removeSelectedGroupTaskTeamUsers(taskUser: ITaskTeamPill) {
    this.removeSelectedFilterGroupTaskTeamUsers(taskUser);
    const taskTeam = this.projectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskUser.taskTeamId && x.deliveryTeamId === taskUser.deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUserGroups.forEach((group) => {
        group.values.forEach((x) => {
          x.check = false;
        });
      });
      const selectedItems = this.selectedTaskTeamUsers.map((x) => ({
        ...x,
        isDelete: x.deliveryTeamId === taskUser.deliveryTeamId && x.taskTeamId === taskUser.taskTeamId,
      }));

      this.selectedTaskTeamUsers = [...selectedItems.filter((x) => !x.isDelete)];
      this.setGroupTaskTeamPillItems();
      this.setDisableItem();
    });
  }

  private removeSelectedFilterGroupTaskTeamUsers(taskUser: ITaskTeamPill) {
    const taskTeam = this.filteredProjectDTTaskTeamTeamGroups.find(
      (x) => x.taskTeamId === taskUser.taskTeamId && x.deliveryTeamId === taskUser.deliveryTeamId
    );
    if (!taskTeam) return;
    runInAction(() => {
      taskTeam.taskTeamUserGroups.forEach((group) => {
        group.values.forEach((x) => {
          x.check = false;
        });
      });
    });
  }

  public removeSelectedExUsers(exU) {
    this.removeSelectedFilterExUsers(exU);
    const group = this.groupExUsers.find((g) => g.key === exU.name.charAt(0).toUpperCase());
    if (!group) return;
    const updateUser = group.values.find((x) => x.id == exU.id);
    if (updateUser) {
      runInAction(() => {
        updateUser.check = false;
        this.externalPillItems = this.externalPillItems.filter((x) => x.id !== updateUser.id);
      });
    }
  }

  private removeSelectedFilterExUsers(exU) {
    const group = this.filteredGroupExUser.find((g) => g.key === exU.name.charAt(0).toUpperCase());
    if (!group) return;
    const updateUser = group.values.find((x) => x.id == exU.id);
    if (updateUser) {
      runInAction(() => {
        updateUser.check = false;
      });
    }
  }

  public removeSelectedDTUsers(dtUser: IDeliveryPill) {
    this.removeSelectedFilterDTUsers(dtUser);
    runInAction(() => {
      const deliveryGroup = this.projectDeliveryTeamGroups.find((x) => x.deliveryTeamId === dtUser.deliveryTeamId);
      deliveryGroup?.values.forEach((group) => {
        group.values.forEach((user) => {
          user.check = false;
        });
      });
      this.selectedDeliveryTeamUsers = this.selectedDeliveryTeamUsers.filter(
        (x) => x.deliveryTeamId !== dtUser.deliveryTeamId
      );
      this.setGroupDeliveryUserPillItems();
      this.setDisableItem();
    });
  }

  private removeSelectedFilterDTUsers(dtUser: IDeliveryPill) {
    runInAction(() => {
      const deliveryGroup = this.filterProjectDeliveryTeamGroups.find(
        (x) => x.deliveryTeamId === dtUser.deliveryTeamId
      );
      deliveryGroup?.values.forEach((group) => {
        group.values.forEach((user) => {
          user.check = false;
        });
      });
    });
  }

  public async getData(
    projectNumber: string,
    deliveryTeamId: number | null,
    taskTeamId: number | null,
    anyoneInTaskTeam: boolean,
    anyoneInDeliverTeam: boolean,
    excludedUsers: string[]
  ) {
    try {
      const deliveryTeams = await getDeliveryTeamsTaskTeamsUsers(projectNumber);
      const externalUsers = await getExternalUsers(projectNumber);
      const allTaskTeams: IProjectTaskTeam[] = [];

      const filteredDeliveryTeams = deliveryTeams
        .filter((f) => !deliveryTeamId || f.id === deliveryTeamId)
        .map((m) => {
          return {
            ...m,
            users: m.users.filter(
              (f) =>
                (anyoneInDeliverTeam || f.deliveryTeamRoleIds.includes(DeliveryTeamRole.Authoriser)) &&
                (!excludedUsers.length || !excludedUsers.map((m) => m.toLowerCase()).includes(f.email.toLowerCase()))
            ),
            taskTeams: m.taskTeams
              .filter((f) => !taskTeamId || f.id === taskTeamId)
              .map((t) => {
                return {
                  ...t,
                  users: t.users.filter(
                    (f) =>
                      (anyoneInTaskTeam || f.taskTeamRoleIds.includes(TaskTeamRole.Approver)) &&
                      (!excludedUsers.length ||
                        !excludedUsers.map((m) => m.toLowerCase()).includes(f.email.toLowerCase()))
                  ),
                };
              }),
          };
        });

      for (const dt of filteredDeliveryTeams) {
        const taskTeams = dt.taskTeams.map((t) => {
          return {
            id: t.id,
            title: t.title,
            transmittalCode: t.transmittalCode,
            deliveryTeamId: dt.id,
            deliveryTeamCode: dt.transmittalCode,
            deliveryTeamTitle: dt.title,
            hasTeamMember: !!t.users.length,
          } as IProjectTaskTeam;
        });
        allTaskTeams.push(...taskTeams);
      }

      runInAction(() => {
        this.deliveryTeamOptions = [
          ...orderBy(
            filteredDeliveryTeams.map((dt) => {
              return {
                id: dt.id.toString(),
                value: `${dt.title} (${dt.transmittalCode})`,
                disabled: false,
              };
            }),
            'value',
            'asc'
          ),
        ];
        this.allProjectTaskTeams = [...allTaskTeams];
        this.projectDeliveryTeams = filteredDeliveryTeams;
        this.projectExternalUsers = externalUsers;
        this.groupAllDeliveryTeamUsers();
        this.groupAllProjectTaskTeamUser();
        this.groupExternalUsers();
        if (this.inputSelectedUsers.length) {
          this.setDefaultSelected();
        }
      });
    } catch {
      runInAction(() => {
        this.resetForm();
      });
    }
  }

  private setExUserSelected() {
    const inputSelectedEXUsers = this.inputSelectedUsers.filter((x) => x.isExternal);
    if (!inputSelectedEXUsers.length) return;

    for (const group of this.groupExUsers) {
      group.values.forEach((u) => {
        if (inputSelectedEXUsers.some((x) => x.userId === u.id)) {
          u.check = true;
          if (!this.externalPillItems.some((p) => p.id === u.id)) {
            this.externalPillItems.push({ ...u });
          }
        }
      });
    }
  }

  private setTTUserSelected() {
    this.projectDTTaskTeamTeamGroups.forEach((taskTeamGroup) => {
      const inputSelectedTTUsers = this.inputSelectedUsers.filter(
        (x) => x.deliveryTeamId === taskTeamGroup.deliveryTeamId && x.taskTeamId === taskTeamGroup.taskTeamId
      );
      if (!inputSelectedTTUsers.length) return;
      taskTeamGroup.taskTeamUserGroups.forEach((i) => {
        i.values.forEach((iu) => {
          if (inputSelectedTTUsers.some((su) => su.userId === iu.id)) {
            iu.check = true;
            const dtUser: IDTUser = {
              id: iu.id,
              name: iu.name,
              email: iu.email,
              deliveryTeamId: iu.deliveryTeamId,
              deliveryTeamCode: taskTeamGroup.deliveryTeamCode,
              deliveryTeamTitle: taskTeamGroup.deliveryTeamTitle,
              taskTeamId: iu.taskTeamId,
              taskTeamTitle: iu.taskTeamTitle,
              type: 'TaskTeamUser',
            };
            this.addSelectedTaskTeamUsers(iu, taskTeamGroup);
            const existedPill = this.taskTeamPillItems.find(
              (dp) => dp.taskTeamId === taskTeamGroup.taskTeamId && dp.deliveryTeamId === taskTeamGroup.deliveryTeamId
            );
            if (!existedPill) {
              const newG: ITaskTeamPill = {
                taskTeamId: taskTeamGroup.taskTeamId,
                taskTeamCode: taskTeamGroup.taskTeamCode,
                deliveryTeamId: taskTeamGroup.deliveryTeamId,
                deliveryTeamCode: taskTeamGroup.deliveryTeamCode,
                deliveryTeamTitle: taskTeamGroup.deliveryTeamTitle,
                count: 1,
                users: [dtUser],
              };
              this.taskTeamPillItems.push(newG);
            } else {
              existedPill.users.push(dtUser);
              existedPill.count += 1;
            }
          }
        });
      });
    });
  }

  private setDefaultSelected() {
    this.setExUserSelected();
    this.setTTUserSelected();
    this.setDisableItem();
  }

  public get getTaskTeamDropdownOptions() {
    if (!this.selectedDeliveryTeamId) {
      return [
        ...orderBy(
          this.allProjectTaskTeams
            .filter((x) => x.hasTeamMember)
            .map((tt) => {
              return {
                id: tt.id.toString(),
                value: `${tt.title} (${tt.transmittalCode})`,
                disabled: false,
              };
            }),
          'value',
          'asc'
        ),
      ];
    } else {
      return [
        ...orderBy(
          this.allProjectTaskTeams
            .filter((tt) => tt.deliveryTeamId === this.selectedDeliveryTeamId && tt.hasTeamMember)
            .map((tt) => {
              return {
                id: tt.id.toString(),
                value: `${tt.title} (${tt.transmittalCode})`,
                disabled: false,
              };
            }),
          'value',
          'asc'
        ),
      ];
    }
  }

  public getSelectedData() {
    return {
      deliveryUsers: this.selectedDeliveryTeamUsers ?? [],
      taskTeamUsers: this.selectedTaskTeamUsers ?? [],
      externalUsers: this.externalPillItems ?? [],
    };
  }

  public onDTPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeDTPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);
    runInAction(() => {
      this.activeDTPanelIds = newIds;
    });
  }

  public onExUserPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeExUserPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activeExUserPanelIds = newIds;
    });
  }

  public onTTUserPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeTTUserPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activeTTUserPanelIds = newIds;
    });
  }

  public collapseAll() {
    runInAction(() => {
      this.activeTTUserPanelIds = new Set<string>();
    });
  }

  public expandAll() {
    const newIds = new Set<string>();
    for (const taskTeam of this.filteredProjectDTTaskTeamTeamGroups) {
      newIds.add(`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`);
    }
    runInAction(() => {
      this.activeTTUserPanelIds = newIds;
    });
  }

  private isUserSelected(userId: number) {
    return (
      this.externalPillItems.some((u) => u.id === userId) ||
      this.selectedDeliveryTeamUsers.some((u) => u.id === userId) ||
      this.selectedTaskTeamUsers.some((u) => u.id === userId)
    );
  }

  private setDisableItem() {
    for (const dtGroup of this.projectDeliveryTeamGroups) {
      this.setDeliveryTeamUserDisabled(dtGroup);
    }

    for (const dtGroup of this.filterProjectDeliveryTeamGroups) {
      this.setDeliveryTeamUserDisabled(dtGroup);
    }

    for (const groupTaskTeam of this.projectDTTaskTeamTeamGroups) {
      this.setTaskTeamUserDisabled(groupTaskTeam);
    }

    for (const groupTaskTeam of this.filteredProjectDTTaskTeamTeamGroups) {
      this.setTaskTeamUserDisabled(groupTaskTeam);
    }
  }

  private setDeliveryTeamUserDisabled(dtGroup: IDeliveryTeamGroup) {
    for (const groupUser of dtGroup.values) {
      for (const user of groupUser.values) {
        const isSelected = this.selectedDeliveryTeamUsers.some(
          (selectedUser) =>
            selectedUser.deliveryTeamId === dtGroup.deliveryTeamId &&
            selectedUser.id === user.id &&
            !selectedUser.taskTeamId
        );
        if (!isSelected && this.isUserSelected(user.id)) {
          user.disabled = true;
        } else {
          user.disabled = false;
        }
      }
    }
  }

  private setTaskTeamUserDisabled(groupTaskTeam: IGroupTaskTeamUser) {
    for (const groupUser of groupTaskTeam.taskTeamUserGroups) {
      for (const user of groupUser.values) {
        const isSelected = this.selectedTaskTeamUsers.some(
          (selectedUser) =>
            selectedUser.deliveryTeamId === groupTaskTeam.deliveryTeamId &&
            selectedUser.id === user.id &&
            selectedUser.taskTeamId === user.taskTeamId
        );
        if (!isSelected && this.isUserSelected(user.id)) {
          user.disabled = true;
        } else {
          user.disabled = false;
        }
      }
    }
  }
}

export default new ProjectTeamSelectorStore();
