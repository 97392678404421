import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TransmittalTabContent.module.scss';
import TransmittalsStore from './TransmittalsStore';
import { Loader, Pagination } from '@aurecon-creative-technologies/styleguide';
import Overlay from '../shared/Overlay';
import TransmittalItem from './TransmittalItem';
import { TabFilter } from './Types';
import TransmittalTabContainerActionBar from './TransmittalTabContainerActionBar';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';

const TransmittalTabContent: FC = () => {
  const navigate = useNavigate();
  const handleNavigateToAddNewTransmittal = () => {
    navigate(`${Pages.Transmittals.Route}/new`);
  };
  return (
    <>
      <div className={Style.tabContainer}>
        {TransmittalsStore.isLoading && (
          <div>
            <Overlay className={Style.modalOverlay}></Overlay>
            <Loader cssClass={Style.savingSpinner} size="small" />
          </div>
        )}
        <TransmittalTabContainerActionBar
          selectedTab={TransmittalsStore.currentTab}
          onFilter={TransmittalsStore.handleFilterChange}
        />
        {!!TransmittalsStore.transmittals.length && (
          <div className={Style.transmittalList}>
            {TransmittalsStore.transmittals.map((transmittal) => (
              <TransmittalItem key={transmittal.title} transmittal={transmittal}></TransmittalItem>
            ))}
          </div>
        )}
        {TransmittalsStore.pagingMetaData && TransmittalsStore.pagingMetaData.totalCount > 0 && (
          <div className={Style.paginationWrapper}>
            <div className={Style.paginationContainer}>
              <Pagination
                page={TransmittalsStore.pagingMetaData.pageNumber}
                pageCount={TransmittalsStore.pagingMetaData.pageCount}
                onChange={(page: number) => {
                  TransmittalsStore.setCurrentPage(page);
                }}
              />
              <div className={Style.paginationFlexGrow}>
                {TransmittalsStore.pagingMetaData.firstItemOnPage + 1} -{' '}
                {TransmittalsStore.pagingMetaData.lastItemOnPage + 1} of {TransmittalsStore.pagingMetaData.totalCount}{' '}
                Files
              </div>
            </div>
          </div>
        )}
        {!TransmittalsStore.transmittals.length && !TransmittalsStore.isLoading && (
          <div className={Style.noDataWrapper}>
            {TransmittalsStore.isSearchedOrFiltered && (
              <div className={Style.noDataMessage}>
                There are no Transmittals that match your criteria.<br></br>
              </div>
            )}
            {!TransmittalsStore.isSearchedOrFiltered && TransmittalsStore.currentTab === TabFilter.MyNotifications && (
              <div>There are no active Transmittals that require your actions.</div>
            )}
            {!TransmittalsStore.isSearchedOrFiltered && TransmittalsStore.currentTab === TabFilter.InitiatedByMe && (
              <div className={Style.noDataMessage}>
                There are no Transmittals initiated by you. <br></br>
                Click <a onClick={handleNavigateToAddNewTransmittal}>here</a> to create new Transmittals.
              </div>
            )}
            {!TransmittalsStore.isSearchedOrFiltered && TransmittalsStore.currentTab === TabFilter.VisibleToMe && (
              <div className={Style.noDataMessage}>
                There are no Transmittals visible to you.<br></br>
                Click <a onClick={handleNavigateToAddNewTransmittal}>here</a> to create new Transmittals.
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default observer(TransmittalTabContent);
