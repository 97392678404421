import { TaskType } from '../../../common/enums/TaskType';
import { IReviewer, ISuitability } from '../../../api/authenticated/tasks/getAddData';
import { IFile } from '../../../api/authenticated/cms/FileModel';
import { addTask, IAddTaskFile } from '../../../api/authenticated/tasks/addTask';
import { FilesStore } from '../FilesStore';

export function useSaveTask(taskTeamId: number, taskType: TaskType, filesStore: FilesStore) {
  return async (
    files: IFile[],
    reviewers: IReviewer[],
    suitability: ISuitability | null,
    selectedMessage: string,
    isSingleApproverRequired: boolean
  ) => {
    await addTask({
      taskTeamId,
      taskType,
      files: files.map<IAddTaskFile>((f) => ({
        fileId: f.id,
        releasedFileId: f.releasedFileId,
      })),
      reviewerUserIds: reviewers.map<number>((r) => r.userId),
      suitabilityId: suitability!.id,
      message: selectedMessage,
      isSingleApproverRequired: isSingleApproverRequired,
    });
    filesStore.loadFiles();
  };
}
