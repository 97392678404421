import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { serverAxios } from '../api';

export interface IAddUsersToDeliveryTeamRoleRequest {
  projectNumber: string;
  deliveryTeamId: number;
  userIds: number[];
  deliveryTeamRoleId: DeliveryTeamRole;
}

export function addUsersToDeliveryTeamRole(
  request: IAddUsersToDeliveryTeamRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/deliveryteam/users/addrole', request, {
    signal: abortSignal,
  });
}
