import './styles/App.scss';
import './styles/StyleGuideOverrides.scss';
import './styles/EsriOverrides.scss';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import AppRouting from './AppRouting';
import { getAppInsightsInstance } from './utils/appInsights';
import applyPollyfills from './utils/polyfills';
import AppStore from './stores/AppStore';

import config from './config';
import packageJson from '../package.json';
import { getAppVersion } from './api/unauthenticated/config/getAppVersion';

applyPollyfills();

const App: FC = () => {
  const { accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const appVersion = await getAppVersion();
      if (appVersion && appVersion.version !== packageJson.version) {
        window.location.reload();
      }
    }, config.versionTimeInterval);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isAuthenticated && accounts && accounts[0]) {
      getAppInsightsInstance().setAuthenticatedUserContext(accounts[0].localAccountId ?? '', undefined, true);
      AppStore.init();
    }
  }, [isAuthenticated, accounts]);

  const waitingForAuthenticationToComplete =
    !isAuthenticated && (inProgress === InteractionStatus.Startup || inProgress === InteractionStatus.HandleRedirect);
  const waitingForAppStoreInitialisation = isAuthenticated && !AppStore.hasInit && !AppStore.hasClientInfoError;

  if (waitingForAuthenticationToComplete || waitingForAppStoreInitialisation) return <Loader label="Loading..." />;

  return <AppRouting />;
};

export default observer(App);
