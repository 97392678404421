import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from './Modal';
import Style from './styles/ErrorModal.module.scss';
import PrimaryButton from './PrimaryButton';
import ModalActions from './ModalActions';
import CentreOnPage from './CentreOnPage';
import Overlay from './Overlay';

interface IErrorModalProps {
  title?: string;
  message?: string | JSX.Element;
  closeModal: () => void;
}

const ErrorModal: FC<IErrorModalProps> = (props) => {
  const {
    closeModal,
    title = 'Something went wrong',
    message = <p>Please try again later. Contact support if the error persists.</p>,
  } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>{title}</h1>
        <div className={Style.message}>{message}</div>
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(ErrorModal);
