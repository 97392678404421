import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/AddTransmittalErrorModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import PrimaryButton from '../../shared/PrimaryButton';

interface IErrorModalProps {
  closeModal: () => void;
  errorMessage?: string;
}

const AddTransmittalErrorModal: FC<IErrorModalProps> = (props) => {
  const { errorMessage, closeModal } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Something went wrong</h1>
        {!errorMessage && <div>Transmittal cannot be responded. Please try again later.</div>}
        {errorMessage && <div>{errorMessage}</div>}
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddTransmittalErrorModal);
