import { makeAutoObservable, runInAction } from 'mobx';
import ProjectUserManagementStore from '../ProjectUserManagementStore';
import { IDeliveryTeam, ITaskTeam } from '../../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import { getUsersByNameAndEmail, IUser } from '../../shared/UserSelector';
import AppointedPartiesStore from './AppointedPartiesStore';
import LayoutStore from '../../layout/LayoutStore';
import { addUsersToTaskTeam } from '../../../api/authenticated/um/addUsersToTaskTeam';
import { addUsersToTaskTeamRole } from '../../../api/authenticated/um/addUsersToTaskTeamRole';
import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { getUsers } from '../../../api/authenticated/um/getUsers';

export class AddTaskTeamMemberStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public deliveryTeam: IDeliveryTeam | null = null;
  public taskTeam: ITaskTeam | null = null;
  public users: IUser[] = [];
  public selectedUsers: IUser[] = [];
  public isApprover = false;
  public isSaving = false;

  public async show(deliveryTeam: IDeliveryTeam, taskTeam: ITaskTeam) {
    if (!ProjectUserManagementStore.project) return;

    AppointedPartiesStore.showModal('AddTaskTeamMember');

    runInAction(() => {
      this.deliveryTeam = deliveryTeam;
      this.taskTeam = taskTeam;
      this.selectedUsers = [];
      this.isSaving = false;
    });

    const clientUsers = await getUsers(ProjectUserManagementStore.project.projectNumber, true);

    runInAction(() => {
      this.users = clientUsers.filter((u) => !taskTeam.users.find((t) => t.id === u.id));
    });
  }

  public getMatchedUsers(text: string): IUser[] {
    return getUsersByNameAndEmail(this.users, text);
  }

  public setSelectedUsers(users: IUser[]) {
    runInAction(() => {
      this.selectedUsers = users;
    });
  }

  public setIsApprover(value: boolean) {
    runInAction(() => {
      this.isApprover = value;
    });
  }

  public async save() {
    if (!ProjectUserManagementStore.project || !this.deliveryTeam || !this.taskTeam || this.selectedUsers.length === 0)
      return;

    runInAction(() => {
      this.isSaving = true;
    });

    try {
      await addUsersToTaskTeam({
        taskTeamId: this.taskTeam.id,
        projectNumber: ProjectUserManagementStore.project.projectNumber,
        userIds: this.selectedUsers.map((u) => u.id),
      });

      if (this.isApprover) {
        await addUsersToTaskTeamRole({
          taskTeamId: this.taskTeam.id,
          projectNumber: ProjectUserManagementStore.project.projectNumber,
          userIds: this.selectedUsers.map((u) => u.id),
          taskTeamRoleId: TaskTeamRole.Approver,
        });
      }

      LayoutStore.displayToast('success', 'User has been added successfully.');

      AppointedPartiesStore.loadAppointedPartiesUsers();
      AppointedPartiesStore.closeModal();
    } catch {
      AppointedPartiesStore.showModal('Error');
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  }
}

export default new AddTaskTeamMemberStore();
