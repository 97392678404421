import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';
import { FileFilter } from './FileFilterClass';
import { IFile } from './FileModel';

export async function getNonWipFiles(condition: FileFilter, abortSignal?: AbortSignal): Promise<IPagedResponse<IFile>> {
  const result = await serverAxios.get<{ files: IPagedResponse<IFile> }>('api/cms/nonwipfiles', {
    params: condition,
    signal: abortSignal,
  });
  return result.data?.files ?? [];
}
