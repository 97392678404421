import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/NavBarSelectorTaskTeam.module.scss';
import { TaskTeamItem } from '../../common/models/ItemType';
import NavBarSelectorItemContainer from './NavBarSelectorItemContainer';
import Icon from './Icon';

export interface INavBarSelectorTaskTeamProps {
  item: TaskTeamItem;
}

const NavBarSelectorTaskTeam: React.FC<INavBarSelectorTaskTeamProps> = ({ item }) => {
  return (
    <NavBarSelectorItemContainer className={Style.container}>
      <Icon className={Style.icon} name="people" />
      <span>{item.taskTeam.deliveryTeamTitle}</span>
      <span>|</span>
      <span className={Style.taskTeamTitle}>{item.taskTeam.title}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorTaskTeam);
