import { serverAxios } from '../api';

export interface IAddSupersedeFileRequest {
  taskTeamId: number;
  fileId: number;
  filename: string;
  totalFileSize: number;
  totalFileChunks: number;
  fileRevisionId?: number | null;
}

export interface IAddSupersedeFileResponse {
  fileUploadId: number;
  fileRevisionId: number;
}

export async function addSupersedeFile(
  request: IAddSupersedeFileRequest,
  abortSignal?: AbortSignal
): Promise<IAddSupersedeFileResponse> {
  const result = await serverAxios.post<IAddSupersedeFileResponse>('api/cms/supersede', request, {
    signal: abortSignal,
  });
  return result.data;
}
