import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TabContent } from '@aurecon-creative-technologies/styleguide';
import { TabStore } from './TabStore';
import TabContainer from './TabContainer';
import { TabFilter } from './Types';
import TasksStore from './TasksStore';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';

const Tasks: FC = () => {
  const labelCount = (prefix: string, store: TabStore) => {
    if (store.isLoadingTasks) return `${prefix} (loading)`;

    if (store.availableTaskCount === store.tasks.length) return `${prefix} (${store.tasks.length})`;

    return `${prefix} (${store.tasks.length} of ${store.availableTaskCount})`;
  };

  return (
    <>
      <PageHeading>Tasks</PageHeading>
      <PageTab
        defaultActiveTab={TasksStore.currentTab}
        onSelectTab={(id) => TasksStore.setCurrentTab(id)}
        tabs={[
          { id: TabFilter.AwaitingMyAction, label: labelCount('Awaiting My Action', TasksStore.awaitingMyActionStore) },
          { id: TabFilter.CreatedByMe, label: labelCount('Initiated By Me', TasksStore.createdByMeStore) },
          { id: TabFilter.AllPendingTasks, label: labelCount('All Pending Tasks', TasksStore.allPendingTasksStore) },
          { id: TabFilter.AllTasks, label: labelCount('All Tasks', TasksStore.allTasksStore) },
        ]}>
        <TabContent for={TabFilter.AwaitingMyAction}>
          <TabContainer store={TasksStore.awaitingMyActionStore} />
        </TabContent>
        <TabContent for={TabFilter.CreatedByMe}>
          <TabContainer store={TasksStore.createdByMeStore} />
        </TabContent>
        <TabContent for={TabFilter.AllPendingTasks}>
          <TabContainer store={TasksStore.allPendingTasksStore} />
        </TabContent>
        <TabContent for={TabFilter.AllTasks}>
          <TabContainer store={TasksStore.allTasksStore} />
        </TabContent>
      </PageTab>
    </>
  );
};

export default observer(Tasks);
