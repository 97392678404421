import React, { FC, useLayoutEffect, useState } from 'react';
import CentreOnPage from '../../../shared/CentreOnPage';
import CloseButton from '../../../shared/CloseButton';
import ModalActions from '../../../shared/ModalActions';
import Overlay from '../../../shared/Overlay';
import PrimaryButton from '../../../shared/PrimaryButton';
import Style from './styles/SupersedeModal.module.scss';
import Modal from '../../../shared/Modal';
import { UploadFileStatusEnum } from '../../../../common/enums/UploadFileStatus';
import SecondaryButton from '../../../shared/SecondaryButton';
import FileStatus from './FileStatus';
import { Checkbox, Loader, Table, TableCell, TableRow, Tooltip } from '@aurecon-creative-technologies/styleguide';
import NavBarSelectorStore from '../../navBarSelector/NavBarSelectorStore';
import Icon from '../../../shared/Icon';
import { dateTime12HFormat } from '../../../../utils/dateUtils';
import { classNames } from '../../../../utils/miscUtils';
import SearchBar from '../../../shared/SearchBar';
import { NavigationItemTypes } from '../../../../common/models/ItemType';
import FilesStore from '../../FilesStore';
import { IFile } from '../../../../api/authenticated/cms/FileModel';
import { getSupersedeFiles, IFileData, ISupersedeFile } from '../../../../api/authenticated/cms/getSupersedeFiles';

export interface ISupersedeFileModel {
  fileName: string;
  status: UploadFileStatusEnum;
  missingMetadata: boolean;
}

export interface ISelectedDuplicateFileModel extends IFileData {
  uploadedFileName: string;
  selectedSupersededFileId: number;
  selectedSupersededFileName: string;
  selectedAtPage: number;
}

interface ISupersedeModalProps {
  onClose: () => void;
  files: ISupersedeFileModel[];
  onConfirm: (selected: ISelectedDuplicateFileModel[]) => void;
}

const SupersedeModal: FC<ISupersedeModalProps> = (props) => {
  const { onClose, onConfirm, files } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFile, setCurrentFile] = useState<ISupersedeFileModel>(files[0]);
  const [selectedDuplicateFile, setSelectedDuplicateFile] = useState<number>(0);
  const [selectedDuplicateFiles, setSelectedDuplicateFiles] = useState<ISelectedDuplicateFileModel[]>([]);
  const [supersededFiles, setSupersededFiles] = useState<ISupersedeFile[]>([]);
  const [openSearchBar, setOpenSearchBar] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [searchSupersededFiles, setSearchSupersededFiles] = useState<ISupersedeFile[]>([]);
  const [loadingDuplicate, setLoadingDuplicate] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const isMultipleFilesSelected = files.length > 1;

  useLayoutEffect(() => {
    const searchDuplicateFiles = async () => {
      if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
      const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
      const file = files[currentPage - 1];
      setLoadingDuplicate(true);
      const results = await getSupersedeFiles(taskTeamId, file.fileName);
      setCurrentFile(file);
      setSupersededFiles(results);
      setLoadingDuplicate(false);
    };

    searchDuplicateFiles();
  }, [currentPage, files]);

  useLayoutEffect(() => {
    const searchDuplicateFiles = async () => {
      const file = files[currentPage - 1];
      if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
      const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
      setLoadingSearch(true);
      const results = await getSupersedeFiles(taskTeamId, file.fileName, searchKeyword);
      setSearchSupersededFiles(results);
      setLoadingSearch(false);
    };

    if (searchKeyword) {
      searchDuplicateFiles();
    }
  }, [searchKeyword, files, currentPage]);

  const handleConfirm = () => {
    onConfirm(selectedDuplicateFiles);
    onClose();
  };

  const handleSelectedSupersedeFile = (file: ISupersedeFile, checked: boolean) => {
    const selected = selectedDuplicateFiles.filter((f) => f.uploadedFileName != currentFile.fileName);
    if (checked) {
      selected.push({
        ...file,
        uploadedFileName: currentFile.fileName,
        selectedSupersededFileId: file.id,
        selectedSupersededFileName: file.originalFilename,
        selectedAtPage: currentPage,
        fileRevisionId: file.fileRevisionId,
      });
      setSelectedDuplicateFile(file.id);
    } else {
      setSelectedDuplicateFile(0);
    }

    setSelectedDuplicateFiles(selected);
  };

  const handleNext = () => {
    goNextPage();
  };

  const handleSkipAndNext = () => {
    const selected = selectedDuplicateFiles.filter((f) => f.uploadedFileName != currentFile.fileName);
    setSelectedDuplicateFiles(selected);
    goNextPage();
  };

  const handleSkipAndConfirm = () => {
    const selected = selectedDuplicateFiles.filter((f) => f.uploadedFileName != currentFile.fileName);
    setSelectedDuplicateFiles(selected);
    onConfirm(selected);
    onClose();
  };

  const goNextPage = () => {
    setCurrentPage((c) => c + 1);
    setSelectedDuplicateFile(0);
    setSearchKeyword('');
    setOpenSearchBar(false);
    setSearchSupersededFiles([]);
  };

  const renderPrimaryButton = () => {
    if (currentPage === files.length) {
      return (
        <PrimaryButton disabled={!selectedDuplicateFiles.length} onClick={handleConfirm}>
          Confirm
        </PrimaryButton>
      );
    }

    return (
      <PrimaryButton disabled={!selectedDuplicateFile} onClick={handleNext}>
        Next
      </PrimaryButton>
    );
  };

  const renderSecondaryButton = () => {
    if (currentPage === files.length) {
      return <SecondaryButton onClick={handleSkipAndConfirm}>Skip and Confirm</SecondaryButton>;
    }

    return <SecondaryButton onClick={handleSkipAndNext}>Skip and Next</SecondaryButton>;
  };

  const hasSelectedFile = (fileId: number) => {
    return selectedDuplicateFiles.some(
      (s) => s.selectedSupersededFileId === fileId && s.selectedAtPage !== currentPage
    );
  };

  const renderSupersedeSelectionTable = (supersedeFiles: ISupersedeFile[]) => {
    if (loadingDuplicate) return <Loader size="extra small" />;
    return (
      <Table
        cssClass={Style.filesTable}
        headers={[{ label: '' }, { label: 'Filename' }, { label: 'Uploaded' }, { label: 'Action' }]}
        hoverable>
        {supersedeFiles.map((f) => (
          <TableRow key={f.id}>
            <TableCell>
              <Checkbox
                onChange={(checked) => handleSelectedSupersedeFile(f, checked)}
                checked={f.id === selectedDuplicateFile}
                disabled={f.isLocked || hasSelectedFile(f.id)}
              />
            </TableCell>
            <TableCell>
              <div className={Style.filenameCell}>
                <span className={classNames([f.isLocked || hasSelectedFile(f.id), Style.lockedContent])}>
                  {f.originalFilename}
                </span>
                {f.isLocked && <Icon className={Style.icon} name="lock" />}
                {hasSelectedFile(f.id) && (
                  <Tooltip show="Currently the file is ready for supersede" defaultUp>
                    <Icon className={Style.icon} outlined name="info" />
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell>
              <span className={classNames([f.isLocked || hasSelectedFile(f.id), Style.lockedContent])}>
                {dateTime12HFormat(f.uploadedDate)}
              </span>
            </TableCell>
            <TableCell>
              <div
                onClick={() =>
                  FilesStore.downloadFile({
                    id: f.id,
                    revision: f.fileRevisionId,
                    releasedFileId: f.releasedFileId,
                  } as IFile)
                }>
                <Icon className={Style.previewIcon} name="open_in_new" />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    );
  };

  const renderSearchArea = () => {
    return (
      <div className={Style.searchContentWrapper}>
        <p>
          Search from content Files?{' '}
          {!openSearchBar && (
            <>
              Click <a onClick={() => setOpenSearchBar(true)}>here</a>
            </>
          )}
        </p>

        {openSearchBar && (
          <>
            <SearchBar
              searchValue={searchKeyword}
              onSearch={(keyword) => setSearchKeyword(keyword)}
              placeHolderText="Search for filename, original filename"
            />

            {loadingSearch ? (
              <Loader size="extra small" />
            ) : (
              <>
                {searchKeyword && !searchSupersededFiles.length && (
                  <div className={Style.searchMessage}>There are no results that match your search.</div>
                )}

                {searchKeyword &&
                  !!searchSupersededFiles.length &&
                  renderSupersedeSelectionTable(searchSupersededFiles)}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const renderModalContent = () => {
    return (
      <>
        {currentFile && (
          <>
            {isMultipleFilesSelected && (
              <p>
                {currentPage} of {files.length}
              </p>
            )}
            <h1>{isMultipleFilesSelected ? 'Supersede Files' : 'Supersede File'}</h1>
            <p>
              A file with similar name already exists. Choose a file you would like to supersede and overwrite existing
              file data. Skipping supersede will allocate a unique name to the file you want to upload.
            </p>
            <div className={Style.fileWrapper}>
              <span>{currentFile.fileName}</span>
              <div className={Style.statusWrapper}>
                <FileStatus
                  missingMetadata={currentFile.missingMetadata}
                  status={currentFile.status}
                  missingFileExtension={false}
                />
              </div>
            </div>

            <p>Choose files to supersede:</p>
            {renderSupersedeSelectionTable(supersededFiles)}
            {renderSearchArea()}
          </>
        )}
      </>
    );
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.supersedeModal}>
        <CloseButton onClick={onClose} />
        {renderModalContent()}
        <ModalActions>
          {renderSecondaryButton()}
          {renderPrimaryButton()}
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default SupersedeModal;
