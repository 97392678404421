import { serverAxios } from '../api';
import { IAppointingParty, IDeliveryTeam, ITaskTeam } from '../um/getProjectMembersAndTeams';

export interface ICreateTransmittal {
  projectNumber: string;
  type: number;
  reason: number;
  message: string;
  subject: string;
  dueDate: Date | null;
  initiatorTaskTeamId: number | null;
  notifyToUsers: IUserDetail[];
  visibleToTaskTeamUsers: IUserDetail[];
  visibleToTaskTeams: ITaskTeam[] | number[];
  visibleToDeliveryTeams: IDeliveryTeam[] | number[];
  visibleToAppointingParties: IAppointingParty[] | number[];
  ContentFileIds?: number[];
  draft: boolean;
  transmittalId?: number;
  transmittalMessageId?: number;
  transmittalTitle?: string;
}

export interface IUserDetail {
  userId: number;
  name: string;
  email: string;
  taskTeamId: number;
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  taskTeamTitle: string;
  taskTeamCode: string;
  isExternal: boolean;
}

export interface ICreateTransmittalResult {
  transmittalTitle: string;
  transmittalId: number;
  transmittalMessageId: number;
}

export async function createTransmittal(
  request: ICreateTransmittal,
  abortSignal?: AbortSignal
): Promise<ICreateTransmittalResult> {
  const result = await serverAxios.post<ICreateTransmittalResult>(`api/transmittals/createTransmittal`, request, {
    signal: abortSignal,
  });
  return result.data;
}
