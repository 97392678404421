import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ComboBox } from '@aurecon-creative-technologies/styleguide';
import { IListFilter } from './TableColumn';
import Style from './styles/ListFilter.module.scss';

export interface IListFilterProps {
  listFilter: IListFilter;
  disabled?: boolean;
  onSelectItem?: (value?: string) => void;
}

const ListFilter: FC<IListFilterProps> = ({ listFilter, disabled, onSelectItem }) => {
  const [selected, setSelected] = useState('');

  const handleOnChange = (val) => {
    setSelected(val);
    onSelectItem && onSelectItem(val);
  };

  const handleOnClear = () => {
    setSelected('');
    onSelectItem && onSelectItem();
  };

  return (
    <ComboBox
      cssClass={Style.list}
      placeholder="-"
      options={listFilter.fieldValues.map((v) => ({ id: v, value: v || '' }))}
      selected={selected}
      showIcon={false}
      onSelect={(value) => handleOnChange(value?.id.toString())}
      onClear={handleOnClear}
      disabled={disabled}></ComboBox>
  );
};

export default observer(ListFilter);
