import { makeAutoObservable, runInAction } from 'mobx';
import { getProjects, IProject } from '../../api/authenticated/config/getProjects';

export class ProjectInformationStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public project: IProject | null = null;

  public init(projectNumber) {
    runInAction(async () => {
      const projects = await getProjects(projectNumber);
      if (projects.length > 0) {
        runInAction(() => {
          this.project = projects[0];
        });
      }
    });
  }
}

export default new ProjectInformationStore();
