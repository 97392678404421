import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import GoBackButton from '../shared/GoBackButton';
import PageHeading from '../shared/PageHeading';
import ProjectInformationStore from './ProjectInformationStore';
import Style from './styles/ProjectInformation.module.scss';
import { IProject } from '../../api/authenticated/config/getProjects';
import { formatDate } from '../../utils/dateUtils';

export interface IProjectInformation {
  project: IProject;
}

const ProjectInformation: FC = () => {
  const params = useParams();
  const project = ProjectInformationStore.project;
  useEffect(() => {
    ProjectInformationStore.init(params.projectNumber);
  }, [params]);

  return (
    <>
      {project ? (
        <>
          <div className={Style.contentHeading}>
            <GoBackButton />
            <PageHeading subPage>{project?.title}</PageHeading>
          </div>
          <div className={Style.contentBody}>
            <ul className={Style.panel}>
              <li className={Style.halfRow}>
                <div className={Style.labelText}>Project Number</div>
                <div className={Style.contentText}>{project.projectNumber}</div>
              </li>
              <li className={Style.halfRow}>
                <div className={Style.labelText}>Programme</div>
                <div className={Style.contentText}>{project.programmeTitle}</div>
              </li>
              <li className={Style.halfRow}>
                <div className={Style.labelText}>Project Phase</div>
                <div className={Style.contentText}></div>
              </li>
              <li className={Style.halfRow}>
                <div className={Style.labelText}>Project Owner</div>
                <div className={Style.contentText}>{project.projectOwnerName}</div>
              </li>
              <li className={Style.fullRow}>
                <div className={Style.labelText}>Description</div>
                <div className={Style.contentText}>{project.description}</div>
              </li>
              <li className={Style.halfRow}>
                <div className={Style.labelText}>Start Date</div>
                <div className={Style.contentText}>{project.startDate ? formatDate(project.startDate) : ''}</div>
              </li>
              <li className={Style.halfRow}>
                <div className={Style.labelText}>End Date</div>
                <div className={Style.contentText}>{project.endDate ? formatDate(project.endDate) : ''}</div>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <div className={Style.contentBody}>
          <div>Project not found</div>
        </div>
      )}
    </>
  );
};

export default observer(ProjectInformation);
