import { serverAxios } from '../api';

export interface ITransmittalSelectedTabFilter {
  projectNumber: string | null;
  notificationToMe?: boolean;
  initiatedByMe?: boolean;
  visibleToMe?: boolean;
}
export interface ITransmittalDetails {
  id: number;
  ProjectId: number;
  title: string;
  transmittalTypeTitle: string;
  transmittalReasonTitle: string;
  closed: boolean;
  closedDate: Date | null;
  dueDate: Date | null;
  createdByEmail: string;
  createdByName: string;
  createdDate: Date;
  lastUpdated: Date | null;
  projectNumber: string;
  flagged: boolean;
  totalAttachmentFiles: number;
  totalReleasedFiles: number;
  totalMessage: number;
  lastMessage: string;
  lastSubject: string;
  notifyToUserNames: string;
  visibleUserNames: string;
  overdue: boolean;
}

export async function getAllTransmittals(
  options: ITransmittalSelectedTabFilter,
  abortSignal?: AbortSignal
): Promise<ITransmittalDetails[]> {
  const result = await serverAxios.get<{
    transmittal: ITransmittalDetails[];
  }>('api/transmittals/getAllTransmittals', {
    params: options,
    signal: abortSignal,
  });
  return result.data.transmittal;
}
