import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ProjectUserManagementStore from '../ProjectUserManagementStore';
import AppointedPartiesStore from './AppointedPartiesStore';
import Style from './styles/AppointedParties.module.scss';
import ErrorModal from '../../shared/ErrorModal';
import SecondaryButton from '../../shared/SecondaryButton';
import SearchBar from '../../shared/SearchBar';
import AppointedPartiesUserTable from './AppointedPartiesUserTable';
import { Button } from '@aurecon-creative-technologies/styleguide';
import TeamSettings from './teamSettings/TeamSettings';
import DeleteTaskTeamUserModal from './DeleteTaskTeamUserModal';
import DeleteDeliveryTeamUserModal from './DeleteDeliveryTeamUserModal';
import TeamSettingsStore from './teamSettings/TeamSettingsStore';
import AddDeliveryTeamAuthoriserModal from './AddDeliveryTeamAuthoriserModal';
import AddTaskTeamMemberModal from './AddTaskTeamMemberModal';

const AppointedParties: FC = () => {
  const projectNumber = ProjectUserManagementStore.project?.projectNumber;

  useEffect(() => {
    const asyncFunc = async () => {
      AppointedPartiesStore.setSearchText('');
      await AppointedPartiesStore.loadAppointedPartiesUsers();
      AppointedPartiesStore.openFirstPanel();
    };

    asyncFunc();
  }, [projectNumber]);

  return (
    <div className={Style.container}>
      <div className={Style.subContainer}>
        <h2 className={Style.subPageHeader}>User List</h2>
        <div className={Style.searchBar}>
          {AppointedPartiesStore.appointedPartiesUsers.length > 0 && (
            <>
              <SecondaryButton className={Style.teamSettingsButton} onClick={TeamSettingsStore.show}>
                <div>Team Settings</div>
              </SecondaryButton>
              <Button type="text" label="Expand All" onClick={AppointedPartiesStore.expandAll} /> /{' '}
              <Button type="text" label="Collapse All" onClick={AppointedPartiesStore.collapseAll} />
            </>
          )}
          <div className={Style.space} />
          <SearchBar
            placeHolderText="Search for user name, user email"
            onSearch={AppointedPartiesStore.setSearchText}
          />
        </div>
        {AppointedPartiesStore.appointedPartiesUsers.length > 0 && <AppointedPartiesUserTable />}
        {AppointedPartiesStore.appointedPartiesUsers.length === 0 && (
          <div className={Style.noTeamsContainer}>
            <div className={Style.noTeamsText}>There are no teams available.</div>
            <SecondaryButton className={Style.teamSettingsButton} onClick={TeamSettingsStore.show}>
              <div>Team Settings</div>
            </SecondaryButton>
          </div>
        )}
      </div>
      {AppointedPartiesStore.showingModal === 'Error' && <ErrorModal closeModal={AppointedPartiesStore.closeModal} />}
      {AppointedPartiesStore.showingModal === 'TeamSettings' && <TeamSettings />}
      {AppointedPartiesStore.showingModal === 'AddDeliveryTeamAuthoriser' && <AddDeliveryTeamAuthoriserModal />}
      {AppointedPartiesStore.showingModal === 'AddTaskTeamMember' && <AddTaskTeamMemberModal />}
      {AppointedPartiesStore.showingModal === 'RemoveDeliveryTeamAuthoriser' && <DeleteDeliveryTeamUserModal />}
      {AppointedPartiesStore.showingModal === 'RemoveTaskTeamMember' && <DeleteTaskTeamUserModal />}
    </div>
  );
};

export default observer(AppointedParties);
