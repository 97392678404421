import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TableCell } from '@aurecon-creative-technologies/styleguide';
import DropDownMenu from './DropDownMenu';
import Style from './styles/DropDownMenuForTableCell.module.scss';

const DropDownMenuForTableCell: FC = ({ children }) => {
  return (
    <TableCell cellClass={Style.tableCell}>
      <div className={Style.cell}>
        <DropDownMenu icon="more_vert" iconClassName={Style.icon} itemsContainerClassName={Style.itemsContainer}>
          {children}
        </DropDownMenu>
      </div>
    </TableCell>
  );
};

export default observer(DropDownMenuForTableCell);
