import React from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/NavBarSelectorItemContainer.module.scss';
import { classNames } from '../../utils/miscUtils';

export interface INavBarSelectorItemContainerProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const NavBarSelectorItemContainer: React.FC<INavBarSelectorItemContainerProps> = ({ className, onClick, children }) => {
  return (
    <span className={classNames(Style.container, className)} onClick={onClick}>
      {children}
    </span>
  );
};

export default observer(NavBarSelectorItemContainer);
