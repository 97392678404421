import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchBox, Pill, Icon } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/AddTransmittalMessageNotifySelector.module.scss';
import { IUser } from '../../../api/authenticated/um/getProjectMembersAndTeams';

export interface IItemSelectorProps {
  label?: string;
  required?: boolean;
  searchPlaceholder: string;
  disabled?: boolean;
  isMultiUser?: boolean;
  selectedItems?: IUser[];
  getUsers(text: string): IUser[];
  onSelectedUserUpdated(setSelectedItems: IUser[]): void;
}

const AddTransmittalMessageNotifySelector: FC<IItemSelectorProps> = ({
  label,
  required,
  searchPlaceholder,
  disabled,
  isMultiUser,
  selectedItems,
  getUsers,
  onSelectedUserUpdated,
}) => {
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>(selectedItems ?? []);

  useEffect(() => {
    if (selectedItems) {
      setSelectedUsers(selectedItems);
    }
  }, [selectedItems]);

  const removeSelectedUser = (id: number) => {
    if (disabled) return;

    const updatedSelectedUsers = selectedUsers.filter((u) => u.userId !== id);
    setSelectedUsers(updatedSelectedUsers);
    onSelectedUserUpdated(updatedSelectedUsers);

    if (updatedSelectedUsers.length === 0 && disableSearchBox) setDisableSearchBox(false);
  };

  const addSelectedUser = (id: string) => {
    if (disabled) return;

    const userId = Number(id.split('/')[0]);
    const taskTeamId = Number(id.split('/')[1]);
    const deliveryTeamId = Number(id.split('/')[2]);
    const user = users.find(
      (u) => u.userId === userId && u.taskTeamId === taskTeamId && u.deliveryTeamId === deliveryTeamId
    );
    const exists = selectedUsers.find((u) => u.userId === userId);
    if (!user || exists) return;
    const updatedSelectedUsers = [...selectedUsers, user];
    setSelectedUsers(updatedSelectedUsers);
    onSelectedUserUpdated(updatedSelectedUsers);

    setUsers([]);
    setTriggerCleanup(triggerCleanup + 1);

    if (!isMultiUser) setDisableSearchBox(true);
  };

  const loadUsers = (text: string) => {
    if (disabled) return;
    if (text === '') setUsers([]);
    else {
      setUsers(getUsers(text));
    }
  };

  const filteredItems = useMemo(() => {
    return users
      .filter(
        (user) =>
          !selectedUsers.find(({ userId, taskTeamId, deliveryTeamId }) => {
            return user.userId === userId && user.taskTeamId === taskTeamId && user.deliveryTeamId === deliveryTeamId;
          })
      )
      .map((r) => ({
        id: `${r.userId}/${r.taskTeamId}/${r.deliveryTeamId}`,
        value: `${r.userName} (${r.userEmail})`,
        display: (
          <>
            <span>
              {r.userName} ({r.userEmail})
            </span>
            <span className={Style.userIcon}>
              <Icon type="people" />
              {r.isExternal && <b>External User</b>}
              {!r.isExternal && (
                <>
                  {r.deliveryTeamTitle} | <b>{r.taskTeamTitle}</b>
                </>
              )}
            </span>
          </>
        ),
      }));
  }, [selectedUsers, users]);

  return (
    <>
      {label && (
        <label className={Style.label}>
          {label}
          {required && <span className={Style.required}>*</span>}
        </label>
      )}
      <div className={Style.searchBoxContainer}>
        {selectedUsers.map((i) => (
          <Pill
            key={`${i.userId}/${i.taskTeamId}/${i.deliveryTeamId}`}
            colour={1}
            onClose={() => removeSelectedUser(i.userId)}
            cssClass={Style.userPill}>
            {i.userName} <Icon type="people" />
            <b>{i.isExternal ? 'Ext.' : i.taskTeamCode}</b>
          </Pill>
        ))}
        <SearchBox
          placeholder={searchPlaceholder}
          hideSearchButton
          disableDefaultMatching
          suggestions={filteredItems}
          onChange={loadUsers}
          onSearch={loadUsers}
          onSelect={(s) => addSelectedUser(String(s.id))}
          triggerCleanup={triggerCleanup}
          disabled={disabled || disableSearchBox}
        />
      </div>
    </>
  );
};

export default observer(AddTransmittalMessageNotifySelector);
