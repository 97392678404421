export enum UploadFileStatusEnum {
  Duplicate = 1,
  Supersede = 2,
  Ready = 3,
  MissingMetadata = 4,
  MissingFileExtension = 5,
}

export const UploadFileStatusText = {
  [UploadFileStatusEnum.Duplicate]: 'Duplicate',
  [UploadFileStatusEnum.Supersede]: 'Ready for Supersede',
  [UploadFileStatusEnum.Ready]: 'Ready for Upload',
  [UploadFileStatusEnum.MissingMetadata]: 'Missing Metadata',
  [UploadFileStatusEnum.MissingFileExtension]: 'Missing File Extension',
};
