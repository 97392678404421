import { TaskType } from '../../../common/enums/TaskType';
import { serverAxios } from '../api';

export interface IAddTaskFile {
  fileId: number;
  releasedFileId: null | number;
}

export interface IAddTaskRequest {
  taskTeamId: number;
  taskType: TaskType;
  suitabilityId: number;
  isSingleApproverRequired: boolean;
  message: string;
  files: IAddTaskFile[];
  reviewerUserIds: number[];
}

export async function addTask(request: IAddTaskRequest, abortSignal?: AbortSignal): Promise<number> {
  const result = await serverAxios.post<number>('api/task/add', request, { signal: abortSignal });
  return result.data;
}
