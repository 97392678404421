import { AxiosError } from 'axios';
import { uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  addTransmittalMessage,
  IAddTransmittalMessage,
} from '../../../api/authenticated/transmittals/addTransmittalMessage';
import { addTransmittalMessageAttachmentFile } from '../../../api/authenticated/transmittals/addTransmittalMessageAttachmentFile';
import { createTransmittal, ICreateTransmittal } from '../../../api/authenticated/transmittals/createTransmittal';
import { uploadTransmittalMessageAttachmentFiles } from '../../../api/authenticated/transmittals/uploadTransmittalMessageAttachmentFiles';
import {
  IFileNameValidationItem,
  validationUploadSupportFilenames,
} from '../../../api/authenticated/transmittals/validationUploadSupportFilenames';
import { IChunk, IUploadFile } from '../../../common/interfaces/fileUpload';
import getFileExtension from '../../../utils/fileUtils';
import { calculateChunks, fileSizeUnits } from '../../../utils/miscUtils';
import AddTransmittalMessageStore from '../../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageStore';
import CreateTransmittalStore from '../CreateTransmittalStore';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { SupportFileMode } from '../Types';

export interface FileItem extends File {
  duplicated: boolean;
}

export class SupportingFilesUploadStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private chunkSize = 3000000;
  public isCheckingDuplicated = false;
  public isSearchedOrFiltered = false;
  public filesSizeExceededTheLimit = false;
  public isDuplicatedFiles = false;
  public missingFileExtension = false;
  public duplicatedFiles: File[] = [];
  public missingExtensionFiles: File[] = [];
  public selectedFiles: FileItem[] = [];
  public showProgressBar = false;
  private fileChunks: { [filename: string]: IUploadFile } = {};
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public totalChunks = 0;
  public totalUploadedChunks = 0;
  public percentageUploaded = 0;
  public openUploadError = false;
  public isProcessing = false;

  public projectNumber?: string;
  public createTransmittalPayload?: ICreateTransmittal | IAddTransmittalMessage | null = null;
  public transmittalId?: number;
  public supportFileMode = SupportFileMode.CreateResponse;
  // Limit 1GB
  public limitBytes = fileSizeUnits.GB;
  public errorMessage?: string;

  public async initialUpload(
    supportFileMode: SupportFileMode,
    createTransmittalPayload?: ICreateTransmittal | IAddTransmittalMessage | null,
    projectNumber?: string,
    transmittalId?: number
  ) {
    this.clear();
    runInAction(() => {
      this.supportFileMode = supportFileMode;
      this.createTransmittalPayload = createTransmittalPayload;
      this.projectNumber = projectNumber;
      this.transmittalId = transmittalId;
    });
  }

  public clear() {
    runInAction(() => {
      this.showProgressBar = false;
      this.selectedFiles = [];
      this.filesSizeExceededTheLimit = false;
      this.isDuplicatedFiles = false;
      this.missingFileExtension = false;
      this.totalChunks = 0;
      this.totalUploadedChunks = 0;
      this.percentageUploaded = 0;
      this.openUploadError = false;
      this.supportFileMode = SupportFileMode.CreateResponse;
      this.errorMessage = undefined;
      this.missingExtensionFiles = [];
    });
  }

  public setOpenUploadError(open: boolean) {
    this.openUploadError = open;
  }

  public setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  public async addFiles(files: FileItem[]) {
    runInAction(() => {
      this.isProcessing = true;
    });
    try {
      const exceedLimitation =
        this.sizeOfUploadFiles(this.selectedFiles) + this.sizeOfUploadFiles(files) > this.limitBytes;

      const duplicatedFiles = files.filter((f) => this.selectedFiles.some((nf) => nf.name === f.name));

      const missingExtensionFiles = files.filter((f) => !f.type && !getFileExtension(f.name));

      runInAction(async () => {
        this.openUploadError = exceedLimitation || !!duplicatedFiles.length || !!missingExtensionFiles.length;
        this.duplicatedFiles = duplicatedFiles;
        this.missingExtensionFiles = missingExtensionFiles;
        this.missingFileExtension = !!missingExtensionFiles.length;
        this.filesSizeExceededTheLimit = exceedLimitation;
        this.isDuplicatedFiles = !!duplicatedFiles.length;
        if (!exceedLimitation || (exceedLimitation && duplicatedFiles.length)) {
          this.selectedFiles = uniqBy(
            [...this.selectedFiles, ...files.filter((f) => duplicatedFiles.findIndex((df) => df.name === f.name) < 0)],
            'name'
          );
        }
        if (missingExtensionFiles.length) {
          this.selectedFiles = this.selectedFiles.filter(
            (f) => missingExtensionFiles.findIndex((df) => df.name === f.name) < 0
          );
        }
      });

      if (exceedLimitation || duplicatedFiles.length || missingExtensionFiles.length) return;
      await this.validationUploadFiles();
    } finally {
      setTimeout(() => {
        runInAction(() => {
          this.isProcessing = false;
        });
      }, 500);
    }
  }

  public get uploadFiles() {
    return this.selectedFiles;
  }

  public removeFile(filename: string) {
    const index = this.selectedFiles.findIndex((f) => f.name === filename);
    if (index > -1) {
      runInAction(() => {
        this.selectedFiles.splice(index, 1);
      });
    }
  }

  private startUploadProcess() {
    runInAction(() => {
      this.showProgressBar = true;
      this.fileChunks = {};
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  private async createDraftTransmittal() {
    if (
      !this.createTransmittalPayload ||
      this.createTransmittalPayload?.transmittalId ||
      this.createTransmittalPayload?.transmittalMessageId
    )
      return;

    this.createTransmittalPayload.draft = true;
    const payload = this.createTransmittalPayload as ICreateTransmittal;
    try {
      const result = await createTransmittal({
        ...payload,
        visibleToTaskTeams: payload.visibleToTaskTeams.map((t) => t.taskTeamId),
        visibleToDeliveryTeams: payload.visibleToDeliveryTeams.map((t) => t.deliveryTeamId),
        visibleToAppointingParties: payload.visibleToAppointingParties.map((t) => t.appointingPartyId),
      });
      runInAction(() => {
        if (!this.createTransmittalPayload) return;

        this.createTransmittalPayload.transmittalId = result.transmittalId;
        this.createTransmittalPayload.transmittalTitle = result.transmittalTitle;
        this.createTransmittalPayload.transmittalMessageId = result.transmittalMessageId;

        this.errorMessage = undefined;
      });
      CreateTransmittalStore.setDraftInfo(result.transmittalTitle, result.transmittalId, result.transmittalMessageId);
    } catch (errors) {
      runInAction(() => {
        this.errorMessage =
          (errors as AxiosError<string>)?.response?.data ?? 'Transmittal cannot be created draft transmittal.';
      });
    }
  }

  private async addDraftTransmittalResponse() {
    if (
      !this.createTransmittalPayload ||
      (this.createTransmittalPayload?.transmittalId && this.createTransmittalPayload?.transmittalMessageId)
    )
      return;
    try {
      const createResponsePayload = { ...this.createTransmittalPayload, draft: true } as IAddTransmittalMessage;
      const result = await addTransmittalMessage(createResponsePayload);
      runInAction(() => {
        if (!this.createTransmittalPayload) return;

        this.createTransmittalPayload.transmittalId = result.transmittalId;
        this.createTransmittalPayload.transmittalMessageId = result.transmittalMessageId;
        this.errorMessage = undefined;
      });
      AddTransmittalMessageStore.setDraftInfo(result.transmittalMessageId);
    } catch (errors) {
      this.errorMessage =
        (errors as AxiosError<string>)?.response?.data ?? 'Transmittal cannot be created draft transmittal response.';
    }
  }

  public async handleUploadSupportingFiles() {
    if (this.selectedFiles.length) {
      if (this.supportFileMode === SupportFileMode.CreateTransmittal) {
        await this.createDraftTransmittal();
      } else {
        // create draft message only
        await this.addDraftTransmittalResponse();
      }

      if (this.errorMessage) return;

      this.startUploadProcess();
      this.totalChunks = 0;
      this.selectedFiles.forEach((file) => {
        this.setCalculateFileChunks(file);
        this.totalChunks += this.fileChunks[file.name].chunks.length;
      });

      await Promise.all(
        this.selectedFiles.map(async (file) => {
          await this.uploadFile(file);
        })
      );

      if (!this.filesFailedToUpload.length) {
        runInAction(() => {
          this.showUploadSuccess = true;
        });
      } else {
        runInAction(() => {
          this.showUploadFailed = true;
        });
      }
    }
  }

  public get filesFailedToUpload() {
    const failedFiles: string[] = [];
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      if (uploadFile.failed) failedFiles.push(filename);
    });
    return failedFiles;
  }

  private setCalculateFileChunks(file: FileItem) {
    runInAction(() => {
      this.fileChunks[file.name] = {
        numberOfChunksUploaded: 0,
        failed: false,
        chunks: calculateChunks(file, this.chunkSize),
      };
    });
  }

  private async uploadFile(file: FileItem) {
    if (
      !this.projectNumber ||
      !this.createTransmittalPayload?.transmittalId ||
      !this.createTransmittalPayload?.transmittalMessageId
    )
      return;
    try {
      const result = await this.addFile(
        this.projectNumber,
        this.createTransmittalPayload.transmittalId,
        this.createTransmittalPayload.transmittalTitle || '',
        this.createTransmittalPayload.transmittalMessageId,
        file,
        this.fileChunks[file.name].chunks
      );
      await this.uploadChunks(
        this.projectNumber,
        this.createTransmittalPayload.transmittalId,
        this.createTransmittalPayload.transmittalMessageId,
        file,
        this.fileChunks[file.name],
        result.attachmentFileUploadId
      );
    } catch (err) {
      runInAction(() => {
        this.fileChunks[file.name].failed = true;
      });
    }
  }

  private addFile(
    projectNumber: string,
    transmittalId: number,
    transmittalTitle: string,
    transmittalMessageId: number,
    file: FileItem,
    chunks: IChunk[]
  ) {
    return addTransmittalMessageAttachmentFile({
      projectNumber: projectNumber,
      transmittalId,
      transmittalTitle,
      transmittalMessageId,
      filename: file.name,
      totalFileSize: file.size,
      totalFileChunks: chunks.length,
    });
  }

  private async uploadChunks(
    projectNumber: string,
    transmittalId: number,
    transmittalMessageId: number,
    file: File,
    uploadFile: IUploadFile,
    fileUploadId: number
  ) {
    for (const chunk of uploadFile.chunks) {
      const blob = file.slice(chunk.start, chunk.end);
      const isLast = chunk.index === uploadFile.chunks.length - 1;
      await uploadTransmittalMessageAttachmentFiles({
        blob: blob,
        projectNumber: projectNumber,
        transmittalId: transmittalId,
        transmittalMessageId: transmittalMessageId,
        attachmentFileUploadId: fileUploadId,
        index: chunk.index,
        offset: chunk.start,
        isLastChunk: isLast,
      });

      runInAction(() => {
        uploadFile.numberOfChunksUploaded++;
        // calc percentage of files upload
        this.totalUploadedChunks += 1;
        this.percentageUploaded = (this.totalUploadedChunks / this.totalChunks) * 100;
      });
    }
  }

  public handleFileListChange(files: FileItem[]) {
    runInAction(() => {
      this.selectedFiles = [];
    });
    this.addFiles(files);
  }

  public async validationUploadFiles() {
    if (!NavBarSelectorStore.selectedItem?.project) return;

    let duplicatedCheckingResults: IFileNameValidationItem[] = [];
    if (
      !this.filesSizeExceededTheLimit &&
      this.createTransmittalPayload?.transmittalId &&
      this.createTransmittalPayload?.transmittalMessageId
    ) {
      await runInAction(() => (this.isCheckingDuplicated = false));
      duplicatedCheckingResults = await validationUploadSupportFilenames({
        projectNumber: NavBarSelectorStore.selectedItem?.project.projectNumber,
        transmittalId: this.createTransmittalPayload.transmittalId,
        transmittalMessageId: this.createTransmittalPayload.transmittalMessageId,
        filenames: [...this.selectedFiles.map((x) => x.name)],
      });
    }

    const duplicatedFiles = duplicatedCheckingResults.filter((x) => x.duplicated);
    const missingExtensionFiles = this.selectedFiles.filter((f) => !f.type && !getFileExtension(f.name));
    runInAction(() => {
      this.missingExtensionFiles = missingExtensionFiles;
      this.duplicatedFiles = this.selectedFiles.filter((f) => duplicatedFiles.some((df) => df.filename === f.name));
      this.selectedFiles = this.selectedFiles.filter(
        (f) => duplicatedFiles.findIndex((df) => df.filename === f.name) < 0
      );
      if (missingExtensionFiles.length)
        this.selectedFiles = this.selectedFiles.filter(
          (f) => missingExtensionFiles.findIndex((df) => df.name === f.name) < 0
        );
      this.missingFileExtension = !!missingExtensionFiles.length;
      this.isDuplicatedFiles = !!duplicatedFiles.length;
      this.openUploadError = this.isDuplicatedFiles || this.filesSizeExceededTheLimit || this.missingFileExtension;
      this.isCheckingDuplicated = false;
    });
  }

  /**
   * Get size of upload support files.
   * @returns number
   */
  public sizeOfUploadFiles(files: File[]) {
    return files.reduce((size, file) => size + file.size, 0);
  }
}

export default new SupportingFilesUploadStore();
