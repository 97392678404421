import { orderBy } from 'lodash';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { getProjectFileDocumentViewerUrl } from '../../api/authenticated/cms/getProjectFileViewerUrl';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import { completeTask } from '../../api/authenticated/tasks/completeTask';
import { getTaskHistory, IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import { getTask, ITask, ITaskFile } from '../../api/authenticated/tasks/getTask';

class TaskInformationStore {
  constructor() {
    makeAutoObservable(this, { anyTaskFilesBusy: computed, taskFilesSize: computed }, { autoBind: true });
  }

  public taskId = 0;
  public isLoadingTask = false;
  public isRefresh = false;
  public task: ITask | null = null;
  public message: string | null = null;
  public isLoadingHistory = false;
  public historyItems: IHistoryItem[] = [];
  public taskFilesBusy: { [filedId: number]: boolean } = {};
  public urn = '';
  public token = '';
  public isLoading = false;
  public hasLoaded = false;

  public init(taskId: number) {
    runInAction(() => {
      this.taskId = taskId;
      this.task = null;
      this.message = null;
      this.historyItems = [];
    });
    this.loadTask();
    this.loadHistory();
  }

  public async loadTask() {
    runInAction(() => {
      this.isLoadingTask = true;
    });
    try {
      const task = await getTask(this.taskId);
      runInAction(() => {
        this.task = task;
      });
    } finally {
      runInAction(() => {
        this.isLoadingTask = false;
      });
    }
  }

  public async loadHistory() {
    runInAction(() => {
      this.isLoadingHistory = true;
    });
    try {
      const historyItems = await getTaskHistory(this.taskId);
      runInAction(() => {
        this.historyItems = orderBy(historyItems, (item) => item.dateTime, 'desc');
      });
    } finally {
      runInAction(() => {
        this.isLoadingHistory = false;
      });
    }
  }

  public setMessage(value: string) {
    runInAction(() => {
      this.message = value;
    });
  }

  public async approveTask() {
    if (!this.task) return;
    await completeTask(this.task.id, this.message, true);
    this.refreshTask();
  }

  public async rejectTask() {
    if (!this.task) return;
    await completeTask(this.task.id, this.message, false);
    this.refreshTask();
  }

  private async refreshTask() {
    if (!this.task) return;
    this.loadTask();
    this.loadHistory();
    this.setIsRefresh(true);
  }

  public setIsRefresh(refresh: boolean) {
    runInAction(() => {
      this.isRefresh = refresh;
    });
  }

  public async downloadTaskFile(file: ITaskFile) {
    if (this.taskFilesBusy[file.fileId]) return;

    runInAction(() => {
      this.taskFilesBusy[file.fileId] = true;
    });

    try {
      const url = await getProjectFileDownloadUrl(
        this.task!.projectNumber,
        file.fileId,
        file.fileRevisionId,
        file.releasedFileId,
        null,
        null
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.taskFilesBusy[file.fileId] = false;
      });
    }
  }

  public async downloadAllTaskFiles() {
    if (!this.task || this.anyTaskFilesBusy) return;
    await Promise.all(this.task.taskFiles.map((taskFile) => this.downloadTaskFile(taskFile)));
  }
  public get taskFilesSize(): number {
    if (!this.task || this.anyTaskFilesBusy) return 0;
    return this.task.taskFiles.reduce((total, file) => total + file.fileSize, 0);
  }

  public async openTaskFile(file: ITaskFile) {
    if (this.taskFilesBusy[file.fileId]) return;

    runInAction(() => {
      this.taskFilesBusy[file.fileId] = true;
    });

    try {
      const url = await getProjectFileDocumentViewerUrl(
        this.task!.projectNumber,
        file.fileId,
        file.fileRevisionId,
        file.releasedFileId,
        null,
        null
      );
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.taskFilesBusy[file.fileId] = false;
      });
    }
  }

  public get anyTaskFilesBusy() {
    if (!this.task) return;
    for (const file of this.task.taskFiles) {
      if (this.taskFilesBusy[file.fileId]) return true;
    }
    return false;
  }

  public setTaskFileBusy(fileId: number, isBusy: boolean) {
    this.taskFilesBusy[fileId] = isBusy;
  }
}

export default new TaskInformationStore();
