import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FileOverLimitErrorModal.module.scss';
import CentreOnPage from '../../../shared/CentreOnPage';
import Modal from '../../../shared/Modal';
import Overlay from '../../../shared/Overlay';
import ModalActions from '../../../shared/ModalActions';
import PrimaryButton from '../../../shared/PrimaryButton';

interface IErrorModalProps {
  closeModal: () => void;
}

const FileOverLimitErrorModal: FC<IErrorModalProps> = (props) => {
  const { closeModal } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h2>Upload limit of 100 Files</h2>
        <div>Please select 100 Files or fewer to upload.</div>
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(FileOverLimitErrorModal);
