import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormInput, Text, Grid, Tooltip, Button, useToast, ComboBox } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/NewFileFromTemplate.module.scss';
import UploadStore from './UploadStore';
import { MetadataFieldType } from '../../../../common/enums/MetadataFieldType';
import SecondaryButton from '../../../shared/SecondaryButton';

const FileMetadataForm: FC = () => {
  const [expand, setExpand] = useState(true);
  const { addToast } = useToast();

  const maxLength = (length) => {
    if (!length) return 100;
    return length;
  };

  const totalSelectedFiles = UploadStore.filteredFiles.filter((x) => x.isSelected === true).length;

  const clearFileData = () => {
    UploadStore.clearFileData();
  };

  const applyFileData = () => {
    UploadStore.applyMetadataToSelectedFiles();
    addToast({
      type: 'success',
      message: `Metadata for ${totalSelectedFiles} Files has been updated successfully.`,
      timeout: 5000,
    });
  };

  const showTextLimit = (field) => {
    return (
      UploadStore.fileDataValues.find((fv) => fv.fieldValueIndex === field.fieldValueIndex)?.value.length ===
        maxLength(field.dataType.maxLength) && <div className={Style.txtLimit}>0 characters left</div>
    );
  };

  const buildMetadataFieldDropdown = (field) => {
    return (
      <>
        {UploadStore.showMetadataField(field) ? (
          <>
            <ComboBox
              label={field.title}
              showIcon={false}
              required={field.dataType.required}
              selected={UploadStore.getSelectedMetadataValueByIndex(field.fieldValueIndex)}
              options={UploadStore.getDropdownValues(field).map((x) => {
                return {
                  id: x.id,
                  value: x.label,
                };
              })}
              onSelect={(item) => {
                UploadStore.setFileDataValues(field.fieldValueIndex, item?.id.toString());
              }}
              onClear={() => UploadStore.removeFileDataValues(field.fieldValueIndex)}></ComboBox>
          </>
        ) : (
          <Tooltip show={`No ${field.title} available.`} defaultUp={false}>
            <ComboBox
              label={field.title}
              showIcon={false}
              required={field.dataType.required}
              options={[]}
              disabled={true}></ComboBox>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <div className={`${Style.fromWrapper} ${expand ? Style.expand : ''}`}>
      <div className={Style.cardHeader}>
        <Text type="h3">File Data</Text>
        {UploadStore.showMetadataWarning && <p>You must input all required metadata fields before uploading.</p>}
      </div>

      <div className={`${Style.createForm} ${expand ? Style.expandCreateForm : ''}`}>
        <div className={Style.formHeader}>
          {expand && <span className={Style.requireText}>* is a required field</span>}
          <span>
            <Button
              type="icon-square"
              icon={expand ? 'chevron_left' : 'chevron_right'}
              cssClass={`${expand ? Style.collapseBtn : Style.expandBtn}`}
              onClick={() => setExpand(!expand)}></Button>
          </span>
        </div>
        {expand && (
          <>
            <Grid row={true} md={12}>
              {UploadStore.fileMetadata &&
                UploadStore.fileMetadata.map((field) => {
                  return (
                    <Grid item md={12} key={field.fieldValueIndex}>
                      <div className={Style.formControl}>
                        {field.dataType.fieldType === MetadataFieldType.UserText ? (
                          <>
                            <FormInput
                              label={field.title}
                              cssClass={Style.input}
                              required={field.dataType.required}
                              value={
                                UploadStore.fileDataValues.find((fv) => fv.fieldValueIndex === field.fieldValueIndex)
                                  ?.value
                              }
                              onChange={(value) => UploadStore.setFileDataValues(field.fieldValueIndex, value)}
                              multilineLimit={maxLength(field.dataType.maxLength)}
                            />
                            {showTextLimit(field)}
                          </>
                        ) : (
                          <div className={Style.dropdown}>{buildMetadataFieldDropdown(field)}</div>
                        )}
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <div className={Style.actionButtons}>
              <Button cssClass={Style.buttonLink} onClick={clearFileData} type="text" label="Reset File Data" />
              <SecondaryButton
                disabled={totalSelectedFiles === 0}
                className={Style.applyMetadataBtn}
                onClick={applyFileData}>
                Apply File Data
              </SecondaryButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(FileMetadataForm);
