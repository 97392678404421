import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ComboBox } from '@aurecon-creative-technologies/styleguide';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import Style from './styles/TabContainerActionBar.module.scss';
import { classNames } from '../../utils/miscUtils';
import { TabStore } from './TabStore';
import SearchBar from '../shared/SearchBar';
import { SortOption } from './Types';
import TasksStore from './TasksStore';

export interface ITabContainerActionBar {
  store: TabStore;
}

const TabContainerActionBar: FC<ITabContainerActionBar> = ({ store }) => {
  return (
    <ActionBar>
      <ActionBarSection>
        <div className={Style.actionBarLabel}>Filter</div>
        <ComboBox
          placeholder="Status"
          showIcon={false}
          cssClass={Style.dropdown}
          selected={store.selectedStatus.toString()}
          onSelect={(item) => store.setSelectedStatus(Number(item?.id))}
          onClear={() => store.setSelectedStatus(-1)}
          options={store.statusItems}></ComboBox>
        <ComboBox
          placeholder="Workflow"
          showIcon={false}
          cssClass={Style.dropdown}
          selected={store.selectedType.toString()}
          onSelect={(item) => store.setSelectedType(Number(item?.id))}
          onClear={() => store.setSelectedType(-1)}
          options={store.typeItems}></ComboBox>
        <div className={classNames(Style.actionBarLabel, Style.marginBetweenFiltersAndSort)}>Sort</div>
        <ComboBox
          placeholder="Sort"
          showIcon={false}
          cssClass={Style.dropdown}
          selected={store.selectedSort.toString()}
          onSelect={(item) => store.setSelectedSort(Number(item?.id))}
          onClear={() => store.setSelectedSort(SortOption.LastUpdatedDate)}
          options={store.sortItems}></ComboBox>
      </ActionBarSection>
      <ActionBarSection>
        <SearchBar
          searchValue={store.searchText}
          onSearch={(value) => TasksStore.setSearchValue(value)}
          placeHolderText="Search for a project ID, project name, owner name, project ..."
        />
      </ActionBarSection>
    </ActionBar>
  );
};

export default observer(TabContainerActionBar);
