import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import SearchBar from '../../shared/SearchBar';
import AppointingPartyStore from './AppointingPartyStore';
import DeleteUserModal from './DeleteUserModal';
import { ReactComponent as AddMemberIcon } from './images/AddMemberIcon.svg';
import AppointingPartyUserTable from './AppointingPartyUserTable';
import Style from './styles/AppointingParty.module.scss';
import ErrorModal from '../../shared/ErrorModal';
import SecondaryButton from '../../shared/SecondaryButton';
import ProjectUserManagementStore from '../ProjectUserManagementStore';
import AddAppointingPartyUserModal from './AddAppointingPartyUserModal';

const AppointingParty: FC = () => {
  const projectNumber = ProjectUserManagementStore.project?.projectNumber;

  useEffect(() => {
    AppointingPartyStore.setSearchText('');
    AppointingPartyStore.loadAppointingPartyUsers();
  }, [projectNumber]);

  return (
    <div className={Style.container}>
      <div className={Style.subContainer}>
        <h2 className={Style.subPageHeader}>User List</h2>
        <div className={Style.searchBar}>
          <SecondaryButton
            className={Style.addMembersButton}
            onClick={() => AppointingPartyStore.setIsOpenAddMembersModal(true)}>
            <AddMemberIcon />
            <div>Add Members</div>
          </SecondaryButton>
          <SearchBar
            placeHolderText="Search for user name, user email"
            onSearch={(value) => AppointingPartyStore.setSearchText(value)}
          />
        </div>
        <AppointingPartyUserTable />
      </div>
      {AppointingPartyStore.userToBeRemoved && <DeleteUserModal />}
      {AppointingPartyStore.isError && <ErrorModal closeModal={AppointingPartyStore.clearError} />}
      {AppointingPartyStore.isShowAddMembersModal && (
        <AddAppointingPartyUserModal closeModal={() => AppointingPartyStore.setIsOpenAddMembersModal(false)} />
      )}
    </div>
  );
};

export default observer(AppointingParty);
