import { makeAutoObservable, runInAction } from 'mobx';
import { chain } from 'lodash';
import { getProjects } from '../../../api/authenticated/config/getProjects';
import { IProgrammeInfo } from '../ProjectInfo';

const localStorageSelectorKey = 'programmes-selector-id';

export interface IItem {
  id: string;
  programme: IProgrammeInfo;
}

export class NavBarSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public items: IItem[] = [];
  public selectedItem?: IItem;

  public async initSelector() {
    const res = await getProjects();

    const sortedProgrammes = chain(res)
      .groupBy((x) => x.programmeId)
      .map((value, key) => ({ programmeId: key, programmeTitle: value[0].programmeTitle, projects: value }))
      .orderBy([(s) => s.programmeTitle.toLowerCase()], ['asc'])
      .value();

    runInAction(() => {
      this.items = [
        {
          id: 'All Programmes',
          programme: {
            programmeTitle: 'All Programmes',
            projects: res,
          },
        },
        ...sortedProgrammes.map((programme) => ({
          id: programme.programmeId,
          programme,
        })),
      ];

      const id = localStorage.getItem(localStorageSelectorKey);
      this.selectedItem = this.items.find((item) => item.id === id);

      if (!this.selectedItem && !!this.items.length) {
        this.selectedItem = this.items[0];
      }
    });
  }

  public setSelectedSelectorItem(id: string) {
    const selectedItem = this.items.find((item) => item.id === id);
    if (!selectedItem) return;

    localStorage.setItem(localStorageSelectorKey, id);

    runInAction(() => {
      this.selectedItem = selectedItem;
    });
  }
}

export default new NavBarSelectorStore();
