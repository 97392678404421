import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import ProjectUserManagementStore from '../ProjectUserManagementStore';
import Styles from './styles/AddUserProjectInfo.module.scss';

export interface IModalInfoPanelProps {
  deliveryTeamName?: string;
  taskTeamName?: string;
}

const ModalInfoPanel: FC<IModalInfoPanelProps> = ({ deliveryTeamName, taskTeamName }) => {
  if (!ProjectUserManagementStore.project) return null;
  return (
    <ul className={Styles.container}>
      <li className={Styles.item}>
        <span>Programme</span>
        <span>{ProjectUserManagementStore.project.programmeTitle}</span>
      </li>
      <li className={Styles.item}>
        <span>Project</span>
        <span>{ProjectUserManagementStore.project.title}</span>
      </li>
      {deliveryTeamName && (
        <li className={Styles.item}>
          <span>Delivery Team</span>
          <span>{deliveryTeamName}</span>
        </li>
      )}
      {taskTeamName && (
        <li className={Styles.item}>
          <span>Task Team</span>
          <span>{taskTeamName}</span>
        </li>
      )}
    </ul>
  );
};

export default observer(ModalInfoPanel);
