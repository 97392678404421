import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as RejectedIcon } from './images/RejectedIcon.svg';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationHistoryItem.module.scss';
import { formatDate } from '../../utils/dateUtils';

export interface ITaskInformationHistoryRejectedProps {
  item: IHistoryItem;
}

const TaskInformationHistoryRejected: FC<ITaskInformationHistoryRejectedProps> = ({ item }) => {
  return (
    <>
      <RejectedIcon />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            <strong>Rejected</strong> by <strong>{item.byUser.name}</strong>
          </span>
        </div>
        <div className={Style.historyItemDetailDate}>{formatDate(item.dateTime)}</div>
        {item.message && <div className={Style.historyItemDetailMessage}>{item.message}</div>}
      </div>
    </>
  );
};

export default observer(TaskInformationHistoryRejected);
