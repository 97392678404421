import config from '../../../config';
import AuthStore from '../../../stores/AuthStore';
import { serverAxios } from '../api';

export interface IUnlockFileRequest {
  taskTeamId: number;
  fileId: number;
}

export async function unlockFile(request: IUnlockFileRequest, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.post('api/cms/unlockfile', request, {
    signal: abortSignal,
  });
  return result.data;
}

export async function fetchUnlockFile(data: IUnlockFileRequest): Promise<void> {
  const url = `${config.serverUri}/api/cms/unlockfile`;
  fetch(url, {
    method: 'POST',
    keepalive: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthStore.accessToken}`,
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
}
