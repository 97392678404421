import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SupportHub.module.scss';
import PrimaryButton from '../shared/PrimaryButton';
import { useMsal } from '@azure/msal-react';
import { logoutRequest } from '../../auth/MsalConfig';

const Unauthorized: FC = () => {
  const { instance } = useMsal();
  return (
    <div className={Style.pageContent}>
      <div className={Style.content}>
        <h1>Tucana Support Hub</h1>
        <h2>Your session has expired. Please log out and log in again to continue.</h2>
        <div className={Style.buttons}>
          <PrimaryButton onClick={() => instance.logoutRedirect(logoutRequest)}>Logout</PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default observer(Unauthorized);
