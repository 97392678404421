import { FileState } from '../../../common/enums/FileState';
import { serverAxios } from '../api';

export interface IUser {
  id: number;
  name: string;
  email: string;
}

export async function getNonWipUsersToCollaborateWith(
  projectNumber: string,
  fileStateId: FileState,
  abortSignal?: AbortSignal
): Promise<IUser[]> {
  const result = await serverAxios.get<{ users: IUser[] }>('api/cms/nonwipuserstocollaboratewith', {
    params: {
      projectNumber,
      fileStateId,
    },
    signal: abortSignal,
  });
  return result.data?.users;
}
