import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from './styles/TermsConditions.module.scss';

const TermsCondition: FC = () => {
  return (
    <div className={Styles.container}>
      <h1>Terms of use – Tucana</h1>
      <strong>1. WHO WE ARE AND HOW TO CONTACT US</strong>
      <p>
        Tucana is a web application (App) operated by Aurecon Australasia Pty Ltd 54 005 139 873 of Level 8, 850 Collins
        Street, Docklands VIC 3008 (we, us, and our).
      </p>
      <p>
        To contact us, please email <a>melbourne@aurecongroup.com</a> OR call +61 3 9975 3000
      </p>
      <strong>2. BY USING OUR APP YOU ACCEPT THESE TERMS</strong>
      <p>
        By using our App, you confirm that you accept these terms of use and that you agree to comply with them. If you
        do not agree to these terms, you must not use our App.
      </p>
      <strong>3. WE MAY MAKE CHANGES TO THESE TERMS</strong>
      <p>
        We amend these terms from time to time. Every time you wish to use our App, please check these terms to ensure
        you understand the terms that apply at that time.
      </p>
      <p>Please check clause 21 (Version History) of these Terms for version history.</p>
      <strong>4. WE MAY MAKE CHANGES TO OUR APP</strong>
      <p>
        We may update and change our App from time to time. We will try to give you reasonable notice of any major
        changes.
      </p>
      <strong>5. WE MAY SUSPEND OR WITHDRAW OUR APP</strong>
      <p>
        We do not guarantee that our App, or any content on it, will always be available, uninterrupted or be
        error-free. We may suspend or withdraw or restrict the availability of all or any part of our App for business
        and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
      </p>
      <p>
        You are also responsible for ensuring that all persons who access our App through your connection or device are
        aware of these terms of use and other applicable terms and conditions, and that they comply with them.
      </p>
      <strong>6. ELIGIBILITY TO USE OUR APP</strong>
      <p>
        Our App is directed to users who at least 18 years old and are residing in and using this App within Australia
        and New Zealand. We do not represent that content available on or through our App is appropriate for use or
        available in other locations. If you access our App from outside Australia or New Zealand, you do at your risk
        and you are responsible for compliance with laws applicable to your accessing the App from your location.
      </p>
      <strong>7. ROLES WITHIN THE APP</strong>
      <p>
        <strong>Project administrators</strong> can be assigned via the App by a Client Project Lead and or Aurecon
        administrator at the discretion of the Client. A project administrator can:
      </p>
      <ul>
        <li>Modify project attributes; and</li>
        <li>
          Modify the list of users that have access to the project instance within the App. This system role is assigned
          by the Client project Lead and at the discretion of the client.
        </li>
      </ul>
      <p>
        <strong>Aurecon Global Administrators</strong> have access to project instances. You accept this by using the
        App. An Aurecon Global Administrator&apos;s role includes:
      </p>
      <ul>
        <li>Facilitating the design (corridor) option and GIS layer upload process;</li>
        <li>Troubleshooting and to log any issues on behalf of the Client.</li>
      </ul>
      <strong>8. YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</strong>
      <p>
        If you choose, or you are provided with user login credentials, being username & password or any other piece of
        information as part of our security procedures, you must treat such information as confidential. You must not
        disclose it to any third party.
      </p>
      <p>
        We have the right to disable any user identification code or password, whether chosen by you or allocated by us,
        at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of
        use.
      </p>
      <p>
        If you know or suspect that anyone other than you knows your user identification code or password, you must
        promptly notify us at <a>melbourne@aurecongroup.com</a>
      </p>
      <strong>9. HOW YOU MAY USE MATERIAL ON OUR APP</strong>
      <p>
        We are the owner or the licensee of all intellectual property rights in our App, and in the material published
        on it. Those works of Aurecon or third-party licensors are protected by copyright laws and treaties around the
        world. All such rights are reserved.
      </p>
      <p>
        You must not use any part of the content on our App for commercial purposes without obtaining a licence to do so
        from us or our licensors.
      </p>
      <p>
        You must not delete or alter any copyright, trade mark or other proprietary rights notices from copies of
        materials from this App.
      </p>
      <strong>10. DO NOT RELY ON INFORMATION ON THIS APP</strong>
      <p>
        This App is provided on an &quot;as is&quot; and &quot;as available&quot; basis, and we make no representations
        or warranties, express or implied, regarding the operation or availability of the App.
      </p>
      <p>
        The content on our App is provided for general information only. It is not intended to amount to advice on which
        you should rely.
      </p>
      <p>
        Although we make reasonable efforts to update the information on our App, we make no representations, warranties
        or guarantees, whether express or implied, that the content on our App is accurate, complete or up-to-date.
      </p>
      <strong>11. USER CONTRIBUTIONS</strong>
      <p>
        This App may include information and materials uploaded by other users of the App, including to comment threads.
        This information and these materials have not been verified or approved by us. The views expressed by other
        users on our App do not represent our views or values.
      </p>
      <p>
        This App may contain comment threads, that allow users to post, submit, publish, display or transmit to other
        users or other persons (post) content or materials (collectively, User Contributions) on or through the App.
      </p>
      <p>All User Contributions must comply with the content standards set out in clause 12 of these terms.</p>
      <p>
        We have the right to remove any posting you make on our App if, in our opinion, your post does not comply with
        the content standards in clause 12 of these terms.
      </p>
      <p>You are solely responsible for securing and backing up your content.</p>
      <p>You represent and warrant that:</p>
      <ul>
        <li>
          You own or control all rights in and to your User Contributions and have the right to grant the granted above
          to us and our affiliates and service providers, and each of their and our respective licensees, successors and
          assigns.
        </li>
        <li>All of your User Contributions do and will comply with these terms.</li>
        <li>
          You understand and acknowledge that you are responsible for any User Contributions you submit or contribute,
          and you (and not us) have fully responsibility for such content, including its legality, reliability, accuracy
          and appropriateness.
        </li>
        <li>
          We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions
          posted by you or any other user of the App.
        </li>
        <li>
          We have the right to remove, refuse to post or take any action with respect to any User Contributions for any
          or no reason in our sole discretion. If you wish to complain about information and materials uploaded by other
          users please contact us on email <a>melbourne@aurecongroup.com</a>
          <strong> OR</strong> call +61 3 9975 3000
        </li>
      </ul>
      <strong>12. CONTENT STANDARDS</strong>
      <p>
        The content standards in this clause 12 apply to any and all User Contributions [and use of social media
        features]. User Contributions must in their entirety comply with all applicable federal, state, local and
        international laws and regulations. In particular, you warrant that your User Contributions will not:
      </p>
      <ul>
        <li>
          Contain any material which is defamatory, obscene, abhorrent, indecent, abusive, offensive, harassing,
          violent, hateful, inflammatory or otherwise objectionable.
        </li>
        <li>
          Contain or promote sexual or pornographic material, violence, or discrimination based on race, sex, religion,
          nationality, disability, sexual orientation or age.
        </li>
        <li>
          Infringe any patent, trade mark, trade secret, copyright or other intellectual property or other rights of any
          other person.
        </li>
        <li>
          Violate the legal rights (including the rights of publicity and privacy) of others or contain any material
          that could give rise to any civil or criminal liability under applicable laws or regulations that otherwise
          may be in conflict with these terms and our privacy policy{' '}
          <a href="https://www.aurecongroup.com/footer/privacy-policy">
            https://www.aurecongroup.com/footer/privacy-policy
          </a>
          .
        </li>
        <li>Be likely to deceive any person.</li>
        <li>Promote any illegal activity, or advocate, promote or assist any unlawful act.</li>
        <li>
          Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any any
          other person.
        </li>
        <li>
          Impersonate any person or misrepresent your identity or affiliation with any person or organisation, including
          with us.
        </li>
        <li>
          Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter
          barter or advertising.
        </li>
        <li>
          Give the impression that they emanate from or are endorsed by us or any other person or entity, if this this
          is not the case.
        </li>
        <li>
          Whenever you make use of a feature that allows you to upload content to our App, or to make contact with with
          other users of our App, you must comply with the content standards in this clause 12.
        </li>
        <li>
          You warrant that any such contribution complies with these standards, and you will be liable to us and
          indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we
          suffer as a result of your breach of warranty. We may report any breach of your warranty to the relevant law
          enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In
          the event of such a breach, your right to use our App will cease immediately.
        </li>
      </ul>
      <strong>13. LIMITATION OF LIABILITY</strong>
      <p>
        In no event will we, our affiliates or their licensors, service providers, employees, agents, officers or
        directors be liable for damages of any kind, under any legal theory, arising out of or in connection with your
        use, or inability to use, our App, any websites or applications linked to it, any content on our App or such
        other websites or any services obtained through our App or such other websites, including any direct, indirect,
        special, incidental, consequential or punitive damages, including but not limited to, personal injury, pain and
        suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss
        of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or
        otherwise, even if foreseeable.
      </p>
      <p>
        Nothing in this clause affects any liability which cannot be excluded or limited under applicable law. We do not
        exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability
        for death or personal injury caused by our negligence or the negligence of our employees, agents or
        subcontractors and for fraud or fraudulent misrepresentation. Where our liability cannot be excluded, we limit
        our liability to the fullest extent permitted by the Australian Consumer Law.
      </p>
      <p>
        You agree to defend, indemnify and hold us, our affiliates, licensors and service providers, and each of their
        respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns,
        harmless from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees
        (including reasonable attorneys’ fees) arising out of or relating to your violation of these terms or your use
        of the App, including, but not limited to, your User Contributions, your use of any information obtained from
        the App and any use of the App’s content, services and products other than as expressly authorised in these
        terms.
      </p>
      <strong>14. WE ARE NOT RESPONSIBLE FOR VIRUSES</strong>
      <p>
        We do not guarantee that our App will be secure or free from bugs or viruses or any other type of malicious code
        or software.
      </p>
      <p>
        You are responsible for configuring your technology to access our App. You should use your own antivirus
        software.
      </p>
      <strong>15. AUSTRALIAN LAW APPLIES TO DISPUTES</strong>
      <p>
        These terms of use, their subject matter and their formation, are governed by Australian law. You and we both
        agree that the courts in Australia will have exclusive jurisdiction.
      </p>
      <strong>16. OUR TRADE MARKS</strong>
      <p>
        AURECON and Product Execution Portal and all related names, logos, product and service names, designs and
        slogans are our trademarks or the trademarks of our affiliates or licensors. You must not use such marks without
        our prior written permission unless they are part of material you are using as permitted under clause 8. Other
        names, logos, product and service names, designs and slogans on this App are the trademarks of their respective
        owners and are used by us under license.
      </p>
      <strong>17. PROHIBITED USES</strong>
      <p>
        You may use the App only for lawful purposes and in accordance with these terms. You agree not to use the App:
      </p>
      <ul>
        <li>
          in any way that violates any applicable federal, state, local or international law or regulation (including,
          without limitation, any laws regarding the export of data or software to and from other countries);
        </li>
        <li>
          for the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to
          inappropriate content, asking for personally identifiable information or otherwise;
        </li>
        <li>
          to send, knowingly receive, upload, download, use or re-use any material which does not comply with the
          content standards set out in clause 12 of these terms;
        </li>
        <li>
          to transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”,
          “chain letter” or “spam” or any other similar solicitation;
        </li>
        <li>
          to impersonate or attempt to impersonate us, any of our employees, another user or any other person or entity
          (including, without limitation, by using email addresses or screen names associated with any of the
          foregoing); or
        </li>
        <li>
          to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the App, or which, as
          determined by us, may harm us or users of the App or expose them to liability.
        </li>
      </ul>
      <p>Additionally, you agree not to:</p>
      <ul>
        <li>
          use the App in any manner that could disable, overburden, damage, or impair the App or interfere with any
          other party’s use of the App, including their ability to engage in real time activities through the App;
        </li>
        <li>
          use any robot, spider or other automatic device, process or means to access the App for any purpose, including
          monitoring or copying any of the material on the App;
        </li>
        <li>
          use any manual process to monitor or copy any of the material on the App or for any other unauthorised purpose
          without our prior written consent;
        </li>
        <li>use any automatic or manual process to reverse engineer or decompile any part of the App;</li>
        <li>use any device, software or routine that interferes with the proper working of the App;</li>
        <li>
          introduce any viruses, trojan horses, worms, logic bombs, spyware, computer code, file, program or material
          which is malicious or technologically harmful;
        </li>
        <li>
          attempt to gain unauthorised access to, interfere with, damage or disrupt any parts of the App, the server on
          which the App is stored, or any server, computer or database connected to the App;
        </li>
        <li>attack the App via a denial-of-service attack or a distributed denial-of-service attack; or</li>
        <li>otherwise attempt to interfere with the proper working of the App.</li>
      </ul>
      <p>
        We may report any of the activities above to the relevant law enforcement authorities and we will cooperate with
        those authorities by disclosing your identity to them. In the event of such a breach, your right to use our App
        will cease immediately.
      </p>
      <strong>18. OTHER TERMS AND CONDITIONS</strong>
      <p>
        Additional terms and conditions may also apply to specific portions, services or features of the App. All such
        additional terms and conditions are incorporated by this reference into these terms.
      </p>
      <strong>19. PRIVACY</strong>
      <p>
        Aurecon, its authorised Personnel and third-party service providers (on its behalf) collect, store and disclose
        Personal Information (as defined in the Privacy Act 1988) collected via the App, in accordance with Aurecon’s
        Privacy Policy, which can be found here:
        <a href="https://www.aurecongroup.com/footer/privacy-policy">
          https://www.aurecongroup.com/footer/privacy-policy
        </a>
        .
      </p>
      <p>
        We also uphold your rights to privacy if you are based in the European Union, in accordance with the General
        Data Protection Regulation (EU) (<strong>GDPR</strong>).
      </p>
      <p>
        By using the App and by creating User Contributions, you give us permission to use your Personal Information in
        accordance with Aurecon’s Privacy Policy. If you do not wish to provide personal information to us, then you do
        not have to do so. However, this may affect your use of this Site or any products and services offered on it.
      </p>
      <p>
        If at any stage, you would like your Personal Information or User Contributions removed, amended or deleted from
        the App, please email <a>melbourne@aurecongroup.com</a> <strong> OR </strong>
        call +61 3 9975 3000.
      </p>
      <p>
        For transparency, we may use personal information collected or stored using the App for the following purposes:
      </p>
      <ul>
        <li>internal product review or support purposes only. (first & last name, occupation, email address)</li>
        <li>
          tracking user behaviour via an analytics tool (Smartlook) that is GDPR compliant. The tool masks any
          confidential user information. <a href="https://www.smartlook.com">https://www.smartlook.com</a>
        </li>
        <li>
          We do <strong>not</strong> track user locations while using the App.
        </li>
      </ul>
      <strong>20. COOKIES</strong>
      <p>
        When you access the Aurecon Tucana, we may send a “cookie” (which is a small summary file containing a unique ID
        number) to your computer. This enables us to recognise your computer and greet you each time you visit our
        website without bothering you with a request to register. You must only consent once use the application.
      </p>
      <p>
        We also may use cookies to measure traffic patterns, to determine which areas of our website have been visited
        and to measure transaction patterns in the aggregate. We use this to research our users’ habits so that we can
        improve our online services. Our cookies do not collect personal information. If you do not wish to receive
        cookies, you can set your browser so that your computer does not accept them. We may log IP addresses (that is,
        the electronic addresses of computers connected to the Internet) to analyse trends, administer the website,
        track users’ movements, and gather broad demographic information.
      </p>
    </div>
  );
};

export default observer(TermsCondition);
