import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import AppointingPartyStore from './AppointingPartyStore';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import Style from './styles/Modal.module.scss';
import ModalActions from '../../shared/ModalActions';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';

const DeleteUserModal: FC = () => {
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Deleting User?</h1>
        <p>
          This action will revoke User’s access from appointing Party. Are you sure you want to delete{' '}
          {AppointingPartyStore.userToBeRemoved?.name}?
        </p>
        <ModalActions>
          <ButtonSavingSpinner isSaving={AppointingPartyStore.isRemovingUser}>
            <SecondaryButton onClick={AppointingPartyStore.removeUserFromAppointingParty}>Yes</SecondaryButton>
          </ButtonSavingSpinner>
          <PrimaryButton onClick={() => AppointingPartyStore.setRemoveUser(null)}>No</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(DeleteUserModal);
