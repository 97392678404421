import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { TransmittalExportOptionText } from '../../common/constants/ExportOptionText';
import { TransmittalExportOption } from '../../common/enums/ExportOption';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import TransmittalsStore from './TransmittalsStore';
import Style from './styles/TransmittalTabContainerActionBar.module.scss';
export interface ITransmittalCSVData {
  filename: string;
  headers: { label: string; key: string }[];
  data: {
    title: string;
    subject: string;
    lastUpdated: string;
    initiator: string;
    notified: string;
    visibleTo: string;
    type: string;
    reason: string;
    initiatedDate: string;
    dueDate: string;
    message: string;
    totalResponses: number;
    totalContentFiles: number;
    totalSupportingFiles: number;
    status: string;
  }[];
}
const TransmittalsExportCSVLink: FC = () => {
  const [csvData, setCsvData] = useState<ITransmittalCSVData | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const fetchDataAsync = async () => {
    setLoaded(true);
    setLoaded(false);
    setClicked(false);
    await TransmittalsStore.getExportAllTransmittals();
    const mappingItems = TransmittalsStore.exportAllTransmittals;
    const data = TransmittalsStore.mappingCSVData(mappingItems);
    setCsvData(data);
    setLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    if (loaded && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, loaded]);
  return (
    <DropDownMenuButton className={Style.btnMenuUploadBtn} disabled={loading} onClick={fetchDataAsync}>
      {TransmittalExportOptionText[TransmittalExportOption.ExportAll]}
      {loaded && (
        <CSVLink
          headers={csvData?.headers ?? []}
          filename={csvData?.filename}
          data={csvData?.data ?? []}
          ref={csvLinkRef}
        />
      )}
    </DropDownMenuButton>
  );
};

export default observer(TransmittalsExportCSVLink);
