import { TransmittalType } from '../../common/enums/TransmittalType';
import { TransmittalIssueReason } from '../../common/enums/TransmittalIssueReason';
import { TransmittalGroup } from '../../common/enums/TransmittalGroup';
import { TransmittalSortOption } from '../../common/enums/TransmittalSortOption';

export enum TabFilter {
  MyNotifications,
  InitiatedByMe,
  VisibleToMe,
}

export const TabFilterText = {
  [TabFilter.MyNotifications]: 'MyNotifications',
  [TabFilter.InitiatedByMe]: 'InitiatedByMe',
  [TabFilter.VisibleToMe]: 'VisibleToMe',
};

export type TypeFilter = TransmittalType | -1;
export type IssueReasonFilter = TransmittalIssueReason | -1;
export type GroupFilter = TransmittalGroup | -1;
export type SortColumn = TransmittalSortOption | -1;

export enum SupportFileMode {
  CreateTransmittal,
  CreateResponse,
}

export type TransmittalTeamChartType = 'Notify' | 'Visible' | '';
