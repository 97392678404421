import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CentreOnPage from '../../../shared/CentreOnPage';
import Overlay from '../../../shared/Overlay';
import Modal from '../../../shared/Modal';
import ModalActions from '../../../shared/ModalActions';
import SecondaryButton from '../../../shared/SecondaryButton';
import PrimaryButton from '../../../shared/PrimaryButton';
import Icon from '../../../shared/Icon';
import CloseButton from '../../../shared/CloseButton';
import SecondaryIconButton from '../../../shared/SecondaryIconButton';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import TeamSettingsStore from './TeamSettingsStore';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import TeamSettingsInput from './TeamSettingsInput';
import ModalInfoPanel from '../ModalInfoPanel';
import ButtonSavingSpinner from '../../../shared/ButtonSavingSpinner';
import AppointedPartiesStore from '../AppointedPartiesStore';
import Style from './styles/TeamSettings.module.scss';

const TeamSettings: FC = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const onCancel = () => setShowCancelModal(true);

  if (showCancelModal) {
    return (
      <ConfirmationModal
        showModal
        heading="Cancel Adding Team?"
        message="Cancelling Adding Team can not be undone, all the data within the task will lost."
        cancelText="Yes"
        confirmText="No"
        onCancel={AppointedPartiesStore.closeModal}
        onConfirm={() => setShowCancelModal(false)}
      />
    );
  }

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={onCancel} disabled={TeamSettingsStore.isSaving} />
        <h1>Team Settings</h1>
        <p>Once a Delivery Team or Task Team is created, you will not be able to edit or delete them later on. </p>
        <ModalInfoPanel />
        <ol className={Style.deliveryTeamsContainer}>
          {TeamSettingsStore.deliveryTeams.map((deliveryTeam) => (
            <li key={deliveryTeam.id}>
              <div className={Style.deliveryTeam}>
                {`${deliveryTeam.title} (${deliveryTeam.transmittalCode})`}
                <Tooltip show="Add Task Team and Abbreviation" defaultUp>
                  <SecondaryIconButton
                    className={Style.deliveryTeamAddButton}
                    icon="add"
                    onClick={() => TeamSettingsStore.setNewTaskTeamDeliveryTeamId(deliveryTeam.id)}
                    disabled={TeamSettingsStore.isSaving}
                  />
                </Tooltip>
              </div>
              <ol className={Style.taskTeamList}>
                {TeamSettingsStore.newTaskTeamDeliveryTeamId === deliveryTeam.id && (
                  <li>
                    <TeamSettingsInput
                      namePlaceHolder="Enter Task Team Name"
                      name={TeamSettingsStore.newTaskTeamTitle}
                      transmittalCode={TeamSettingsStore.newTaskTeamTransmittalCode}
                      valid={!TeamSettingsStore.disableNewTaskTeamAddButton}
                      onNameChange={TeamSettingsStore.setNewTaskTeamTitle}
                      onTransmittalCodeChange={TeamSettingsStore.setNewTaskTeamTransmittalCode}
                      onAdd={TeamSettingsStore.addNewTaskTeam}
                      error={
                        TeamSettingsStore.newTaskTeamTitleError || TeamSettingsStore.newTaskTeamTransmittalCodeError
                      }
                    />
                  </li>
                )}
                {deliveryTeam.taskTeams.map((taskTeam) => (
                  <li key={taskTeam.id} className={Style.taskTeam}>
                    {`${taskTeam.title} (${taskTeam.transmittalCode})`}
                  </li>
                ))}
              </ol>
            </li>
          ))}
          {TeamSettingsStore.showNewDeliveryTeam && (
            <li className={Style.newDeliveryTeam}>
              <TeamSettingsInput
                namePlaceHolder="Enter Delivery Team Name"
                name={TeamSettingsStore.newDeliveryTeamTitle}
                transmittalCode={TeamSettingsStore.newDeliveryTeamTransmittalCode}
                valid={!TeamSettingsStore.disableNewDeliveryTeamAddButton}
                onNameChange={TeamSettingsStore.setNewDeliveryTeamTitle}
                onTransmittalCodeChange={TeamSettingsStore.setNewDeliveryTeamTransmittalCode}
                onAdd={TeamSettingsStore.addNewDeliveryTeam}
                error={
                  TeamSettingsStore.newDeliveryTeamTitleError || TeamSettingsStore.newDeliveryTeamTransmittalCodeError
                }
              />
            </li>
          )}
          <li>
            <button
              className={Style.addDeliveryTeamButton}
              onClick={TeamSettingsStore.setShowNewDeliveryTeam}
              disabled={TeamSettingsStore.isSaving}>
              <Icon className={Style.addDeliveryTeamButtonIcon} name="add" />
              <span>Add Delivery Team</span>
            </button>
          </li>
        </ol>
        <ModalActions>
          <SecondaryButton onClick={onCancel} disabled={TeamSettingsStore.isSaving}>
            Cancel
          </SecondaryButton>
          <ButtonSavingSpinner isSaving={TeamSettingsStore.isSaving}>
            <PrimaryButton onClick={TeamSettingsStore.save} disabled={TeamSettingsStore.saveDisabled}>
              Save
            </PrimaryButton>
          </ButtonSavingSpinner>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TeamSettings);
