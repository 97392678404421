import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from './styles/CreateTransmittalActions.module.scss';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import CreateTransmittalStore from './CreateTransmittalStore';
import LayoutStore from '../layout/LayoutStore';
import CreateTransmittalErrorModal from './CreateTransmittalErrorModal';
import { Pages } from '../../common/constants/Pages';
import { useNavigate } from 'react-router-dom';
import FilesStore from '../files/FilesStore';
import { ContentSelection } from '../files/ContentSelection';
import AppStore from '../../stores/AppStore';
import DropDownMenu from '../shared/DropDownMenu';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import ConfirmationModal from '../shared/ConfirmationModal';
import SupportingFilesUploadStore from './supportingFilesUpload/SupportingFilesUploadStore';
import { ClientUserRole } from '../../common/enums/ClientUserRole';

const CreateTransmittalActions: FC = () => {
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleTransmit = async () => {
    await CreateTransmittalStore.onSavingTransmittal();
    if (CreateTransmittalStore.errorMessage) setShowErrorModal(true);
    else {
      LayoutStore.displayToast(
        'success',
        `Transmittal ${CreateTransmittalStore.transmittalTitle} has been created successfully.`
      );
      navigate(`${Pages.Transmittals.Route}`);
    }
  };

  const handleCloseUploadFileBtnClick = () => {
    CreateTransmittalStore.toggleUploadFileModal(true);
  };

  return (
    <>
      <ActionBar className={Styles.actionBar}>
        <ActionBarSection>
          <PrimaryButton onClick={handleTransmit} disabled={CreateTransmittalStore.disableTransmitButton}>
            Transmit
          </PrimaryButton>
          <div className={Styles.expandBtn}>
            <DropDownMenu
              label="Add"
              disabled={CreateTransmittalStore.disableTransmitButton}
              icon="expand_more"
              iconClassName={Styles.expandBtnIcon}
              itemsContainerClassName={Styles.itemsContainer}
              menuClassName={Styles.btnMenu}>
              {AppStore.client?.user.clientUserRole !== ClientUserRole.External && (
                <DropDownMenuButton
                  disabled={CreateTransmittalStore.disableTransmitButton}
                  onClick={() => {
                    CreateTransmittalStore.setIsOpenFiles(true);
                    AppStore.setDisableNavigation(true);
                    FilesStore.cleanup();
                    FilesStore.applyFileFilterForTransmittals(
                      ContentSelection.Shared,
                      AppStore.selectedProjectNumber,
                      AppStore.selectedTaskTeamId
                    );
                    FilesStore.setSelectedSection(ContentSelection.Shared);
                  }}>
                  Content Files
                </DropDownMenuButton>
              )}
              <DropDownMenuButton
                disabled={CreateTransmittalStore.disableTransmitButton}
                className={Styles.btnMenuUploadBtn}
                onClick={handleCloseUploadFileBtnClick}>
                Supporting Files
              </DropDownMenuButton>
            </DropDownMenu>
          </div>
          <SecondaryButton onClick={() => setOpenWarningModal(true)}>Cancel</SecondaryButton>
        </ActionBarSection>
      </ActionBar>
      <ConfirmationModal
        showModal={openWarningModal}
        heading="Cancel Transmittal"
        message="You have unsaved changes. Are you sure you want to leave this page?"
        confirmText="Yes"
        cancelText="No"
        onCancel={() => setOpenWarningModal(false)}
        onConfirm={() => {
          setOpenWarningModal(false);
          SupportingFilesUploadStore.clear();
          CreateTransmittalStore.clear();

          navigate(`${Pages.Transmittals.Route}`);
        }}
      />
      {showErrorModal && (
        <CreateTransmittalErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorMessage={CreateTransmittalStore.errorMessage ?? undefined}
        />
      )}
    </>
  );
};

export default observer(CreateTransmittalActions);
