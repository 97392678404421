export default function applyPollyfills(): void {
  // NOTE: add polyfills here for now

  if (!String.prototype.startsWith) {
    // eslint-disable-next-line
    String.prototype.startsWith = function (searchString, position) {
      return this.substr(position || 0, searchString.length) === searchString;
    };
  }
}
