import classNames from 'classnames';
import React, { FC } from 'react';
import { UploadFileStatusEnum, UploadFileStatusText } from '../../../../common/enums/UploadFileStatus';
import Style from './styles/FileStatus.module.scss';

interface IFileStatusProps {
  missingMetadata: boolean;
  missingFileExtension: boolean;
  status: UploadFileStatusEnum;
}
const FileStatus: FC<IFileStatusProps> = (props) => {
  const { missingMetadata, missingFileExtension, status } = props;
  const isShowOriginStatus = (
    missingMetadata: boolean,
    missingFileExtension: boolean,
    status: UploadFileStatusEnum
  ) => {
    return (
      !missingMetadata ||
      (missingFileExtension && status === UploadFileStatusEnum.MissingFileExtension) ||
      (missingMetadata && status === UploadFileStatusEnum.Duplicate) ||
      (missingMetadata && status === UploadFileStatusEnum.Supersede)
    );
  };
  return (
    <>
      {isShowOriginStatus(missingMetadata, missingFileExtension, status) && (
        <span
          className={classNames(Style.status, {
            [Style.ready]: status === UploadFileStatusEnum.Ready,
            [Style.supersede]: status === UploadFileStatusEnum.Supersede,
            [Style.duplicate]: status === UploadFileStatusEnum.Duplicate,
            [Style.missingMetadata]: status === UploadFileStatusEnum.MissingMetadata,
            [Style.missingFileExtension]: status === UploadFileStatusEnum.MissingFileExtension,
          })}>
          {UploadFileStatusText[status]}
        </span>
      )}
      {missingMetadata && status !== UploadFileStatusEnum.Supersede && (
        <span className={`${Style.status} ${Style.missingMetadata}`}>
          {UploadFileStatusText[UploadFileStatusEnum.MissingMetadata]}
        </span>
      )}
    </>
  );
};

export default FileStatus;
