import { IProgramme, IProject, ITaskTeam } from '../../api/authenticated/config/getClientInfo';

interface IItemBase {
  id: string;
  type: string;
  programme: IProgramme;
  disabled?: boolean;
  navbarSelected?: boolean;
}

export enum NavigationItemTypes {
  Programme = 'Programme',
  Project = 'Project',
  TaskTeam = 'TaskTeam',
}

export type ProgrammeItem = IItemBase & { type: NavigationItemTypes.Programme };

export type ProjectItem = IItemBase & { type: NavigationItemTypes.Project; project: IProject };

export type TaskTeamItem = IItemBase & { type: NavigationItemTypes.TaskTeam; project: IProject; taskTeam: ITaskTeam };

export type Item = ProgrammeItem | ProjectItem | TaskTeamItem;
