import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SecondaryButton from '../shared/SecondaryButton';
import FilesStore from './FilesStore';
import DownloadModal from '../shared/DownloadModal';
import { getFileSizeDetails } from '../../utils/miscUtils';

const DownloadButton: FC = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(FilesStore.selectedFilesSize);

  return (
    <>
      <SecondaryButton
        disabled={FilesStore.canDownloadSelectedFiles}
        onClick={() => {
          if (isOverLimit) {
            setShowDownloadModal(true);
          } else {
            FilesStore.downloadSelectedFiles();
          }
        }}>
        Download
      </SecondaryButton>
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FilesStore.downloadSelectedFiles();
          setShowDownloadModal(false);
        }}
      />
    </>
  );
};

export default observer(DownloadButton);
