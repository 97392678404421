import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { serverAxios } from '../api';

export interface IRemoveUserFromTaskTeamRoleRequest {
  projectNumber: string;
  taskTeamId: number;
  userId: number;
  taskTeamRoleId: TaskTeamRole;
}

export function removeUserFromTaskTeamRole(
  request: IRemoveUserFromTaskTeamRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/taskteam/users/removerole', request, {
    signal: abortSignal,
  });
}
