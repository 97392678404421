import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Compass from '@arcgis/core/widgets/Compass';

import WidgetBase from './WidgetBase';

const CompassWidget: FC = () => {
  return WidgetBase(Compass, { visible: true });
};

export default observer(CompassWidget);
