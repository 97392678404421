import { makeAutoObservable, runInAction } from 'mobx';
import { getProjects, IProject } from '../../api/authenticated/config/getProjects';

export class ProjectUserManagementStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public selectedTabId = 1;
  public project: IProject | null = null;

  public setSelectedTabId(value) {
    runInAction(() => {
      this.selectedTabId = value;
    });
  }

  public async init(projectNumber: string) {
    const projects = await getProjects(projectNumber);
    if (projects.length) {
      runInAction(() => {
        this.project = projects[0];
      });
    }
  }
}

export default new ProjectUserManagementStore();
