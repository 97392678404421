import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import AppointedPartiesStore from './AppointedPartiesStore';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/Modal.module.scss';
import ModalActions from '../../shared/ModalActions';

const DeleteTaskTeamUserModal: FC = () => {
  if (!AppointedPartiesStore.taskTeamUserToBeRemoved) return null;
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Deleting Task Team Member?</h1>
        {AppointedPartiesStore.isUserInOtherTaskTeams ? (
          <p>
            This action will revoke User&apos;s access as a Task Team Member from{' '}
            {AppointedPartiesStore.taskTeamOfUserToBeRemoved?.title}. Are you sure you want to delete{' '}
            {AppointedPartiesStore.taskTeamUserToBeRemoved?.name}?
          </p>
        ) : (
          <p>
            This action will revoke User&apos;s access as a Task Team Member from{' '}
            {AppointedPartiesStore.taskTeamOfUserToBeRemoved?.title} and also as a Delivery Team authoriser from{' '}
            {AppointedPartiesStore.deliveryTeamOfUserToBeRemoved?.title}. Are you sure you want to delete{' '}
            {AppointedPartiesStore.taskTeamUserToBeRemoved?.name} from both roles?
          </p>
        )}
        {AppointedPartiesStore.isRemovingUser ? (
          <Loader cssClass={Style.savingSpinner} size="small" />
        ) : (
          <ModalActions>
            <SecondaryButton onClick={AppointedPartiesStore.removeUserFromTaskTeam}>Yes</SecondaryButton>
            <PrimaryButton
              onClick={() =>
                AppointedPartiesStore.setRemoveTaskTeamUser({ deliveryTeam: null, taskTeam: null, taskTeamUser: null })
              }>
              No
            </PrimaryButton>
          </ModalActions>
        )}
      </Modal>
    </CentreOnPage>
  );
};

export default observer(DeleteTaskTeamUserModal);
