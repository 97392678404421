import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import SecondaryButton from '../../shared/SecondaryButton';
import HistoryDate from './HistoryDate';
import { IHistoryItem, IReleasedHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import Style from './styles/HistoryRelease.module.scss';
import { getFileSizeDetails } from '../../../utils/miscUtils';
import DownloadModal from '../../shared/DownloadModal';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import ErrorModal from '../../shared/ErrorModal';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';

export interface IHistoryReleaseProps {
  item: IHistoryItem;
  release: IReleasedHistoryItem;
}

const HistoryRelease: FC<IHistoryReleaseProps> = ({ item, release }) => {
  const { totalFileSize, isOverLimit } = getFileSizeDetails(FileInformationStore.filesSize);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleForgeViewer = async () => {
    FileInformationStore.busyReleasedHistoryItem[release.releasedFileId] = true;
    await ForgeViewerStore.getForgeViewer(
      release.fileRevisionId,
      NavBarSelectorStore.selectedItem!.project.projectNumber
    );
    FileInformationStore.busyReleasedHistoryItem[release.releasedFileId] = false;
    if (ForgeViewerStore.errorMessage !== null) setShowErrorModal(true);
  };

  return (
    <>
      <li className={Style.item}>
        <div className={Style.revision}>Revision {release.formattedRevision}</div>
        {release.suitabilityCode && release.suitabilityTitle && (
          <div className={Style.suitability}>{`${release.suitabilityCode} ${release.suitabilityTitle}`}</div>
        )}
        <HistoryDate dateTime={item.dateTime} />
        <div>
          <SecondaryButton
            className={Style.buttonFullWidth}
            disabled={FileInformationStore.busyReleasedHistoryItem[release.releasedFileId]}
            onClick={() => {
              if (isOverLimit) {
                setShowDownloadModal(true);
              } else {
                FileInformationStore.download(release);
              }
            }}>
            Download
          </SecondaryButton>
        </div>
        <div>
          {release.hasDocumentViewer && (
            <SecondaryButton
              className={Style.buttonFullWidth}
              disabled={FileInformationStore.busyReleasedHistoryItem[release.releasedFileId]}
              onClick={() => FileInformationStore.openFile(release)}>
              Open File
            </SecondaryButton>
          )}
        </div>
        <div>
          {release.isForgeFile && (
            <SecondaryButton
              className={Style.buttonFullWidth}
              disabled={FileInformationStore.busyReleasedHistoryItem[release.releasedFileId]}
              onClick={() => handleForgeViewer()}>
              Open File
            </SecondaryButton>
          )}
        </div>
      </li>
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FileInformationStore.download(release);
          setShowDownloadModal(false);
        }}
      />
      {showErrorModal && <ErrorModal closeModal={() => setShowErrorModal(false)} />}
    </>
  );
};

export default observer(HistoryRelease);
