import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import TaskInformationStore from './TaskInformationStore';
import SecondaryButton from '../shared/SecondaryButton';
import Style from './styles/TaskInformationFiles.module.scss';
import TaskInformationFileAction from './TaskInformationFileAction';
import DownloadModal from '../shared/DownloadModal';
import { getFileSizeDetails } from '../../utils/miscUtils';

const TaskInformationFiles: FC = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(TaskInformationStore.taskFilesSize);
  return (
    <>
      <Table
        headers={[{ label: 'Filename' }, { label: 'Original Filename' }, { label: 'Action', align: 'right' }]}
        breakpoint={10}
        hoverable>
        {TaskInformationStore.task?.taskFiles.map((taskFile) => (
          <TableRow key={taskFile.fileId}>
            <TableCell>{taskFile.fileTitle}</TableCell>
            <TableCell>{taskFile.originalFilename}</TableCell>
            <TaskInformationFileAction taskFile={taskFile} />
          </TableRow>
        ))}
      </Table>
      <SecondaryButton
        className={Style.downloadAllButton}
        onClick={() => {
          if (isOverLimit) {
            setShowDownloadModal(true);
          } else {
            TaskInformationStore.downloadAllTaskFiles();
          }
        }}
        disabled={TaskInformationStore.anyTaskFilesBusy}>
        Download all files
      </SecondaryButton>
      <DownloadModal
        closeModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        downloadAction={() => {
          TaskInformationStore.downloadAllTaskFiles();
          setShowDownloadModal(false);
        }}
        fileSize={totalFileSize}
      />
    </>
  );
};

export default observer(TaskInformationFiles);
