import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createHashHistory } from 'history';

const hashHistory = createHashHistory();
const reactPlugin = new ReactPlugin();
let appInsightsInstance: ApplicationInsights;

const initAppInsights = (instrumentationKey: string): void => {
  appInsightsInstance = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: hashHistory },
      },
    },
  });
  appInsightsInstance.loadAppInsights();
};

const getAppInsightsInstance = (): ApplicationInsights => {
  if (!appInsightsInstance) {
    throw new Error("App Insights hasn't been initialized");
  }

  return appInsightsInstance;
};

export { initAppInsights, getAppInsightsInstance, reactPlugin };
