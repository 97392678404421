import * as React from 'react';
import { observer } from 'mobx-react-lite';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';

const NavBarSelectorAll: React.FC = () => {
  return (
    <NavBarSelectorItemContainer>
      <span>All</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorAll);
