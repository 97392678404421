import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TransmittalResponseCard from './TransmittalResponseCard';
import Styles from './styles/TransmittalResponse.module.scss';
import { ITransmittalMessage } from '../../api/authenticated/transmittals/getTransmittal';

export interface ITransmittalResponseProps {
  transmittalMessages: ITransmittalMessage[] | undefined;
  onSelected?(id: number): void;
}

const TransmittalResponse: FC<ITransmittalResponseProps> = (props) => {
  const [selectedResponseId, setSelectedResponseId] = useState(0);

  const selectResponse = (id: number) => {
    setSelectedResponseId(id);
    props.onSelected && props.onSelected(id);
  };

  if (!props.transmittalMessages) return <div>Page not found</div>;

  return (
    <div className={Styles.responsePanel}>
      <h2>Response</h2>
      {props.transmittalMessages.map((response) => {
        return (
          <div key={response.id} onClick={() => selectResponse(response.id)}>
            <TransmittalResponseCard
              response={response}
              isSelected={response.id === selectedResponseId}></TransmittalResponseCard>
          </div>
        );
      })}
    </div>
  );
};

export default observer(TransmittalResponse);
