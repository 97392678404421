import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import FileList from './FileList';
import Reviewers from './Reviewers';
import Header from './Header';
import AllReviewersToggle from './AllReviewersToggle';
import Suitability from './Suitability';
import Message from './Message';
import ErrorMessage from './ErrorMessage';
import ActionButtons from './ActionButtons';
import Style from './styles/ReviewModal.module.scss';
import { useReviewStore } from './ReviewStoreContext';
import ErrorModal from '../../shared/ErrorModal';

const ReviewModalContainer: FC = () => {
  const store = useReviewStore();

  return (
    <>
      {store.errorMessage === null ? (
        <CentreOnPage>
          <Overlay />
          <Modal className={Style.modal}>
            <CloseButton onClick={() => !store.isSaving && store.closeModal()} disabled={store.isSaving} />
            <Header />
            <div className={Style.modalBody}>
              <FileList />
              <Reviewers />
              <AllReviewersToggle />
              <Suitability />
              <Message />
              <ErrorMessage />
            </div>
            <ActionButtons />
          </Modal>
        </CentreOnPage>
      ) : (
        <ErrorModal closeModal={() => store.closeModal()} />
      )}
    </>
  );
};

export default observer(ReviewModalContainer);
