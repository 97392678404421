import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../shared/Icon';
import Style from './styles/TeamChart.module.scss';
import { ITaskTeamUserGroup } from './interface/TeamChart.interface';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import { TaskTeamRoleText } from '../../common/constants/TaskTeamRoleText';
import { TaskTeamRole } from '../../common/enums/TaskTeamRole';
import UserTooltipModal from './UserTooltipModal';

interface IUserTooltipProps {
  user: ITaskTeamUserGroup;
}

const TaskUserTooltip: FC<IUserTooltipProps> = (props) => {
  const { user } = props;
  const renderItemContent = (user) => {
    return (
      <div className={Style.itemTooltip}>
        <div>
          <span className={Style.userName}>{user.name}</span>
        </div>
        {!!user.taskTeamRoleIds.length && (
          <div className={Style.deliveryTeamRoleGroup}>
            <div className={`${Style.deliveryTeamRoleTitle} ${Style.taskTeam}`}>
              <div className={Style.deliveryTeamRolePersonIcon}>
                <Icon name="people" className={Style.listIcon}></Icon>
              </div>
              <div>{TaskTeamRoleText[TaskTeamRole.Approver]}</div>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={Style.teamChartUserItem}>
      {(user.disabled || user.check) && (
        <Tooltip cssClass={Style.tooltipWrapper} show={<div>User has been selected</div>} defaultUp={false}>
          {renderItemContent(user)}
        </Tooltip>
      )}
      {!user.disabled && !user.check && renderItemContent(user)}
      <div className={Style.iconInfoWrapper}>
        <Tooltip
          cssClass={Style.tooltipWrapper}
          show={
            <UserTooltipModal
              initials={user.initials}
              name={user.name}
              email={user.email}
              groupTitle={'Task Team User'}
            />
          }
          defaultUp={false}>
          <div className={Style.itemTooltip}>
            <div className={Style.iconInfo}>
              <Icon name="info_outlined" className={Style.listIcon}></Icon>
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
export default observer(TaskUserTooltip);
