import { PublicClientApplication, EventType, IPublicClientApplication } from '@azure/msal-browser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let msalInstance: IPublicClientApplication = null as any;

export const initMsalClient = (clientId: string, authority: string): void => {
  const instance = new PublicClientApplication({
    auth: {
      clientId,
      authority,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  });

  // Default to using the first account if no account is active on page load
  if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    instance.setActiveAccount(instance.getAllAccounts()[0]);
  }

  // This will update account state if a user signs in from another tab or window
  instance.enableAccountStorageEvents();

  (instance as IPublicClientApplication).addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      instance.setActiveAccount(account);
    }
  });

  msalInstance = instance;
};
