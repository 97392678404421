import { serverAxios } from '../api';

export interface IAddFileRequest {
  taskTeamId: number;
  filename: string;
  totalFileSize: number;
  totalFileChunks: number;
  fieldValue1?: string;
  fieldValue2?: string;
  fieldValue3?: string;
  fieldValue4?: string;
  fieldValue5?: string;
  fieldValue6?: string;
  fieldValue7?: string;
  fieldValue8?: string;
  fieldValue9?: string;
  fieldValue10?: string;
  fieldValue11?: string;
  fieldValue12?: string;
  fieldValue13?: string;
  fieldValue14?: string;
  fieldValue15?: string;
  fieldValue16?: string;
  fieldValue17?: string;
  fieldValue18?: string;
  fieldValue19?: string;
  fieldValue20?: string;
}

export interface IAddFileResponse {
  fileId: number;
  fileUploadId: number;
}

export async function addFile(request: IAddFileRequest, abortSignal?: AbortSignal): Promise<IAddFileResponse> {
  const result = await serverAxios.post<IAddFileResponse>('api/cms/addfile', request, {
    signal: abortSignal,
  });
  return result.data;
}
