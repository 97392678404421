import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import { ReactComponent as ClientLogo } from '../../assets/client-logo.svg';
import { ReactComponent as TucanaLogo } from '../../assets/tucana-logo.svg';
import { ReactComponent as TucanaFooterLogo } from '../../assets/tucana-footer-logo.svg';
import { ReactComponent as AureconLogo } from '../../assets/aurecon-logo.svg';
import { loginRequest } from '../../auth/MsalConfig';
import { classNames } from '../../utils/miscUtils';
import { Links } from '../../common/constants/Links';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';

import Style from './styles/Login.module.scss';
import { InteractionStatus } from '@azure/msal-browser';
import AppStore from '../../stores/AppStore';

const Login: FC = () => {
  const { instance, inProgress } = useMsal();

  const handleButtonClick = (url: string): void => {
    window.open(url, '_blank', 'noopener');
  };

  const handleLogin = (): void => {
    instance.loginRedirect({
      ...loginRequest,
      scopes: [...loginRequest.scopes, ...[AppStore.settings?.appApiScope ?? '']],
    });
  };

  if (inProgress == InteractionStatus.Logout) return null;

  return (
    <div className={classNames(Style.loginPage, 'login')}>
      <div className={Style.leftGradient} />
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <ClientLogo height={100} width={245} />
        </div>
        <div className={Style.content}>
          <TucanaLogo />
          <p>Bringing project teams together through automation, collaboration, and visualisation</p>
          <SecondaryButton onClick={handleLogin}>Login</SecondaryButton>
        </div>
        <div className={Style.footer}>
          <div className={Style.tucanaLogo}>
            <TucanaFooterLogo height={25} />
            <AureconLogo height={40} />
          </div>
          <PrimaryButton className={Style.textOnly} onClick={() => handleButtonClick(Links.contactPortal)}>
            Contact Us
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default observer(Login);
