import { makeAutoObservable, runInAction } from 'mobx';
import ProjectUserManagementStore from '../ProjectUserManagementStore';
import { IDeliveryTeam } from '../../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { getUsersByNameAndEmail, IUser } from '../../shared/UserSelector';
import { addUsersToDeliveryTeamRole } from '../../../api/authenticated/um/addUsersToDeliveryTeamRole';
import AppointedPartiesStore from './AppointedPartiesStore';
import LayoutStore from '../../layout/LayoutStore';

export class AddDeliveryTeamAuthoriserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public deliveryTeam: IDeliveryTeam | null = null;
  public users: IUser[] = [];
  public selectedUsers: IUser[] = [];
  public isSaving = false;

  public show(deliveryTeam: IDeliveryTeam) {
    if (!ProjectUserManagementStore.project) return;

    AppointedPartiesStore.showModal('AddDeliveryTeamAuthoriser');

    runInAction(() => {
      this.deliveryTeam = deliveryTeam;
      const authorisers = deliveryTeam.users.map((user) => user.id);

      this.users = (deliveryTeam.taskTeams ?? [])
        .map((taskTeam) => taskTeam?.users ?? [])
        .filter((arr) => arr.length)
        .reduce((firstArr, secondArr) => [...firstArr, ...secondArr], [])
        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
        .filter((user) => !authorisers.some((authoriser) => authoriser === user.id))
        .map((u) => ({ id: u.id, name: u.name, email: u.email }));

      this.selectedUsers = [];
      this.isSaving = false;
    });
  }

  public getMatchedUsers(text: string): IUser[] {
    return getUsersByNameAndEmail(this.users, text);
  }

  public setSelectedUsers(users: IUser[]) {
    runInAction(() => {
      this.selectedUsers = users;
    });
  }

  public async save() {
    if (!ProjectUserManagementStore.project || !this.deliveryTeam || this.selectedUsers.length === 0) return;

    runInAction(() => {
      this.isSaving = true;
    });

    try {
      await addUsersToDeliveryTeamRole({
        deliveryTeamId: this.deliveryTeam.id,
        projectNumber: ProjectUserManagementStore.project.projectNumber,
        userIds: this.selectedUsers.map((u) => u.id),
        deliveryTeamRoleId: DeliveryTeamRole.Authoriser,
      });

      LayoutStore.displayToast('success', 'User role has been changed successfully.');

      AppointedPartiesStore.loadAppointedPartiesUsers();
      AppointedPartiesStore.closeModal();
    } catch {
      AppointedPartiesStore.showModal('Error');
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  }
}

export default new AddDeliveryTeamAuthoriserStore();
