import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from '../../FilesStore';
import { ContentSelection } from '../../ContentSelection';
import { Text } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/NewFileFromTemplate.module.scss';
import SecondaryButton from '../../../shared/SecondaryButton';
import PrimaryButton from '../../../shared/PrimaryButton';
import FileMetadataForm from './FileMetadataForm';

const NewFileFromTemplate: FC = () => {
  const onCancelButtonClick = async (): Promise<void> => await FilesStore.setSelectedSection(ContentSelection.WIP);

  const title =
    FilesStore.selectedSection === ContentSelection.AddBlankDocument
      ? `Blank ${FilesStore.selectedFileType}`
      : 'From Template';
  return (
    <div className={Style.container}>
      <Text type="h2">
        <b>{`Create New ${title} in Work In Progress`}</b>
      </Text>
      <FileMetadataForm />
      <div className={Style.createButtons}>
        <SecondaryButton onClick={onCancelButtonClick}>Cancel</SecondaryButton>
        <PrimaryButton>Create</PrimaryButton>
      </div>
    </div>
  );
};

export default observer(NewFileFromTemplate);
