import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/PageHeading.module.scss';

export interface IPageHeadingProps {
  subPage?: boolean;
}

const PageHeading: FC<IPageHeadingProps> = ({ subPage, children }) => {
  return <h1 className={classNames([!subPage, Style.heading], [!!subPage, Style.subHeading])}>{children}</h1>;
};

export default observer(PageHeading);
