import { SortTypes } from '../../../common/enums/SortType';
import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';
import { ITransmittal } from './getTransmittal';

export interface ISearchTransmittalResult {
  pageResult: IPagedResponse<ITransmittal>;
  transmittalSummary: ITransmittalSummary;
}

export interface ITransmittalSummary {
  totalInitiatedByMe: number;
  totalMyNotifications: number;
  totalVisibleToMe: number;
}

export interface ISearchTransmittal {
  notificationToMe?: boolean;
  initiatedByMe?: boolean;
  visibleToMe?: boolean;
  searchText?: string;
  transmittalTypeIds?: number[];
  transmittalReasonIds?: number[];
  closed?: boolean | null;
  flagged?: boolean;
  overdue?: boolean;
  sortColumn?: string;
  sortDirection?: SortTypes.ASC | SortTypes.DESC;
  projectNumber: string | null;
  page?: number;
  pageSize?: number;
}

export async function searchTransmittals(
  options: ISearchTransmittal,
  abortSignal?: AbortSignal
): Promise<ISearchTransmittalResult> {
  const result = await serverAxios.post<{
    transmittalSummary: ITransmittalSummary;
    pageResult: IPagedResponse<ITransmittal>;
  }>(`api/transmittals/search`, options, {
    signal: abortSignal,
  });
  return {
    pageResult: result.data?.pageResult || [],
    transmittalSummary: result.data?.transmittalSummary || {
      totalInitiatedByMe: 0,
      totalMyNotifications: 0,
      totalVisibleToMe: 0,
    },
  };
}
