import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import TaskInformationStore from './TaskInformationStore';
import { HistoryItemType } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationHistory.module.scss';
import TaskInformationHistoryApproved from './TaskInformationHistoryApproved';
import TaskInformationHistoryAssigned from './TaskInformationHistoryAssigned';
import TaskInformationHistoryReassigned from './TaskInformationHistoryReassigned';
import TaskInformationHistoryRejected from './TaskInformationHistoryRejected';

const TaskInformationHistory: FC = () => {
  const historyItems = TaskInformationStore.historyItems.map((item, index) => {
    let component;
    switch (item.historyItemType) {
      case HistoryItemType.ApprovedBy:
        component = <TaskInformationHistoryApproved item={item} />;
        break;
      case HistoryItemType.AssignedBy:
        component = <TaskInformationHistoryAssigned item={item} />;
        break;
      case HistoryItemType.ReassignedTo:
        component = <TaskInformationHistoryReassigned item={item} />;
        break;
      case HistoryItemType.RejectedBy:
        component = <TaskInformationHistoryRejected item={item} />;
        break;
    }
    return (
      <li key={index} className={Style.historyItem}>
        {component}
      </li>
    );
  });
  return <ol className={Style.history}>{historyItems}</ol>;
};

export default observer(TaskInformationHistory);
