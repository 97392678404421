import { makeAutoObservable, runInAction } from 'mobx';
import AppStore from '../../../stores/AppStore';
import { orderBy } from 'lodash';
import { Item, NavigationItemTypes, ProjectItem, TaskTeamItem } from '../../../common/models/ItemType';

const localStorageSelectorKey = 'files-selector-id';

export class NavBarSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public items: Item[] = [];
  public selectedItem?: TaskTeamItem | ProjectItem;
  public navbarSelected?: boolean = true;

  public initSelector(navbarSelected?: boolean) {
    runInAction(() => {
      this.items = this.buildOptionItems();
      this.navbarSelected = navbarSelected;
      this.setDefaultItemSelected();
    });
  }

  private setDefaultItemSelected() {
    let id = this.getGlobalItemSelected();
    if (!id) {
      id = localStorage.getItem(localStorageSelectorKey) ?? this.items[0]?.id;
    }
    const predicate = (itemId: string, itemType: NavigationItemTypes, typeToCheck: NavigationItemTypes) =>
      itemId === id && itemType === typeToCheck;
    const hasTaskTeams = this.items.some((p) => predicate(p.id, p.type, NavigationItemTypes.TaskTeam));

    runInAction(() => {
      this.selectedItem = hasTaskTeams
        ? (this.items.find((p) => predicate(p.id, p.type, NavigationItemTypes.TaskTeam)) as TaskTeamItem)
        : (this.items.find((p) => predicate(p.id, p.type, NavigationItemTypes.Project)) as ProjectItem);

      if (this.selectedItem) {
        this.selectedItem.navbarSelected = this.navbarSelected;
      }

      if (!this.selectedItem && this.items.length) {
        this.selectedItem = hasTaskTeams
          ? (this.items.find((p) => p.type === NavigationItemTypes.TaskTeam) as TaskTeamItem)
          : (this.items.find((p) => p.type === NavigationItemTypes.Project) as ProjectItem);
      }
      this.updateGlobalNavItemSelected(this.selectedItem);
    });
  }

  private getGlobalItemSelected() {
    let id: string | null = null;
    let globalTaskTeamItem: Item | undefined;
    if (
      AppStore.selectedProjectNumber &&
      (AppStore.selectedDeliveryTeamCode || AppStore.selectedDeliveryTeamId) &&
      (AppStore.selectedTaskTeamCode || AppStore.selectedDeliveryTeamId)
    ) {
      globalTaskTeamItem = this.items.find(
        (x) =>
          x.type === NavigationItemTypes.TaskTeam &&
          x.project.projectNumber === AppStore.selectedProjectNumber &&
          (x.taskTeam.deliveryTeamCode === AppStore.selectedDeliveryTeamCode ||
            x.taskTeam.deliveryTeamId === AppStore.selectedDeliveryTeamId) &&
          (x.taskTeam.code === AppStore.selectedTaskTeamCode || x.taskTeam.id === AppStore.selectedTaskTeamId)
      );
    }
    if (globalTaskTeamItem) {
      return globalTaskTeamItem.id;
    }

    if (AppStore.selectedProgrammeId && AppStore.selectedProjectNumber) {
      const globalItem = this.items.filter(
        (x) =>
          x.programme.id === AppStore.selectedProgrammeId &&
          x.type === NavigationItemTypes.Project &&
          x.project.projectNumber === AppStore.selectedProjectNumber
      );
      id = globalItem[0]?.id ?? null;
    }
    return id;
  }

  private buildOptionItems() {
    const items: Item[] = [];

    if (AppStore.client) {
      const sortedProgrammes = orderBy(AppStore.client.programmes, 'title', 'asc');
      for (const programme of sortedProgrammes) {
        items.push({ id: `programmeId:${programme.id}`, type: NavigationItemTypes.Programme, programme });

        const sortedProjects = orderBy(programme.projects, ['projectNumber', 'title'], 'asc');
        for (const project of sortedProjects) {
          items.push({
            id: `projectId:${project.projectNumber}`,
            type: NavigationItemTypes.Project,
            programme,
            project,
            disabled: project.taskTeams.length > 0,
          });

          const sortedTaskTeams = orderBy(project.taskTeams, ['deliveryTeamTitle', 'title'], 'asc');
          for (const taskTeam of sortedTaskTeams) {
            items.push({
              id: `taskTeamId:${taskTeam.id}`,
              type: NavigationItemTypes.TaskTeam,
              programme,
              project,
              taskTeam,
            });
          }
        }
      }
    }
    return items;
  }

  public setSelectedSelectorItem(id: string) {
    const item = this.items.find((p) => p.id === id);
    if (
      !item ||
      (item.type !== NavigationItemTypes.TaskTeam && item.type !== NavigationItemTypes.Project) ||
      item.disabled
    )
      return;

    this.updateGlobalNavItemSelected(item);

    runInAction(() => {
      this.selectedItem = { ...item, navbarSelected: true };
      this.navbarSelected = true;
    });
  }

  private updateGlobalNavItemSelected(item: ProjectItem | TaskTeamItem) {
    AppStore.setProgrammeId(item.programme.id);
    AppStore.setProjectNumber(item.project.projectNumber);
    if (item.type === NavigationItemTypes.TaskTeam) {
      AppStore.setDeliveryTeamCode(item.taskTeam.deliveryTeamCode);
      AppStore.setTaskTeamCode(item.taskTeam.code);
      AppStore.setDeliveryTeamId(item.taskTeam.deliveryTeamId);
      AppStore.setTaskTeamId(item.taskTeam.id);
    }

    localStorage.setItem(localStorageSelectorKey, item.id);
  }
}

export default new NavBarSelectorStore();
