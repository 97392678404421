import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformationDetails.module.scss';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { formatDate } from '../../utils/dateUtils';
import TaskInformationStore from './TaskInformationStore';
import TaskInformationAssignedBy from './TaskInformationAssignedBy';
import TaskInformationAssignedTo from './TaskInformationAssignedTo';

const TaskInformationDetails: FC = () => {
  if (TaskInformationStore.task === null) return null;

  return (
    <ul className={Style.panel}>
      <li className={Style.message}>{TaskInformationStore.task.message}</li>
      <li className={Style.column}>
        <div className={Style.label}>Workflow</div>
        <div>{TaskTypeText[TaskInformationStore.task.taskTypeId]}</div>
      </li>
      <li className={Style.column}>
        <div className={Style.label}>Suitability</div>
        <div>{TaskInformationStore.task.suitabilityTitle}</div>
      </li>
      <li className={Style.divider}></li>
      <li className={Style.column}>
        <div className={Style.label}>Start Date</div>
        <div>{formatDate(TaskInformationStore.task.createdDate)}</div>
      </li>
      <li className={Style.column}>
        <div className={Style.label}>Last Updated</div>
        <div>{formatDate(TaskInformationStore.task.lastUpdatedDate)}</div>
      </li>
      <li className={Style.divider}></li>
      <li className={Style.column}>
        <div className={Style.label}>Assigned by</div>
        <TaskInformationAssignedBy />
      </li>
      <li className={Style.column}>
        <div className={Style.label}>Assigned to</div>
        <TaskInformationAssignedTo />
      </li>
    </ul>
  );
};

export default observer(TaskInformationDetails);
