import { AppointingPartyRole } from '../../../common/enums/AppointingPartyRole';
import { serverAxios } from '../api';

export interface IAddUsersToAppointingPartyRoleRequest {
  projectNumber: string;
  userIds: number[];
  appointingPartyRoleId: AppointingPartyRole;
}

export function addUsersToAppointingPartyRole(
  request: IAddUsersToAppointingPartyRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/appointingparty/users/addrole', request, {
    signal: abortSignal,
  });
}
