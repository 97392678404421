import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { serverAxios } from '../api';

export interface IAddUsersToTaskTeamRoleRequest {
  projectNumber: string;
  taskTeamId: number;
  userIds: number[];
  taskTeamRoleId: TaskTeamRole;
}

export function addUsersToTaskTeamRole(
  request: IAddUsersToTaskTeamRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/taskteam/users/addrole', request, {
    signal: abortSignal,
  });
}
