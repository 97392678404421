export const Pages = {
  Home: { Route: '/' },
  Login: { Route: '/login' },
  TermsConditions: { Route: '/terms-conditions' },
  PrivacyPolicy: { Route: '/privacy-policy' },
  Files: { Route: '/files' },
  Tasks: { Route: '/tasks' },
  Visualisation: { Route: '/visualisation' },
  ProjectSettings: { Route: '/project-settings' },
  Project: { Route: '/project' },
  Transmittals: { Route: '/transmittals' },
};
