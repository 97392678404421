import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { getForgeViewerAuthenticate } from '../../../api/authenticated/cms/getForgeViewerAuthenticate';

export class ForgeViewerStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  public urn = '';
  public token = '';
  public showForgeViewerModal = false;
  public isLoading = false;
  public errorMessage: string | null = null;

  public async getForgeViewer(fileRevisionId: number, projectNumber: string) {
    try {
      this.isLoading = true;
      this.errorMessage = null;
      const response = await getForgeViewerAuthenticate({
        request: {
          projectNumber: projectNumber,
          fileRevisionId: fileRevisionId,
        },
      });
      runInAction(() => {
        this.urn = response.urn;
        this.token = response.token;
        this.showForgeViewerModal = true;
        this.isLoading = false;
      });
    } catch (errors) {
      this.isLoading = false;
      this.errorMessage = (errors as AxiosError<string>)?.response?.data ?? 'Failed getting Forge file details.';
      return errors;
    }
  }

  public forgeViewerModalClose() {
    this.showForgeViewerModal = false;
  }
}

export default new ForgeViewerStore();
