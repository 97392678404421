import { groupBy, mapValues, uniq } from 'lodash';
import { IChunk } from '../common/interfaces/fileUpload';

const kb = 1024;
const mb = kb * 1024;
const gb = mb * 1024;
export const fileSizeUnits = {
  KB: kb,
  MB: mb,
  GB: gb,
};
export function scrollToTop(): void {
  window.scrollTo(0, 0);
}

/**
 * Combines class names into a string so it can be passed to a component's className property.
 * Handles undefined and null.
 * Can optionally supply a tuple of class names with the first element a boolean to either include class names or not.
 * For example classNames([true, 'class1', 'class2'], 'class3') this will give 'class1 class2 class3'
 * @param classNames one or more class names or a tuple with the first element a boolean and the remainder class names
 * @returns a string that includes all the class names
 */
export function classNames(
  ...classNames: (string | undefined | null | [boolean, ...(string | undefined | null)[]])[]
): string {
  const classNamesToInclude: string[] = [];
  classNames.forEach((className) => {
    if (!className) return;

    if (Array.isArray(className)) {
      if (className[0]) {
        className.forEach((childClassName, index) => {
          if (index > 0 && childClassName) classNamesToInclude.push(childClassName as string);
        });
      }
      return;
    }

    if (typeof className === 'string') classNamesToInclude.push(className);
  });
  return uniq(classNamesToInclude).join(' ');
}

export function getFileSizeDetails(size: number) {
  const mbLimit = mb * 500;

  let unit = 'MB';
  let mbSize = size / mb;

  if (Math.round(mbSize * 100) / 100 === 0) {
    mbSize = size;
    unit = 'KB';
  }

  const fileSizeDetails = { totalFileSize: `${mbSize.toFixed(2)} ${unit}`, isOverLimit: false };

  if (size > mbLimit) {
    fileSizeDetails.isOverLimit = true;
  }
  return fileSizeDetails;
}

export const getFileSizeString = (size: number): string => {
  const kb = 1024;
  const mb = kb * 1024;
  const gb = mb * 1024;

  if (size < mb) return `${(size / kb).toFixed(2)} KB`;

  if (size < gb) return `${(size / mb).toFixed(2)} MB`;

  return `${(size / gb).toFixed(2)} GB`;
};

export function isAlphanumeric(value: string) {
  return !/[^a-zA-Z0-9]+/.test(value);
}

export function isAlphanumericSpace(value: string) {
  return !/[^a-zA-Z0-9 ]+/.test(value);
}

export function isAlphanumericUpper(value: string) {
  return !/[^A-Z0-9]+/.test(value);
}

export function validateEmail(value: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export const calculateChunks = (file: File, chunkSize: number) => {
  const chunks: IChunk[] = [];
  let index = 0;
  let offset = 0;
  while (offset < file.size) {
    const start = offset;
    const end = Math.min(offset + chunkSize, file.size);
    chunks.push({ index, start, end });
    index++;
    offset = end;
  }
  return chunks;
};

export const multiGroupBy = (seq, keys) => {
  if (!keys.length) return seq;
  const first = keys[0];
  const rest = keys.slice(1);
  return mapValues(groupBy(seq, first), (value) => multiGroupBy(value, rest));
};
