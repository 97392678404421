import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import AppointedPartiesStore from './AppointedPartiesStore';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/Modal.module.scss';
import ModalActions from '../../shared/ModalActions';

const DeleteDeliveryTeamUserModal: FC = () => {
  if (!AppointedPartiesStore.deliveryTeamUserToBeRemoved) return null;
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Deleting Delivery Team Authoriser?</h1>
        <p>
          This action will revoke User&apos;s access as a Delivery Team Authoriser from{' '}
          {AppointedPartiesStore.deliveryTeamOfUserToBeRemoved?.title}. This action does not remove User&apos;s access
          as a Task Team Member. Are you sure you want to delete{' '}
          {AppointedPartiesStore.deliveryTeamUserToBeRemoved?.name}?
        </p>
        {AppointedPartiesStore.isRemovingUser ? (
          <Loader cssClass={Style.savingSpinner} size="small" />
        ) : (
          <ModalActions>
            <SecondaryButton onClick={AppointedPartiesStore.removeUserFromDeliveryTeam}>Yes</SecondaryButton>
            <PrimaryButton onClick={() => AppointedPartiesStore.setRemoveDeliveryTeamUser(null, null)}>
              No
            </PrimaryButton>
          </ModalActions>
        )}
      </Modal>
    </CentreOnPage>
  );
};

export default observer(DeleteDeliveryTeamUserModal);
