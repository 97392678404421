import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SupersedeFilesUploadModal.module.scss';
import SecondaryButton from '../../../shared/SecondaryButton';
import PrimaryButton from '../../../shared/PrimaryButton';
import UploadStore from './UploadStore';
import CentreOnPage from '../../../shared/CentreOnPage';
import Modal from '../../../shared/Modal';
import CloseButton from '../../../shared/CloseButton';
import Overlay from '../../../shared/Overlay';
import ModalActions from '../../../shared/ModalActions';
import SupersedeUploadFilesForm from './SupersedeUploadFilesForm';
import ProgressBar from './ProgressBar';
import SupersedeSelectedFiles from './SupersedeSelectedFiles';
import FilesStore from '../../FilesStore';
import { fetchUnlockFile } from '../../../../api/authenticated/cms/unlockFile';

interface ISupersedeFileUploadProps {
  closeModal: () => void;
  fileId: number;
  fileRevisionId: number | null;
  fileExtension?: string;
  showModal: boolean;
}
const SupersedeFileUpload: FC<ISupersedeFileUploadProps> = (props) => {
  const { closeModal, showModal, fileId, fileRevisionId, fileExtension } = props;
  const handleCloseModal = () => {
    UploadStore.clear();
    if (closeModal) closeModal();
  };
  const onUploadButtonClick = () => {
    UploadStore.handleUploadSupersedeFile(fileId, fileRevisionId);
  };
  const handleFileListChange = (files) => {
    UploadStore.validationUploadFiles(files);
  };
  useEffect(() => {
    if (!showModal) {
      UploadStore.clear();
    }
  }, [showModal]);

  const beforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = 'Are you sure you want to leave this page?';
    return event.returnValue;
  };

  const onUnload = useCallback(() => {
    if (!FilesStore.getTaskTeamId) return;
    const data = {
      fileId: props.fileId,
      taskTeamId: FilesStore.getTaskTeamId,
    };
    fetchUnlockFile(data);
  }, [props.fileId]);

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    window.addEventListener('unload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
      window.removeEventListener('unload', onUnload);
    };
  }, [onUnload]);

  if (!showModal) return null;

  return (
    <>
      <CentreOnPage>
        <Overlay />
        <Modal className={Style.container}>
          <CloseButton onClick={handleCloseModal} disabled={false} />
          <SupersedeUploadFilesForm multiple={false} acceptedExtension={fileExtension} />
          <SupersedeSelectedFiles onFileListChange={(files) => handleFileListChange(files)} />
          <ModalActions>
            <SecondaryButton onClick={handleCloseModal}>Cancel</SecondaryButton>
            <PrimaryButton disabled={UploadStore.isSupersedeFileUploadDisabled()} onClick={() => onUploadButtonClick()}>
              Upload
            </PrimaryButton>
          </ModalActions>
        </Modal>
      </CentreOnPage>
      {UploadStore.showProgressBar && <ProgressBar />}
    </>
  );
};

export default observer(SupersedeFileUpload);
