import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Text } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/SupersedeFileUpload.module.scss';
import SupersedeFileDropZone from './SupersedeFileDropZone';
import UploadStore from './UploadStore';
interface IUploadFilesFormProps {
  multiple: boolean;
  disabled?: boolean;
  acceptedExtension?: string;
}
const SupersedeUploadFilesForm: FC<IUploadFilesFormProps> = (props) => {
  const handleOnDropzoneChange = (acceptedFiles) => {
    UploadStore.setShowMetadataWarning(props.disabled ?? false);
    if (!props.disabled) {
      UploadStore.addFiles(acceptedFiles, props.multiple, props.acceptedExtension);
    }
  };

  return (
    <>
      {!UploadStore.openResultsModal && (
        <div className={Style.fromWrapper}>
          <div>
            <Text type="h3">Upload File(s)</Text>
          </div>
          <div className={Style.uploadForm}>
            <SupersedeFileDropZone
              loading={UploadStore.isProcessing}
              multiple={props.multiple}
              disabled={props.disabled}
              onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles)}
            />
            {UploadStore.isSelectedInvalidExtension === true && (
              <p className={Style.validationError}>
                Please choose files that have the same extension as the original [
                <strong>*.{props.acceptedExtension}</strong>].
                {UploadStore.isSelectedInvalidExtension}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default observer(SupersedeUploadFilesForm);
