import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ITransmittalMessage } from '../../api/authenticated/transmittals/getTransmittal';
import UserCircle from '../shared/UserCircle';
import { formatDate } from '../../utils/dateUtils';
import Styles from './styles/TransmittalResponseCard.module.scss';
import Icon from '../shared/Icon';
import classNames from 'classnames';
interface ITransmittalResponseCard {
  response: ITransmittalMessage;
  isSelected: boolean;
}

const TransmittalResponseCard: FC<ITransmittalResponseCard> = (props) => {
  const { response, isSelected } = props;

  const [showMessage, setShowMessage] = useState(false);
  return (
    <div className={classNames(Styles.card, { [Styles.cardFocus]: isSelected })}>
      <ol className={Styles.fields}>
        <li className={Styles.fieldValue}>
          <UserCircle initials={response.userInitials} />
          <div className={Styles.responseName}>{response.createdByUserName}</div>
          <div className={Styles.responseTime}>{formatDate(response.createdDate)}</div>
        </li>
        <li
          className={classNames(Styles.responseDetails, { [Styles.responseDetailShow]: showMessage })}
          onClick={() => setShowMessage(true)}>
          {response.subject}{' '}
          <span dangerouslySetInnerHTML={{ __html: response.message }} className={Styles.responseMessage} />
        </li>
        <li className={Styles.fieldValue}>
          <Icon name="notifications" className={Styles.icon}></Icon>
          <span className={Styles.countValue}>
            {response.transmittalMessageNotifyTaskTeamUsers.length +
              response.transmittalMessageNotifyExternalUsers.length}
          </span>
        </li>
      </ol>
    </div>
  );
};

export default observer(TransmittalResponseCard);
