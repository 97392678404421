import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Toggle } from '@aurecon-creative-technologies/styleguide';
import VisualisationStore from '../VisualisationStore';
import Style from './styles/LayersWidget.module.scss';

const ANIMATE_DURATION = 500;

interface ILayer {
  id: string;
  title: string;
  active: boolean;
  onToggle: (active: boolean, layerId: string) => void;
}

const LayersWidget: FC = () => {
  useEffect(() => {
    if (VisualisationStore.mapLayers && VisualisationStore.mapLayers.length > 0) {
      const selLayers: string[] = [];

      VisualisationStore.mapLayers.forEach((l) => {
        if (l.visible) {
          selLayers.push(l.id);
        }
      });
      VisualisationStore.setSelectedlayers(selLayers);
    }
  }, []);

  const handleClearSelection = () => {
    VisualisationStore.clearSelectedLayers();
    VisualisationStore.mapLayers.forEach((l) => {
      if (l.visible) {
        l.visible = false;
      }
    });
  };

  const activeLayers = useMemo(() => {
    const selLayers = VisualisationStore.selectedLayers;
    return selLayers.length > 0;
  }, []);

  const handleSetLayers = useCallback((active: boolean, layerId: string) => {
    if (active) {
      VisualisationStore.addToSelectedLayers(layerId);
    } else {
      VisualisationStore.removeFromSelectedLayers(layerId);
    }

    const sellayer = VisualisationStore.mapLayers.find((l) => l.id === layerId);
    if (!sellayer) return;
    sellayer.visible = active;
  }, []);

  const zoomToLayerExtent = (layerId: string) => {
    if (!VisualisationStore.mapView) return;

    const sellayer = VisualisationStore.mapLayers.find((l) => l.id === layerId);

    VisualisationStore.mapView
      .goTo(sellayer?.fullExtent, { animate: true, duration: ANIMATE_DURATION, easing: 'linear' })
      .catch(() => {
        /* don't display the error*/
      });
  };

  const LayerToggle = ({ id, title, active, onToggle }: ILayer) => {
    return (
      <div className={Style.toggle}>
        <Toggle
          label={title}
          onChange={(active) => onToggle(active, id)}
          value={active}
          hideValue
          labelPosition="right"
        />
        <Button
          type="icon-round-secondary"
          icon="fit_screen"
          size="extra small"
          onClick={() => zoomToLayerExtent(id)}
        />
      </div>
    );
  };

  return (
    <div>
      <Button
        type="text"
        onClick={handleClearSelection}
        label="Clear all selection"
        cssClass={activeLayers ? Style.clear : `${Style.clear} ${Style.inactive}`}
        icon="layers_clear"
      />
      <div className={Style.mapLayers}>
        {VisualisationStore.mapLayers &&
          VisualisationStore.mapLayers.map((layer) => {
            return (
              <div key={layer.id}>
                <LayerToggle
                  id={layer.id}
                  title={layer.title}
                  active={VisualisationStore.isLayerSelected(layer.id)}
                  onToggle={handleSetLayers}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default observer(LayersWidget);
