import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IGroupTaskTeamUser } from './interface/TeamChart.interface';
import TaskTeamUserGroupItem from './TaskTeamUserGroupItem';
import Style from './styles/TeamChart.module.scss';

interface ITaskTeamUserGroupProps {
  taskTeamUser: IGroupTaskTeamUser;
}

const TaskTeamUserGroup: FC<ITaskTeamUserGroupProps> = (props) => {
  const { taskTeamUser } = props;
  const [taskTeamUserGroups, setTaskTeamUserGroups] = useState(taskTeamUser.taskTeamUserGroups);

  useEffect(() => {
    if (taskTeamUser.isFilterApprovers) {
      const filterApprovers = [
        ...taskTeamUser.taskTeamUserGroups.map((x) => {
          return {
            key: x.key,
            values: [...x.values.filter((y) => !!y.taskTeamRoleIds.length)],
          };
        }),
      ];
      setTaskTeamUserGroups(filterApprovers.filter((x) => !!x.values.length));
    } else {
      setTaskTeamUserGroups(taskTeamUser.taskTeamUserGroups);
    }
  }, [taskTeamUser.isFilterApprovers, taskTeamUser.taskTeamUserGroups]);

  return (
    <div className={Style.userGroupScroll}>
      {taskTeamUserGroups.map((userGroup) => (
        <TaskTeamUserGroupItem
          key={`${taskTeamUser.deliveryTeamId}-${taskTeamUser.taskTeamId}-${userGroup.key}`}
          deliveryTeamId={taskTeamUser.deliveryTeamId}
          taskTeamId={taskTeamUser.taskTeamId}
          values={userGroup.values}
          initialKey={userGroup.key}
        />
      ))}
    </div>
  );
};
export default observer(TaskTeamUserGroup);
