import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import SecondaryIconButton from '../../../shared/SecondaryIconButton';
import { FormInput } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/TeamSettingsInput.module.scss';
import Icon from '../../../shared/Icon';
import AppStore from '../../../../stores/AppStore';

export interface ITeamSettingsInputProps {
  namePlaceHolder: string;
  name: string;
  transmittalCode: string;
  valid: boolean;
  error: string | null;
  onNameChange: (value: string) => void;
  onTransmittalCodeChange: (value: string) => void;
  onAdd: () => void;
}

const TeamSettingsInput: FC<ITeamSettingsInputProps> = ({
  namePlaceHolder,
  name,
  transmittalCode,
  valid,
  error,
  onNameChange,
  onTransmittalCodeChange,
  onAdd,
}) => {
  return (
    <div className={Style.container}>
      <div className={Style.row}>
        <FormInput
          cssClass={Style.name}
          label=""
          placeholder={namePlaceHolder}
          required
          value={name}
          onChange={onNameChange}
          onKeyDown={(key) => {
            if (valid && key === 'Enter') onAdd();
          }}
        />
        <FormInput
          cssClass={Style.transmittalCode}
          label=""
          placeholder="Enter Abbreviation"
          required
          multilineLimit={AppStore.client?.namingCodeMaximumLength || 0}
          value={transmittalCode}
          onChange={onTransmittalCodeChange}
          onKeyDown={(key) => {
            if (valid && key === 'Enter') onAdd();
          }}
        />
        <SecondaryIconButton className={Style.add} icon="add" disabled={!valid} onClick={onAdd} />
      </div>
      <div className={Style.validation}>
        {!!error && (
          <div className={Style.error}>
            {error} <Icon name="error" />
          </div>
        )}
        {!!valid && (
          <div className={Style.valid}>
            <Icon name="check_circle" />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TeamSettingsInput);
