import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { makeAutoObservable, runInAction } from 'mobx';
import ProjectUserManagementStore from '../ProjectUserManagementStore';
import {
  IDeliveryTeam,
  getDeliveryTeamsTaskTeamsUsers,
  ITaskTeam,
  IDeliveryTeamUser,
} from '../../../api/authenticated/um/getDeliveryTeamsTaskTeamsUsers';
import LayoutStore from '../../layout/LayoutStore';
import { IRemoveTaskTeamUser, removeUserFromTaskTeam } from '../../../api/authenticated/um/removeUserFromTaskTeam';
import { removeUserFromTaskTeamRole } from '../../../api/authenticated/um/removeUserFromTaskTeamRole';
import { addUsersToTaskTeamRole } from '../../../api/authenticated/um/addUsersToTaskTeamRole';
import { removeUserFromDeliveryTeamRole } from '../../../api/authenticated/um/removeUserFromDeliveryTeamRole';
import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { ITaskTeamUser } from '../../../api/authenticated/um/interfaces/user.interface';

export type ModalTypes =
  | 'AddDeliveryTeamAuthoriser'
  | 'AddTaskTeamMember'
  | 'Error'
  | 'TeamSettings'
  | 'RemoveDeliveryTeamAuthoriser'
  | 'RemoveTaskTeamMember';

export class AppointedPartiesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public searchText = '';
  public isRemovingUser = false;
  public appointedPartiesUsers: IDeliveryTeam[] = [];
  public appointedTaskTeamRoles: IDropdownItemProps[] = [
    {
      id: 1,
      label: 'Task Team Approver',
    },
  ];
  public activePanelIds = new Set<string>();
  public deliveryTeamOfUserToBeRemoved: IDeliveryTeam | null = null;
  public deliveryTeamUserToBeRemoved?: IDeliveryTeamUser | null = null;
  public taskTeamOfUserToBeRemoved: ITaskTeam | null = null;
  public taskTeamUserToBeRemoved: ITaskTeamUser | null = null;
  public showingModal: ModalTypes | null = null;
  public isUserInOtherTaskTeams = true;

  private isUserMatchedToSearchText(user: { name: string; email: string }) {
    return user.name.toLowerCase().includes(this.searchText) || user.email.toLowerCase().includes(this.searchText);
  }

  private deliveryTeamAuthoriserMatchesSearchText(user: IDeliveryTeamUser) {
    return (
      this.isUserMatchedToSearchText(user) &&
      user.deliveryTeamRoleIds.find((role) => role === DeliveryTeamRole.Authoriser)
    );
  }

  private userWithAuthoriserRole(user: IDeliveryTeamUser) {
    return user.deliveryTeamRoleIds.find((role) => role === DeliveryTeamRole.Authoriser);
  }

  public getDeliveryTeams() {
    if (!this.searchText) return this.appointedPartiesUsers;

    return this.appointedPartiesUsers
      .filter(
        (deliveryTeam) =>
          deliveryTeam.users.some((user) => this.isUserMatchedToSearchText(user)) ||
          deliveryTeam.taskTeams.some((taskTeam) => taskTeam.users.some((user) => this.isUserMatchedToSearchText(user)))
      )
      .map((deliveryTeam) => ({
        ...deliveryTeam,
        users: deliveryTeam.users.filter((user) => this.deliveryTeamAuthoriserMatchesSearchText(user)),
        taskTeams: deliveryTeam.taskTeams
          .filter((taskTeam) => taskTeam.users.some((user) => this.isUserMatchedToSearchText(user)))
          .map((taskTeam) => ({
            ...taskTeam,
            users: taskTeam.users.filter((user) => this.isUserMatchedToSearchText(user)),
          })),
      }));
  }

  public async loadAppointedPartiesUsers() {
    if (!ProjectUserManagementStore.project) return;

    try {
      const deliveryTeams = await getDeliveryTeamsTaskTeamsUsers(ProjectUserManagementStore.project.projectNumber);

      const filteredData = this.filterOnDeliveryTeamAuthoriser(deliveryTeams);
      this.sortData(filteredData);

      runInAction(() => {
        this.appointedPartiesUsers = filteredData;
      });
    } catch {
      runInAction(() => {
        this.appointedPartiesUsers = [];
      });
    }
  }

  public filterOnDeliveryTeamAuthoriser(deliveryTeams: IDeliveryTeam[]) {
    return deliveryTeams.map((deliveryTeam) => ({
      ...deliveryTeam,
      users: deliveryTeam.users.filter((user) => this.userWithAuthoriserRole(user)),
      taskTeams: deliveryTeam.taskTeams,
    }));
  }

  public sortData(users: IDeliveryTeam[]) {
    users.sort((a, b) => (a.title > b.title ? 1 : -1));
    users.forEach(
      (u) =>
        u.users.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)) &&
        u.taskTeams.sort((a, b) => (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? 1 : -1)) &&
        u.taskTeams.forEach((t) =>
          t.users.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1))
        )
    );
  }

  public setSearchText(value: string) {
    runInAction(() => {
      this.searchText = value.toLowerCase();
    });
    if (value) this.expandAll();
    else this.openFirstPanel();
  }

  public handlePanelToggle(id: string) {
    const newIds = new Set<string>(this.activePanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activePanelIds = newIds;
    });
  }

  public openFirstPanel() {
    const deliveryTeams = this.getDeliveryTeams();
    if (!deliveryTeams.length) return;

    const newIds = new Set<string>([`D-${deliveryTeams[0].id}`]);

    runInAction(() => {
      this.activePanelIds = newIds;
    });
  }

  public collapseAll() {
    runInAction(() => {
      this.activePanelIds = new Set<string>();
    });
  }

  public expandAll() {
    const deliveryTeams = this.getDeliveryTeams();
    if (!deliveryTeams.length) return;

    const newIds = new Set<string>();

    for (const deliveryTeam of deliveryTeams) {
      newIds.add(`D-${deliveryTeam.id}`);
      for (const taskTeam of deliveryTeam.taskTeams) {
        newIds.add(`T-${taskTeam.id}`);
      }
    }

    runInAction(() => {
      this.activePanelIds = newIds;
    });
  }

  public setRemoveTaskTeamUser(data: IRemoveTaskTeamUser) {
    this.showModal('RemoveTaskTeamMember');
    this.validateUserInDeliveryTeamAuthoriser(data.deliveryTeam, data.taskTeam, data.taskTeamUser);
    runInAction(() => {
      this.taskTeamUserToBeRemoved = data.taskTeamUser;
      this.taskTeamOfUserToBeRemoved = data.taskTeam;
      this.deliveryTeamOfUserToBeRemoved = data.deliveryTeam;
    });
  }

  public setRemoveDeliveryTeamUser(selectedTeam: IDeliveryTeam | null, selectedUser: IDeliveryTeamUser | null) {
    this.showModal('RemoveDeliveryTeamAuthoriser');
    runInAction(() => {
      this.deliveryTeamOfUserToBeRemoved = selectedTeam;
      this.deliveryTeamUserToBeRemoved = selectedUser;
    });
  }

  public async removeUserFromDeliveryTeam() {
    if (!ProjectUserManagementStore.project) return;

    if (!this.deliveryTeamOfUserToBeRemoved) return;

    if (!this.deliveryTeamUserToBeRemoved) return;

    runInAction(() => {
      this.isRemovingUser = true;
    });

    try {
      await removeUserFromDeliveryTeamRole({
        deliveryTeamRoleId: DeliveryTeamRole.Authoriser,
        projectNumber: ProjectUserManagementStore.project.projectNumber,
        userId: this.deliveryTeamUserToBeRemoved.id,
        deliveryTeamId: this.deliveryTeamOfUserToBeRemoved.id,
      });

      this.loadAppointedPartiesUsers();

      LayoutStore.displayToast('success', 'User has been deleted successfully');
    } catch {
      runInAction(() => {
        this.showModal('Error');
      });
    } finally {
      runInAction(() => {
        this.isRemovingUser = false;
        this.deliveryTeamOfUserToBeRemoved = null;
        this.deliveryTeamUserToBeRemoved = null;
      });
    }
  }

  public async removeUserFromTaskTeam() {
    if (!ProjectUserManagementStore.project) return;

    const taskTeam = this.taskTeamOfUserToBeRemoved;
    if (!taskTeam) return;

    const userId = this.taskTeamUserToBeRemoved?.id;
    if (!userId) return;

    runInAction(() => {
      this.isRemovingUser = true;
    });

    try {
      await removeUserFromTaskTeam({
        projectNumber: ProjectUserManagementStore.project.projectNumber,
        taskTeamId: taskTeam?.id,
        userId: userId,
      });

      this.loadAppointedPartiesUsers();

      LayoutStore.displayToast('success', 'User has been deleted successfully');
    } catch {
      runInAction(() => {
        this.showModal('Error');
      });
    } finally {
      runInAction(() => {
        this.isRemovingUser = false;
        this.taskTeamOfUserToBeRemoved = null;
        this.taskTeamUserToBeRemoved = null;
        this.isUserInOtherTaskTeams = true;
      });
    }
  }

  public async changeTaskTeamUserRole(
    selectedTaskTeam: ITaskTeam,
    selectedUser: ITaskTeamUser,
    newRoles: (string | number)[]
  ) {
    if (!ProjectUserManagementStore.project || selectedUser == null) return;

    try {
      let updated = false;

      const removedRole = selectedUser.taskTeamRoleIds.filter((r) => !newRoles.includes(r));
      if (removedRole.length) {
        updated = true;
        await removeUserFromTaskTeamRole({
          taskTeamRoleId: removedRole[0],
          projectNumber: ProjectUserManagementStore.project.projectNumber,
          userId: selectedUser.id,
          taskTeamId: selectedTaskTeam.id,
        });
      }

      const newRole = newRoles.filter((r) => !selectedUser.taskTeamRoleIds.includes(Number(r)));
      if (newRole.length) {
        updated = true;
        await addUsersToTaskTeamRole({
          taskTeamRoleId: Number(newRole[0]),
          projectNumber: ProjectUserManagementStore.project.projectNumber,
          userIds: [selectedUser.id],
          taskTeamId: selectedTaskTeam.id,
        });
      }

      if (updated) {
        this.loadAppointedPartiesUsers();
        LayoutStore.displayToast('success', 'User role has been changed successfully');
      }
    } catch {
      runInAction(() => {
        this.showModal('Error');
      });
    }
  }

  public showModal(type: ModalTypes) {
    runInAction(() => (this.showingModal = type));
  }

  public closeModal() {
    runInAction(() => {
      this.showingModal = null;
    });
  }

  public validateUserInDeliveryTeamAuthoriser(
    deliveryTeam: IDeliveryTeam | null,
    taskTeam: ITaskTeam | null,
    user: ITaskTeamUser | null
  ) {
    this.isUserInOtherTaskTeams = true;
    if (!deliveryTeam || !taskTeam || !user) return;

    if (!deliveryTeam.users.map((dtuser) => dtuser.id).includes(user.id)) return;

    const otherTaskTeams = deliveryTeam.taskTeams.filter((t) => t != taskTeam);

    otherTaskTeams.forEach((t) => {
      if (this.isUserInOtherTaskTeams) {
        if (!t.users.map((u) => u.id).includes(user.id)) {
          this.isUserInOtherTaskTeams = false;
        }
      }
    });
  }
}

export default new AppointedPartiesStore();
