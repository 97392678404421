import React, { useEffect } from 'react';
import { Dropdown, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import NavBarSelectorStore from './NavBarSelectorStore';
import NavBarSelectorProject from './NavBarSelectorProject';
import NavBarSelectorProgramme from './NavBarSelectorProgramme';
import NavBarSelectorAll from './NavBarSelectorAll';

interface INavBarSelectorProp {
  disable: boolean;
}

const NavBarSelector: React.FC<INavBarSelectorProp> = (props) => {
  useEffect(() => {
    NavBarSelectorStore.initSelector();
  }, []);

  const handleOnSelectItem = (id: string | number): void => {
    NavBarSelectorStore.setSelectedSelectorItem(id.toString());
  };

  const items: IDropdownItemProps[] = NavBarSelectorStore.items.map((item) =>
    item.all
      ? {
          id: item.id,
          label: <NavBarSelectorAll />,
        }
      : item.programme
      ? {
          id: item.id,
          label: <NavBarSelectorProgramme title={item.programme.title} />,
        }
      : {
          id: item.id,
          label: <NavBarSelectorProject title={item.project!.title} />,
        }
  );

  return (
    <Dropdown
      size={'small'}
      selectedItem={NavBarSelectorStore.selectedItem?.id}
      items={items}
      onSelectItem={handleOnSelectItem}
      disabled={props.disable}
    />
  );
};

export default observer(NavBarSelector);
