import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from '../FilesStore';
import SecondaryButton from '../../shared/SecondaryButton';
import ReviewModal from '../reviewModal/ReviewModal';
import { TaskType } from '../../../common/enums/TaskType';
import { useSaveTask } from '../customHooks/SaveTaskHook';
import { useGetAddTaskData } from '../customHooks/GetAddTaskDataHook';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from '../styles/TabContainer.module.scss';

interface PublishButtonProps {
  taskTeamId: number;
}

const PublishButton: FC<PublishButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);
  const saveTask = useSaveTask(taskTeamId, TaskType.PublishAuthorise, FilesStore);
  const getAddTaskData = useGetAddTaskData(TaskType.PublishAuthorise, taskTeamId);
  return (
    <>
      {FilesStore.hasSelectedReferenceFiles ? (
        <Tooltip show={'Reference Files selected.'} defaultUp={false} cssClass={Style.toolTipBtn}>
          <SecondaryButton
            disabled={!FilesStore.canPublishReviewOnSelectedFiles || !taskTeamId}
            onClick={() => setIsShowing(true)}>
            Publish
          </SecondaryButton>
        </Tooltip>
      ) : (
        <SecondaryButton
          disabled={!FilesStore.canPublishReviewOnSelectedFiles || !taskTeamId}
          onClick={() => setIsShowing(true)}>
          Publish
        </SecondaryButton>
      )}

      {isShowing && (
        <ReviewModal
          title="Publish"
          description="If the 'Publish' workflow is authorised, the information will be automatically forwarded to the Appointing Party for Acceptance before appearing in the Published Content State."
          closeModal={() => setIsShowing(false)}
          approversLabel="Authorisers"
          approversSearchLabel="Add Authorisers"
          allApproversLabel="Require all authorisers to authorise?"
          isSingleApproverRequired={true}
          showSuitability
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Published"
        />
      )}
    </>
  );
};

export default observer(PublishButton);
