import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from './styles/AddTransmittalMessageActions.module.scss';
import ActionBar from '../../shared/ActionBar';
import ActionBarSection from '../../shared/ActionBarSection';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import AddTransmittalMessageStore from './AddTransmittalMessageStore';
import LayoutStore from '../../layout/LayoutStore';
import AddTransmittalErrorModal from './AddTransmittalErrorModal';
import AddTransmittalUnsavedModal from './AddTransmittalUnsavedModal';
import { Pages } from '../../../common/constants/Pages';
import { useNavigate } from 'react-router-dom';
import SecondaryIconButton from '../../shared/SecondaryIconButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import DropDownMenu from '../../shared/DropDownMenu';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import AppStore from '../../../stores/AppStore';
import { ContentSelection } from '../../files/ContentSelection';
import FilesStore from '../../files/FilesStore';
import NavBarSelectorStore from '../../transmittals/navBarSelector/NavBarSelectorStore';

const AddTransmittalMessageActions: FC = () => {
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);

  const handleTransmit = async () => {
    await AddTransmittalMessageStore.onSubmitTransmittalResponse();
    if (AddTransmittalMessageStore.errorMessage !== null) setShowErrorModal(true);
    else {
      LayoutStore.displayToast(
        'success',
        `Transmittal ${AddTransmittalMessageStore.transmittalResponse?.transmittalTitle} has been responded to successfully.`
      );
      navigate(
        `${Pages.Transmittals.Route}/${AddTransmittalMessageStore.transmittal?.title}/${NavBarSelectorStore.selectedItem?.project?.projectNumber}`
      );
    }
  };

  const handleCancel = () => {
    if (!AddTransmittalMessageStore.transmittal) {
      setShowErrorModal(true);
      return;
    } else if (AddTransmittalMessageStore.hasUnsavedChanges) setShowUnsavedModal(true);
    else
      navigate(
        `${Pages.Transmittals.Route}/${AddTransmittalMessageStore.transmittal.title}/${NavBarSelectorStore.selectedItem?.project?.projectNumber}`
      );
  };

  const handleCloseUploadFileBtnClick = () => {
    AddTransmittalMessageStore.toggleUploadFileModal(true);
  };

  return (
    <>
      <ActionBar className={Styles.actionBar}>
        <ActionBarSection>
          <PrimaryButton onClick={handleTransmit} disabled={AddTransmittalMessageStore.disableTransmitButton}>
            Transmit
          </PrimaryButton>
          <div className={Styles.expandBtn}>
            <DropDownMenu
              label="Add"
              disabled={AddTransmittalMessageStore.disableTransmitButton}
              icon="expand_more"
              iconClassName={Styles.expandBtnIcon}
              itemsContainerClassName={Styles.itemsContainer}
              menuClassName={Styles.btnMenu}>
              {AppStore.client?.user.clientUserRole !== ClientUserRole.External && (
                <DropDownMenuButton
                  disabled={AddTransmittalMessageStore.disableTransmitButton}
                  onClick={() => {
                    AddTransmittalMessageStore.setIsOpenFiles(true);
                    AppStore.setDisableNavigation(true);
                    FilesStore.cleanup();
                    FilesStore.applyFileFilterForTransmittals(
                      ContentSelection.Shared,
                      AppStore.selectedProjectNumber,
                      AppStore.selectedTaskTeamId
                    );
                    FilesStore.setSelectedSection(ContentSelection.Shared);
                  }}>
                  Content Files
                </DropDownMenuButton>
              )}
              <DropDownMenuButton
                disabled={AddTransmittalMessageStore.disableTransmitButton}
                className={Styles.btnMenuUploadBtn}
                onClick={handleCloseUploadFileBtnClick}>
                Supporting Files
              </DropDownMenuButton>
            </DropDownMenu>
          </div>
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        </ActionBarSection>
        <ActionBarSection>
          {AddTransmittalMessageStore.showResponse ? (
            <SecondaryIconButton icon="chat_bubble" onClick={() => AddTransmittalMessageStore.setShowResponse()} />
          ) : (
            <PrimaryIconButton
              icon="chat_bubble"
              onClick={() => AddTransmittalMessageStore.setShowResponse()}
              className={Styles.chatBubbleButton}
            />
          )}
        </ActionBarSection>
      </ActionBar>

      {showErrorModal && (
        <AddTransmittalErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorMessage={AddTransmittalMessageStore.errorMessage ?? undefined}
        />
      )}
      {showUnsavedModal && <AddTransmittalUnsavedModal closeModal={() => setShowUnsavedModal(false)} />}
    </>
  );
};

export default observer(AddTransmittalMessageActions);
