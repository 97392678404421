import { serverAxios } from '../api';

export async function getProjectFileThumbnailUrl(
  projectNumber: string,
  fileId: number | null,
  fileRevisionId: number | null,
  releasedFileId: number | null,
  abortSignal?: AbortSignal
): Promise<string | null> {
  const result = await serverAxios.get<{ url: string | null }>('api/cms/projectfile/thumbnailurl', {
    params: {
      projectNumber,
      fileId,
      fileRevisionId,
      releasedFileId,
    },
    signal: abortSignal,
  });
  return result.data?.url;
}
