import { FileState } from '../../../common/enums/FileState';
import { TaskType } from '../../../common/enums/TaskType';
import { SortType, SortTypes } from '../../../common/enums/SortType';

export class FileFilter {
  fileId?: number;
  projectNumber?: string;
  taskTeamId?: number;
  fileStateId?: FileState;
  page?: number;
  pageSize?: number;
  title?: string;
  originalFilename?: string;
  fieldValue1?: string | null;
  fieldValue2?: string | null;
  fieldValue3?: string | null;
  fieldValue4?: string | null;
  fieldValue5?: string | null;
  fieldValue6?: string | null;
  fieldValue7?: string | null;
  fieldValue8?: string | null;
  fieldValue9?: string | null;
  fieldValue10?: string | null;
  fieldValue11?: string | null;
  fieldValue12?: string | null;
  fieldValue13?: string | null;
  fieldValue14?: string | null;
  fieldValue15?: string | null;
  fieldValue16?: string | null;
  fieldValue17?: string | null;
  fieldValue18?: string | null;
  fieldValue19?: string | null;
  fieldValue20?: string | null;
  taskTypeId?: TaskType | null;
  date1?: string | null;
  date2?: string | null;
  operator?: number | null;
  sortColumn?: string | null;
  sortDirection?: string | null;
  suitabilityCode?: string | null;
  formattedRevision?: string | null;

  setDefaultFilter(
    projectNumber: string,
    fileStateId: FileState,
    page: number,
    pageSize: number,
    taskTeamId?: number
  ): FileFilter {
    this.projectNumber = projectNumber;
    this.taskTeamId = taskTeamId;
    this.fileStateId = fileStateId;
    this.page = page;
    this.pageSize = pageSize;
    return this;
  }

  setPageCondition(page: number, pageSize: number): FileFilter {
    this.page = page;
    this.pageSize = pageSize;
    return this;
  }

  setFileName(fileName?: string) {
    this.title = fileName;
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExtraConditions(json?: any): FileFilter {
    this.title = json?.title;
    this.originalFilename = json?.originalFilename;
    this.fieldValue1 = json?.fieldValue1;
    this.fieldValue2 = json?.fieldValue2;
    this.fieldValue3 = json?.fieldValue3;
    this.fieldValue4 = json?.fieldValue4;
    this.fieldValue5 = json?.fieldValue5;
    this.fieldValue6 = json?.fieldValue6;
    this.fieldValue7 = json?.fieldValue7;
    this.fieldValue8 = json?.fieldValue8;
    this.fieldValue9 = json?.fieldValue9;
    this.fieldValue10 = json?.fieldValue10;
    this.fieldValue11 = json?.fieldValue11;
    this.fieldValue12 = json?.fieldValue12;
    this.fieldValue13 = json?.fieldValue13;
    this.fieldValue14 = json?.fieldValue14;
    this.fieldValue15 = json?.fieldValue15;
    this.fieldValue16 = json?.fieldValue16;
    this.fieldValue17 = json?.fieldValue17;
    this.fieldValue18 = json?.fieldValue18;
    this.fieldValue19 = json?.fieldValue19;
    this.fieldValue20 = json?.fieldValue20;
    this.taskTypeId = json?.taskTypeId;
    if (this.fileStateId === FileState.Wip) {
      this.date1 = json?.modifiedDate?.startDate ?? null;
      this.date2 = json?.modifiedDate?.endDate ?? null;
      this.operator = json?.modifiedDate?.operator ?? null;
    } else {
      this.date1 = json?.releasedDate?.startDate ?? null;
      this.date2 = json?.releasedDate?.endDate ?? null;
      this.operator = json?.releasedDate?.operator ?? null;
    }

    this.suitabilityCode = json?.suitabilityTitle;
    this.formattedRevision = json?.formattedRevision;

    return this;
  }

  setSort(column?: string, direction?: SortType): FileFilter {
    this.sortColumn = column || null;
    if (!direction || direction === 'none') {
      this.sortDirection = null;
    } else {
      this.sortDirection = direction;
    }

    return this;
  }

  setDefaultSort(fileStateId: FileState) {
    const defaultColumn = fileStateId === FileState.Wip ? 'modifiedDate' : 'releasedDate';
    this.setSort(defaultColumn, SortTypes.DESC);
  }

  setFileState(fileStateId: FileState): FileFilter {
    this.fileStateId = fileStateId;
    return this;
  }

  setModifiedFilter(fileStateId: FileState): FileFilter {
    if (
      (this.fileStateId === FileState.Wip && fileStateId !== FileState.Wip) ||
      (this.fileStateId !== FileState.Wip && fileStateId === FileState.Wip)
    ) {
      this.date1 = null;
      this.date2 = null;
      this.operator = null;
    }

    return this;
  }
}
