import { FileState } from '../../../common/enums/FileState';
import { TaskType } from '../../../common/enums/TaskType';
import { serverAxios } from '../api';

export interface IReviewer {
  userId: number;
  name: string;
  email: string;
}

export interface ISuitability {
  id: number;
  code: string;
  title: string;
  isSuitabilityRefCode: boolean | null;
}

export interface IAddTaskData {
  reviewers: IReviewer[];
  suitabilities: ISuitability[];
}

export async function getAddData(
  taskType: TaskType,
  taskTeamId: number,
  abortSignal?: AbortSignal
): Promise<IAddTaskData> {
  const result = await serverAxios.get<IAddTaskData>('api/task/adddata', {
    params: { taskType, taskTeamId },
    signal: abortSignal,
  });
  return result.data;
}

export interface IProjectSuitabilities {
  suitabilities: ISuitability[];
}

export async function getSuitabilities(
  projectNumber: string,
  fileStateId: FileState,
  abortSignal?: AbortSignal
): Promise<ISuitability[]> {
  const result = await serverAxios.get<IProjectSuitabilities>('api/task/suitabilities', {
    params: { projectNumber: projectNumber, fileStateId: fileStateId },
    signal: abortSignal,
  });
  return result.data.suitabilities;
}
