import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { makeAutoObservable, runInAction } from 'mobx';
import { addUsersToAppointingParty } from '../../../api/authenticated/um/addUsersToAppointingParty';
import { addUsersToAppointingPartyRole } from '../../../api/authenticated/um/addUsersToAppointingPartyRole';
import { IUser, getAppointingPartyUsers } from '../../../api/authenticated/um/getAppointingPartyUsers';
import { getUsers } from '../../../api/authenticated/um/getUsers';
import { removeUserFromAppointingParty } from '../../../api/authenticated/um/removeUserFromAppointingParty';
import { removeUserFromAppointingPartyRole } from '../../../api/authenticated/um/removeUserFromAppointingPartyRole';
import { AppointingPartyRoleText } from '../../../common/constants/AppointingPartyRoleText';
import { AppointingPartyRole } from '../../../common/enums/AppointingPartyRole';
import LayoutStore from '../../layout/LayoutStore';
import ProjectUserManagementStore from '../ProjectUserManagementStore';

export interface IBaseUser {
  id: number;
  name: string;
  email: string;
  disabled?: boolean;
}

export class AppointingPartyStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public searchText = '';
  public isRemovingUser = false;
  public isError = false;
  public appointingPartyUsers: IUser[] = [];
  public userToBeRemoved?: IUser | null = null;
  public appointingPartyRoles: IDropdownItemProps[] = [
    {
      id: AppointingPartyRole.Acceptor,
      label: AppointingPartyRoleText[AppointingPartyRole.Acceptor],
    },
  ];
  public isShowAddMembersModal = false;
  public isEnabledAddMemberBtn = false;
  public allUsers: IBaseUser[] = [];
  public selectedUserIds: number[] = [];
  public selectedRoleIds: number[] = [];

  public getAppointingPartyUsers() {
    if (this.searchText && this.appointingPartyUsers) {
      return this.appointingPartyUsers.filter(
        (u) =>
          u.email.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0 ||
          u.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
      );
    } else return this.appointingPartyUsers;
  }

  public async loadAppointingPartyUsers() {
    if (!ProjectUserManagementStore.project) return;

    try {
      const users = await getAppointingPartyUsers(ProjectUserManagementStore.project.projectNumber);
      users.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));

      runInAction(() => {
        this.appointingPartyUsers = users;
      });
    } catch {
      runInAction(() => {
        this.appointingPartyUsers = [];
      });
    }
  }

  public setSearchText(value: string) {
    runInAction(() => {
      this.searchText = value;
    });
  }

  public setRemoveUser(selectedUser: IUser | null) {
    runInAction(() => {
      this.userToBeRemoved = selectedUser;
    });
  }

  public async removeUserFromAppointingParty() {
    if (!ProjectUserManagementStore.project) return;

    const userId = this.userToBeRemoved?.id;
    if (userId == null) return;

    runInAction(() => {
      this.isRemovingUser = true;
    });

    try {
      await removeUserFromAppointingParty({
        projectNumber: ProjectUserManagementStore.project.projectNumber,
        userId: userId,
      });

      this.loadAppointingPartyUsers();

      LayoutStore.displayToast('success', 'User has been deleted successfully');
    } catch {
      runInAction(() => {
        this.isError = true;
      });
    } finally {
      runInAction(() => {
        this.isRemovingUser = false;
        this.userToBeRemoved = null;
      });
    }
  }

  public async changeUserRole(selectedUser: IUser, newRoles: (string | number)[]) {
    if (!ProjectUserManagementStore.project || selectedUser == null) return;

    try {
      let updated = false;

      const removedRole = selectedUser.appointingPartyRoleIds.filter((r) => !newRoles.includes(r));
      if (removedRole.length) {
        updated = true;
        await removeUserFromAppointingPartyRole({
          appointingPartyRoleId: removedRole[0],
          projectNumber: ProjectUserManagementStore.project.projectNumber,
          userId: selectedUser.id,
        });
      }

      const newRole = newRoles.filter((r) => !selectedUser.appointingPartyRoleIds.includes(Number(r)));
      if (newRole.length) {
        updated = true;
        await addUsersToAppointingPartyRole({
          appointingPartyRoleId: Number(newRole[0]),
          projectNumber: ProjectUserManagementStore.project.projectNumber,
          userIds: [selectedUser.id],
        });
      }

      if (updated) {
        this.loadAppointingPartyUsers();
        LayoutStore.displayToast('success', 'User role has been changed successfully');
      }
    } catch {
      runInAction(() => {
        this.isError = true;
      });
    }
  }

  public clearError() {
    runInAction(() => {
      this.isError = false;
    });
  }

  /**
   * Create the data for the select user option.
   */
  public async getOptionUsers() {
    if (!ProjectUserManagementStore.project) return;

    try {
      this.allUsers = [];
      const users = await getUsers(ProjectUserManagementStore.project.projectNumber, true);
      users.sort((a, b) => (a.name > b.name ? 1 : -1));

      runInAction(() => {
        this.allUsers = users.filter(({ id: id1 }) => !this.appointingPartyUsers.some(({ id: id2 }) => id2 === id1));
      });
    } catch {
      runInAction(() => {
        this.allUsers = [];
      });
    }
  }

  /**
   * Filter users matched with users by keyword.
   * @param searchText
   * @returns
   */
  public getMatchedUsers(searchText: string) {
    return this.allUsers
      .filter(
        (r) =>
          (r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1) &&
          !this.selectedUserIds.some((x) => x === r.id)
      )
      .map((r) => ({
        id: r.id,
        name: r.name,
        email: r.email,
        disabled: r.disabled,
      }));
  }

  /**
   * Set selected user ids to be added.
   * @param users
   */
  public onSelectedUsersUpdated(users: IBaseUser[]) {
    this.selectedUserIds = [];
    users.forEach((u) => {
      const user = this.allUsers.find((r) => r.id === u.id);
      if (user) {
        runInAction(() => {
          this.selectedUserIds.push(user.id);
        });
      }
    });
    // Enabled add members button if select at least 1 user.
    this.isEnabledAddMemberBtn = this.selectedUserIds.length > 0;
  }

  public setSelectedRole(role: IDropdownItemProps, checked: boolean) {
    runInAction(() => {
      if (checked) {
        this.selectedRoleIds.push(+role.id);
      } else {
        this.selectedRoleIds = this.selectedRoleIds.filter((id) => id != +role.id);
      }
    });
  }

  public isRoleSelected(role: IDropdownItemProps) {
    return !!this.selectedRoleIds.some((id) => id == +role.id);
  }

  /**
   * Open/Close Add Members modal
   * @param isOpen
   */
  public setIsOpenAddMembersModal(isOpen: boolean) {
    this.isShowAddMembersModal = isOpen;
  }

  /**
   * Reset Add member data
   */
  public resetSelectedUsers() {
    this.allUsers = [];
    this.selectedUserIds = [];
    this.selectedRoleIds = [];
    this.isEnabledAddMemberBtn = false;
  }

  /**
   * Add member to appointing party and assign party role
   * @returns
   */
  public async addMembersToAppointingParty() {
    if (!ProjectUserManagementStore.project || this.selectedUserIds.length == 0) return;

    try {
      // Add user to project appointing party
      const payload = {
        projectNumber: ProjectUserManagementStore.project.projectNumber,
        userIds: this.selectedUserIds,
      };
      await addUsersToAppointingParty(payload);

      // Assign project party role to users selected above
      if (this.selectedRoleIds.length > 0) {
        const assignProjectRolePayload = {
          projectNumber: ProjectUserManagementStore.project.projectNumber,
          userIds: this.selectedUserIds,
          appointingPartyRoleId: this.selectedRoleIds[0],
        };
        await addUsersToAppointingPartyRole(assignProjectRolePayload);
      }

      this.loadAppointingPartyUsers();
      LayoutStore.displayToast('success', 'Users has been added successfully.');
      this.setIsOpenAddMembersModal(false);
      this.resetSelectedUsers();
    } catch {
      runInAction(() => {
        this.isError = true;
        this.setIsOpenAddMembersModal(false);
        this.resetSelectedUsers();
      });
    }
  }
}

export default new AppointingPartyStore();
