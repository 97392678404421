import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import UserSelector, { IUser } from '../../shared/UserSelector';
import { useReviewStore } from './ReviewStoreContext';
import SecondaryButton from '../../shared/SecondaryButton';
import Styles from './styles/Reviewers.module.scss';
import TeamChart from '../../TeamChart/TeamChart';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import AppStore from '../../../stores/AppStore';
import FilesStore from '../FilesStore';
import { ContentSelection } from '../ContentSelection';
import { ITeamChartResult } from '../../TeamChart/interface/TeamChart.interface';
import AuthStore from '../../../stores/AuthStore';

const Reviewers: FC = () => {
  const store = useReviewStore();
  const [showTeamChart, setShowTeamChart] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const handleShowTeamChart = () => {
    setShowTeamChart(true);
  };

  const showAnyoneInTaskTeam = () => {
    if (
      FilesStore.selectedSection === ContentSelection.Published ||
      FilesStore.selectedSection === ContentSelection.Shared
    )
      return true;
    if (
      FilesStore.selectedSection === ContentSelection.WIP &&
      (store.state === 'Review' || store.state === 'Collaborate')
    )
      return true;

    return false;
  };

  const showAnyoneInDeliveryTeam = () => {
    if (
      FilesStore.selectedSection === ContentSelection.Published ||
      FilesStore.selectedSection === ContentSelection.WIP
    )
      return true;

    if (
      FilesStore.selectedSection === ContentSelection.Shared &&
      (store.state === 'Review' || store.state === 'Collaborate')
    )
      return true;

    return false;
  };

  const onTeamChartUserSelected = (teamChartData: ITeamChartResult) => {
    const users: IUser[] = [];
    if (teamChartData.deliveryUsers.length)
      users.push(
        ...teamChartData.deliveryUsers.map((m) => {
          return {
            id: m.id,
            name: m.name,
            email: m.email,
          };
        })
      );

    if (teamChartData.taskTeamUsers.length)
      users.push(
        ...teamChartData.taskTeamUsers.map((m) => {
          return {
            id: m.id,
            name: m.name,
            email: m.email,
          };
        })
      );

    setSelectedUsers(users);
    store.onSelectedUsersUpdated(users);
    setShowTeamChart(false);
  };

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.selectorWrapper}>
          <UserSelector
            label={store.approversLabel}
            required
            searchPlaceholder={store.approversSearchLabel}
            getUsers={store.getMatchedUsers}
            onSelectedUsersUpdated={store.onSelectedUsersUpdated}
            disabled={store.isSaving}
            isMultiUser={true}
            defaultSelectedUsers={selectedUsers}
          />
        </div>
        <div className={Styles.buttonWrapper}>
          <SecondaryButton
            onClick={() => {
              handleShowTeamChart();
            }}>
            Team Chart
          </SecondaryButton>
        </div>
      </div>
      {showTeamChart && NavBarSelectorStore?.selectedItem?.project.projectNumber && (
        <TeamChart
          closeModal={() => setShowTeamChart(false)}
          projectNumber={NavBarSelectorStore.selectedItem.project.projectNumber}
          taskTeamId={
            (FilesStore.selectedSection === ContentSelection.Shared && store.state === 'Collaborate') ||
            (FilesStore.selectedSection === ContentSelection.Published && store.state === 'Collaborate')
              ? undefined
              : AppStore.taskTeamId
          }
          deliveryTeamId={
            (FilesStore.selectedSection === ContentSelection.Shared && store.state === 'Collaborate') ||
            (FilesStore.selectedSection === ContentSelection.Published && store.state === 'Collaborate')
              ? undefined
              : AppStore.deliveryTeamId
          }
          onSelect={onTeamChartUserSelected}
          hideExternalSelection={true}
          showAnyoneInTaskTeam={showAnyoneInTaskTeam()}
          showAnyoneInDeliveryTeam={showAnyoneInDeliveryTeam()}
          disableTaskTeam={FilesStore.selectedSection === ContentSelection.Shared && store.state !== 'Collaborate'}
          disableDeliveryTeam={FilesStore.selectedSection === ContentSelection.WIP}
          excludedUsers={[AuthStore.email]}
        />
      )}
    </>
  );
};

export default observer(Reviewers);
