import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/AddTransmittalUnsavedModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import PrimaryButton from '../../shared/PrimaryButton';
import { Pages } from '../../../common/constants/Pages';
import { useNavigate } from 'react-router-dom';
import AddTransmittalMessageStore from './AddTransmittalMessageStore';
import NavBarSelectorStore from '../../transmittals/navBarSelector/NavBarSelectorStore';

interface IModalProps {
  closeModal: () => void;
}

const AddTransmittalUnsavedModal: FC<IModalProps> = (props) => {
  const navigate = useNavigate();
  const { closeModal } = props;

  const directToTransmittalDetail = () => {
    if (!AddTransmittalMessageStore.transmittal) return;
    navigate(
      `${Pages.Transmittals.Route}/${AddTransmittalMessageStore.transmittal.title}/${NavBarSelectorStore.selectedItem?.project?.projectNumber}`
    );
  };
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>You have unsaved changes on this Transmittal Respond.</h1>
        <div>Are you sure you want to cancel responding?</div>
        <ModalActions>
          <PrimaryButton onClick={directToTransmittalDetail}>Ok</PrimaryButton>
          <PrimaryButton onClick={() => closeModal()}>Cancel</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddTransmittalUnsavedModal);
