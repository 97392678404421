import React, { FC } from 'react';
import { Icon } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import Modal from '../../../shared/Modal';
import CloseButton from '../../../shared/CloseButton';
import PrimaryButton from '../../../shared/PrimaryButton';
import ModalActions from '../../../shared/ModalActions';
import CentreOnPage from '../../../shared/CentreOnPage';
import Overlay from '../../../shared/Overlay';
import UploadStore from './UploadStore';
import Style from './styles/UploadResultsModal.module.scss';
import { CSVLink } from 'react-csv';

interface IUploadResultsModalProps {
  onConfirm: () => void;
}

const UploadResultsModal: FC<IUploadResultsModalProps> = ({ onConfirm }) => {
  const succeededFiles = UploadStore.selectedfiles.filter(
    (f) => !UploadStore.filesFailedToUpload.some((failed) => failed === f.file.name)
  );

  if (!UploadStore.openResultsModal) return null;

  const exportData = UploadStore.getResultsCSVData();
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.resultsModal}>
        <CloseButton onClick={onConfirm} />
        <h1>Upload Results</h1>
        {UploadStore.showUploadSuccess && (
          <div className={Style.message}>
            <Icon cssClass={Style.success} type="check_circle" />
            <p>
              {succeededFiles.length} files have been superseded and uploaded successfully. Click on Export as CSV to
              view the upload results.
            </p>
          </div>
        )}
        {UploadStore.showUploadFailed && !succeededFiles.length && (
          <div className={Style.message}>
            <Icon cssClass={Style.warning} type="warning" />
            <p>
              {UploadStore.filesFailedToUpload.length} files cannot be uploaded due to an error. Click on Export as CSV
              to view the upload results.
            </p>
          </div>
        )}
        {UploadStore.showUploadFailed && !!succeededFiles.length && (
          <div className={Style.message}>
            <Icon cssClass={Style.warning} type="warning" />
            <p>
              Click on Export as CSV to view the upload results.
              <br />
              {succeededFiles.length} Files were uploaded successfully <br />
              {UploadStore.filesFailedToUpload.length} Files failed to upload. Click on Export as CSV for upload result
              details.
            </p>
          </div>
        )}
        <CSVLink
          className={Style.exportButton}
          data={exportData.data}
          headers={exportData.headers}
          filename={exportData.filename}
          target="_blank">
          Export as CSV
        </CSVLink>
        <ModalActions>
          <PrimaryButton onClick={onConfirm}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(UploadResultsModal);
