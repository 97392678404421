import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from './FilesStore';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { ContentSelection } from './ContentSelection';
import { OverflowMenu } from '@aurecon-creative-technologies/styleguide';
import DeleteModal from './wipTab/DeleteModal';
import LayoutStore from '../layout/LayoutStore';
import Style from './styles/DeleteOverflowButton.module.scss';
import { NavigationItemTypes } from '../../common/models/ItemType';

const DeleteOverflowButton: FC = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState<{ deleting: number; deleted: number }>();

  if (
    !loading &&
    !showDeleteModal &&
    NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam &&
    (FilesStore.selectedSection !== ContentSelection.WIP || !NavBarSelectorStore.selectedItem.taskTeam.canDelete)
  )
    return null;

  const closeModal = () => {
    setShowDeleteModal(false);
    setDone(undefined);
  };

  const deleteAction = async () => {
    setLoading(true);
    const deleting = FilesStore.selectedFiles.length;
    const deleted = await FilesStore.deleteSelectedFiles();
    setLoading(false);

    if (deleted == deleting) {
      LayoutStore.displayToast('success', 'Files have been deleted successfully');
      FilesStore.resetSelectedFile();
      closeModal();
    } else {
      setDone({ deleted, deleting });
    }
  };

  return (
    <>
      <OverflowMenu
        disabled={!FilesStore.canDeleteSelectedFiles}
        size="medium"
        items={[{ id: 'delete', label: 'Delete' }]}
        cssClass={Style.overflowButton}
        onSelectItem={() => setShowDeleteModal(true)}
      />
      <DeleteModal
        showModal={showDeleteModal}
        closeModal={closeModal}
        deleteAction={deleteAction}
        message={FilesStore.errorMessage ?? ''}
        loading={loading}
        done={done}
      />
    </>
  );
};

export default observer(DeleteOverflowButton);
