import { makeAutoObservable, runInAction } from 'mobx';
import AppStore from '../../../stores/AppStore';
import { orderBy } from 'lodash';
import { Item, NavigationItemTypes, ProjectItem, TaskTeamItem } from '../../../common/models/ItemType';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';

const localStorageSelectorKey = 'transmittals-selector-id';

export class NavBarSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public items: Item[] = [];
  public selectedItem?: TaskTeamItem | ProjectItem;

  public initSelector() {
    const items: Item[] = [];

    if (AppStore.client) {
      const sortedProgrammes = orderBy(AppStore.client.programmes, 'title', 'asc');
      for (const programme of sortedProgrammes) {
        items.push({ id: `programmeId:${programme.id}`, type: NavigationItemTypes.Programme, programme });

        const sortedProjects = orderBy(programme.projects, ['projectNumber', 'title'], 'asc');
        for (const project of sortedProjects) {
          items.push({
            id: `projectId:${project.projectNumber}`,
            type: NavigationItemTypes.Project,
            programme,
            project,
          });

          const sortedTaskTeams = orderBy(project.taskTeams, ['deliveryTeamTitle', 'title'], 'asc');
          for (const taskTeam of sortedTaskTeams) {
            items.push({
              id: `taskTeamId:${taskTeam.id}`,
              type: NavigationItemTypes.TaskTeam,
              programme,
              project,
              taskTeam,
            });
          }
        }
      }
    }

    runInAction(() => {
      this.items = items;
      this.setDefaultItemSelected();
    });
  }

  private setDefaultItemSelected() {
    let id = this.getGlobalItemSelected();
    if (!id) {
      id =
        localStorage.getItem(localStorageSelectorKey) ??
        this.items.find((p) => p.type === NavigationItemTypes.TaskTeam)?.id ??
        this.items[0]?.id;
    }
    const predicate = (itemId: string, itemType: NavigationItemTypes, typeToCheck: NavigationItemTypes) =>
      itemId === id && itemType === typeToCheck;
    const hasTaskTeams = this.items.some((p) => predicate(p.id, p.type, NavigationItemTypes.TaskTeam));

    runInAction(() => {
      this.selectedItem = hasTaskTeams
        ? (this.items.find((p) => predicate(p.id, p.type, NavigationItemTypes.TaskTeam)) as TaskTeamItem)
        : (this.items.find((p) => predicate(p.id, p.type, NavigationItemTypes.Project)) as ProjectItem);

      if (!this.selectedItem && this.items.length) {
        this.selectedItem = hasTaskTeams
          ? (this.items.find((p) => p.type === NavigationItemTypes.TaskTeam) as TaskTeamItem)
          : (this.items.find((p) => p.type === NavigationItemTypes.Project) as ProjectItem);
      }
      this.updateGlobalNavItemSelected(this.selectedItem);
    });
  }

  private getGlobalItemSelected() {
    let id: string | null = null;
    if (AppStore.selectedProgrammeId && AppStore.selectedProjectNumber) {
      const globalItem = this.items.filter(
        (x) =>
          x.programme.id === AppStore.selectedProgrammeId &&
          (x.type === NavigationItemTypes.Project || x.type === NavigationItemTypes.TaskTeam) &&
          x.project.projectNumber === AppStore.selectedProjectNumber &&
          (!AppStore.selectedDeliveryTeamId ||
            (AppStore.selectedDeliveryTeamId &&
              x.type === NavigationItemTypes.TaskTeam &&
              x.taskTeam.deliveryTeamId === AppStore.selectedDeliveryTeamId)) &&
          (!AppStore.selectedTaskTeamId ||
            (AppStore.selectedTaskTeamId &&
              x.type === NavigationItemTypes.TaskTeam &&
              x.taskTeam.id === AppStore.selectedTaskTeamId))
      );
      id = globalItem.find((p) => p.type === NavigationItemTypes.TaskTeam)?.id ?? globalItem[0]?.id ?? null;
    }
    return id;
  }

  public setSelectedSelectorItem(id: string) {
    const item = this.items.find((p) => p.id === id);
    if ((!item || item.type !== NavigationItemTypes.TaskTeam) && (!item || item.type !== NavigationItemTypes.Project))
      return;

    if (
      (AppStore.client?.user.clientUserRole === ClientUserRole.External && item.type === NavigationItemTypes.Project) ||
      (AppStore.client?.user.clientUserRole !== ClientUserRole.External &&
        (item.type === NavigationItemTypes.TaskTeam || NavigationItemTypes.Project)) ||
      (AppStore.client?.user.clientUserRole !== ClientUserRole.External &&
        item.project.appointingPartyUser &&
        item.type === NavigationItemTypes.Project)
    ) {
      this.updateGlobalNavItemSelected(item);

      runInAction(() => {
        this.selectedItem = item;
      });
    }
  }
  private updateGlobalNavItemSelected(item: TaskTeamItem | ProjectItem) {
    AppStore.setProgrammeId(item.programme.id);
    AppStore.setProjectNumber(item.project.projectNumber);

    if (item.type == NavigationItemTypes.TaskTeam) {
      AppStore.setDeliveryTeamId(item.taskTeam.deliveryTeamId);
      AppStore.setTaskTeamId(item.taskTeam.id);
    }
    localStorage.setItem(localStorageSelectorKey, item.id);
  }
}

export default new NavBarSelectorStore();
