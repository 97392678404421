import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformationAssignedTo.module.scss';
import UserCircle from '../shared/UserCircle';
import TaskInformationStore from './TaskInformationStore';
import TaskUserStatusIndicator from '../shared/TaskUserStatusIndicator';

const TaskInformationAssignedTo: FC = () => {
  if (TaskInformationStore.task === null) return null;

  return (
    <ul className={Style.users}>
      {TaskInformationStore.task.taskUsers.map((user) => (
        <li key={user.userId} className={Style.user}>
          <div className={Style.namePanel}>
            <UserCircle initials={user.initials} email={user.email} />
            <div className={Style.name}>{user.name}</div>
          </div>
          <TaskUserStatusIndicator taskUserStatusId={user.taskUserStatusId} />
        </li>
      ))}
    </ul>
  );
};

export default observer(TaskInformationAssignedTo);
