import { TaskStatus } from '../../../common/enums/TaskStatus';
import { TaskType } from '../../../common/enums/TaskType';
import { TaskUserStatus } from '../../../common/enums/TaskUserStatus';
import { serverAxios } from '../api';

export interface ITask {
  id: number;
  message?: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  completedDate?: Date;
  taskStatusId: TaskStatus;
  taskTypeId: TaskType;
  createdByUserId: number;
  createdByUserName: string;
  createdByUserEmail: string;
  createdByUserInitials: string;
  suitabilityId: number;
  suitabilityCode: string;
  suitabilityTitle: string;
  projectNumber: string;
  taskFiles: ITaskFile[];
  taskUsers: ITaskUser[];
  canApprove: boolean;
  canReject: boolean;
  canReassign: boolean;
}

export interface ITaskFile {
  fileId: number;
  fileRevisionId: number;
  releasedFileId: number | null;
  fileTitle: string;
  originalFilename: string;
  fileSize: number;
  hasDocumentViewer: boolean;
  isForgeFile: boolean;
  fileStateId: number;
  revision: number;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
  uploadedDate: Date;
  uploadedByUserName: string;
  suitabilityCode: string;
  isSuitabilityRefCode: boolean | null;
  suitabilityTitle: string;
  extension: string;
  uploadedSize: number;
  formattedRevision: string;
  deliveryTeamTitle: string;
  taskTeamTitle: string;
}

export interface ITaskUser {
  userId: number;
  name: string;
  email: string;
  initials: string;
  taskUserStatusId: TaskUserStatus;
  message?: string;
  completedDate?: Date;
}

export async function getTask(taskId: number, abortSignal?: AbortSignal): Promise<ITask> {
  const result = await serverAxios.get<ITask>(`api/task/${taskId}`, { signal: abortSignal });
  return result.data;
}
