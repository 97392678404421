import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import CloseButton from '../../shared/CloseButton';
import ModalInfoPanel from './ModalInfoPanel';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';
import AppointedPartiesStore from './AppointedPartiesStore';
import AddTaskTeamMemberStore from './AddTaskTeamMemberStore';
import Styles from './styles/AddTaskTeamMemberModal.module.scss';
import { Checkbox } from '@aurecon-creative-technologies/styleguide';

const AddTaskTeamMemberModal: FC = () => {
  if (!AddTaskTeamMemberStore.deliveryTeam || !AddTaskTeamMemberStore.taskTeam) return null;
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Styles.modal}>
        <CloseButton onClick={AppointedPartiesStore.closeModal} disabled={AddTaskTeamMemberStore.isSaving} />
        <h1>Adding Members</h1>
        <p></p>
        <ModalInfoPanel
          deliveryTeamName={`${AddTaskTeamMemberStore.deliveryTeam.title} (${AddTaskTeamMemberStore.deliveryTeam.transmittalCode})`}
          taskTeamName={`${AddTaskTeamMemberStore.taskTeam.title} (${AddTaskTeamMemberStore.taskTeam.transmittalCode})`}
        />
        <UserSelectorExpandable
          label="User Name"
          required
          searchPlaceholder="Add User name"
          isMultiUser
          getUsers={AddTaskTeamMemberStore.getMatchedUsers}
          onSelectedUsersUpdated={AddTaskTeamMemberStore.setSelectedUsers}
          disabled={AddTaskTeamMemberStore.isSaving}
        />
        <div className={Styles.additionalRole}>Additional Role</div>
        <Checkbox
          cssClass={Styles.approver}
          checked={AddTaskTeamMemberStore.isApprover}
          onChange={AddTaskTeamMemberStore.setIsApprover}
          label="Approver"
          disabled={AddTaskTeamMemberStore.isSaving}
        />
        <ModalActions>
          <SecondaryButton onClick={AppointedPartiesStore.closeModal} disabled={AddTaskTeamMemberStore.isSaving}>
            Cancel
          </SecondaryButton>
          <ButtonSavingSpinner isSaving={AddTaskTeamMemberStore.isSaving}>
            <PrimaryButton
              onClick={AddTaskTeamMemberStore.save}
              disabled={AddTaskTeamMemberStore.selectedUsers.length === 0}>
              Add Members
            </PrimaryButton>
          </ButtonSavingSpinner>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddTaskTeamMemberModal);
