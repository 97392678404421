import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformation.module.scss';
import TaskInformationStore from './TaskInformationStore';
import TaskInformationDetails from './TaskInformationDetails';
import TaskInformationActions from './TaskInformationActions';
import { Loader, TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide';
import TaskInformationHistory from './TaskInformationHistory';
import TaskInformationFiles from './TaskInformationFiles';
import TaskStatusIndicator from '../shared/TaskStatusIndicator';
import { useNavigate, useParams } from 'react-router-dom';
import GoBackButton from '../shared/GoBackButton';
import PageHeading from '../shared/PageHeading';
import FilesStore from '../files/FilesStore';
import FileInformation from '../files/fileInformation/FileInformation';
import { Pages } from '../../common/constants/Pages';
import TasksStore from '../tasks/TasksStore';

const TaskInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    TaskInformationStore.init(Number(params.taskId));
  }, [params]);

  return (
    <>
      <GoBackButton
        onClick={() => {
          if (TaskInformationStore.isRefresh) {
            TaskInformationStore.setIsRefresh(false);
            TasksStore.refreshTasks();
          }

          navigate(Pages.Tasks.Route);
        }}
      />
      {TaskInformationStore.task ? (
        <>
          <PageHeading subPage>Task Information</PageHeading>
          <div className={Style.detailsPanel}>
            <div className={Style.taskdetails}>
              <div className={Style.panel}>
                <h2 className={Style.panelHeading}>
                  #{TaskInformationStore.task.id}
                  <TaskStatusIndicator
                    className={Style.taskStatus}
                    taskStatusId={TaskInformationStore.task.taskStatusId}
                  />
                </h2>
                <div className={Style.panelBody}>
                  <TaskInformationDetails />
                  <TaskInformationActions />
                </div>
              </div>

              <TabMenu
                cssClass={Style.tab}
                type="line"
                tabs={[
                  { id: 1, label: `Files (${TaskInformationStore.task.taskFiles.length})` },
                  { id: 2, label: `History` },
                ]}>
                <TabContent for={1}>
                  <div className={Style.tabContent}>
                    <TaskInformationFiles />
                  </div>
                </TabContent>
                <TabContent for={2}>
                  <div className={Style.tabContent}>
                    <TaskInformationHistory />
                  </div>
                </TabContent>
              </TabMenu>
            </div>
            {FilesStore.isShowingFileInformation && (
              <div className={Style.fileDetails}>
                <FileInformation />
              </div>
            )}
          </div>
        </>
      ) : TaskInformationStore.isLoadingTask ? (
        <Loader label="Loading task..." />
      ) : (
        <div>Task not found</div>
      )}
    </>
  );
};

export default observer(TaskInformation);
