import { makeAutoObservable, runInAction } from 'mobx';
import { closeTransmittal } from '../../api/authenticated/transmittals/closeTransmittal';
import {
  getTransmittal,
  IGroupTeamUsers,
  ITransmittal,
  ITransmittalMessage,
  IUser,
} from '../../api/authenticated/transmittals/getTransmittal';
import { setFlaggedTransmittal } from '../../api/authenticated/transmittals/setFlaggedTransmittal';
import NavBarSelectorStore from '../transmittals/navBarSelector/NavBarSelectorStore';
import { UserManagementTypeEnum } from '../../enums/UserManagementTypeEnum';
import AuthStore from '../../stores/AuthStore';
import AppStore from '../../stores/AppStore';

export class TransmittalDetailsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public isLoadingTransmittal = false;
  public isClosingTransmittal = false;
  public transmittal: ITransmittal | null = null;
  public navbarKey: string | null = null;
  public openPanelIds = new Set<string>();
  public showNotifierModal = false;
  public showVisibleUserModal = false;
  public showResponse = false;
  public activeTaskTeamPanelIds = new Set<string>();
  public selectedResponse?: ITransmittalMessage;
  public projectNumber?: string | null = null;

  public async init(transmittalTitle?: string, projectNumber?: string | null) {
    if (!transmittalTitle) {
      runInAction(() => {
        this.isLoadingTransmittal = false;
        this.transmittal = null;
        this.selectedResponse = undefined;
      });
      return;
    }

    runInAction(() => {
      this.isLoadingTransmittal = true;
      this.transmittal = null;
      this.selectedResponse = undefined;
      this.projectNumber = projectNumber ? projectNumber : NavBarSelectorStore.selectedItem?.project?.projectNumber;
    });
    try {
      const result = await getTransmittal(transmittalTitle, this.projectNumber ?? null);
      runInAction(() => {
        this.transmittal = result?.transmittal ?? null;
        const orderMessages = this.getTransmittalMessages() ?? [];
        this.selectedResponse = orderMessages[0] ?? undefined;
        if (this.transmittal) NavBarSelectorStore.setSelectedSelectorItem(this.getNavKey(this.transmittal));
      });
    } finally {
      runInAction(() => {
        this.isLoadingTransmittal = false;
      });
    }
  }

  public setResponseSelected = (id: number) => {
    const selectedMessage = this.transmittal?.transmittalMessages.find((x) => x.id === id);
    runInAction(() => {
      this.selectedResponse = selectedMessage;
    });
  };

  public async closedTransmittal(transmittalId: number) {
    runInAction(() => {
      this.isClosingTransmittal = true;
    });
    try {
      await closeTransmittal(NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null, transmittalId);
    } finally {
      runInAction(() => {
        this.isClosingTransmittal = false;
      });
    }
  }

  public async flagTransmittal(transmittalId: number, flagged: boolean) {
    runInAction(() => {
      this.isLoadingTransmittal = true;
    });
    try {
      await setFlaggedTransmittal(
        NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
        transmittalId,
        flagged
      );
    } finally {
      runInAction(() => {
        this.isLoadingTransmittal = false;
      });
    }
  }

  public get transmittalNotifications() {
    const sortExternalUsers = [...(this.transmittal?.allNotifiedUsers.filter((x) => x.taskTeamId === 0) || [])];
    sortExternalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));
    return {
      externalUsers: sortExternalUsers,
      taskTeams: this.buildTaskTeamGroup(this.transmittal?.allNotifiedUsers.filter((x) => x.taskTeamId > 0)),
    };
  }

  public get transmittalVisible() {
    const sortExternalUsers = [...(this.transmittal?.transmittalVisibilityExternalUsers || [])];
    sortExternalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    const visibilityUsers = this.transmittal?.transmittalVisibilityAllUsers || [];
    const appointingParties =
      this.transmittal?.transmittalVisibilityAppointingParties.map((ap) => ({
        taskTeamId: ap.id,
        taskTeamTitle: ap.title,
        users: visibilityUsers
          .filter((u) => u.groupId === ap.id)
          .sort((a, b) => (a.userName.toLocaleLowerCase() > b.userName.toLocaleLowerCase() ? 1 : -1))
          .map((u) => ({
            id: u.userId,
            name: u.userName,
            initials: u.initials,
            taskTeamId: u.groupId,
            taskTeamTitle: u.groupTitle,
          })) as IUser[],
        type: UserManagementTypeEnum.AppointingParty,
      })) || [];

    const deliveryTeams =
      this.transmittal?.transmittalVisibilityDeliveryTeams.map((dt) => ({
        taskTeamId: dt.id,
        taskTeamTitle: dt.title,
        users: visibilityUsers
          .filter((u) => u.groupId === dt.id)
          .sort((a, b) => (a.userName.toLocaleLowerCase() > b.userName.toLocaleLowerCase() ? 1 : -1))
          .map((u) => ({
            id: u.userId,
            name: u.userName,
            initials: u.initials,
            taskTeamId: u.groupId,
            taskTeamTitle: u.groupTitle,
          })) as IUser[],
        type: UserManagementTypeEnum.DeliveryTeam,
      })) || [];

    const taskTeams =
      this.transmittal?.transmittalVisibilityTaskTeams.map((tt) => ({
        deliveryTeamTitle: tt.deliveryTeamTitle,
        deliveryTeamCode: tt.deliveryTeamCode,
        taskTeamId: tt.id,
        taskTeamTitle: tt.title,
        users: visibilityUsers
          .filter((u) => u.groupId === tt.id)
          .sort((a, b) => (a.userName.toLocaleLowerCase() > b.userName.toLocaleLowerCase() ? 1 : -1))
          .map((u) => ({
            id: u.userId,
            name: u.userName,
            initials: u.initials,
            taskTeamId: u.groupId,
            taskTeamTitle: u.groupTitle,
          })) as IUser[],
        type: UserManagementTypeEnum.TaskTeam,
      })) || [];

    const teams = [
      ...this.buildTaskTeamGroup(this.transmittal?.transmittalVisibilityTaskTeamUsers),
      ...appointingParties,
      ...deliveryTeams,
      ...taskTeams,
    ];

    return {
      externalUsers: sortExternalUsers,
      taskTeams: teams,
    };
  }

  private buildTaskTeamGroup(users?: IUser[]) {
    const teams: IGroupTeamUsers[] = [];
    if (!users) return [];
    const sortUsers = [...users];
    sortUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    sortUsers?.forEach((element) => {
      if (!teams.some((x) => element.taskTeamId === x.taskTeamId)) {
        teams.push({
          deliveryTeamTitle: element.deliveryTeamTitle,
          taskTeamId: element.taskTeamId,
          taskTeamTitle: element.taskTeamTitle,
          users: [element],
          type: UserManagementTypeEnum.TaskTeam,
        });
      } else {
        const existed = teams.find((x) => x.taskTeamId === element.taskTeamId);
        existed?.users.push(element);
      }
    });

    return teams;
  }

  public handleTaskTeamPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeTaskTeamPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activeTaskTeamPanelIds = newIds;
    });
  }

  public modalClose() {
    runInAction(() => {
      this.activeTaskTeamPanelIds = new Set<string>();
      this.showVisibleUserModal = false;
      this.showNotifierModal = false;
    });
  }

  public panelToggle(id: string) {
    runInAction(() => {
      this.openPanelIds.has(id) ? this.openPanelIds.delete(id) : this.openPanelIds.add(id);
    });
  }

  public getTransmittalMessages() {
    return this.transmittal?.transmittalMessages
      .slice()
      .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());
  }

  public getNotifyUsersFromSelectedResponse() {
    if (!this.selectedResponse) return [];
    return this.selectedResponse.transmittalMessageNotifyTaskTeamUsers.concat(
      this.selectedResponse.transmittalMessageNotifyExternalUsers
    );
  }

  public setShowResponse() {
    this.showResponse = !this.showResponse;
  }

  private getNavKey(transmittal: ITransmittal) {
    const notifiedUser = transmittal.allNotifiedUsers.find(
      (s) => s.email.toLowerCase() === AuthStore.email.toLowerCase()
    );
    if (notifiedUser) return `taskTeamId:${notifiedUser.taskTeamId}`;

    const visibilityTaskTeamUser = transmittal.transmittalVisibilityTaskTeamUsers.find(
      (s) => s.email.toLowerCase() === AuthStore.email.toLowerCase()
    );
    if (visibilityTaskTeamUser) return `taskTeamId:${visibilityTaskTeamUser.taskTeamId}`;

    const initiatorUser = transmittal.createdByEmail.toLowerCase() === AuthStore.email.toLowerCase();
    if (initiatorUser) return `taskTeamId:${transmittal.taskTeamId}`;

    const userTaskTeamIds = AppStore.client?.programmes
      .flatMap((m) => m.projects)
      .flatMap((m) => m.taskTeams)
      .map((m) => m.id);

    const visibilityTaskTeam = transmittal.transmittalVisibilityTaskTeams.find((s) => userTaskTeamIds?.includes(s.id));
    if (visibilityTaskTeam) return `taskTeamId:${visibilityTaskTeam.id}`;

    return `projectId:${transmittal.projectNumber}`;
  }
}

export default new TransmittalDetailsStore();
