import axios, { AxiosRequestTransformer } from 'axios';
import config from '../../config';
import AuthStore from '../../stores/AuthStore';
import { dateTimeFormat, localDueDateFormat, handleDates } from '../../utils/dateUtils';

const dateTransformer: AxiosRequestTransformer = (data) => {
  if (data instanceof FormData) return data;

  if (data instanceof Date) {
    return data ? dateTimeFormat(new Date(data), localDueDateFormat) : null;
  }

  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val));
  }

  if (typeof data === 'object' && data) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
  }

  return data;
};

export const serverAxios = axios.create({
  baseURL: config.serverUri,
  transformRequest: [dateTransformer].concat(axios.defaults.transformRequest as AxiosRequestTransformer[]),
});

serverAxios.interceptors.request.use(
  async (c) => {
    await AuthStore.refreshToken();
    await new Promise((resolve) => setTimeout(resolve, 1000));

    return {
      ...c,
      headers: {
        ...c?.headers,
        Authorization: `Bearer ${AuthStore.accessToken}`,
      },
    };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

serverAxios.interceptors.response.use((response) => {
  handleDates(response.data);
  return response;
});
