import { makeAutoObservable, runInAction } from 'mobx';
import { TransmittalIssueReason } from '../../common/enums/TransmittalIssueReason';
import { TabFilter } from './Types';
import { TransmittalIssueReasonText } from '../../common/constants/TransmittalIssueReasonText';
import { IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { TransmittalGroup } from '../../common/enums/TransmittalGroup';
import { TransmittalGroupText } from '../../common/constants/TransmittalGroupText';
import TransmittalsStore from './TransmittalsStore';
import { ISearchTransmittal } from '../../api/authenticated/transmittals/searchTransmittal';
import { SortTypes } from '../../common/enums/SortType';
import { TransmittalSortOptionText } from '../../common/constants/TransmittalSortOptionText';
import { TransmittalSortOption } from '../../common/enums/TransmittalSortOption';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { getTransmittalTypes, IType } from '../../api/authenticated/transmittals/getTransmittalTypes';

export class ActionBarStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public defaultSortColumn = TransmittalSortOption.LastUpdated;
  public selectedTypeIds: number[] | undefined = [];
  public selectedReasonIds: number[] | undefined = [];
  public closed?: boolean = false;
  public flagged?: boolean = false;
  public overdue?: boolean = false;
  public sortColumn?: string | number = this.defaultSortColumn;
  public sortDirection?: SortTypes.ASC | SortTypes.DESC = SortTypes.DESC;

  public isVisibleFilter = false;

  public searchPayload: ISearchTransmittal = {
    sortColumn: TransmittalSortOptionText[this.defaultSortColumn],
    projectNumber: null,
  };

  public transmittalTypes: IType[] = [];

  public get typeItems(): IDropdownItemProps[] {
    const types: IDropdownItemProps[] = [];

    this.transmittalTypes.forEach((type) => {
      types.push({
        id: Number(type.id),
        label: type.title,
      });
    });

    return types;
  }

  public async getTypes() {
    if (!NavBarSelectorStore.selectedItem) return;
    this.transmittalTypes = await getTransmittalTypes(NavBarSelectorStore.selectedItem.project.projectNumber);
  }

  public get issueReasonItems(): IDropdownItemProps[] {
    const issueReasons: IDropdownItemProps[] = [];
    for (const reason in TransmittalIssueReason) {
      if (!isNaN(Number(reason))) {
        issueReasons.push({
          id: Number(reason),
          label: TransmittalIssueReasonText[reason],
        });
      }
    }
    return issueReasons;
  }

  public get groupItems(): IDropdownItemProps[] {
    const groupItems: IDropdownItemProps[] = [
      { id: TransmittalGroup.Overdue, label: TransmittalGroupText[TransmittalGroup.Overdue], disabled: false },
      { id: TransmittalGroup.Flagged, label: TransmittalGroupText[TransmittalGroup.Flagged], disabled: false },
    ];

    if (
      TransmittalsStore.currentTab === TabFilter.InitiatedByMe ||
      TransmittalsStore.currentTab === TabFilter.VisibleToMe
    ) {
      groupItems.push({
        id: TransmittalGroup.Closed,
        label: TransmittalGroupText[TransmittalGroup.Closed],
        disabled: false,
      });
    }

    return groupItems;
  }

  public sortItems: IDropdownItemProps[] = [
    { id: TransmittalSortOption.LastUpdated, label: TransmittalSortOptionText[TransmittalSortOption.LastUpdated] },
    {
      id: TransmittalSortOption.InitiationDate,
      label: TransmittalSortOptionText[TransmittalSortOption.InitiationDate],
    },
    { id: TransmittalSortOption.DueDate, label: TransmittalSortOptionText[TransmittalSortOption.DueDate] },
  ];

  public setSelectedTab() {
    runInAction(() => {
      TransmittalsStore.setTabFilterCondition();
    });
  }

  public setIsVisibleFilter(isVisible: boolean) {
    runInAction(() => {
      if (
        (this.searchPayload.transmittalTypeIds && this.searchPayload.transmittalTypeIds.length) ||
        (this.searchPayload.transmittalReasonIds && this.searchPayload.transmittalReasonIds.length) ||
        this.searchPayload.closed ||
        this.searchPayload.flagged ||
        this.searchPayload.overdue ||
        (this.searchPayload.sortColumn &&
          this.searchPayload.sortColumn !== TransmittalSortOptionText[this.defaultSortColumn]) ||
        this.searchPayload.sortDirection === SortTypes.ASC
      ) {
        this.isVisibleFilter = true;
      } else {
        this.isVisibleFilter = isVisible;
      }
    });
  }

  public setSelectedType(selectedTypes: (string | number)[]) {
    runInAction(() => {
      this.searchPayload.transmittalTypeIds = selectedTypes.map((t) => Number(t));
    });
  }

  public setSelectedIssueReason(selectedReasons: (string | number)[]) {
    runInAction(() => {
      this.searchPayload.transmittalReasonIds = selectedReasons.map((t) => Number(t));
    });
  }

  public setSelectedGroups(selectedGroups: (string | number)[]) {
    const values = selectedGroups.reduce((prv, cur) => Number(prv) | Number(cur), 0) as TransmittalGroup;
    runInAction(() => {
      this.flagged = (values & TransmittalGroup.Flagged) === TransmittalGroup.Flagged || undefined;

      const overdueTick = this.groupItems.find((x) => x.id === TransmittalGroup.Overdue);
      const closedTick = this.groupItems.find((x) => x.id === TransmittalGroup.Closed);

      if (overdueTick) {
        overdueTick.disabled = (values & TransmittalGroup.Closed) === TransmittalGroup.Closed;
      }

      if (closedTick) {
        closedTick.disabled = (values & TransmittalGroup.Overdue) === TransmittalGroup.Overdue;
      }

      this.closed =
        (closedTick && !closedTick.disabled && (values & TransmittalGroup.Closed) === TransmittalGroup.Closed) ||
        undefined;
      this.overdue =
        (overdueTick && !overdueTick.disabled && (values & TransmittalGroup.Overdue) === TransmittalGroup.Overdue) ||
        undefined;

      this.searchPayload.closed = this.closed;
      this.searchPayload.flagged = this.flagged;
      this.searchPayload.overdue = this.overdue;
    });
  }

  public showSelectAllBtn() {
    return TransmittalsStore.transmittals.some((t) => !t.checked);
  }

  public showSelectClear() {
    return !!TransmittalsStore.totalSelected;
  }

  public clearSelectionTransmittals() {
    TransmittalsStore.clearTransmittalSelected();
  }

  public selectedAllTransmittals() {
    TransmittalsStore.selectAllTransmittal();
  }

  public get selectedGroups(): TransmittalGroup[] {
    const select: number[] = [];
    this.flagged && select.push(TransmittalGroup.Flagged);
    this.closed && select.push(TransmittalGroup.Closed);
    this.overdue && select.push(TransmittalGroup.Overdue);
    return select;
  }

  public setSearchText(searchedText: string) {
    runInAction(() => {
      this.searchPayload.searchText = searchedText;
    });
  }

  public get getCSVExport() {
    return TransmittalsStore.getCSVData;
  }

  public get disabledExportBtn() {
    return TransmittalsStore.totalSelected === 0;
  }

  public get getTotalTransmittalSelected() {
    return TransmittalsStore.totalSelected;
  }

  public setSortColumn(sortedColumn?: string | number) {
    runInAction(() => {
      this.sortColumn = sortedColumn;
      this.searchPayload.sortColumn =
        sortedColumn !== undefined
          ? TransmittalSortOptionText[sortedColumn]
          : TransmittalSortOptionText[this.defaultSortColumn];
      this.sortDirection = SortTypes.DESC;
      this.searchPayload.sortDirection = SortTypes.DESC;
    });
  }

  public setSortOrder() {
    runInAction(() => {
      if (this.sortDirection === SortTypes.DESC) {
        this.sortDirection = SortTypes.ASC;
      } else {
        this.sortDirection = SortTypes.DESC;
      }
      this.searchPayload.sortDirection = this.sortDirection;
    });
  }

  public clear() {
    this.closed = undefined;
    this.flagged = undefined;
    this.overdue = undefined;
    this.sortDirection = SortTypes.DESC;
    this.sortColumn = this.defaultSortColumn;
    this.searchPayload.transmittalTypeIds = undefined;
    this.searchPayload.transmittalReasonIds = undefined;
    this.searchPayload.searchText = undefined;
  }
}

export default new ActionBarStore();
