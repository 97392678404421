import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FileUpload.module.scss';
import UploadStore from './UploadStore';
import FileMetadataForm from './FileMetadataForm';
import UploadFilesForm from './UploadFilesForm';
import ProgressBar from './ProgressBar';
import SelectedFiles from './SelectedFiles';

const FileUpload: FC = () => {
  useEffect(() => {
    return () => {
      UploadStore.unlockSupersedeFiles();
    };
  }, []);

  const handleFileListChange = (files) => {
    UploadStore.validationUploadFiles(files);
  };
  return (
    <>
      <div className={Style.container}>
        <div className={Style.uploadColumn}>
          <div className={Style.metadataWrapper}>
            <FileMetadataForm />
          </div>
          <div className={Style.uploadWrapper}>
            <div className={Style.rightContainer}>
              <UploadFilesForm multiple={true} />
              <SelectedFiles onFileListChange={(files) => handleFileListChange(files)} />
            </div>
          </div>
        </div>
        {UploadStore.showProgressBar && <ProgressBar />}
      </div>
    </>
  );
};

export default observer(FileUpload);
