import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Footer from './Footer';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { classNames } from '../../utils/miscUtils';
import { useToast } from '@aurecon-creative-technologies/styleguide';

import LayoutStore from './LayoutStore';
import AppStore from '../../stores/AppStore';

import Style from './styles/Layout.module.scss';

const Layout: FC = ({ children }) => {
  const { addToast } = useToast();
  LayoutStore.setAddToast(addToast);

  const hideSideBar = AppStore.hasClientInfoError || !AppStore.projects.length;

  return (
    <div className={classNames(Style.layout, [LayoutStore.showSidebar, Style.sidebarOpen])}>
      <NavBar className={Style.navbar} onClick={() => !hideSideBar && LayoutStore.toggleSidebar()} />
      {!hideSideBar && <Sidebar className={Style.sidebar} />}
      <main className={Style.pageContent}>{children}</main>
      <Footer className={Style.footer} />
    </div>
  );
};

export default observer(Layout);
