import { computed, makeAutoObservable, runInAction } from 'mobx';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';

export class ProjectSettingsStore {
  constructor() {
    makeAutoObservable(
      this,
      {
        projects: computed,
        projectsCount: computed,
        cardsPerPage: computed,
        pageCount: computed,
        shownProjectStartIndex: computed,
        shownProjectLastIndex: computed,
      },
      { autoBind: true }
    );
  }

  public searchText = '';
  public itemsPerPage = 8;
  public page = 1;

  public setSearchText(value: string) {
    runInAction(() => {
      this.searchText = value;
    });
  }

  private selectedProjects() {
    if (!NavBarSelectorStore.selectedItem) return [];
    return NavBarSelectorStore.selectedItem.programme.projects.map((project) => ({
      programmeTitle: project.programmeTitle,
      projectNumber: project.projectNumber,
      projectTitle: project.title,
      startDate: project.startDate,
      endDate: project.endDate,
    }));
  }

  private filteredProjects() {
    if (!this.searchText) return this.selectedProjects();
    return this.selectedProjects().filter((project) => {
      const matchesProjectTitle = project.projectTitle?.toLowerCase().includes(this.searchText.toLowerCase());
      const matchesProjectNumber = project.projectNumber?.toLowerCase().includes(this.searchText.toLowerCase());
      return matchesProjectTitle || matchesProjectNumber;
    });
  }

  public get projects() {
    return this.filteredProjects().slice(this.itemsToSkip, this.cardsPerPage);
  }

  public get projectsCount(): number {
    return this.filteredProjects().length;
  }

  public get itemsToSkip() {
    return (this.page - 1) * this.itemsPerPage;
  }

  public get cardsPerPage() {
    return this.page * this.itemsPerPage;
  }

  public get pageCount(): number {
    return Math.ceil(this.projectsCount / this.itemsPerPage) || 1;
  }

  public setPage(index: number) {
    this.page = index;
  }

  public get shownProjectStartIndex(): number {
    return this.itemsToSkip + 1;
  }

  public get shownProjectLastIndex(): number {
    return Math.min(this.cardsPerPage, this.projectsCount);
  }
}

export default new ProjectSettingsStore();
