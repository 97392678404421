import { ComboBox, DateInput, FormInput } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillModules } from '../../common/constants/TextEditor';
import GoBackButton from '../shared/GoBackButton';
import Icon from '../shared/Icon';
import PageHeading from '../shared/PageHeading';
import AddTransmittalMessageVisibilitySelector from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageVisibilitySelector';
import AddTransmittalMessageNotifySelector from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageNotifySelector';
import Styles from '../transmittals/styles/CreateTransmittal.module.scss';
import CreateTransmittalActions from '../transmittals/CreateTransmittalActions';
import CreateTransmittalStore from '../transmittals/CreateTransmittalStore';
import PageNotFound from '../shared/PageNotFound';
import CreateTransmittalFile from './CreateTransmittalFile';
import CreateTransmittalContentFile from './CreateTransmittalContentFile';
import AppStore from '../../stores/AppStore';
import SupportingFilesUploadModal from './supportingFilesUpload/SupportingFilesUploadModal';
import { IAppointingParty, IDeliveryTeam, ITaskTeam } from '../../api/authenticated/um/getProjectMembersAndTeams';
import { SupportFileMode, TransmittalTeamChartType } from './Types';
import TransmittalSupportingFiles from './TransmittalSupportingFiles';
import LayoutStore from '../layout/LayoutStore';
import FilesStore from '../files/FilesStore';
import ErrorModal from '../shared/ErrorModal';
import SecondaryButton from '../shared/SecondaryButton';
import TeamChart from '../TeamChart/TeamChart';
import { ITeamChartResult } from '../TeamChart/interface/TeamChart.interface';

const CreateTransmittal: FC = () => {
  const [showTeamChart, setShowTeamChart] = useState(false);
  const [transmittalTeamChartType, setTransmittalTeamChartType] = useState<TransmittalTeamChartType>('');

  useEffect(() => {
    CreateTransmittalStore.init();
    CreateTransmittalStore.getOptionUsers();
    CreateTransmittalStore.getTypes();
    CreateTransmittalStore.getReasons();
    AppStore.setDisableNavigation(false);

    return () => CreateTransmittalStore.clear();
  }, []);

  const onCloseUploadFileModal = async (uploadSucceeded: boolean, errorMessage?: string) => {
    CreateTransmittalStore.toggleUploadFileModal(false, uploadSucceeded, errorMessage);
  };

  const handleShowTeamChart = () => {
    setShowTeamChart(true);
  };

  const onTeamChartUserSelected = (teamChartData: ITeamChartResult) => {
    if (transmittalTeamChartType === 'Notify') {
      CreateTransmittalStore.setNotifierUsersFromTeamChart(teamChartData);
    } else if (transmittalTeamChartType === 'Visible') {
      CreateTransmittalStore.setVisibilityUsersFromTeamChart(teamChartData);
    }
    setShowTeamChart(false);
    setTransmittalTeamChartType('');
  };

  if (!CreateTransmittalStore.createTransmittal) return <PageNotFound />;
  if (CreateTransmittalStore.isOpenFiles)
    return (
      <CreateTransmittalFile
        isOpenFiles={CreateTransmittalStore.isOpenFiles}
        setIsOpenFiles={(value) => CreateTransmittalStore.setIsOpenFiles(value)}
        onAddFilesToTransmittal={(selectedFiles) => {
          CreateTransmittalStore.setContentFiles(selectedFiles);
          CreateTransmittalStore.setIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
          selectedFiles.length
            ? LayoutStore.displayToast('success', 'Files added to Transmittal successfully.')
            : LayoutStore.displayToast(
                'error',
                'Files cannot be added to Transmittal at the moment. Please try again later.'
              );
          FilesStore.cleanup();
        }}
        onCancelAddFilesToTransmittal={() => {
          CreateTransmittalStore.setIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
          FilesStore.cleanup();
        }}
      />
    );

  return (
    <>
      <GoBackButton />
      <div className={Styles.heading}>
        <PageHeading>New Transmittal</PageHeading>
      </div>
      <CreateTransmittalActions />
      <div className={Styles.panel}>
        <div className={Styles.row}>
          <div className={Styles.userSelectorLabel}>
            <Icon className={Styles.labelIcon} name="notifications" />
            Notify <span className={Styles.requiredSymbol}>*</span>
          </div>
          <div className={Styles.userSelector}>
            <AddTransmittalMessageNotifySelector
              required
              selectedItems={CreateTransmittalStore.createTransmittal.notifyToUsers.map((user) => ({
                userId: user.userId,
                userName: user.name,
                userEmail: user.email,
                taskTeamId: user.taskTeamId,
                taskTeamTitle: user.taskTeamTitle,
                taskTeamCode: user.taskTeamCode,
                deliveryTeamId: user.deliveryTeamId,
                deliveryTeamTitle: user.deliveryTeamTitle,
                isExternal: user.isExternal,
              }))}
              getUsers={CreateTransmittalStore.getMatchedNotifiedUsers}
              onSelectedUserUpdated={CreateTransmittalStore.onSelectedNotifiedUsersUpdated}
              isMultiUser={true}
              disabled={false}
              searchPlaceholder={'Enter User Name'}
            />
          </div>
          <div className={Styles.teamChartBtnWrapper}>
            <SecondaryButton
              className={Styles.teamChartBtn}
              onClick={() => {
                handleShowTeamChart();
                setTransmittalTeamChartType('Notify');
              }}>
              Team Chart
            </SecondaryButton>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.userSelectorLabel}>
            <Icon className={Styles.labelIcon} name="visibility" />
            Visible to
          </div>
          <div className={Styles.userSelector}>
            <AddTransmittalMessageVisibilitySelector
              required
              selectedVisibleTos={{
                users: CreateTransmittalStore.createTransmittal.visibleToTaskTeamUsers.map((user) => ({
                  userId: user.userId,
                  userName: user.name,
                  userEmail: user.email,
                  taskTeamId: user.taskTeamId,
                  taskTeamTitle: user.taskTeamTitle,
                  taskTeamCode: user.taskTeamCode,
                  deliveryTeamId: user.deliveryTeamId,
                  deliveryTeamTitle: user.deliveryTeamTitle,
                  isExternal: user.isExternal,
                })),
                taskTeams: CreateTransmittalStore.createTransmittal.visibleToTaskTeams as ITaskTeam[],
                appointingParties: CreateTransmittalStore.createTransmittal
                  .visibleToAppointingParties as IAppointingParty[],
                deliveryTeams: CreateTransmittalStore.createTransmittal.visibleToDeliveryTeams as IDeliveryTeam[],
              }}
              getItems={CreateTransmittalStore.getMatchedVisibilityItems}
              onSelectedItemUpdated={CreateTransmittalStore.onSelectedVisibilityItemsUpdated}
              isMultiUser={true}
              disabled={false}
              searchPlaceholder={'Enter User Name'}
            />
          </div>
          <div className={Styles.teamChartBtnWrapper}>
            <SecondaryButton
              className={Styles.teamChartBtn}
              onClick={() => {
                handleShowTeamChart();
                setTransmittalTeamChartType('Visible');
              }}>
              Team Chart
            </SecondaryButton>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Type <span className={Styles.requiredSymbol}>*</span>
          </div>
          <ComboBox
            placeholder="Select a Transmittal Type"
            showIcon={false}
            selected={CreateTransmittalStore.createTransmittal.type.toString()}
            onSelect={(item) => {
              CreateTransmittalStore.handleTypeChange(Number(item?.id));
            }}
            onClear={CreateTransmittalStore.handleTypeChange}
            options={CreateTransmittalStore.typeItems}></ComboBox>
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Reason <span className={Styles.requiredSymbol}>*</span>
          </div>
          <ComboBox
            placeholder="Select a Reason for Issue"
            showIcon={false}
            options={CreateTransmittalStore.reasonItems}
            selected={CreateTransmittalStore.createTransmittal.reason.toString()}
            onSelect={(item) => {
              CreateTransmittalStore.handleReasonChange(Number(item?.id));
            }}
            onClear={CreateTransmittalStore.handleReasonChange}></ComboBox>
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>Due Date</div>
          <DateInput
            dates={{
              startDate: CreateTransmittalStore.createTransmittal.dueDate,
              endDate: null,
            }}
            onDateChange={CreateTransmittalStore.onDueDateChange}
            error={CreateTransmittalStore.dueDateErrorMessage}
            cssClass={Styles.date}
          />
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Subject <span className={Styles.requiredSymbol}>*</span>
          </div>
          <FormInput
            cssClass={Styles.formInput}
            value={CreateTransmittalStore.createTransmittal.subject}
            onChange={CreateTransmittalStore.onSubjectChange}
            error={CreateTransmittalStore.subjectErrorMessage}
          />
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Message <span className={Styles.requiredSymbol}>*</span>
          </div>
          <div className={Styles.textEditor}>
            <ReactQuill
              modules={quillModules}
              theme="snow"
              value={CreateTransmittalStore.createTransmittal.message}
              onChange={CreateTransmittalStore.onMessageChange}
              onBlur={CreateTransmittalStore.onEditorBlur}
            />
          </div>
        </div>
        <div className={Styles.row}>
          <CreateTransmittalContentFile
            contentFiles={CreateTransmittalStore.contentFiles}
            onRemoveContentFile={(id) => CreateTransmittalStore.removeContentFile(id)}
          />
        </div>
        <div className={Styles.row}>
          <TransmittalSupportingFiles
            openPanelIds={CreateTransmittalStore.openPanelIds}
            onPanelToggle={CreateTransmittalStore.panelToggle}
            supportingFiles={CreateTransmittalStore.getUploadSupportingFiles}
          />
        </div>
      </div>

      {CreateTransmittalStore.showUploadSupportFileModal && (
        <SupportingFilesUploadModal
          mode={SupportFileMode.CreateTransmittal}
          payload={CreateTransmittalStore.getCreateTransmittal}
          projectNumber={CreateTransmittalStore.getNavBarSelectorProjectNumber}
          closeModal={(uploadSucceeded, errorMessage) =>
            onCloseUploadFileModal(uploadSucceeded, errorMessage)
          }></SupportingFilesUploadModal>
      )}
      {CreateTransmittalStore.showUploadSupportFileErrorModal && (
        <ErrorModal
          title={'Upload Support File Error'}
          closeModal={() => CreateTransmittalStore.setShowUploadSupportFileErrorModal(false)}
          message={CreateTransmittalStore.uploadSupportFileErrorMessage}
        />
      )}

      {showTeamChart && CreateTransmittalStore.projectNumber && (
        <TeamChart
          closeModal={() => setShowTeamChart(false)}
          projectNumber={CreateTransmittalStore.projectNumber}
          onSelect={onTeamChartUserSelected}
          selectedUsers={CreateTransmittalStore.selectedUsersForTeamChart(transmittalTeamChartType)}
        />
      )}
    </>
  );
};

export default observer(CreateTransmittal);
